import { styled, InputBase, alpha } from "@mui/material";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1.6),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    border: '0.1px solid #384144',
    fontSize: 16,
    color: '#91A3A9',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    padding: '8px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },

    '&:disabled': {
      backgroundColor: '#F8FAFA',
      border: 'none',
    }
  },
}));

export default BootstrapInput;
