import { useEffect, useState } from "react";
import SurveysService from "../../../../services/api/surveysService";
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  CircularProgress,
  useTheme,
  Tooltip,
  Chip,
} from "@mui/material";
import { convertDateStringToLocal } from "../../../../helpers/date";
import { NotificationsTagTypeEnum, SurveyNotificationLog } from "../../../../types/Survey";
import TZPicker from "src/components/TZPicker/TZPicker";
import { PickersTimezone } from "@mui/x-date-pickers";
import { getNotificationTargetingTags } from "src/helpers/surveyHelper";
import { useAtom } from "jotai";
import { allAvailableUtmTagsAtom } from "src/atoms/utm-tags";
import { umtAllTagkey } from "src/constants/survey";
import { UTM_TARGETING_TAG_PREFIX } from "src/types/TargetingTag";

enum SurveyNotificationLogStatus {
  Scheduled = "Scheduled",
  Failed = "Failed",
  Complete = "Complete",
  PartiallyFailed = "PartiallyFailed",
  Cancelled = "Cancelled",
}

type Props = {
  surveyId?: string;
  targetingTags?: {
    _id: string;
    tag: string;
  }[];
};

const SurveyNotificationLogs = ({ surveyId, targetingTags }: Props) => {
  const theme = useTheme();
  const [allLogs, setAllLogs] = useState<SurveyNotificationLog[]>([]);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
  const [pickersTimezone, setPickersTimezone] = useState<PickersTimezone>("America/Los_Angeles");
  const [timezone, setTimeZone] = useState<PickersTimezone>("America/Los_Angeles");
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [allAvailableUtmTags] = useAtom(allAvailableUtmTagsAtom);
  const [totalPages, setTotalPages] = useState<number>();

  const tagId2Name = (targetingTags ?? [])
    .concat(allAvailableUtmTags ?? [])
    ?.reduce<Record<string, string>>((res, tag) => {
      res[tag._id] = tag.tag;
      return res;
    }, {});

  const fetchNotificationLogs = async (page: number) => {
    if (!surveyId) return;
    setIsInitialLoad(false);
    setLoading(true);
    const activities = await SurveysService.getSurveyNotificationLogs(surveyId, { page });
    setLoading(false);
    setAllLogs([...allLogs, ...activities.data.docs]);
    setTotalPages(activities.data.pages);
  };

  const loadMoreLogs = () => {
    const nextPage = page + 1;
    if (totalPages === undefined || nextPage <= totalPages) {
      fetchNotificationLogs(nextPage);
      setPage(nextPage);
    }
  };

  const loadMoreButtonDisabled = () => {
    return !isInitialLoad && (totalPages === undefined || totalPages <= page);
  };

  const getEmailSentTo = (log: SurveyNotificationLog) => {
    if (log.payload.singleEmailToNotify) {
      return (
        <Typography variant="body2" component="span">
          {log.payload.singleEmailToNotify}
        </Typography>
      );
    }

    if (log.payload.notificationsTagType === NotificationsTagTypeEnum.TAG) {
      const tags = getNotificationTargetingTags(log.payload).map((tag) =>
        tagId2Name[tag] ?? tag === umtAllTagkey ? `${UTM_TARGETING_TAG_PREFIX}${tag}` : tag
      );

      return (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {tags.map((tag, index) => (
            <Chip key={tag + index} label={tag} size="small" />
          ))}
        </Box>
      );
    }

    return (
      <Typography variant="body2" component="span">
        {log.payload.notificationsTagType}
      </Typography>
    );
  };

  useEffect(() => {
    if (pickersTimezone === "system") {
      setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    } else {
      setTimeZone(pickersTimezone);
    }
  }, [pickersTimezone]);

  return (
    <Stack spacing={3}>
      {!isInitialLoad ? (
        <>
          <Typography variant="h5" textAlign="center" color="primary" sx={{ fontWeight: 500, mb: 2 }}>
            Notification History
          </Typography>

          <Box sx={{ mx: "auto", mb: 2 }}>
            <TZPicker onTZChange={setPickersTimezone} />
          </Box>

          <TableContainer
            component={Paper}
            elevation={2}
            sx={{
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow sx={{ backgroundColor: theme.palette.grey[50] }}>
                  <TableCell sx={{ fontWeight: 600 }}>Email Sent Time</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Template Used</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Email Sent To</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allLogs.map((log) => (
                  <TableRow
                    key={log._id ?? log.createdAt}
                    sx={{
                      "&:nth-of-type(odd)": {
                        backgroundColor: theme.palette.grey[50],
                      },
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                      },
                    }}
                  >
                    <TableCell>
                      {convertDateStringToLocal(new Date(log.payload.scheduledAt || log.createdAt).toISOString(), {
                        timeZone: timezone,
                        month: "short",
                        year: "numeric",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{log.payload.emailTemplate.name}</Typography>
                    </TableCell>
                    <TableCell sx={{ minWidth: 200 }}>{getEmailSentTo(log)}</TableCell>
                    <TableCell>
                      <Tooltip title={log.status} arrow>
                        <Chip label={log.status} size="small" />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : null}

      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Button
            variant="contained"
            onClick={loadMoreLogs}
            disabled={loadMoreButtonDisabled()}
            sx={{
              px: 6,
              py: 1.5,
              textTransform: "none",
              borderRadius: 1,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
              "&:disabled": {
                backgroundColor: theme.palette.grey[300],
              },
            }}
          >
            {isInitialLoad ? "View Notification History" : "Load More"}
          </Button>
        )}
      </Box>
    </Stack>
  );
};

export default SurveyNotificationLogs;
