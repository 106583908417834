import {
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Box,
  Divider,
  Paper,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

interface User {
  email: string;
  valid: boolean;
}

const UsersBulkDeleteDialog = ({ onClose, onSubmit, open, setCSVFileParsed, CSVFileParsed }: any) => {
  const handleClose = () => {
    onClose();
  };

  const removeRow = (index: number) => {
    const newCSVFileParsed = [...CSVFileParsed];
    newCSVFileParsed.splice(index, 1);
    setCSVFileParsed(newCSVFileParsed);
    if (newCSVFileParsed.length === 0) {
      handleClose();
    }
  };

  const validUsers = CSVFileParsed.filter((user: User) => user.valid);
  const invalidUsers = CSVFileParsed.filter((user: User) => !user.valid);
  const handleSubmitValidOnly = async () => {
    setCSVFileParsed(validUsers);
  };

  const renderUserTable = (users: User[], title: string) => (
    <Box mb={4}>
      <Typography variant="h6" gutterBottom color="primary">
        {title}
      </Typography>
      <TableContainer component={Paper} elevation={2}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <TableRow key={`${user.email}:${index}`} hover>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <Typography variant="body1">{user.email}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" color={user.valid ? "success.main" : "error.main"} fontWeight="bold">
                    {user.valid ? "Valid" : "Invalid"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => removeRow(CSVFileParsed.indexOf(user))} color="error" size="small">
                    <DeleteOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          borderRadius: 4,
          maxWidth: "1100px",
          width: "950px",
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          p: 3,
        }}
      >
        Confirm User Deletion
        <Typography variant="body1" paragraph>
          Are you sure you want to delete these users? This action cannot be undone.
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        {validUsers.length > 0 && <Box sx={{ mb: 4 }}>{renderUserTable(validUsers, "Valid Users")}</Box>}

        {invalidUsers.length > 0 && <Box sx={{ mb: 4 }}>{renderUserTable(invalidUsers, "Invalid Users")}</Box>}

        <Divider sx={{ mb: 3 }} />

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          {invalidUsers.length > 0 ? (
            <Button variant="contained" onClick={handleSubmitValidOnly} disabled={!validUsers.length} color="primary">
              Proceed with Valid Users
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => onSubmit()}
              disabled={CSVFileParsed.length === 0}
              color="primary"
            >
              Submit All
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UsersBulkDeleteDialog;
