import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { faqs } from "./constants";
import "./Faq.scss";
import ContactUsButton from "../../../components/ContactUsButton/ContactUsButton";
import ContactUsDialog from "src/components/ContactUsDialog/ContactUsDialog";
import { useState } from "react";

function FAQPage() {
  const [contactUsDialogOpen, setContactUsDialogOpen] = useState(false);

  return (
    <div>
      <Typography variant="h6" align="center" sx={{ mb: 2 }}>
        Frequently Asked Questions
      </Typography>
      <Container sx={{ mb: 5, pb: 5 }}>
        {faqs.map((faq, index) => (
          <Accordion sx={{ mt: 1 }} key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`panel${index + 1}bh-header`}>
              <Typography variant="h6">{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
      <ContactUsButton onClick={() => setContactUsDialogOpen(true)} />
      <ContactUsDialog onClose={() => setContactUsDialogOpen(false)} open={contactUsDialogOpen} />
    </div>
  );
}

export default FAQPage;
