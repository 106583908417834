export enum InvitationCodeStatusEnum {
  Active = "Active",
  Claimed = "Invitation Claimed",
  Used = "Used",
}

export type InvitationCode = {
  _id: string;
  shortCode: string;
  activeDate?: Date;
  expireDate?: Date;
  invitationUsedBy?: string;
  discount: number;
  discountUsedBy?: string;
  status: InvitationCodeStatusEnum;
  createdAt: Date;
  updatedAt: Date;
  expDate: Date;
};
