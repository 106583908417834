import { useEffect, useState } from "react";
import { FormControlLabel, FormGroup, Grid, Paper, Switch, SxProps, Theme } from "@mui/material";

import "./SettingsTab.scss";
import SettingsService, { SettingType } from "src/services/api/settingsService";
import { Button } from "@mui/material";
import { settingKeys } from "src/types/Settings";
import ScheduledEmail from "./components/ScheduledEmail/ScheduledEmail";

const buttonStyle: SxProps<Theme> = {
  textTransform: "capitalize",
  backgroundColor: "#2EBDEE",
  border: "1px solid #2EBDEE",
  borderRadius: "4px",
  padding: "5px 60px",
  margin: 1,
  "&:hover": {
    background: "#2EBDEE",
    opacity: "0.7",
  },
};

const EmailSettings = () => {
  const [emailsEnabled, setEmailsEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    (async () => {
      const { data } = (await SettingsService.getSetting(settingKeys.EMAILS_ENABLED)) as { data: SettingType };
      setEmailsEnabled(data.payload?.value);
      setLoading(false);
    })();
  }, []);

  const handleEmailEnabledSubmit = () => {
    SettingsService.postSetting({
      key: settingKeys.EMAILS_ENABLED,
      payload: { value: emailsEnabled },
    });
  };

  return (
    <Paper
      sx={{
        margin: "20px 0 40px",
        padding: "70px",
        boxShadow: "3px 24px 20px rgba(0, 0, 0, 0.06)",
        borderRadius: "16px",
      }}
    >
      <Grid container direction="column">
        <Grid item margin={1}>
          <FormGroup>
            <FormControlLabel
              label="Email Notifications"
              control={
                <Switch
                  checked={emailsEnabled ?? false}
                  color="primary"
                  style={{ transition: loading ? "none" : undefined }}
                  onChange={() => setEmailsEnabled((enabled) => !enabled)}
                />
              }
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <Button sx={buttonStyle} variant="contained" color="primary" onClick={handleEmailEnabledSubmit}>
            Confirm Change
          </Button>
        </Grid>
        <Grid item>
          <ScheduledEmail />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EmailSettings;
