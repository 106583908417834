import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { PickersTimezone } from "@mui/x-date-pickers/models";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Los_Angeles");

interface Props {
  value: Date | null;
  setValue: (date: Date | null) => void;
  currentTimezone: PickersTimezone;
  minDate?: Date;
  disabled?: boolean;
  minTime?: Date;
  label?: string;
}

export default function CustomDateTimePicker({
  label,
  value,
  minTime,
  setValue,
  currentTimezone,
  minDate,
  disabled,
}: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        minTime={minTime ? dayjs(minTime) : undefined}
        disabled={disabled}
        minDate={minDate ? dayjs(minDate) : undefined}
        timezone={currentTimezone}
        value={value ? dayjs(value) : null}
        onChange={(d) => setValue(d ? new Date(d.toISOString()) : null)}
        label={label}
      />
    </LocalizationProvider>
  );
}
