import { CSSProperties } from "@material-ui/core/styles/withStyles";

export const paperStyle: CSSProperties = {
  padding: "55px 65px 110px",
  width: "100%",
  borderRadius: "20px",
};

export const avatarStyle: CSSProperties = { backgroundColor: "#1bbd7e" };

export const btnStyle: CSSProperties = {
  textTransform: "none",
  margin: "10px 0",
  backgroundColor: "#2EBDEE",
  width: "100%",
  borderRadius: "4px",
  border: 0,
  padding: "14px",
  color: "white",
};

export const btnStyleDisabled: CSSProperties = {
  ...btnStyle,
  backgroundColor: "rgb(179 236 255)",
};

export const TypographyStyle: CSSProperties = {
  marginTop: "35px",
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
};

export const inputStyle: CSSProperties = {
  marginTop: "0px",
  height: "40px",
  width: "100%",
  borderRadius: "8px",
  padding: "10px, 10px, 10px, 15px",
  minWidth: "270px",
};

export const phoneButtonStyle: CSSProperties = {
  backgroundColor: "white",
  borderRadius: "8px 0 0 8px",
};

export const forgotPasswordStyle: CSSProperties = {
  fontFamily: "system-ui",
  color: "#2EBDEE",
  fontSize: "14px",
  fontWeight: 700,
  LineHeight: "20px",
  alignItems: "center",
  display: "flex",
  textDecoration: "none",
};

export const checkBoxStyle: CSSProperties = {
  padding: "10px",
  width: "20px",
  color: "#91A3A9",
};

export const rememberStyle: CSSProperties = {
  color: "#91A3A9",
  fontSize: "14px",
  fontWeight: 400,
  LineHeight: "16px",
  fontFamily: "system-ui",
};

export const labelStyle: CSSProperties = {
  display: "flex",
  marginTop: "25px",
  width: "100%",
  justifyContent: "space-between",
  flexWrap: "wrap" as const,
  rowGap: "10px",
};

export const pStyle: CSSProperties = {
  fontFamily: "system-ui",
  fontSize: "14px",
  lineHeight: "16px",
  fontWeight: 700,
  color: "#384144",
  marginTop: "24px",
  marginBottom: "8px",
};

export const h3Style: CSSProperties = {
  fontWeight: 700,
  fontSize: "20px",
  color: "#1E415F",
};

export const logoStyle: CSSProperties = {
  marginTop: "0px",
  marginBottom: "70px",
  display: "flex",
  justifyContent: "center",
};
export const forgotPaswordTitleContainerStyle: CSSProperties = {
  marginTop: "0px",
  display: "flex",
  justifyContent: "center",
};
export const forgotPaswordSubtitleContainerStyle: CSSProperties = {
  marginBottom: "70px",
  display: "flex",
  justifyContent: "center",
};
export const forgotPaswordSubtitleStyle: CSSProperties = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  textAlign: "center",
  color: "#91A3A9",
};
export const passwordRecoverySubtitleStyle: CSSProperties = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#91A3A9",
};
export const pleseEnterNewPasswordStyle: CSSProperties = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#91A3A9",
  marginTop: "30px",
  marginBottom: "0px",
};

export const backgroundStyle: CSSProperties = {
  height: "100%",
  minHeight: "100vh",
  margin: "0",
  display: "flex",
};

export const backgroundStyleCenter: CSSProperties = {
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
};

export const alertStyle: CSSProperties = {
  border: "aliceblue",
  color: "red",
  fontSize: "small",
  padding: "5px 0",
};

export const checkListStyle: CSSProperties = {
  margin: "20px 0",
};

export const checkListItemStyle: CSSProperties = {
  fontSize: "14px",
  lineHeight: "16px",
  fontWeight: 700,
  color: "#bac6ca",
  display: "flex",
  alignItems: "center",
  columnGap: "8px",
};

export const plabelStyle: CSSProperties = {
  fontFamily: "system-ui",
  fontWeight: 700,
  fontStyle: "normal",
  fontSize: "12px",
  lineHeight: "16px",
  marginBottom: "4px",
  marginTop: "30px",
};

export const phoneErrorStyle: CSSProperties = {
  fontFamily: "system-ui",
  fontWeight: 700,
  fontStyle: "normal",
  fontSize: "12px",
  lineHeight: "16px",
  marginBottom: "4px",
  marginTop: "10px",
  color: "red",
};
export const passwordSubtitleStyle: CSSProperties = {
  fontFamily: "system-ui",
  fontWeight: 700,
  fontStyle: "normal",
  fontSize: "12px",
  lineHeight: "16px",
  marginBottom: "4px",
  marginTop: "16px",
};

export const h1Style: CSSProperties = {
  fontFamily: "system-ui",
  fontWeight: 700,
  fontStyle: "normal",
  fontSize: "27px",
  lineHeight: "32px",
  marginBottom: "4px",
  marginTop: "60px",
  color: "#384144",
};

export const containerStyle: CSSProperties = {
  flex: "1 1 auto",
  display: "flex",
  minHeight: "100vh",
};

export const rowStyle: CSSProperties = {
  display: "flex",
  flexWrap: "wrap" as const,
  alignContent: "center",
  justifyContent: "space-between",
  columnGap: "24px",
  width: "100%",
};

export const columnStyle: CSSProperties = {
  flex: "1 1 0%",
};

export const hiddenStyle: CSSProperties = {
  display: "none",
};

export const notice: CSSProperties = {
  margin: "10px auto",
  textAlign: "center",
  width: "100%",
};

export const mfaBlockStyle: CSSProperties = {
  display: "flex",
  alignItems: "start",
  marginTop: "24px",
};

export const mfaCheckboxStyle: CSSProperties = {
  margin: 0,
  padding: 0,
};

export const mfaTextStyle: CSSProperties = {
  marginTop: "5px",
  marginLeft: "12px",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#384144",
};

export const mfaAdditionalInfo: CSSProperties = {
  textAlign: "justify",
  fontSize: "14px",
  lineHeight: "16px",
  color: "#384144",
};
