import React from "react";

import Home from "../screens/landing-page/Home/Home";
import PrivacyPolicy from "../screens/landing-page/PrivacyPolicy/PrivacyPolicy";
import SignIn from "../screens/auth/SignIn";
import SignUp from "../screens/auth/SignUp";
import CheckPassword from "../screens/auth/CheckPassword";
import VerifyEmail from "../screens/auth/VerifyEmail";
import ForgotPasswordEmail from "../screens/auth/ForgotPasswordEmail";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import VerifyAccountEmail from "../screens/auth/VerifyAccountEmail";
import VerifyAccountPhone from "../screens/auth/VerifyAccountPhone";
import SelectTopics from "../screens/auth/SelectTopics";
import { PasswordType } from "src/interfaces/IPassType";
import { backgroundStyle } from "src/screens/auth/styles";
import backgroundImage from "src/assets/sign-in-background-logo.svg";
import SetAdditionalAttributes from "../screens/auth/SetAdditionalAttributes";
import ResearcherSignup from "../screens/auth/ResearcherSignup";

const AuthStackNavigation: React.FC = () => {
  if (window.location.pathname !== "/" || window.location.search) {
    sessionStorage.setItem("redirectUrl", window.location.pathname + window.location.search);
  }

  return (
    <div
      style={{
        ...backgroundStyle,
        backgroundImage: `linear-gradient(150.73deg, #FFFFFF 13.82%, #E8FAFF 77.36%)`,
      }}
    >
      <div
        style={{
          ...backgroundStyle,
          display: "block",
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100%",
        }}
      >
        <Router>
          <Routes>
            <Route path="/" element={<SignIn />}>
              <Route index element={<Home />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
            </Route>
            <Route path="/login" element={<SignIn />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/register/select-password" element={<CheckPassword type={PasswordType.SIGNUP} />} />
            <Route path="/register/additional-attributes" element={<SetAdditionalAttributes />} />
            <Route path="/register/verify-email" element={<VerifyAccountEmail />} />
            <Route path="/register/verify-phone" element={<VerifyAccountPhone />} />
            <Route path="/register/select-topics" element={<SelectTopics />} />
            <Route path="/forgot-password" element={<ForgotPasswordEmail />} />
            <Route path="/recovery/select-password" element={<CheckPassword type={PasswordType.RECOVERY} />} />
            <Route path="/send-otp" element={<VerifyEmail />} />
            <Route path="/register/researcher" element={<ResearcherSignup />}></Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
};

export default AuthStackNavigation;
