import { useCallback, useEffect, useState } from "react";
import ProgressDialog, { ProgressBarType } from "../../../components/Progress/ProgressDialog";
import SurveysService from "../../../services/api/surveysService";
import { sleep } from "../../../helpers/generic";

type SurveysSyncProgressProps = {
  onSyncSurveys: any;
};

const POLLING_INTERVAL = 2000;

const SurveysSyncProgress = ({ onSyncSurveys }: SurveysSyncProgressProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const updateStatus = useCallback(async () => {
    const response = await SurveysService.getSurveysSyncStatus();

    return !!(response.data && response.data.syncRunning);
  }, []);

  const startPolling = async () => {
    let polling = true;
    while (polling) {
      const status = await updateStatus();

      setOpen(status);
      polling = status;

      await sleep(POLLING_INTERVAL);
    }
  };

  useEffect(() => {
    startPolling();
  }, [onSyncSurveys, updateStatus]);

  return (
    <ProgressDialog
      type={ProgressBarType.INFINITE}
      open={open}
      onClose={() => setOpen(false)}
      title="Syncing surveys"
    />
  );
};

export default SurveysSyncProgress;
