import { Box, Button } from "@mui/material";
import "./Home.scss";
import laTimes from "src/assets/landing-page/la-times.png";
import times from "src/assets/landing-page/time.png";
import realClearPolitics from "src/assets/landing-page/rcp.png";
import forbes from "src/assets/landing-page/forbes.png";
import theHill from "src/assets/landing-page/the-hill.png";
import economist from "src/assets/landing-page/the-economist.png";
import megaPhone from "src/assets/landing-page/mega-phone.svg";
import pillars from "src/assets/landing-page/pillars.svg";
import trendingUp from "src/assets/landing-page/trending-up.svg";
import economy from "src/assets/landing-page/economy.svg";
import chat from "src/assets/landing-page/chat.svg";
import gift from "src/assets/landing-page/gift.svg";
import star from "src/assets/landing-page/star.svg";
import oldWayImage from "src/assets/landing-page/old-way.png";
import { getBaseUrl } from "src/helpers/urlHelper"

const Home = () => {
  return (
    <Box id="landing-page-home-wrapper">
      <Box className="section call-to-action">
        <Box>
          <span className="text gray-text">VERASIGHT COMMUNITY</span>
          <h1 className="title">
            Let us <span className="dark-title">Venmo</span> you
            <br />
            for <span className="underline">taking short</span>
            <br />
            <span className="underline">surveys</span>
          </h1>
          <span className="text dark-gray-text">Your opinions are valuable, so it's time you are paid for them</span>
          <Box sx={{ marginTop: "20px" }}>
            <Button href={`${getBaseUrl()}/register`} disableElevation variant="contained">
              Join Community
            </Button>
            <Button href={`${getBaseUrl()}/login`} variant="outlined" sx={{ marginLeft: "20px" }}>
              Login
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        className="section old-way"
        sx={{
          display: "flex",
        }}
      >
        <Box className="landing-banner-box">
          <img src={oldWayImage} alt="Girls Sharing" />
        </Box>
      </Box>
      <Box className="section opinions">
        <h1 className="section-title center">
          How your opinions will
          <br />
          <span className="underline">shape the future</span>
        </h1>
        <Box className="opinions-list">
          <Box className="info-box" sx={{ backgroundColor: "rgba(46,189,238,0.1)" }}>
            <Box className="info-img-wrapper" sx={{ backgroundColor: "rgba(46,189,238,0.1)" }}>
              <img src={megaPhone} alt="Mega Phone" />
            </Box>
            <p>
              Make sure the media <span className="bold">hear</span>
              <br />
              <span className="bold">what you think</span>
            </p>
          </Box>

          <Box className="info-box" sx={{ backgroundColor: "rgba(169,144,246,0.1)" }}>
            <Box className="info-img-wrapper" sx={{ backgroundColor: "rgba(169,144,246,0.1)" }}>
              <img src={pillars} alt="Pillars" />
            </Box>
            <p>
              <span className="bold">Help your government officials</span>
              <br />
              design policies
            </p>
          </Box>

          <Box className="info-box" sx={{ backgroundColor: "rgba(55,153,182,0.1)" }}>
            <Box className="info-img-wrapper" sx={{ backgroundColor: "rgba(55,153,182,0.1)" }}>
              <img src={trendingUp} alt="Trending Up" />
            </Box>
            <p>
              Give brands input to make
              <br />
              new and <span className="bold">improved products</span>
            </p>
          </Box>
        </Box>
      </Box>
      <Box className="section community">
        <Box className="community-box">
          <Box sx={{ display: "flex", flexGrow: "1", justifyContent: "center" }}>
            <h1 className="section-title">
              Benefits of the
              <br />
              Verasight
              <br />
              Community
            </h1>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: "1",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <Box>
                <div className="community-icon-wrapper">
                  <img src={economy} alt="Economy Icon" />
                </div>
                <p>
                  Get compensated for
                  <br />
                  every survey that you take
                </p>
              </Box>
              <Box>
                <div className="community-icon-wrapper">
                  <img src={chat} alt="Chat Icon" />
                </div>
                <p>
                  Opportunities to shape
                  <br />
                  the national conversation
                </p>
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <Box>
                <div className="community-icon-wrapper">
                  <img src={gift} alt="Gift Icon" />
                </div>
                <p>
                  Frequent giveaways and
                  <br />
                  exclusive prizes for
                  <br />
                  community members
                </p>
              </Box>
              <Box>
                <div className="community-icon-wrapper">
                  <img src={star} alt="Star Icon" />
                </div>
                <p>
                  Prediction contests -
                  <br />
                  earn extra rewards when
                  <br />
                  your predictions are accurate
                </p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="section featured">
        <h1 className="section-title center">
          Where our community's
          <br />
          data has been featured
        </h1>
        <Box className="featured-list">
          <img src={laTimes} alt="Los Angeles Times" />
          <img src={times} alt="The Times" />
          <img src={realClearPolitics} alt="Real Clear Politics" />
          <img src={forbes} alt="Forbes" />
          <img src={theHill} alt="The Hill" />
          <img src={economist} alt="The Economist" />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
