import { Box, Container, Typography } from "@mui/material";
import VeraLink from "../../../components/VeraLink";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import SurveysService from "../../../services/api/surveysService";
import { useNavigate } from "react-router-dom";
import { SurveyLimits, SurveyType } from "../../../types/Survey";
import SurveyCost from "src/components/SurveyCost/SurveyCost";
import { SearchParams, AppPaths } from "src/constants/url";
import { getErrorMessage } from "src/helpers/errors";
import { isSurvey2CreateValid } from "src/helpers/surveyHelper";
import { apiURL } from "src/services/api/apiService";
import IntegrationGuideLink from "./IntegrationGuideLink";

export default function ResearcherCreateSurvey() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [limits, setLimits] = useState<SurveyLimits>({
    matrixQuestions: 0,
    multipleChoiceQuestions: 0,
    openEndedQuestions: 0,
    sampleSize: 0,
  });

  const isSurveyValid = isSurvey2CreateValid({ title: "valid", limits });

  const createSurvey = async () => {
    setIsLoading(true);
    let params: Partial<SurveyType> = {
      externalId: uuidv4(),
      limits,
      externalSurveyRedirectLinksData: {
        baseUrl: `${apiURL}/surveys/redirect`,
        parameters: [],
      },
    };

    try {
      const survey = await SurveysService.createSurvey(params);
      const surveyId = survey.data._id;

      navigate(
        AppPaths.researcher.surveys.edit(surveyId, { [SearchParams.clientSecret]: survey.data.paymentSessionId }),
        {
          replace: true,
        }
      );
    } catch (error: any) {
      enqueueSnackbar(getErrorMessage(error), { variant: "error" });
    }

    setIsLoading(false);
    setTimeout(() => {
      closeSnackbar();
    }, 5000);
  };

  return (
    <Container
      sx={{
        marginTop: "60px",
      }}
      data-testid="researcher-create-survey"
    >
      <Box>
        <Typography variant="h6">
          <VeraLink to="/researcher/surveys">Back</VeraLink>
        </Typography>
      </Box>
      <Box sx={{ marginTop: "1rem" }}>
        <IntegrationGuideLink />
      </Box>
      <Box pt={5} sx={{ marginBottom: "2rem" }}>
        <SurveyCost setLimits={setLimits}>
          <Box display="flex" alignItems="flex-end" pt={5}>
            <button
              disabled={isLoading || !isSurveyValid}
              type="button"
              onClick={() => createSurvey()}
              className="last-container__button"
              data-testid="btn-submit"
              style={{ marginLeft: "auto" }}
            >
              {isLoading ? "Loading..." : "Continue to payment"}
            </button>
          </Box>
        </SurveyCost>
      </Box>
    </Container>
  );
}
