import React from "react";
import { Alert, Box, Button, Dialog, Paper, Stack, TextField } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import UserService from "../../../services/api/usersService";
import ChangePasswordForm from "../../../components/ChangePasswordForm/ChangePasswordForm";
import { useValidEmail } from "../../../hooks/useValidEmail";
import { useValidPhone } from "../../../hooks/useValidPhone";

import "./SettingsTab.scss";
import { AuthContext } from "../../../contexts/authContext";

function ChangePasswordDialog(props: any) {
  const { onClose, open } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <div className="change-password-dialog">
        <h1 className="title">Change Password</h1>
        <ChangePasswordForm isDialog hasCancelOption onCancel={onClose} />
      </div>
    </Dialog>
  );
}

const AccountAndSecurity = () => {
  const [userData, setUserData] = React.useState<any>(null);
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");
  const { email, setEmail } = useValidEmail("");
  const { phone, setPhone, phoneError, parsedPhone } = useValidPhone("");
  const [allFieldsValid, setAllFieldsValid] = React.useState(false);
  const { user, refreshUser } = React.useContext(AuthContext);
  const [needToRefresh,setNeedToRefresh] = React.useState<boolean>(false);


  const [image, setImage] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!user) {
      setNeedToRefresh(true)
      setLoading(true);
    } else {
      setUserData(user);
      user.firstName && setFirstName(user.firstName);
      user.lastName && setLastName(user.lastName);
      user.email && setEmail(user.email);
      user.phone && setPhone(user.phone);
      user.image && setImage(user.image);
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    if (refreshUser == null || !needToRefresh) {
      return;
    }
    refreshUser(false);
    setLoading(false);
    setNeedToRefresh(false)
  }, [refreshUser,needToRefresh]);


  React.useEffect(() => {

    setAllFieldsValid(
      !!firstName &&
      !!lastName &&
      phoneError == null
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email,firstName, lastName, parsedPhone]);

  const onImageChange = (event: any) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoading(true);
    setImage(URL.createObjectURL(file));
    let formData = new FormData();
    formData.append("image", file);
    UserService.updateUserData(formData).then((res) => {
      setLoading(false);
      setNeedToRefresh(true);
      setAlertMessage("Profile photo successfully updated!");
    });
  };

  const saveChanges = () => {
    setLoading(true);
    let params = new FormData();
    if (firstName !== userData.firstName) params.append("firstName", firstName);
    if (lastName !== userData.lastName) params.append("lastName", lastName);
    if (email !== userData.email) params.append("email", email as string);
    if (phone !== userData.phone) params.append("phone", parsedPhone);

    if (Array.from(params.entries()).length) {
      UserService.updateUserData(params).then((res) => {
        setLoading(false);
        setNeedToRefresh(true);
        setAlertMessage("Account information successfully updated!");
      });
    } else {
      setLoading(false);
    }
  };

  return (
    <Paper
      className="account-and-security-container"
      sx={{
        margin: "20px 0 40px",
        padding: "70px",
        boxShadow: "3px 24px 20px rgba(0, 0, 0, 0.06)",
        borderRadius: "16px",
      }}
    >
      {alertMessage ? (
        <Stack sx={{ width: "100%", margin: "0 0 20px" }}>
          <Alert variant="filled" severity="success" onClose={() => setAlertMessage("")}>
            {alertMessage}
          </Alert>
        </Stack>
      ) : null}

      <Box className="user-profile-image-wrapper">
        {image ? (
          <div className="image-wrapper">
            <img src={image} alt="survey-cover" />
          </div>
        ) : (
          <div className="image-wrapper default-image">
            <PersonOutlineOutlinedIcon />
          </div>
        )}

        <input type="file" accept="image/png, image/jpeg, image/jpg" id="change-image-button" onChange={onImageChange} style={{ display: "none" }} />

        <label htmlFor="change-image-button" className="change-image-label">
          Change Photo
        </label>
      </Box>

      <Box className="row">
        <TextField
          label="First Name"
          variant="outlined"
          size="small"
          value={firstName}
          inputProps={{ maxLength: 50 }}
          onChange={(event) => setFirstName(event.target.value)}
        />
        <TextField
          label="Last Name"
          variant="outlined"
          size="small"
          value={lastName}
          inputProps={{ maxLength: 50 }}
          onChange={(event) => setLastName(event.target.value)}
        />
      </Box>

      <Box className="row">
        <TextField
          label="Email"
          variant="outlined"
          type="email"
          size="small"
          value={email}
          // error={emailError != null}
          disabled
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          label="Phone"
          variant="outlined"
          size="small"
          value={phone}
          error={phoneError != null}
          onChange={(event) => setPhone(event.target.value)}
        />
      </Box>

      <p className="change-password-link" onClick={() => setOpenDialog(true)}>
        Change Password
      </p>

      <Button
        variant="contained"
        onClick={() => saveChanges()}
        disabled={loading || !allFieldsValid}
        sx={{
          textTransform: "capitalize",
          backgroundColor: "#2EBDEE",
          border: "1px solid #2EBDEE",
          borderRadius: "4px",
          padding: "5px 60px",
          marginTop: "40px",
          "&:hover": {
            background: "#2EBDEE",
            opacity: "0.7",
          },
        }}
      >
        Save
      </Button>

      <ChangePasswordDialog open={openDialog} onClose={() => setOpenDialog(false)} />
    </Paper>
  );
};

export default AccountAndSecurity;
