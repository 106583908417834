import { IAuthFunctionalities, MFAType, SocialMedia } from "../interfaces/IAuthFunctionalities";

export const initGlobalConfig = (config: IAuthFunctionalities | undefined) => {
  let initialize: IAuthFunctionalities = {
    RememberDevice: false,
    MFA: MFAType.SMS,
    RegisterAuxiliaryParams: [],
    SocialMedia: [SocialMedia.Facebook],
    ForgotPassword: true,
    LoginType: "BASIC",
  };
  if (config)
    initialize = {
      ...initialize,
      ...config,
      //RegisterAuxiliaryParams: config.RegisterAuxiliaryParams ? config.RegisterAuxiliaryParams : []
    };

  return initialize;
};
