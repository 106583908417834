import { useState, useEffect } from "react";

import * as yup from "yup";

export const useValidEmail = (initialValue: string | null) => {
  const [email, setEmail] = useState(initialValue);
  const [emailError, setEmailError] = useState<string | null>(null);

  useEffect(() => {
    const emailSchema = yup.object().shape({
      email: yup.string().email().required(),
    });

    const isValid = emailSchema.isValidSync({ email });
    setEmailError(isValid ? null : "Email address is not valid.");
  }, [email]);

  return { email, setEmail, emailError };
};
