import { Link } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ActivationRedirect } from "src/types/Survey";

interface Props {
  activationRedirect?: ActivationRedirect;
}
export default function RedirectVerification({ activationRedirect }: Readonly<Props>) {
  if (!activationRedirect) {
    return null;
  }
  if (activationRedirect?.activationLink) {
    return (
      <Link underline="hover" target="_self" rel="noopener noreferrer" href={activationRedirect?.activationLink}>
        Complete to activate
      </Link>
    );
  }
  return <CheckCircleIcon sx={{ color: "green", marginLeft: "5px" }} />;
}
