import { FC, useContext, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

import "../../styles/main.scss";
import { Box } from "@material-ui/core";
import { AuthContext } from "src/contexts/authContext";
import { UserRoles } from "../../types/User";
import { useMobileSize } from "src/hooks/useMobileSize";
import ResearcherNavMenu from "src/components/ResearcherNavMenu/ResearcherNavMenu";

const ResearcherLayout: FC = () => {
  const { user, refreshUser } = useContext(AuthContext);
  const { isProfileUrl, setIsProfileUrl } = useMobileSize();

  useEffect(() => {
    if (refreshUser == null || user) {
      return;
    }
    refreshUser(false);
  }, [refreshUser, user]);

  if (user == null) {
    return null;
  }

  if (user.role !== UserRoles.RESEARCHER) {
    return <Navigate to={"/"} replace />;
  }

  return (
    <Box sx={{ minHeight: "100vh", width: "100vw" }}>
      <ResearcherNavMenu />
      <Outlet context={{ isProfileUrl, setIsProfileUrl }} />
    </Box>
  );
};

export default ResearcherLayout;
