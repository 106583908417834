import { FC } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Box, Button, ButtonProps, CardMedia, Grid, LinearProgress, Typography } from "@mui/material";
import paypalLogo from "../../../../assets/paypal.svg";
import giftCardLogo from "../../../../assets/gift-card.svg";
import venmoLogo from "../../../../assets/venmo.svg";
import pointsIcon from "../../../../assets/icon-points.svg";
import { styled } from "@mui/material/styles";
import { CashoutVendorEnum } from "../../../../types/generic";

type RewardsCardProps = {
  vendor: CashoutVendorEnum;
  userPointsBalance: number;
  pointsValue: number;
  dollarValue: number;
  onRedeemPointsClicked?: (
    points: number,
    dollars: number,
    vendor: CashoutVendorEnum,
    method: "phone" | "email"
  ) => void;
};

const logos = {
  paypal: paypalLogo,
  venmo: venmoLogo,
  giftCard: giftCardLogo,
};

const _Button = (props: ButtonProps) => (
  <Button {...props} variant="outlined">
    {props.children}
  </Button>
);

const RedeemButton = styled(_Button)(({ theme }) => ({
  borderRadius: +theme.shape.borderRadius * 2,
  width: "100%",
  textTransform: "none",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  ":hover": {
    backgroundColor: theme.palette.primary.light,
  },
  ":disabled": {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.grey[50],
    borderWidth: 0,
  },
}));

const RewardsCard: FC<RewardsCardProps> = (props) => {
  const { vendor: type, userPointsBalance, pointsValue, dollarValue, onRedeemPointsClicked } = props;
  const image = logos[type];
  const imageHeight = type === CashoutVendorEnum.GIFT_CARD ? 77 : 106;
  const cardText = `$${dollarValue.toLocaleString("en-US", { useGrouping: true })} gift card`;
  const cardPoints = pointsValue.toLocaleString("en", { useGrouping: true });

  const hasEnoughPoints = userPointsBalance >= pointsValue;

  const method = type === "paypal" ? "email" : "phone";

  const redeemButtonOrProgressBar = hasEnoughPoints ? (
    <RedeemButton
      sx={{ height: "100%" }}
      onClick={() => onRedeemPointsClicked?.(pointsValue, dollarValue, type, method)}
      data-testid="card-button"
    >
      {type === CashoutVendorEnum.GIFT_CARD ? "Select" : "Redeem now"}
    </RedeemButton>
  ) : (
    <Grid container direction="column" spacing={0.3} data-testid="progress-bar">
      <Grid item xs={4}>
        <Typography variant="body2">Need more points to redeem</Typography>
      </Grid>
      <Grid item xs={4}>
        <LinearProgress
          variant="determinate"
          value={(100 * userPointsBalance) / pointsValue}
          color="inherit"
          sx={{ borderRadius: 4 }}
        />
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2">{`${userPointsBalance} / ${pointsValue}`}</Typography>
      </Grid>
    </Grid>
  );
  return (
    <Card
      className="rewards-card"
      data-testid="rewards-card"
      elevation={0}
      sx={{
        width: 270,
        height: 252,
        p: 3,
        borderRadius: 4,
        padding: 0,
        boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box sx={{ padding: 2 }}>
        <CardMedia component="img" image={image} height={imageHeight} sx={{ objectFit: "contain" }} />
        <CardContent
          sx={{
            color: (theme) => theme.palette.grey[500],
            p: 0,
            pt: 2,
            ":last-child": { paddingBottom: 0 },
          }}
        >
          <Grid container direction="column" alignItems="center" spacing={1}>
            <Grid width="100%" item sx={{ textAlign: "center" }}>
              {type === CashoutVendorEnum.GIFT_CARD ? (
                <Typography
                  fontSize={19}
                  fontWeight={700}
                  sx={{ color: (theme) => theme.palette.grey[800] }}
                  data-testid="card-description"
                >
                  Gift Card Rewards
                </Typography>
              ) : (
                <></>
              )}
              <Typography
                fontSize={14}
                sx={{ color: (theme) => theme.palette.grey[600] }}
                data-testid="card-description"
              >
                {cardText}
              </Typography>
              <Grid
                container
                item
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="foobar"
                width="100%"
                sx={{ color: (theme) => theme.palette.grey[800] }}
              >
                <Grid item>
                  <img height="60%" width="60%" src={pointsIcon} alt="Points Icon" />
                </Grid>
                <Grid item>
                  <Typography fontSize={18} fontWeight={700} data-testid="card-points">
                    {cardPoints}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ width: "100%" }}>
              {redeemButtonOrProgressBar}
            </Grid>
          </Grid>
        </CardContent>
      </Box>
    </Card>
  );
};

export default RewardsCard;
