import { isLocal } from "../helpers/generic";

declare global {
  interface Window {
    TiktokAnalyticsObject: string;
    [key: string]: any;
  }
}

export interface TiktokPixelSetTrackingArgs<L = null> {
  tracking: string;
  instance?: string;
  data?: L;
}

export interface TiktokPixelTTQUtils<O = any, Z = null> {
  methods: string[];
  setAndDefer: (t: any, e: string | number) => void;
  instance: (t: string | number) => any[];
  load: (e: string, n?: {}) => void;
  _i: any;
  _u: string;
  _t: any;
  _o: any;
  track: (tracking: string, data?: O) => void;
  page: () => void;
  identify: (id: string, data?: O) => void;
  instances: () => any[];
  debug: (debug: boolean) => void;
  on: (event: string, callback: () => void) => void;
  off: (event: string, callback: () => void) => void;
  once: (event: string, callback: () => void) => void;
  ready: (callback: () => void) => void;
  alias: (alias: string, original: string) => void;
  group: (groupId: string, traits?: Z) => void;
  enableCookie: () => void;
  disableCookie: () => void;
  [key: string]: any;
}

export class TiktokPixelUtils {
  public cliendId: string | null;
  public pixel_path: string;
  constructor() {
    this.cliendId = null;
    this.pixel_path = "ttq";
  }

  public setClientId(clientId: string): void {
    clientId && (this.cliendId = clientId);
  }

  public setPixelTag(path: string): void {
    path && (this.pixel_path = path);
  }

  public init(id?: string): void {
    try {
      if (id) {
        this.cliendId = id;
      }

      if (!this.cliendId) {
        throw new Error("Tiktok Pixel Client Id is not defined");
      }

      const isInitialized = typeof window !== "undefined" && !!(window as any).ttq;
      if (isInitialized) {
        return;
      }

      window.TiktokAnalyticsObject = this.pixel_path;
      const ttq = (window[this.pixel_path] = window[this.pixel_path] || []);

      if (Array.isArray(ttq) && ttq.length) {
        console.warn("2: Tiktok Pixel is already initialized. Please check your code.");
      }

      ttq.methods = [
        "page",
        "track",
        "identify",
        "instances",
        "debug",
        "on",
        "off",
        "once",
        "ready",
        "alias",
        "group",
        "enableCookie",
        "disableCookie",
      ];

      ttq.setAndDefer = function (t: TiktokPixelTTQUtils, e: string | number) {
        t[e] = function () {
          t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      };

      for (let i = 0; i < ttq.methods.length; i++) {
        ttq.setAndDefer(ttq, ttq.methods[i]);
      }

      ttq.instance = function (t: string | number) {
        const e = ttq._i[t] || [];
        for (let n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
        return e;
      };

      ttq.load = function (e: string, n?: {}) {
        let i = "https://analytics.tiktok.com/i18n/pixel/events.js";
        ttq._i = ttq._i || {};
        ttq._i[e] = [];
        ttq._i[e]._u = i;
        ttq._t = ttq._t || {};
        ttq._t[e] = +new Date();
        ttq._o = ttq._o || {};
        ttq._o[e] = n || {};
        let o = document.createElement("script");
        o.type = "text/javascript";
        o.async = !0;
        o.src = i + "?sdkid=" + e + "&lib=" + this.pixel_path;
        let a = document.getElementsByTagName("script")[0];
        a.parentNode && a.parentNode.insertBefore(o, a);
      };
      ttq.load(this.cliendId);
      ttq.page();
    } catch (error) {
      console.error(error);
    }
  }

  public setTracking<K = null>(args: TiktokPixelSetTrackingArgs<K>): boolean {
    try {
      if (!this.cliendId) {
        throw new Error("Tiktok Pixel Client Id is not defined");
      }

      let tagN = this.pixel_path;

      if (!window[tagN]) {
        throw new Error("Tiktok Pixel is not initialized");
      }

      if (!args.tracking) {
        throw new Error("Tiktok Pixel Tracking is not defined");
      }

      let instance = args.instance ? window[tagN].instance(args.instance) : window[tagN];

      instance.track(args.tracking, args?.data ?? null);

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public setPageView(_instance?: string): boolean {
    try {
      if (!this.cliendId) {
        throw new Error("Tiktok Pixel Client Id is not defined");
      }

      let tagN = this.pixel_path;

      if (!window[tagN]) {
        throw new Error("Tiktok Pixel is not initialized");
      }

      let instance = _instance ? window[tagN].instance(_instance) : window[tagN];

      instance.page();

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

export class TiktokPixelUtilsStub {
  public setClientId(_clientId: string): void {}

  public setPixelTag(_path: string): void {}

  public init(_id?: string): void {}

  public setTracking<K = null>(args: TiktokPixelSetTrackingArgs<K>): boolean {
    return true;
  }

  public setPageView(_instance?: string): boolean {
    return true;
  }
}

const TikTokPixelUtil = isLocal ? new TiktokPixelUtilsStub() : new TiktokPixelUtils();

export default TikTokPixelUtil;
