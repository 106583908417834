import React, { useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { AuthContext } from "../../contexts/authContext";
import { Alert } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Grid, Paper } from "@material-ui/core";
import {
  backgroundStyle,
  btnStyle,
  h1Style,
  inputStyle,
  logoStyle,
  paperStyle,
  plabelStyle,
  containerStyle,
  rowStyle,
  btnStyleDisabled,
  passwordSubtitleStyle,
  phoneButtonStyle,
  phoneErrorStyle,
} from "./styles";
import verasightLogo from "../../assets/landing-page/verasight-logo-community.svg";
import { LinearProgress, OutlinedInput, Snackbar } from "@mui/material";

import SideCarousel from "../../components/SignUpSideCarousel/SignUpSideCarousel";
import { useValidEmail } from "../../hooks/useValidEmail";
import { useValidPhone } from "../../hooks/useValidPhone";
import SnackBarClose from "../../components/common/SnackbarClose";

const SetAdditionalAttributes: React.FC<any> = () => {
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const { signUpUserData, setSignUpUserData } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [open, setOpen] = React.useState(false);
  const [genericErrorMessage] = useState("");
  const { email, setEmail, emailError } = useValidEmail("");
  const { phone, setPhone, phoneError, parsedPhone } = useValidPhone("");
  const [allFieldsValid, setAllFieldsValid] = useState(false);

  const navigation = useNavigate();

  React.useEffect(() => {
    setAllFieldsValid(emailError == null && phoneError == null);
  }, [parsedPhone, email]);

  const saveSignUpUserData = () => {
    if (setSignUpUserData == null) {
      console.error("Failed to set the users's signup data");
      return;
    }
    setSignUpUserData({
      ...signUpUserData,
      payPalEmail: email || "",
      giftCardEmail: email || "",
      venmoPhone: parsedPhone || "",
    });
  };

  const onNextPressed = async (e: any) => {
    e.stopPropagation();
    saveSignUpUserData();
    const ref = searchParams.get("ref");
    let url;
    if (ref) {
      url = `/register/select-password?ref=${ref}`;
    } else {
      url = `/register/select-password`;
    }
    navigation(url);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div style={backgroundStyle}>
      <div style={containerStyle}>
        <Paper elevation={1} style={paperStyle}>
          <Grid style={{ ...logoStyle, justifyContent: "start" }}>
            <img src={verasightLogo} alt="Verasight Logo" style={{ width: "150px" }} />
          </Grid>
          <LinearProgress variant="determinate" value={40} style={{ color: "#2EBDEE" }} />
          <h1 style={h1Style}>Set additional phone and email</h1>
          <p style={passwordSubtitleStyle}>PayPal Email</p>
          <OutlinedInput
            size="small"
            placeholder="PayPal Email"
            value={email}
            name="payPalEmail"
            type="text"
            style={{ ...inputStyle, maxWidth: "400px" }}
            onChange={(e: any) => setEmail(e.target.value)}
            error={emailError != null}
            data-testid="email-input"
          />
          <p style={plabelStyle}>Venmo Phone</p>
          <PhoneInput
            country={"us"}
            onlyCountries={["us"]}
            value={phone}
            inputStyle={{ ...inputStyle, maxWidth: "400px" }}
            buttonStyle={phoneButtonStyle}
            onChange={(phone) => setPhone(phone)}
            placeholder="Mobile Number"
            countryCodeEditable={false}
          />
          {phoneError && <p style={phoneErrorStyle}>You must enter a valid US phone number</p>}
          <div style={{ ...rowStyle, marginTop: "60px", maxWidth: "400px" }}>
            <div style={{ flex: "1 1 30%" }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={{
                  ...btnStyle,
                  backgroundColor: "white",
                  color: "#5F6F74",
                }}
                fullWidth
                onClick={(e) => {
                  navigation(-1);
                }}
              >
                Back
              </Button>
            </div>
            <div style={{ flex: "1 1 60%" }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={allFieldsValid ? btnStyle : btnStyleDisabled}
                fullWidth
                onClick={(e) => onNextPressed(e)}
              >
                Next
              </Button>
            </div>
          </div>
        </Paper>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message={genericErrorMessage}
          action={<SnackBarClose handleClose={handleClose} />}
        />

        {alertMessage ? (
          <div style={{ position: "absolute", width: "100%" }}>
            <Alert
              variant="filled"
              severity="success"
              onClose={() => setAlertMessage("")}
              sx={{ maxWidth: "925px", margin: "auto", mt: "25px" }}
            >
              {alertMessage}
            </Alert>
          </div>
        ) : null}
      </div>
      <SideCarousel />
    </div>
  );
};

export default SetAdditionalAttributes;
