import { AxiosResponse } from "axios";
import apiService from "./apiService";
import endpoints from "./endpoints";
import { SurveyPrice } from "src/types/Payment";

export default class PaymentsService {
  static getPrices(): Promise<AxiosResponse<{ docs: SurveyPrice[] }>> {
    return apiService.get(endpoints.prices);
  }
}
