export enum SurveyProviders {
  Qualtrics = "Qualtrics",
  Other = "Other",
}
export const SurveyProvider2UserIdToken: Record<SurveyProviders, string> = {
  // eslint-disable-next-line no-template-curly-in-string
  [SurveyProviders.Qualtrics]: "${e://Field/vsid}",
  [SurveyProviders.Other]: "",
};

export const umtAllTagkey = "all";
