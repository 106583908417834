import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";

import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#2EBDEE",
      dark: "#2084A6",
      light: "#57CAF1",
      contrastText: "#fff",
    },
    text: {
      primary: "#5f6f74",
    },
  },
});
