import { AuthOptions } from "@aws-amplify/auth/lib-esm/types";

export const authOptions: AuthOptions = {
  // REQUIRED - Amazon Cognito Region
  region: process.env.REACT_APP_AWS_REGION,

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  authenticationFlowType: 'USER_PASSWORD_AUTH',

  // OPTIONAL - Hosted UI configuration
  oauth: {
    /* Application domain */
    domain:
      process.env.REACT_APP_AWS_APPLICATION_DOMAIN ?? "https://verasight-805975320018.auth.us-west-2.amazoncognito.com",

    /* Authorized scopes 'phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin' */
    scope: ["phone", "email"],

    /* Redirect url executing sign in */
    redirectSignIn: "topflightapps://authentication/",

    /* Redirect url executing sign out */
    redirectSignOut: "topflightapps://authentication/",

    /*
    **
    'code' for Authorization code grant, 
    'token' for Implicit grant
    Note that REFRESH token will only be generated when the responseType is code 
    **
    */
    responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
};
