import { Box, Typography, styled } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";

type Props = { label: string; val: number | string; testId: string } & DefaultComponentProps<any>;

export const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#c5deda",
  padding: theme.spacing(1),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  textAlign: "center",
}));

const TotalLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const InfoLabel = ({ label, val, testId, ...rest }: Props) => {
  return (
    <StyledBox display="flex" justifyContent="space-between" flex={1} {...rest}>
      <TotalLabel>{label}: </TotalLabel>
      <TotalLabel data-testid={testId}>{val}</TotalLabel>
    </StyledBox>
  );
};

export default InfoLabel;
