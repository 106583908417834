import Box from "@mui/material/Box";
import { Paper, Tooltip } from "@mui/material";
import { getEnvironmentStatus } from "../../App";

const EnvInfo = () => {
  const ENV_COLORS: Record<string, string> = {
    local: "green",
    development: "darkturquoise",
    staging: "blue",
    unknown: "red",
  };
  const tooltipData = getEnvironmentStatus();
  const env = tooltipData.app.nodeEnv ?? "unknown";
  if (env === "production") {
    return <></>;
  }
  return (
    <Tooltip
      arrow
      title={
        <>
          <table>
            <thead>
              <tr>
                <th colSpan={3}>App</th>
              </tr>
            </thead>
            <tbody style={{ all: "unset" }}>
              <tr>
                <td>App Environment</td>
                <td>{tooltipData.app.nodeEnv}</td>
              </tr>
              <tr>
                <td>Server Environment</td>
                <td>{tooltipData.app.serverNodeEnv}</td>
              </tr>
              <tr>
                <td>Version</td>
                <td>{tooltipData.app.version}</td>
              </tr>
            </tbody>
          </table>

          <table>
            <thead>
              <tr>
                <th colSpan={3}>API</th>
              </tr>
            </thead>
            <tbody style={{ all: "unset" }}>
              <tr>
                <td>App Environment</td>
                <td>{tooltipData.api.nodeEnv}</td>
              </tr>
              <tr>
                <td>Server Environment</td>
                <td>{tooltipData.api.serverNodeEnv}</td>
              </tr>
              <tr>
                <td>Version</td>
                <td>{tooltipData.api.version}</td>
              </tr>
            </tbody>
          </table>
        </>
      }
    >
      <Paper
        elevation={1}
        sx={{ p: "10px", borderRadius: "5px", borderColor: "green", backgroundColor: ENV_COLORS[env] }}
      >
        <Box p={1} sx={{ color: "white" }}>
          {env}
        </Box>
      </Paper>
    </Tooltip>
  );
};
export default EnvInfo;
