import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import pointsIcon from "../../assets/icon-points.svg";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SettingsService from "../../services/api/settingsService";

import "./ProgressBanner.scss";
import { AuthContext } from "../../contexts/authContext";
import { formatNumber } from "../../helpers/generic";
import { useMobileSize } from "../../hooks/useMobileSize";


export const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 7,
  width: "100%",
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D6F2FC",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#2EBDEE",
  },
}));

const ProgressBanner = (props:any) => {
  
  const {
    handleCloseNavMenu,
  } = props;
  
  const { user: userData } = useContext(AuthContext);
  
  const [image, setImage] = React.useState<string>("");
  const [bonusPoints, setBonusPoints] = React.useState<number>(200);
  const [surveysTaken, setSurveysTaken] = React.useState<number>(0);
  const [surveysNeeded, setSurveysNeeded] = React.useState<number>(10);
  const { isMobile } = useMobileSize();

  const calculateProgress = (value: number) => (value * 100) / surveysNeeded;

  React.useEffect(() => {
    if (userData) {
      setImage(userData?.image ?? "");
      setSurveysTaken(userData.currentCompletedSurveysCounter ?? 0);

      if (userData.settings && userData.settings.pointsPerBonuts) {
        setBonusPoints(userData.settings.pointsPerBonuts);
      } else {
        SettingsService.getSetting("points-per-bonus").then((res) => {
          if (res.data.payload && res.data.payload.value) {
            setBonusPoints(res.data.payload.value);
          }
        });
      }

      if (userData.settings && userData.settings.minSurveysForBonus) {
        setSurveysNeeded(userData.settings.minSurveysForBonus);
      } else {
        SettingsService.getSetting("min-survey-for-bonus").then((res) => {
          if (res.data.payload && res.data.payload.value) setSurveysNeeded(res.data.payload.value);
        });
      }
    }
  }, [userData]);

  return (
    <div className="banner-wrapper">
      <div className="avatar-wrapper" onClick={() => handleCloseNavMenu(`/profile${!isMobile? "/account-details" : ""}`)}>
        {image ? <img src={image} alt="user-avatar" /> : <PersonOutlineOutlinedIcon />}
      </div>

      <div className="banner-info-wrapper">
        <div className="bonus-points-wrapper">
          <img src={pointsIcon} alt="Points" />
          <p>Earn {formatNumber(bonusPoints)} Bonus Pts</p>
        </div>
        <div className="progress-bar-wrapper">
          <BorderLinearProgress variant="determinate" value={calculateProgress(surveysTaken)} />
          <span className="progress-bar-text">
            {surveysTaken} / {surveysNeeded}
          </span>
        </div>
      </div>
    </div>
  );
};
export default ProgressBanner;
