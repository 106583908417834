import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import BootstrapInput from "../BootstrapInput";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import { Fragment, useEffect, useState } from "react";

import "./ParameterTable.scss";

export type TableData = {
  orderId: number;
  parameterName: string;
  parameterType: string;
  value: string;
};

export type ParameterChoice = {
  value: string;
  text: string;
};

type ParameterTableProps = {
  tableData?: any;
  setTableData: (data: any) => void;
  tableTitle?: string;
  typeChoices: ParameterChoice[];
  headers?: string[];
  actionComponent?: React.ReactNode;
};

const ParameterTable = ({
  tableData,
  setTableData,
  tableTitle,
  typeChoices,
  headers,
  actionComponent,
}: ParameterTableProps) => {
  const [tableRows, setTableRows] = useState<TableData[]>([]);
  const [rowName, setRowName] = useState<string>("");
  const [rowType, setRowType] = useState<string>("");
  const [rowValue, setRowValue] = useState<string>("");
  const [rowValueInputVisible, setRowValueInputVisible] = useState<boolean>(false);
  const defaultHeaderNames = ["Parameter Name", "Type", "Value"];
  const [internalHeaderNames, setInternalHeaderNames] = useState<string[]>(defaultHeaderNames);

  useEffect(() => {
    const data = tableData?.parameters?.map((row: any) => {
      return {
        orderId: row.orderId,
        parameterName: row.parameterName,
        parameterType: row.parameterType,
        value: row.arguments?.join(", "),
      };
    });
    setTableRows(data || []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (headers) {
      setInternalHeaderNames(headers);
    }
  }, [headers]);

  useEffect(() => {
    setTableData({
      parameters: tableRows.map((row) => {
        return {
          ...row,
          arguments: row.value ? [row.value] : [],
        };
      }),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRows]);

  const [genericErrorMessage, setGenericErrorMessage] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const handleAlertClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;

    setAlertOpen(false);
  };

  const addTableRow = () => {
    const errorMessage = tableRowErrorMessage();
    if (errorMessage) {
      setGenericErrorMessage(errorMessage);
      setAlertOpen(true);
      return;
    }

    const nextId = tableRows.length > 0 ? tableRows[tableRows.length - 1].orderId + 1 : 1;

    setTableRows([
      ...tableRows,
      {
        orderId: nextId,
        parameterName: rowName,
        parameterType: rowType,
        value: rowValue,
      },
    ]);

    clearRowFields();
  };

  const rowErrorMessages = {
    requiredParams: "Please fill in the Parameter Name and Type fields",
    requiredValue: "Please fill in the Value field",
    valueShouldBeNumeric: "Value should be numeric",
  };

  const tableRowErrorMessage = (): string | void => {
    if (rowName === "" || !rowType) return rowErrorMessages.requiredParams;
  };

  const clearRowFields = () => {
    setRowName("");
    setRowType("");
    setRowValue("");
    setRowValueInputVisible(false);
  };

  const deleteTableRow = (id: number) => {
    setTableRows(
      tableRows
        .filter((row) => row.orderId !== id)
        .map((row, idx) => ({
          ...row,
          orderId: idx,
        }))
    );
  };

  const handleChangeName = (event: any) => {
    setRowName(event.target.value);
  };

  const handleChangeType = (event: any) => {
    setRowType(event.target.value);
    setRowValueInputVisible(true);
  };

  const handleChangeValue = (event: any) => {
    setRowValue(event.target.value);
  };

  const alertAction = (
    <Fragment>
      <Button style={{ color: "#2ebdee" }} size="small" onClick={handleAlertClose}>
        Close
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleAlertClose} />
    </Fragment>
  );

  return (
    <>
      <Box
        className="parameter-table-container"
        sx={{
          display: "flex",
          marginTop: "20px",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ minWidth: 750, display: "flex", justifyContent: "space-between" }}>
          <FormControl variant="standard">
            <div className="parameter-table-title">{tableTitle}</div>
          </FormControl>
          {actionComponent ? <Box>{actionComponent}</Box> : null}
        </Box>

        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {internalHeaderNames.map((headerName, idx) => {
                    return (
                      <TableCell key={headerName} className="parameter-table-header-col">
                        {headerName}
                      </TableCell>
                    );
                  })}
                  <TableCell align="right">&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRows.map((row) => (
                  <TableRow key={row.orderId} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.parameterName}
                    </TableCell>
                    <TableCell align="right">{row.parameterType}</TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                    <TableCell align="right">
                      <DeleteOutlineIcon
                        className="parameter-table-action-icon"
                        onClick={() => deleteTableRow(row.orderId)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <FormControl>
                      <BootstrapInput value={rowName} onChange={handleChangeName} />
                    </FormControl>
                  </TableCell>
                  <TableCell align="right">
                    <FormControl fullWidth>
                      <InputLabel id="row-type-label">Row Type</InputLabel>
                      <Select
                        labelId="row-type-label"
                        id="row-type"
                        value={rowType}
                        label="Row Type"
                        onChange={handleChangeType}
                      >
                        {typeChoices.map((choice) => (
                          <MenuItem key={choice.value} value={choice.value}>
                            {choice.text}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="right">
                    {rowValueInputVisible ? (
                      <FormControl>
                        <BootstrapInput value={rowValue} onChange={handleChangeValue} />
                      </FormControl>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <AddIcon className="parameter-table-action-icon" onClick={() => addTableRow()} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={handleAlertClose}
        message={genericErrorMessage}
        action={alertAction}
      />
    </>
  );
};

export default ParameterTable;
