import { Box, Dialog, DialogTitle, LinearProgress } from "@mui/material";
import ProgressBar from "./ProgressBar";
import "./ProgressDialog.scss";

export enum ProgressBarType {
  PERCENTAGE = "percentage",
  INFINITE = "infinite",
}

type ProgressDialogProps = {
  type?: ProgressBarType;
  open: boolean;
  onClose: () => void;
  title: string;
  value?: number;
  messages?: string[];
};

const ProgressDialog = ({ type, open, onClose, title, value = 0, messages }: ProgressDialogProps) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <div className="progress-dialog-body">
        <DialogTitle className="progress-dialog-title">{title}</DialogTitle>

        {type === ProgressBarType.PERCENTAGE ? (
          <Box>
            <ProgressBar value={value} messages={messages} />
          </Box>
        ) : (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </div>
    </Dialog>
  );
};

export default ProgressDialog;
