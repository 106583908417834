import React from "react";
import { Link } from "react-router-dom";

const VeraLink = ({ to, children }: any) => {
  return (
    <Link
      to={to}
      style={{
        textDecoration: "none",
        color: "#91A3A9",
        fontSize: "16px",
        lineHeight: "24px",
      }}
    >
      {children}
    </Link>
  );
};

export default VeraLink;
