import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";
import { useContext, useState } from "react";
import { AuthContext } from "src/contexts/authContext";
import { SurveySource } from "../../../types/Survey";
import { ListItemIcon } from "@mui/material";
import { atom, useAtom } from "jotai";
import { reloadSurveysAtom } from "./SurveysList";
import FilterWrapper from "../../../components/common/FilterWrapper";
import SearchWrapper from "../../../components/common/SearchWrapper";
import { UserRoles } from "src/types/User";

const categories = ["Art", "Economy", "Environment", "Science", "Sports", "Politics"];

export enum StatusFilterEnum {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export const statusFilterAtom = atom<StatusFilterEnum | "">("");
export const sourceFilterAtom = atom<SurveySource | "">("");
export const selectedCategoriesAtom = atom<string[]>([]);
export const searchValueAtom = atom("");
export const createdByRoleAtom = atom<UserRoles | null>(null);

type SurveysFilterProps = {
  setPage: (page: number) => void;
};

const SurveysFilter = ({ setPage }: SurveysFilterProps) => {
  const [openFilters, setOpenFilters] = useState(false);
  const [statusFilter, setStatusFilter] = useAtom(statusFilterAtom);
  const [createdByRole, setCreatedByRole] = useAtom(createdByRoleAtom);
  const { user } = useContext(AuthContext);

  const [sourceFilter, setSourceFilter] = useAtom(sourceFilterAtom);
  const [selectedCategories, setSelectedCategories] = useAtom(selectedCategoriesAtom);
  const [searchValue, setSearchValue] = useAtom(searchValueAtom);
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [reloadSurveys, setReloadSurveys] = useAtom(reloadSurveysAtom);

  const isAdmin = user?.role === UserRoles.SUPER_ADMIN;

  const changeStatusFilter = (value: StatusFilterEnum) => {
    setStatusFilter(value === statusFilter ? "" : value);
  };

  const changeSourceFilter = (value: SurveySource) => {
    setSourceFilter(value === sourceFilter ? "" : value);
  };

  const onChangeCategories = (_event: any, newValue: string[]) => {
    setSelectedCategories(newValue);
  };

  const applyFilters = () => {
    setPage(1);
    setOpenFilters(false);
    setReloadSurveys(!reloadSurveys);
  };

  const clearFilters = () => {
    setStatusFilter("");
    setSourceFilter("");
    setSelectedCategories([]);
    setPage(1);
    setOpenFilters(false);
    setReloadSurveys(!reloadSurveys);
    setCreatedByRole(UserRoles.SUPER_ADMIN);
  };

  const onSearchClick = () => {
    setSearchIsActive(!!searchValue);
    setReloadSurveys(!reloadSurveys);
  };

  const onClearSearch = () => {
    setSearchValue("");
    setSearchIsActive(false);
    setReloadSurveys(!reloadSurveys);
  };

  return (
    <div className="filters-container">
      <FilterWrapper
        openFilters={openFilters}
        clearFilters={clearFilters}
        applyFilters={applyFilters}
        setOpenFilters={setOpenFilters}
      >
        {isAdmin ? (
          <>
            <p className="option-title">Created By</p>
            <ListItem key={"active"} disablePadding sx={{ height: "35px" }}>
              <ListItemButton onClick={() => setCreatedByRole(null)} dense sx={{ height: "100%" }}>
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <Checkbox edge="start" checked={!createdByRole} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText primary={`Admin`} />
              </ListItemButton>
            </ListItem>
            <ListItem key={"inactive"} disablePadding sx={{ height: "35px" }}>
              <ListItemButton onClick={() => setCreatedByRole(UserRoles.RESEARCHER)} dense sx={{ height: "100%" }}>
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <Checkbox edge="start" checked={createdByRole === UserRoles.RESEARCHER} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText primary={`Researcher`} />
              </ListItemButton>
            </ListItem>
          </>
        ) : null}

        <p className="option-title">Status</p>
        <ListItem key={"active"} disablePadding sx={{ height: "35px" }}>
          <ListItemButton onClick={() => changeStatusFilter(StatusFilterEnum.ACTIVE)} dense sx={{ height: "100%" }}>
            <ListItemIcon sx={{ minWidth: "auto" }}>
              <Checkbox edge="start" checked={statusFilter === StatusFilterEnum.ACTIVE} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText primary={`Active`} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"inactive"} disablePadding sx={{ height: "35px" }}>
          <ListItemButton onClick={() => changeStatusFilter(StatusFilterEnum.INACTIVE)} dense sx={{ height: "100%" }}>
            <ListItemIcon sx={{ minWidth: "auto" }}>
              <Checkbox edge="start" checked={statusFilter === StatusFilterEnum.INACTIVE} tabIndex={-1} disableRipple />
            </ListItemIcon>
            <ListItemText primary={`Inactive`} />
          </ListItemButton>
        </ListItem>

        {isAdmin ? (
          <>
            <p className="option-title">Source</p>
            <ListItem key={SurveySource.QUALTRICS} disablePadding sx={{ height: "35px" }}>
              <ListItemButton onClick={() => changeSourceFilter(SurveySource.QUALTRICS)} dense sx={{ height: "100%" }}>
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <Checkbox
                    edge="start"
                    checked={sourceFilter === SurveySource.QUALTRICS}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={`Qualtrics`} />
              </ListItemButton>
            </ListItem>
            <ListItem key={SurveySource.TYPEFORM} disablePadding sx={{ height: "35px" }}>
              <ListItemButton onClick={() => changeSourceFilter(SurveySource.TYPEFORM)} dense sx={{ height: "100%" }}>
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <Checkbox edge="start" checked={sourceFilter === SurveySource.TYPEFORM} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText primary={`Typeform`} />
              </ListItemButton>
            </ListItem>
            <ListItem key={SurveySource.EXTERNAL} disablePadding sx={{ height: "35px" }}>
              <ListItemButton onClick={() => changeSourceFilter(SurveySource.EXTERNAL)} dense sx={{ height: "100%" }}>
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <Checkbox edge="start" checked={sourceFilter === SurveySource.EXTERNAL} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText primary={`External`} />
              </ListItemButton>
            </ListItem>
          </>
        ) : null}

        {isAdmin ? (
          <>
            <p className="option-title">Categories</p>
            <Autocomplete
              id="categories-select"
              multiple
              options={categories}
              value={selectedCategories}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ textTransform: "capitalize" }}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ textTransform: "capitalize" }}
              size="small"
              onChange={onChangeCategories}
              renderInput={(params) => <TextField {...params} placeholder="Categories" />}
            />
          </>
        ) : null}
      </FilterWrapper>

      <SearchWrapper
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onSearchClick={onSearchClick}
        onClearSearch={onClearSearch}
        searchIsActive={searchIsActive}
      />
    </div>
  );
};

export default SurveysFilter;
