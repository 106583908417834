import React from "react";
import { Outlet } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import AccountSecurity from "./AccountAndSecurity";
import RewardPoints from "./RewardPointsSettings";

import "./SettingsTab.scss";
import EmailSettings from "./EmailSettings";
import ApiKeysTab from "./ApiKeys";
import ReferralsTab from "./ReferralsTab";

enum TabKeys {
  ACCOUNT_AND_SECURITY = "account-and-security",
  REWARD_POINTS = "reward-points",
  EMAIL_SETTINGS = "email-settings",
  API_KEYS = "api-keys",
  REFERRALS = "referrals",
}

type TabType = {
  name: string;
  key: TabKeys;
  component: JSX.Element;
};

const tabs: TabType[] = [
  {
    name: "Account & Security",
    key: TabKeys.ACCOUNT_AND_SECURITY,
    component: <AccountSecurity />,
  },
  {
    name: "Reward Points",
    key: TabKeys.REWARD_POINTS,
    component: <RewardPoints />,
  },
  {
    name: "Email Settings",
    key: TabKeys.EMAIL_SETTINGS,
    component: <EmailSettings />,
  },
  {
    name: "API Keys",
    key: TabKeys.API_KEYS,
    component: <ApiKeysTab />,
  },
  {
    name: "Referrals",
    key: TabKeys.REFERRALS,
    component: <ReferralsTab />,
  },
];

const StyledLink = styled(Button)({
  display: "flex",
  fontWeight: "700",
  fontSize: "15px",
  lineHeight: "24px",
  letterSpacing: "1px",
  color: "#91A3A9",
  textTransform: "capitalize",
  textDecoration: "none",

  "&:hover, &.active": {
    color: "#114556",
  },
});

const SettingsTab = () => {
  const [activeTab, setActiveTab] = React.useState<TabKeys>(TabKeys.ACCOUNT_AND_SECURITY);

  return (
    <div className="content-wrapper">
      <p className="title">Settings</p>

      <Box className="settings-tabs-wrapper">
        {tabs.map((tab) => (
          <StyledLink
            variant={"text"}
            key={tab.key}
            className={activeTab === tab.key ? "active" : ""}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.name}
          </StyledLink>
        ))}
      </Box>

      {tabs.find((tab) => tab.key === activeTab)?.component}

      <Outlet />
    </div>
  );
};

export default SettingsTab;
