import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import pointsIcon from "../../assets/icon-points.svg";
import verasightLogo from "../../assets/landing-page/verasight-logo-community.svg";
import pinIcon from "../../assets/icon-pin-feed.svg";
import { formatNumber } from "../../helpers/generic";

const SurveyCard = ({
  surveyId,
  name,
  image,
  reward,
  upToReward,
  categories,
  completed,
  liked,
  onLikeClick,
  onCardClick,
  cardHref,
  isEditMode,
  isPinned = false,
  isPreview = false,
}: any) => {
  const [isLiked, setIsLiked] = React.useState<boolean>(liked);
  const [isLikeDisabled, setIsLikeDisabled] = React.useState(false);
  const likeClick = () => {
    setIsLikeDisabled(true);
    onLikeClick(surveyId, !isLiked, () => setIsLikeDisabled(false));
    setIsLiked(!isLiked);
  };
  const onClickAttr = {
    onClick: () => onCardClick(),
  };

  const hrefAttr = {
    href: cardHref,
    target: "_blank",
  };

  return (
    <Card className={`survey-card ${isPreview ? "preview-card__survey-card" : ""}`}>
      {isPinned && <img src={pinIcon} alt="pinned" className="pin-survey-icon" />}
      <div className="card-container">
        <CardActionArea {...(cardHref ? hrefAttr : onClickAttr)}>
          {image ? (
            <CardMedia component="img" height="150" src={image} />
          ) : (
            <CardMedia>
              <div className="default-image">
                <img src={verasightLogo} alt="Verasight Logo" />
              </div>
            </CardMedia>
          )}
          <CardContent>
            <div className="survey-card-tags-wrapper">
              {categories.map((category: string) => (
                <span key={category} className="survey-card-category-item">
                  #{category}
                </span>
              ))}
            </div>
            <p className="survey-card-title">{name}</p>
          </CardContent>
        </CardActionArea>

        <CardActions>
          <div className="survey-card-actions">
            {completed ? (
              <div className="completed-label">
                <CheckOutlinedIcon className="completed-icon" />
                <p>Completed</p>
              </div>
            ) : (
              <div className="reward-points-label">
                <img src={pointsIcon} alt="Points" />
                <p>
                  {upToReward !== undefined && upToReward > 0 && "up to "}
                  {formatNumber(Math.max(reward, upToReward ?? 0))}
                </p>
              </div>
            )}
            {!isEditMode && (
              <IconButton
                data-testid="survey-card-like"
                disabled={isLikeDisabled}
                size="medium"
                color="inherit"
                onClick={likeClick}
              >
                {isLiked ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
              </IconButton>
            )}
          </div>
        </CardActions>
      </div>
    </Card>
  );
};

export default SurveyCard;
