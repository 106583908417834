import React from "react";
import { Button, FormControlLabel, Popover, Radio, RadioGroup } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TextField from "@mui/material/TextField";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import VeraButton from "../../../../components/VeraButton";

const categories = ["Art", "Economy", "Environment", "Science", "Sports", "Politics"];

enum FilterStatuses {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
}

export type FilterParams = {
  status: FilterStatuses;
}

type FilterOptionsProps = {
  onChangeSort: (value: string | null) => void;
  onChangeFilter: (value: string[]) => void;
  onFilter: (value: FilterParams) => void;
};

const FilterOptions = ({ onChangeSort, onChangeFilter, onFilter }: FilterOptionsProps) => {
  const [openSort, setOpenSort] = React.useState<boolean>(false);
  const [sortAttribute, setSortAttribute] = React.useState<string | null>(null);
  const [openCategryFilters, setOpenCategryFilters] = React.useState<boolean>(false);
  const [selectedCategories, setCategories] = React.useState<string[]>([]);
  const [sortAnchorEl, setSortAnchorEl] = React.useState(null);
  const [categoryAnchorEl, setCategoryAnchorEl] = React.useState(null);

  const [openFilter, setOpenFilter] = React.useState<boolean>(false);
  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
  const defaultFilterStatus = FilterStatuses.INCOMPLETE;
  const [filtersStatus, setFiltersStatus] = React.useState<FilterStatuses>(defaultFilterStatus);

  const toggleSort = (evt: any) => {
    if (openCategryFilters) setOpenCategryFilters(false);
    if (openFilter) setOpenFilter(false);
    setOpenSort(!openSort);
    setSortAnchorEl(evt.currentTarget);
  };

  const closeSortFilter = () => {
    setOpenSort(false);
    setSortAnchorEl(null);
  };

  const toggleCategoryFilter = (evt: any) => {
    if (openSort) setOpenSort(false);
    if (openFilter) setOpenFilter(false);
    setOpenCategryFilters(!openCategryFilters);
    setCategoryAnchorEl(evt.currentTarget);
  };

  const closeCategoryFilter = () => {
    setOpenCategryFilters(false);
    setCategoryAnchorEl(null);
  };

  const toggleFilter = (evt: any) => {
    if (openSort) setOpenSort(false);
    if (openCategryFilters) setOpenCategryFilters(false);
    setOpenFilter(!openFilter);
    setFilterAnchorEl(evt.currentTarget);
  }

  const closeFilter = () => {
    setOpenFilter(false);
    setFilterAnchorEl(null);
  }

  const changeFiltersStatus = (event: any) => {
    setFiltersStatus(event.target.value);
  };

  const changeSortAttribute = (value: string) => {
    if (value === sortAttribute) setSortAttribute(null);
    else setSortAttribute(value);
  };

  const onChangeCategories = (event: any, newValue: any) => {
    setCategories(newValue);
  };

  const applyCategories = () => {
    onChangeFilter(selectedCategories);
    setOpenCategryFilters(false);
  };

  const clearCategories = () => {
    setCategories([]);
    onChangeFilter([]);
    setOpenCategryFilters(false);
  };

  const applyFilters = () => {
    onFilter({ status: filtersStatus });
    setOpenFilter(false);
    setFilterAnchorEl(null);
  }

  const clearFilters = () => {
    setFiltersStatus(defaultFilterStatus);
    onFilter({ status: defaultFilterStatus });
    setOpenFilter(false);
    setFilterAnchorEl(null);
  }

  React.useEffect(() => {
    onChangeSort(sortAttribute);
    // eslint-disable-next-line
  }, [sortAttribute]);

  return (
    <div className="filter-options-wrapper">
      <div className="filter-wrapper">
        <Button
          variant="contained"
          endIcon={openSort ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          onClick={toggleSort}
          disableElevation={true}
          style={{
            marginRight: "20px",
            backgroundColor: "#fefefe",
            color: "#000",
            border: "1px solid #bac6ca",
            fontWeight: "bold",
            textTransform: "capitalize",
          }}
        >
          Sort
        </Button>

        <Popover
          open={openSort}
          onClose={closeSortFilter}
          anchorEl={sortAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="filter-options">
            <p className="option-title">By Creation Date</p>
            <ListItem key={"newest"} disablePadding sx={{ height: "35px" }}>
              <ListItemButton onClick={() => changeSortAttribute("-createdAt")} dense sx={{ height: "100%" }}>
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <Checkbox edge="start" checked={sortAttribute === "-createdAt"} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText primary={`Newest to Oldest`} />
              </ListItemButton>
            </ListItem>
            <ListItem key={"oldest"} disablePadding sx={{ height: "35px" }}>
              <ListItemButton onClick={() => changeSortAttribute("createdAt")} dense sx={{ height: "100%" }}>
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <Checkbox edge="start" checked={sortAttribute === "createdAt"} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText primary={`Oldest to Newest`} />
              </ListItemButton>
            </ListItem>

            <p className="option-title">By Reward Points</p>
            <ListItem key={"highest"} disablePadding sx={{ height: "35px" }}>
              <ListItemButton onClick={() => changeSortAttribute("-points")} dense sx={{ height: "100%" }}>
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <Checkbox edge="start" checked={sortAttribute === "-points"} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText primary={`Highest to Lowest`} />
              </ListItemButton>
            </ListItem>
            <ListItem key={"lowest"} disablePadding sx={{ height: "35px" }}>
              <ListItemButton onClick={() => changeSortAttribute("points")} dense sx={{ height: "100%" }}>
                <ListItemIcon sx={{ minWidth: "auto" }}>
                  <Checkbox edge="start" checked={sortAttribute === "points"} tabIndex={-1} disableRipple />
                </ListItemIcon>
                <ListItemText primary={`Lowest to Highest`} />
              </ListItemButton>
            </ListItem>
          </div>
        </Popover>
      </div>

      <div className="filter-wrapper">
        <Button
          variant="contained"
          endIcon={openCategryFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          onClick={toggleCategoryFilter}
          disableElevation={true}
          style={{
            marginRight: "20px",
            backgroundColor: "#fefefe",
            color: "#000",
            border: "1px solid #bac6ca",
            fontWeight: "bold",
            textTransform: "capitalize",
          }}
        >
          Categories
        </Button>

        <Popover
          open={openCategryFilters}
          onClose={closeCategoryFilter}
          anchorEl={categoryAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="filter-options">
            <p className="option-title">Categories</p>
            <Autocomplete
              id="categories-select"
              multiple
              options={categories}
              value={selectedCategories}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ textTransform: "capitalize" }}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ textTransform: "capitalize" }}
              size="small"
              onChange={onChangeCategories}
              renderInput={(params) => <TextField {...params} placeholder="Categories" />}
            />

            <div className="buttons-wrapper">
              <VeraButton variant="text" onClick={clearCategories} sx={{ border: "none" }}>
                Clear
              </VeraButton>
              <VeraButton variant="outlined" onClick={applyCategories}>
                Apply
              </VeraButton>
            </div>
          </div>
        </Popover>
      </div>

      <div className="filter-wrapper">
        <Button
          variant="contained"
          endIcon={openFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          onClick={toggleFilter}
          disableElevation={true}
          style={{
            marginRight: "20px",
            backgroundColor: "#fefefe",
            color: "#000",
            border: "1px solid #bac6ca",
            fontWeight: "bold",
            textTransform: "capitalize",
          }}
        >
          Filter
        </Button>

        <Popover
          open={openFilter}
          onClose={closeFilter}
          anchorEl={filterAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="filter-options">
            <p className="option-title" id="demo-radio-buttons-group-label">Status</p>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="complete"
              name="filters-status"
              value={filtersStatus}
              onChange={changeFiltersStatus}
            >
              <FormControlLabel value={FilterStatuses.INCOMPLETE} control={<Radio />} label="Incomplete" />
              <FormControlLabel value={FilterStatuses.COMPLETE} control={<Radio />} label="Complete" />
            </RadioGroup>

            <div className="buttons-wrapper">
              <VeraButton variant="text" onClick={clearFilters} sx={{ border: "none" }}>
                Clear
              </VeraButton>
              <VeraButton variant="outlined" onClick={applyFilters}>
                Apply
              </VeraButton>
            </div>
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default FilterOptions;
