import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";

import type { ITopic } from "./types";

interface IProps {
  data: ITopic & { id: string };
  isSelected: boolean;
  onClick: () => void;
}

const useStyles = makeStyles({
  root: {
    background: "#F8FAFA",
    padding: 12,
    borderRadius: 4,
    cursor: "pointer",
    border: "1px solid transparent",

    "& > img": {
      marginRight: 12,
    },

    "&.selected": {
      border: "1px solid #384144",
      boxSizing: "border-box",
      "-moz-box-sizing": "border-box",
      "-webkit-box-sizing": "border-box",
    },
  },
});

const TopicItem = ({ data: { icon, name }, isSelected, onClick }: IProps) => {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" className={clsx(classes.root, isSelected && "selected")} onClick={onClick}>
      {icon && <img src={icon} alt={name} />}
      <Typography>{name.charAt(0).toUpperCase() + name.slice(1)}</Typography>
    </Box>
  );
};

export default React.memo(TopicItem);
