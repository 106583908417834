export const formatNumber = (value: number | string) => {
  if (typeof value === "number") {
    return value.toLocaleString("en-US");
  }
  return parseInt(value).toLocaleString("en-US");
};

export const addValidFieldIfCorrrectEmailFormat = (data: { email: string; points: string; valid: boolean }[]) => {
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  for (const item of data) {
    if (item?.email.match(validRegex)) {
      item.valid = true;
    } else {
      item.valid = false;
    }
  }
  return data;
};

export const checkIfThereAreInvalidEmails = (data: { email: string; points: string; valid: boolean }[]) => {
  for (const item of data) {
    if (!item.valid) {
      return !item.valid;
    }
  }
  return false;
};

export const isCaptchaEnabled = () => {
  if (process.env.REACT_APP_CAPTCHA_ENABLED === "true") {
    return true;
  }
  return getSiteEnvironment() === "production";
};

export const isLocal = process.env.REACT_APP_API_BASE_URL?.includes("localhost");

export const getSiteEnvironment = () => {
  const site = window.location.hostname || "";

  if (site.includes("web.dev")) {
    return "development";
  }

  if (site.includes("web")) {
    return "staging";
  }

  if (site.includes("app")) {
    return "production";
  }

  if (site.includes("localhost")) {
    return "local";
  }

  return "unknown";
};

export const getSendgridTemplateParameters = (text: string) => {
  const templateLiteralRegex = /(?<={{).*?(?=}})/gs;
  // the regex above will also match triple brackets which are SendGrid specific and not anything we can pass in as a parameter.
  // such matches will contain a { at the start
  const templateParams = (text.match(templateLiteralRegex) || []).filter((param) => !param.startsWith("{"));

  return templateParams.filter((value, index, array) => array.indexOf(value) === index).map((value) => value.trim());
};

export const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const dateFromId = (id: string) => {
  return new Date(parseInt(id.substring(0, 8), 16) * 1000);
};
