import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { createWidget as createTypeformWidget } from "@typeform/embed";
import SurveysService from "../../../services/api/surveysService";
import "./SurveyDisplay.scss";
import "@typeform/embed/build/css/widget.css";
import UsersService from "../../../services/api/usersService";
import SurveyComplete from "../../../components/SurveyComplete/SurveyComplete";
import { AuthContext } from "src/contexts/authContext";
import { completeSurvey } from "src/helpers/dataLayers";
import SurveyCard from "../../../components/SurveyCard/SurveyCard";
import { getRedirectLinkPoints } from "../../../helpers/surveyHelper";
import { SurveySource } from "../../../types/Survey";
import { AxiosError } from "axios";

const SurveyDisplay = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [survey, setSurvey] = React.useState<any>(null);
  const [externalSurveyRedirectUrl, setExternalSurveyRedirectUrl] = React.useState<any>(undefined);
  const [isSurveyComplete, setIsSurveyComplete] = React.useState<boolean>(false);
  const { user, refreshUser } = useContext(AuthContext);
  const [hasReceivedCompletionBonus, setHasReceivedCompletionBonus] = useState(false);
  const [completedSurvey, setCompletedSurvey] = useState<any>(null);

  const [spinnerBonus, setSpinnerBonus] = useState(0);

  const onSurveySubmit = React.useCallback((surveyId: string | undefined) => {
    if (surveyId) {
      UsersService.completeSurvey(surveyId).then((response) => {
        setSurvey((prev: any) => ({
          ...prev,
          points: response.data.completedSurvey.points,
        }));
        if (response.data.awardedCompletionBonus) {
          setHasReceivedCompletionBonus(true);
        } else {
          setIsSurveyComplete(true);
        }
        setSpinnerBonus(response.data.spinnerBonus);
        completeSurvey(
          response.data.completedSurvey.last_updated_at, //survey was created in the survey builder (Typeform/Qualtrics)
          response.data.completedSurveyCount, //user_surveys_completed
          new Date(), //complete_survey_date
          response.data.completedSurveys[response.data.completedSurveys.length - 2].createdAt, //penultimate_survey_created_at
          response.data.completedSurvey.points + response.data.completedSurvey.spinnerBonus, //survey_points_added
          response.data.totalPoints, //complete_survey_user_point_total
          response.data.completedSurvey.spinnerBonus, //complete_survey_spinny_wheel
          response.data.completedSurvey.points, //complete_survey_points
          response.data.completedSurvey.category //complete_survey_topic
        );
      });
    }
  }, []);

  const onLikeClick = (surveyId: string, like: boolean, callback: () => void) => {
    if (like) {
      SurveysService.likeSurvey(surveyId).then(() => callback());
    } else {
      SurveysService.unlikeSurvey(surveyId).then(() => callback());
    }
  };

  useEffect(() => {
    if (refreshUser && (isSurveyComplete || hasReceivedCompletionBonus)) {
      refreshUser(false);
    }

    // HACK: TRS - the spinner animation takes 6 seconds so wait for that to finish before refreshing the user
    if (spinnerBonus > 0) {
      const t = setTimeout(() => {
        if (refreshUser) refreshUser(false);
      }, 6 * 1_000);
      return () => clearTimeout(t);
    }
  }, [isSurveyComplete, hasReceivedCompletionBonus, spinnerBonus, refreshUser]);

  const generateQualtricsURL = React.useCallback(
    (qualtricsId: string) => {
      if (user && user.email) {
        return `https://verasight.qualtrics.com/jfe/form/${qualtricsId}?email=${encodeURIComponent(user.email)}`;
      }
    },
    [user]
  );

  const postMessageHandler = (event: any) => {
    if (event.data && typeof event.data === "string" && event.data.includes("qualtrics-survey-end")) {
      onSurveySubmit(id);
    }
  };

  React.useEffect(() => {
    const completedSurveys = user?.completedSurveys;
    const email = user?.email;
    if (survey == null || completedSurveys == null || email == null) {
      return;
    }
    const alreadyCompleted = completedSurveys.find((survey: any) => survey.survey._id === id);
    if (alreadyCompleted) {
      setCompletedSurvey(alreadyCompleted);

      if (hasReceivedCompletionBonus) {
        return;
      }

      if (survey.points !== alreadyCompleted.points) {
        setSurvey({
          ...survey,
          points: alreadyCompleted.points,
        });
      }
      setIsSurveyComplete(true);
    }
    if (survey.source === SurveySource.TYPEFORM && !isSurveyComplete && !hasReceivedCompletionBonus) {
      createTypeformWidget(survey.externalId, {
        container: document.querySelector("#survey-display-container") as HTMLElement,
        height: 800,
        hidden: { email },
        onSubmit: () => {
          onSurveySubmit(survey._id);
        },
      });
    }
    // eslint-disable-next-line
  }, [id, onSurveySubmit, survey, user, user?.completedSurveys, user?.email]);

  React.useEffect(() => {
    if (!id) return;

    SurveysService.getSurvey(id)
      .then((res) => {
        if (!res.data) navigate("/");

        setSurvey(res.data);
      })
      .catch((error: AxiosError) => {
        navigate(`/?error_message=${error.response?.data?.message}`);
      });
  }, [id]);

  React.useEffect(() => {
    if (!survey || survey.source !== "external") return;

    SurveysService.getExternalSurveyRedirectUrl(survey._id).then((res) => {
      setExternalSurveyRedirectUrl(res.data.url);
    });
  }, [survey]);

  React.useEffect(() => {
    window.addEventListener("message", postMessageHandler);
    return () => {
      window.removeEventListener("message", postMessageHandler);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {!survey || !user ? (
        <h3>Loading...</h3>
      ) : (
        <Box>
          {!isSurveyComplete &&
            !hasReceivedCompletionBonus &&
            (survey.source === SurveySource.EXTERNAL ? (
              <div className="external-survey-card">
                <SurveyCard
                  key={survey._id}
                  surveyId={survey._id}
                  cardHref={externalSurveyRedirectUrl}
                  name={survey.title}
                  image={survey.image ? survey.image : ""}
                  reward={survey.points ? survey.points : 0}
                  upToReward={
                    survey.externalSurveyRedirectPointsData
                      ? Math.max(...getRedirectLinkPoints(survey.externalSurveyRedirectPointsData))
                      : undefined
                  }
                  categories={survey.tags.length ? survey.tags : []}
                  completed={survey.completed ? survey.completed : false}
                  liked={survey.isLiked}
                  onLikeClick={onLikeClick}
                  isEditMode={false}
                  isPinned={survey.isPinned}
                />
              </div>
            ) : (
              <>
                <h2>Survey: {survey.title}</h2>
                {survey.source === SurveySource.TYPEFORM && <Box id="survey-display-container" />}
                {survey.source === SurveySource.QUALTRICS && (
                  <Box id="qualtrics-wrapper">
                    <iframe title="qualtrics" id="qualtrics-iframe" src={generateQualtricsURL(survey.externalId)} />
                  </Box>
                )}
              </>
            ))}
          {isSurveyComplete && (
            <SurveyComplete
              surveyId={survey._id}
              points={survey.points}
              completedSurveys={user.currentCompletedSurveysCounter ?? 0}
              spinnerBonus={spinnerBonus || 0}
              awardedPoints={0}
              completedSurvey={completedSurvey}
            />
          )}
          {hasReceivedCompletionBonus && (
            <SurveyComplete
              surveyId={survey._id}
              points={survey.points}
              completedSurveys={user.currentCompletedSurveysCounter ?? 0}
              spinnerBonus={spinnerBonus || 0}
              awardedPoints={1000}
            />
          )}
        </Box>
      )}
    </Container>
  );
};

export default SurveyDisplay;
