import { Box, LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import "./ProgressBar.scss";

type ProgressBarProps = LinearProgressProps & { value: number; messages?: string[] };

const ProgressBar = (props: ProgressBarProps) => {
  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>

      {!!props.messages?.length && (
        <div className="progress-bar-messages">
          {props.messages?.map((message, index) => (
            <Typography key={index} variant="body2" color="text.secondary">
              {message}
            </Typography>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
