export interface IAuthFunctionalities {
  /* Multiple Factor authentication, you can select between 3 methods to process the user authentication (SMS Default) */
  MFA?: MFAType;
  RegisterAuxiliaryParams?: Array<IRegisterParam>;
  RememberDevice?: boolean;
  ForgotPassword?: boolean;
  SocialMedia?: Array<SocialMedia>;
  LoginType?: "BASIC" | "MINIMAL";
}

export enum SocialMedia {
  Cognito = "COGNITO",
  Google = "Google",
  Facebook = "Facebook",
  Amazon = "LoginWithAmazon",
  Apple = "SignInWithApple",
}

export enum LoginType {
  Basic = "BASIC",
  Minimal = "MINIMAL",
}

export interface IRegisterParam {
  key: string;
  value: string | number;
  rexCode: string;
  errorMessage: string;
}

export enum MFAType {
  /* OTP verification code by SMS message */
  SMS = "SMS",

  /* OTP verification code by Email message */
  EMAIL = "EMAIL",

  /* OTP verification code by SMS and Email message */
  FULL = "FULL",
}
