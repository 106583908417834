import { Dialog, Input, InputLabel, Button, Paper, Snackbar, Alert, AlertColor } from "@mui/material";
import closeIcon from "src/assets/icon-close.svg";
import backIcon from "src/assets/icon-back-arrow.svg";
import React, { useContext, useState } from "react";
import EmailService from "src/services/api/emailService";
import { AuthContext } from "src/contexts/authContext";
import "./ContactUsDialog.scss";
import SnackBarClose from "../common/SnackbarClose";

const ContactUsDialog = (props: any) => {
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [snackbarMessageType, setSnackbarMessageType] = useState<AlertColor>("info");
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const { user } = useContext(AuthContext);

  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const { onClose, onSubmit, open, to = process.env.REACT_APP_SENDER_EMAIL, from = user?.email } = props;
  const mobileSize = window.innerWidth < 600;
  const handleClose = () => {
    onClose();
  };
  const onFormSubmit = async () => {
    try {
      const res = await EmailService.sendEmailContactUs({ subject, message });
      if (typeof onSubmit === "function") {
        onSubmit(res, undefined);
      } else {
        setSnackbarMessageType("success");
        setSnackbarMessage(res.data.message);
        setSnackbarOpen(true);
        handleClose();
      }
    } catch (error: any) {
      if (typeof onSubmit === "function") {
        onSubmit(undefined, error);
      } else {
        setSnackbarMessageType("error");
        setSnackbarMessage(error.response?.data?.message);
        setSnackbarOpen(true);
        handleClose();
      }
    }
  };

  return (
    <>
      <Dialog
        fullScreen={mobileSize}
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: { borderRadius: mobileSize ? 0 : 28 },
        }}
      >
        <Paper className="contact-us-paper">
          <div className="contact-us-dialog">
            <div className="contact-us-close" onClick={handleClose}>
              <img src={mobileSize ? backIcon : closeIcon} alt="ContactUs" />
            </div>
            <div className="contact-us-header">Contact Support</div>
            <InputLabel className="contact-us-label">To</InputLabel>
            <Input disabled disableUnderline fullWidth readOnly value={to} className="contact-us-input" />
            <div className="contact-us-delimiter"></div>
            <InputLabel className="contact-us-label">From</InputLabel>
            <Input disabled disableUnderline fullWidth readOnly value={from} className="contact-us-input" />
            <div className="contact-us-delimiter"></div>
            <Input
              fullWidth
              disableUnderline
              placeholder="Subject"
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
              className="contact-us-input"
            />
            <div className="contact-us-delimiter"></div>
            <Input
              fullWidth
              disableUnderline
              placeholder="Message"
              value={message}
              multiline
              maxRows={mobileSize ? 7 : 4}
              onChange={(event) => setMessage(event.target.value)}
              className="contact-us-input"
            />
            <Button
              color="primary"
              variant="contained"
              onClick={() => onFormSubmit()}
              disabled={!subject.length || !message.length}
              className="contact-us-submit"
            >
              Submit
            </Button>
          </div>
        </Paper>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        action={<SnackBarClose handleClose={handleCloseSnackbar} />}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarMessageType} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactUsDialog;
