import React, { useContext, useEffect } from "react";
import AuthStackNavigation from "../../routes/authStackNavigator";

import AuthProvider, { AuthIsSignedIn, AuthIsNotSignedIn, AuthContext } from "src/contexts/authContext";
import { IAuthAWS } from "../../interfaces/IAuthAWS";
import { IAuthFunctionalities } from "../../interfaces/IAuthFunctionalities";

interface IProps {
  awsConfig: IAuthAWS;
  theme?: undefined;
  authConfig?: IAuthFunctionalities;
}

const AuthModule: React.FC<IProps> = ({ awsConfig, theme, authConfig, children }) => {
  const {user ,refreshUser } = useContext(AuthContext);

  useEffect(() => {
    if (refreshUser == null || user) {
      return;
    }
    refreshUser(false);
  }, [refreshUser]);

  return (
    <AuthProvider config={awsConfig} authConfig={authConfig}>
      <AuthIsSignedIn>{children}</AuthIsSignedIn>
      <AuthIsNotSignedIn>
        <AuthStackNavigation />
      </AuthIsNotSignedIn>
    </AuthProvider>
  );
};

export default AuthModule;
