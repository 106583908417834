import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import verasightLogo from "../../assets/landing-page/verasight-logo-community.svg";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import LogoutIcon from "@mui/icons-material/Logout";

import "./AdminNavMenu.scss";
import { AuthContext, logoutAction } from "../../contexts/authContext";
import EnvInfo from "src/components/EnvInfo/EnvInfo";

const pages = [
  {
    name: "Surveys",
    slug: "surveys",
    icon: <FormatListBulletedIcon />,
  },
  {
    name: "Users",
    slug: "users",
    icon: <PersonOutlineOutlinedIcon />,
  },
  {
    name: "Invitations",
    slug: "invitations",
    icon: <MailOutlineOutlinedIcon />,
  },
  {
    name: "Researchers",
    slug: "researchers",
    icon: <GroupsIcon />,
  },
  {
    name: "Settings",
    slug: "settings",
    icon: <SettingsOutlinedIcon />,
  },
  {
    name: "Chamber of Secrets",
    slug: "secret",
    icon: null,
  },
];

// these accounts are allowed to bulk create test users
export const emailsAllowedToCreateTestUsers = ["craig.tataryn+verasight-admin@warecorp.com"];

const AdminNavMenu = () => {
  const navigate = useNavigate();
  const [image, setImage] = React.useState<string | null>(null);
  const [name, setName] = React.useState<string>("Admin");
  const [needToRefresh, setNeedToRefresh] = React.useState<boolean>(false);
  const { user, refreshUser } = React.useContext(AuthContext);

  const onLogoutClick = async () => {
    await logoutAction();
    window.location.reload();
  };

  React.useEffect(() => {
    if (!user) {
      setNeedToRefresh(true);
    } else {
      user.image && setImage(user.image);
      user.firstName && setName(user.firstName);
    }
  }, [user]);

  React.useEffect(() => {
    if (refreshUser == null || !needToRefresh) {
      return;
    }
    refreshUser(false);
  }, [refreshUser, needToRefresh]);

  return (
    <div className="admin-nav-wrapper">
      <div className="logo-wrapper">
        <img src={verasightLogo} alt="Verasight Logo" onClick={() => navigate(`/`)} />
      </div>

      <Box className="nav-menu-items-wrapper">
        {pages.map((page) => {
          if (page.slug === "secret" && !emailsAllowedToCreateTestUsers.includes(user?.email || "")) {
            return null;
          }
          return (
            <Link to={page.slug} key={page.name} className="nav-menu-item">
              {page.icon}
              {page.name}
            </Link>
          );
        })}
      </Box>

      <div className="profile-wrapper">
        <div className="image-wrapper">
          {image ? <img src={image} alt="user-avatar" /> : <PersonOutlineOutlinedIcon />}
        </div>
        {name}
        <div className="logout-wrapper" onClick={() => onLogoutClick()}>
          <LogoutIcon />
        </div>
      </div>
      <EnvInfo />
    </div>
  );
};
export default AdminNavMenu;
