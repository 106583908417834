import { RedeemedPoints } from "./../types/User";
import { maxBy } from "lodash";
import { User } from "src/types/User";
import { CashoutVendorEnum } from "../types/generic";

export const clickLogout = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "click_logout",
    destinationPageTitle: "Login screen",
  });
};

export const completeCashout = (user: User, method: CashoutVendorEnum) => {
  const now = new Date();
  const m = now.getMonth() + 1;
  const d = now.getDate();
  const y = now.getFullYear();
  const nowDateStr = `${m}/${d}/${y}`;

  // Find number of surveys completed since previous cashout
  const mostRecentCashout = maxBy(user.redeemedPoints, (redemption: RedeemedPoints) => redemption.createdAt);

  const mostRecentCashoutDate =
    mostRecentCashout == null
      ? new Date("1900-01-01") /* just use an old date if no recent cashouts */
      : new Date(mostRecentCashout.createdAt);

  const numSurveysSinceLastCashout = user.completedSurveys.filter((survey) => {
    return new Date(survey.createdAt) > mostRecentCashoutDate;
  }).length;

  // Send event
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: `complete_cashout_${method}`,
    [`complete_cashout_${method}_date`]: nowDateStr,
    user_email: user.email,
    user_surveys_completed_total: user.completedSurveyCount,
    user_surveys_completed: numSurveysSinceLastCashout,
    user_point_total: user.totalPoints,
  });
};

export const clickCashoutButton = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "click_cashout_button",
    destinationPageTitle: "Cash Out screen",
  });
};

export const visitSurveyHome = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "visit_survey_home",
    pageTitle: "Survey screen",
  });
};

export const visitLoginScreen = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "visit_login_screen",
    pageTitle: "Login screen",
  });
};

export const visitRegistrationScreen = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "visit_registration_screen",
    pageTitle: "Signup screen",
  });
};

export const clickHomeIcon = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "click_home_icon",
    destinationPageTitle: "Survey screen",
  });
};

export const completeRegistration = (
  name: string,
  email: string,
  phone: string,
  invited: boolean,
  registration_date: any,
  email_verified: boolean,
  phone_verified: boolean,
  surveys_completed: number
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "complete_registration",
    name: name,
    email: email,
    phone: phone,
    invited: invited,
    registration_date: `${
      registration_date.getMonth() + 1 + "/" + registration_date.getDate() + "/" + registration_date.getFullYear()
    }`,
    email_verified: email_verified,
    phone_verified: phone_verified,
    surveys_completed: surveys_completed,
    // user_interests: ,
  });
};
export const completedInterests = (email: string, user_interests: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "complete_interests",
    email: email,
    user_interests: user_interests,
  });
};
export const completeLogin = (
  name: any,
  email: string,
  phone: any,
  invited: boolean,
  registration_date: any,
  surveys_completed: any
) => {
  const registrationDate = new Date(registration_date);
  const registrationDateFormatted =
    registrationDate.getMonth() + 1 + "/" + registrationDate.getDate() + "/" + registrationDate.getFullYear();
  const difference = new Date().getTime() - registrationDate.getTime();
  const days_since_registration = Math.floor(difference / (1000 * 3600 * 24));
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "complete_login",
    name: name,
    email: email,
    phone: phone,
    invited: invited,
    registration_date: registrationDateFormatted,
    days_since_registration: days_since_registration,
    login_surveys_completed: surveys_completed,
  });
};
export const clickSurveyLink = (click_survey_points: any, click_survey_category: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "click_survey_link",
    click_survey_points: click_survey_points,
    click_survey_category: click_survey_category,
  });
};

export const completeSurvey = (
  survey_completion_date: any,
  user_surveys_completed: number,
  complete_survey_date: Date,
  penultimate_survey_created_at: any,
  survey_points_added: number,
  complete_survey_user_point_total: number,
  complete_survey_spinny_wheel: boolean,
  complete_survey_points: number,
  complete_survey_category: string
) => {
  const completeSurveyDate = new Date(complete_survey_date);
  const penultimateSurveyCreatedAt = new Date(penultimate_survey_created_at);
  const difference = completeSurveyDate.getTime() - penultimateSurveyCreatedAt.getTime();
  const days_since_last_survey = Math.floor(difference / (1000 * 3600 * 24));
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "complete_survey",
    survey_completion_date: `${
      new Date(survey_completion_date).getMonth() +
      1 +
      "/" +
      new Date(survey_completion_date).getDate() +
      "/" +
      new Date(survey_completion_date).getFullYear()
    }`.toString(),
    user_surveys_completed: user_surveys_completed,
    complete_survey_date: `${
      new Date(complete_survey_date).getMonth() +
      1 +
      "/" +
      new Date(complete_survey_date).getDate() +
      "/" +
      new Date(complete_survey_date).getFullYear()
    }`,
    days_since_last_survey: days_since_last_survey,
    survey_points_added: survey_points_added,
    complete_survey_user_point_total: complete_survey_user_point_total,
    complete_survey_spinny_wheel: complete_survey_spinny_wheel,
    complete_survey_points: complete_survey_points,
    complete_survey_category: complete_survey_category,
  });
};
