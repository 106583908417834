import { useState, useEffect } from "react";
import * as yup from "yup";

export const useValidZip = (initialValue: string) => {
  const [zip, setZip] = useState(initialValue);
  const [zipError, setZipError] = useState<string | null>(null);

  useEffect(() => {
    const zipSchema = yup.object().shape({
      zip: yup.string().matches(/^[0-9]{5}(?:-[0-9]{4})?$/),
    });

    const isValid = zipSchema.isValidSync({ zip });
    setZipError(isValid ? null : "Zip code is not valid.");
  }, [zip]);

  return { zip, setZip, zipError };
};
