import { AxiosResponse } from "axios";
import { CheckIsPhoneNumberVoIPType, VerifyCaptchaType } from "../../types/User";
import apiService from "./apiService";
import endpoints from "./endpoints";

export type reCaptType = {
  challenge_ts: string;
  hostname: string;
  success: boolean;
  'error-codes'?: string[];
}

export default class AuthService {
  static verifyCaptcha( data: VerifyCaptchaType) {
    return apiService.post<any, AxiosResponse<reCaptType>>(endpoints.verifyCaptcha, data);
  }

  static checkIsPhoneNumberVoIP(data: CheckIsPhoneNumberVoIPType) {
    return apiService.post(endpoints.checkIsPhoneNumberVoIP, data);
  }
}
