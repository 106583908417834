import dayjs from "dayjs";
import { PickersTimezone } from "@mui/x-date-pickers/models";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface Props {
  value: Date | null;
  setValue: (date: Date | null) => void;
  currentTimezone: PickersTimezone;
}

export default function CustomDatePicker({ value, setValue, currentTimezone }: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value && dayjs(value)}
        onChange={(newValue: any) => {
          setValue(new Date(newValue.toISOString()));
        }}
        timezone={currentTimezone}
      />
    </LocalizationProvider>
  );
}
