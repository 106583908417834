import { Button, IconButton } from "@mui/material";

type SnackBarCloseProps = {
  handleClose: any;
};

const SnackBarClose = ({ handleClose }: SnackBarCloseProps) => {
  return (
    <>
      <Button style={{ color: "#2ebdee" }} size="small" onClick={handleClose}>
        Close
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} />
    </>
  );
};

export default SnackBarClose;
