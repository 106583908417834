import React, { useContext, useState, useEffect } from "react";

import { AuthContext } from "../../contexts/authContext";
import { Grid, Paper, Button } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { useValidPassword } from "../../hooks/useValidPassword";
import { useValidEmail } from "../../hooks/useValidEmail";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  backgroundStyle,
  backgroundStyleCenter,
  btnStyle,
  forgotPasswordStyle,
  inputStyle,
  labelStyle,
  logoStyle,
  paperStyle,
  rememberStyle,
  checkBoxStyle,
  pStyle,
  btnStyleDisabled,
  notice,
} from "./styles";
import { IconButton, InputAdornment, OutlinedInput, Snackbar } from "@mui/material";
import verasightLogo from "../../assets/landing-page/verasight-logo-community.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { visitLoginScreen } from "../../helpers/dataLayers";
import { LoadingButton } from "@mui/lab";
import { getItemIDB } from "../../services/indexedDb";
import SnackBarClose from "../../components/common/SnackbarClose";

enum BannedErrorMessagesEnum {
  Disabled = "user is disabled.",
  NotExist = "user does not exist.",
  AllowedMessage = "Platform maintenance",
}

enum CognitoSignInErrorCodes {
  UserNotFound = "UserNotFoundException",
  UserNotConfirmed = "UserNotConfirmedException",
}

const SignIn: React.FC<any> = () => {
  /* States and context declaration */
  const location = useLocation();
  const { email, setEmail, emailError } = useValidEmail("");
  const { password, setPassword } = useValidPassword("");
  const [showPassword, setshowPassword] = useState<boolean>(false);
  const navigation = useNavigate();
  const authContext = useContext(AuthContext);
  const [genericMessage, setGenericMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [signUpUserData, setSignUpUserData] = useState<any>(null);
  const { user, refreshUser } = useContext(AuthContext);

  useEffect(() => {
    visitLoginScreen();
    getUserData().catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    const { successMessage } = (location?.state as Record<string, string>) || {};
    if (successMessage) {
      setGenericMessage(successMessage);
      setOpen(true);
    }
  }, [location?.state]);

  const getUserData = async () => {
    const userData = await getItemIDB("signUpUserData");
    setSignUpUserData(userData);
  };

  const getRedirectUrl = () => {
    const url = sessionStorage.getItem("redirectUrl");
    sessionStorage.removeItem("redirectUrl");
    return url;
  };

  const onLoginPressed = async (e: any) => {
    setLoading(true);
    e.stopPropagation();
    if (email == null || password == null || authContext.signInWithEmailnoRedirect == null) {
      return;
    }
    authContext
      .signInWithEmailnoRedirect(email, password)
      .then(async () => {
        if (refreshUser != null && !user) {
          refreshUser(true);
        }

        const redirectUrl = getRedirectUrl();
        if (redirectUrl) navigation(redirectUrl);
      })
      .catch((error: any) => {
        setLoading(false);
        let errMessage = error.message as string;
        if (errMessage.toLowerCase() === BannedErrorMessagesEnum.Disabled) {
          errMessage = BannedErrorMessagesEnum.AllowedMessage;
        }
        if (error.code === CognitoSignInErrorCodes.UserNotFound) {
          errMessage = `Incorrect username or password.`;
        }
        setGenericMessage(errMessage);
        if (error.code === CognitoSignInErrorCodes.UserNotConfirmed) {
          if (authContext.setSignUpUserData) {
            const signUpdData = signUpUserData
              ? JSON.parse(signUpUserData)
              : {
                  email,
                  passwordAdvance: password,
                };
            authContext.setSignUpUserData(signUpdData);
          }
          authContext.reSendCode(email).catch();
          navigation(`/register/verify-phone`);
        }
        setOpen(true);
      });
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const validationErrors = emailError !== null || email === "" || password === "";

  const onOutlinedInputKeyDown = async (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !validationErrors) {
      await onLoginPressed(e);
    }
  };

  const navigateWithParams = () => {
    navigation(`/register/${window.location.search}`);
  };

  return (
    <div style={{ ...backgroundStyle, ...backgroundStyleCenter }}>
      <Paper
        style={{
          ...paperStyle,
          boxShadow: "23px 32px 50px rgba(0, 0, 0, 0.12)",
          maxWidth: "600px",
        }}
      >
        <div style={{ margin: "0px auto", width: "100%" }}>
          <Grid style={logoStyle}>
            <img src={verasightLogo} alt="Verasight Logo" style={{ width: "150px" }} />
          </Grid>
          <div style={notice}>
            Welcome to Verasight! Sign-in or register below to earn Venmo payments or gift cards for taking short
            surveys!
          </div>
          <p style={pStyle}>Email</p>
          <OutlinedInput
            size="medium"
            placeholder="Email"
            fullWidth
            required
            value={email || ""}
            error={emailError !== null || email === ""}
            style={inputStyle}
            onChange={(e: any) => setEmail(e.target.value)}
            onKeyDown={onOutlinedInputKeyDown}
          />
          <p style={pStyle}>Your Password</p>
          <OutlinedInput
            size="medium"
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            fullWidth
            required
            value={password || ""}
            error={password === ""}
            style={inputStyle}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  onClick={() => {
                    setshowPassword(!showPassword);
                  }}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            onChange={(e: any) => setPassword(e.target.value)}
            onKeyDown={onOutlinedInputKeyDown}
          />
          <div style={labelStyle}>
            <FormControlLabel style={rememberStyle} control={<Checkbox style={checkBoxStyle} />} label="Remember me" />
            <Link to="/forgot-password" style={forgotPasswordStyle}>
              Forgot password
            </Link>
          </div>
          <LoadingButton
            loading={loading}
            type="submit"
            variant="outlined"
            style={{
              ...(validationErrors ? btnStyleDisabled : btnStyle),
              marginTop: "60px",
            }}
            disabled={validationErrors}
            fullWidth
            onClick={onLoginPressed}
          >
            Sign In
          </LoadingButton>
          <Button
            type="submit"
            variant="outlined"
            style={{
              ...btnStyle,
              backgroundColor: "white",
              color: "#5F6F74",
              border: "1px solid rgba(95, 111, 116, 1)",
              marginTop: "20px",
            }}
            fullWidth
            onClick={navigateWithParams}
          >
            Sign Up
          </Button>
        </div>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={genericMessage}
        action={<SnackBarClose handleClose={handleClose} />}
      />
    </div>
  );
};

export default SignIn;
