import ProgressDialog, { ProgressBarType } from "../../../../components/Progress/ProgressDialog";

type CsvUploadProgressProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  progress: number;
};

export const POLLING_INTERVAL = 2000;

const CsvUploadProgress = ({ open, setOpen, progress }: CsvUploadProgressProps) => {
  return (
    <ProgressDialog
      type={ProgressBarType.PERCENTAGE}
      open={open}
      onClose={() => setOpen(false)}
      title="Uploading CSV of target users"
      value={progress}
    />
  );
};

export default CsvUploadProgress;
