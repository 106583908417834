import { AxiosPromise } from "axios";
import { EmailContactUs, EmailTemplate } from "../../types/Email";
import apiService from "./apiService";
import endpoints from "./endpoints";

export default class EmailService {
  static sendEmailContactUs(data: EmailContactUs) {
    return apiService.post(endpoints.emailContactus, data);
  }

  static getTemplates(): AxiosPromise<EmailTemplate[]> {
    return apiService.get(endpoints.emailTemplates);
  }

  static getTemplate(id: string): AxiosPromise<EmailTemplate> {
    return apiService.get(endpoints.emailTemplate(id));
  }
}
