import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import verasightLogo from "../../assets/landing-page/verasight-logo-community.svg";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutIcon from "@mui/icons-material/Logout";

import "./ResearcherNavMenu.scss";
import { AuthContext, logoutAction } from "../../contexts/authContext";
import EnvInfo from "src/components/EnvInfo/EnvInfo";
import { Button } from "@mui/material";

const ResearcherNavMenu = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState<string | null>(null);
  const [name, setName] = useState<string>("Researcher");
  const [needToRefresh, setNeedToRefresh] = useState<boolean>(false);
  const { user, refreshUser } = useContext(AuthContext);

  const onLogoutClick = async () => {
    await logoutAction();
    window.location.reload();
  };

  useEffect(() => {
    if (!user) {
      setNeedToRefresh(true);
    } else {
      user.image && setImage(user.image);
      user.firstName && setName(user.firstName);
    }
  }, [user]);

  useEffect(() => {
    if (refreshUser == null || !needToRefresh) {
      return;
    }
    refreshUser(false);
  }, [refreshUser, needToRefresh]);

  return (
    <div className="researcher-nav-wrapper">
      <Button className="logo-wrapper" onClick={() => navigate(`/`)}>
        <img src={verasightLogo} alt="Verasight Logo" />
      </Button>

      <div className="profile-wrapper">
        <Button onClick={() => navigate("/researcher/profile/account-details")} className="image-wrapper">
          {image ? <img src={image} alt="user-avatar" /> : <PersonOutlineOutlinedIcon />}
        </Button>
        {name}
        <Button className="logout-wrapper" onClick={() => onLogoutClick()}>
          <LogoutIcon />
        </Button>
      </div>
      <EnvInfo />
    </div>
  );
};
export default ResearcherNavMenu;
