import Dialog from "@mui/material/Dialog";
import DialogIcon from "./DialogIcon";
import VeraButton from "../../../../components/VeraButton";

const InformativeDialog = (props: any) => {
    const { onClose, userId, userName, onAction, open, handleOpenUploadCSVFileDialog, errorText } = props;

    const handleClose = () => {
        onClose();
    };

    const uploadNewFile = () => {
        handleOpenUploadCSVFileDialog("Upload CSV File to add tags","Tags")
        onClose();
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open?.length > 0}
            PaperProps={{
                style: { borderRadius: 28 }
            }}
        >
            <div className="dialog-content">
                <DialogIcon open={open} />
                
                {(open === "disable" || open === "delete") && <p className="dialog-title">Are you sure you want to {open} this user?</p>}
                {(open === "removeRow") && <p className="dialog-title">Are you sure you want to remove this row?</p>}
                {(open === "disable" || open === "delete") &&<p className="dialog-user-name">{userName}</p> }
                {(open === 'addPointsToBatchOfUserSubmitted') &&<p className="dialog-user-name">Points successfully added!</p> }
                {(open === 'addTagsToBatchOfUserSubmitted') &&<p className="dialog-user-name">Tags successfully added!</p> }
                {(open === 'error' && !errorText) &&<p className="dialog-user-name">There was an error</p> }
                {(open === 'error' && errorText && errorText.length > 0) &&<p className="dialog-user-name">The format is incorrect</p> }
                
                <div className="buttons-wrapper">
                {(open === "disable" || open === "delete") &&
                    <>
                        <VeraButton variant="text" onClick={onClose} sx={{ border: "none" }}>
                            Cancel
                        </VeraButton>
                        <VeraButton variant="outlined" onClick={() => onAction(userId)} className="dialog-disable-button">
                            {open === "delete" && "Delete"}
                            {open === "disable" && "Disable"}
                        </VeraButton>
                    </>
                }
                {(open === "removeRow") &&
                    <>
                        <VeraButton variant="text" onClick={onClose} sx={{ border: "none" }}>
                            Cancel
                        </VeraButton>
                        <VeraButton variant="outlined" onClick={() => onAction(userId)} className="dialog-disable-button">
                            Remove Row
                        </VeraButton>
                    </>
                }
                {(open === "error" )  &&
                   <>
                        <VeraButton variant="text" onClick={onClose} sx={{ border: "none" }}>
                            Cancel
                        </VeraButton>
                        <VeraButton variant="outlined" onClick={() => uploadNewFile()} className="dialog-upload-button" style={{width: '264px'}}>
                            Upload New File
                        </VeraButton>
                    </>
                }
                {(open === "addPointsToBatchOfUserSubmitted" || open === "addTagsToBatchOfUserSubmitted")  &&
                    <VeraButton variant="outlined" onClick={onClose} className="dialog-upload-button" style={{width: '264px'}}>
                        Close
                    </VeraButton>
                }
                </div>
            </div>
        </Dialog>
    );
}

export default InformativeDialog;