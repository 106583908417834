import { format, parseISO, intlFormat } from "date-fns";

export const convertDatetimeStringToReadable = (
  datetime: string,
  formatString = "MMM dd, yyyy",
  defaultValue = "--"
) => {
  try {
    return format(parseISO(new Date(datetime).toISOString()), formatString);
  } catch (err) {
    return defaultValue;
  }
};

export const convertDateStringToLocal = (
  dateTime: string,
  opts?: {
    localeMatcher?: "lookup" | "best fit";
    weekday?: "narrow" | "short" | "long";
    era?: "narrow" | "short" | "long";
    year?: "numeric" | "2-digit";
    month?: "numeric" | "2-digit" | "narrow" | "short" | "long";
    day?: "numeric" | "2-digit";
    hour?: "numeric" | "2-digit";
    minute?: "numeric" | "2-digit";
    second?: "numeric" | "2-digit";
    timeZoneName?: "short" | "long";
    formatMatcher?: "basic" | "best fit";
    hour12?: boolean;
    timeZone?: string;
  }
) => {
  return intlFormat(new Date(dateTime), opts);
};

export const getIsoString = (date?: Date): string | null => (date ? new Date(date).toISOString() : null);
