import * as yup from "yup";
import { useState, useEffect } from "react";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
const validCodes = [
  "326",
  "839",
  "447",
  "448",
  "474",
  "572",
  "582",
  "742",
  "771",
  "840",
  "945",
  "263",
  "350",
  "354",
  "368",
  "464",
  "468",
  "472",
  "557",
  "584",
  "656",
  "683",
  "826",
  "835",
  "943",
  "948",
  "983",
  "227",
  "274",
  "283",
  "329",
  "353",
  "363",
  "369",
  "382",
  "428",
  "532",
  "624",
  "633",
  "645",
  "728",
  "730",
  "861",
  "975",
];

const phoneUtil = PhoneNumberUtil.getInstance();

const isValidPhone = (s: string | undefined): boolean => {
  try {
    const valid = phoneUtil.isValidNumberForRegion(phoneUtil.parse(s, "US"), "US");
    if (s && !valid && phoneUtil.isPossibleNumber(phoneUtil.parse(s, "US"))) {
      const number = phoneUtil.parseAndKeepRawInput(s, "US");
      const code = String(number.getNationalNumber())?.slice(0, 3);
      return validCodes.includes(code);
    }
    return valid;
  } catch {
    return false;
  }
};

const parsePhone = (s?: string): string => {
  try {
    return phoneUtil.format(phoneUtil.parse(s, "US"), PhoneNumberFormat.E164);
  } catch {
    return "";
  }
};

export const useValidPhone = (initialValue: string) => {
  const [phone, setPhone] = useState(initialValue);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [parsedPhone, setParsedPhone] = useState(initialValue);

  useEffect(() => {
    const phoneSchema = yup.object().shape({
      phone: yup.string().test("is-phone", isValidPhone),
    });

    const isValid = phoneSchema.isValidSync({ phone });
    setPhoneError(isValid ? null : "Phone number is not valid.");
    setParsedPhone(parsePhone(phone ?? undefined));
  }, [phone]);

  return { phone, setPhone, phoneError, parsedPhone, setPhoneError };
};
