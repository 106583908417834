import { styled } from "@mui/material/styles";
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

const StyledTableHeadCell = styled(TableCell)({
  fontSize: "14px",
  lineHeight: "16px",
  fontWeight: "bold",
  color: "#91a3a9",
  textAlign: "left",
  padding: "10px 15px",
});

type TableHeadSortableProps = {
  order: "asc" | "desc";
  orderBy: string;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  headCells: { id: string; label: string }[];
};

const TableHeadSortable = ({ order, orderBy, onRequestSort, headCells }: TableHeadSortableProps) => {
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow key={"table-head"}>
        {headCells.map((headCell) => (
          <StyledTableHeadCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableHeadCell>
        ))}
        <StyledTableHeadCell />
      </TableRow>
    </TableHead>
  );
};

export default TableHeadSortable;
