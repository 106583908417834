import { Card, CardContent, Typography, Box, Divider } from "@mui/material";
import { format } from "date-fns";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import Loader from "src/components/Loader/Loader";
import { getErrorMessage } from "src/helpers/errors";
import SurveysService from "src/services/api/surveysService";
import { SurveyIncludesEnum, SurveyType } from "src/types/Survey";

type Props = {
  id: string;
};

const SurveyStats = ({ id }: Props) => {
  const [survey, setSurvey] = useState<SurveyType>();

  useEffect(() => {
    if (id) {
      SurveysService.getSurvey(id, { includes: [SurveyIncludesEnum.stats] })
        .then((response) => {
          setSurvey(response.data);
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar(getErrorMessage(err), { variant: "error" });
        });
    }
  }, [id]);

  return !survey ? (
    <Loader />
  ) : (
    <Card sx={{ minWidth: 500, margin: "auto" }}>
      <CardContent>
        <Typography variant="h5" gutterBottom align="center">
          Survey Statistics
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <Box mb={2}>
          <Typography variant="body1" color="textSecondary">
            <strong>Users Completed:</strong> {survey.stats?.completedUsers ?? 0}
          </Typography>
        </Box>

        <Box mb={2}>
          <Typography variant="body1" color="textSecondary">
            <strong>Users Opted-Out:</strong> {survey.stats?.optedOutUsers ?? 0}
          </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box mb={2}>
          <Typography variant="body1" color="textSecondary">
            <strong>Start Time:</strong>{" "}
            {survey.start ? format(new Date(survey.start), "MMMM d, yyyy HH:mm:ss") : "To be started"}
          </Typography>
        </Box>

        {survey.end ? (
          <Box mb={2}>
            <Typography variant="body1" color="textSecondary">
              <strong>End Time:</strong> {format(new Date(survey.end), "MMMM d, yyyy HH:mm:ss")}
            </Typography>
          </Box>
        ) : null}
      </CardContent>
    </Card>
  );
};

export default SurveyStats;
