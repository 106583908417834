import React, { useContext, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import PublicNavMenu from "../../components/PublicNavLayout/PublicNavMenu";

import "../../styles/main.scss";
import { AuthContext } from "src/contexts/authContext";
import { visitSurveyHome } from "../../helpers/dataLayers";
import { useMobileSize } from "../../hooks/useMobileSize";
import { UserRoles } from "../../types/User";

const PublicLayout = () => {
  const { user, refreshUser } = useContext(AuthContext);
  const { isMobile, isProfileUrl, setIsProfileUrl } = useMobileSize();
  const [profileTabsActive, isProfileTabsActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    isProfileTabsActive(
      path.includes("profile/account-details") || path.includes("profile/security") || path.includes("/profile/rewards")
    );
  }, [location.pathname]);

  useEffect(() => {
    if (refreshUser == null || user) {
      return;
    }
    refreshUser(false);
  }, [refreshUser, user]);

  useEffect(() => {
    if (window.location.pathname === "/") {
      visitSurveyHome();
    }
  }, []);

  if (user == null) {
    return null;
  }

  if (user.role === UserRoles.SUPER_ADMIN) {
    return <Navigate to={"/admin/surveys"} replace />;
  } else if (user.role === UserRoles.RESEARCHER) {
    return <Navigate to={"/researcher/surveys"} replace />;
  }

  return (
    <div style={{ minHeight: "100vh", width: "100vw" }}>
      {isMobile && profileTabsActive ? <></> : <div className="home-top-color" />}
      <PublicNavMenu setIsProfileUrl={setIsProfileUrl} />
      <Outlet context={{ isProfileUrl, setIsProfileUrl }} />
    </div>
  );
};

export default PublicLayout;
