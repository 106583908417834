import { Backdrop, Box, Button, CircularProgress, Dialog, DialogContent, Grid, SvgIcon } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { FC, useContext, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import RewardsCard from "src/screens/public/RewardsPage/components/RewardsCard";
import UsersService from "src/services/api/usersService";
import { PREDEFINED_TAGS } from "src/types/Survey";
import { useSnackbar } from "notistack";
import { AuthContext } from "src/contexts/authContext";
import { completeCashout } from "src/helpers/dataLayers";
import QuestionDialog from "../../../components/QuestionDialog/QuestionDialog";
import { useNavigate } from "react-router-dom";
import { AuthStatus } from "../../../interfaces/IAuth";
import { isCaptchaEnabled } from "../../../helpers/generic";
import { CashoutVendorEnum } from "../../../types/generic";

const PAYPAL_SUCCESS_MSG = "An email has been sent to you with your payment.";
const VENMO_SUCCESS_MSG = "You will receive your Venmo payment in a few minutes.";
const GIFTCARD_SUCCESS_MSG =
  "You have successfully cashed out. To complete the process, we have sent you an email to redeem your reward.";

type VendorAmountType = {
  vendor: CashoutVendorEnum;
  amount: number;
};

type RewardsPageProps = {
  pointsPerDollar: number;
  vendorAmounts: VendorAmountType[];
};

const RewardsPage: FC<RewardsPageProps> = (props) => {
  const { pointsPerDollar, vendorAmounts } = props;
  const { user, refreshUser, setRedeemUserData, redeemUserData, setAuthStatus } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const [modalState, setModalState] = useState({ open: false, text: PAYPAL_SUCCESS_MSG });
  const [needToRefresh, setNeedToRefresh] = useState(false);
  const [openQuestionDialog, setOpenQuestionDialog] = useState(false);
  const [redeemQuestionText, setRedeemQuestionText] = useState("");
  const [redeemDataText, setRedeemDataText] = useState("");
  const [isCaptchaCaptured, setIsCaptchaCaptured] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [recaptchaToken, setReCaptchaToken] = useState<string>();
  const [openCaptchaDialog, setOpenCaptchaDialog] = useState(false);
  const captchaRef = useRef<ReCAPTCHA | null>(null);

  const navigation = useNavigate();

  useEffect(() => {
    if (redeemUserData && redeemUserData.isNotRedeemed) {
      const { points, vendor, method, dollarValue, recaptchaToken } = redeemUserData;
      if (!(points && vendor && method && dollarValue)) {
        return;
      }
      redeemPoints(points, vendor, method, dollarValue, recaptchaToken).catch((e) => console.error(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!refreshUser || !needToRefresh) {
      return;
    }
    refreshUser(false);
    setNeedToRefresh(false);
  }, [refreshUser, needToRefresh]);

  const closeModal = () => {
    setModalState((prev) => ({ ...prev, open: false }));
  };

  const getVendorRecipient = (vendor: CashoutVendorEnum) => {
    if (vendor === CashoutVendorEnum.PAYPAL) {
      return user?.payPalEmail ?? user?.email;
    }

    if (vendor === CashoutVendorEnum.VENMO) {
      return user?.venmoPhone ?? user?.phone;
    }

    if (vendor === CashoutVendorEnum.GIFT_CARD) {
      return user?.giftCardEmail ?? user?.email;
    }
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const onRedeem = async (
    points: number,
    dollarValue: number,
    vendor: CashoutVendorEnum,
    method: "email" | "phone"
  ) => {
    if (!user) {
      return;
    }
    await UsersService.isUserDisabled(user._id as string).catch((e) => {
      (setAuthStatus as React.Dispatch<React.SetStateAction<AuthStatus>>)(AuthStatus.SIGNED_OUT);
    });

    if (setRedeemUserData) {
      setRedeemUserData({
        points,
        dollarValue,
        vendor,
        method,
        recaptchaToken,
      });
    }

    const userVendorRecipient = getVendorRecipient(vendor);

    if (vendor === CashoutVendorEnum.VENMO) {
      setRedeemQuestionText(
        `Is this where you want your cash to go to? If not, please click "no" to edit the phone number where you would like to receive your cash.`
      );
    }

    if (vendor === CashoutVendorEnum.PAYPAL) {
      setRedeemQuestionText(
        `Is this where you want your cash to go to? If not, please click "no" to edit the email address  where you would like to receive your cash.`
      );
    }

    if (vendor === CashoutVendorEnum.GIFT_CARD) {
      setRedeemQuestionText(
        `Is this where you want your cash to go to? If not, please click "no" to edit the email address where you would like to receive your gift card.`
      );
    }

    setRedeemDataText(`${capitalizeFirstLetter(vendor)}: ${userVendorRecipient}`);

    if (!isCaptchaEnabled()) {
      setOpenQuestionDialog(true);
      return;
    }

    setOpenCaptchaDialog(true);
  };

  const redeemPoints = async (
    points: number,
    vendor: CashoutVendorEnum,
    method: "email" | "phone",
    dollarValue: number,
    token?: string
  ) => {
    setIsLoading(true);
    await UsersService.redeemPoints(points, vendor, method, token ?? recaptchaToken)
      .then((userResponse) => {
        if (setRedeemUserData) {
          setRedeemUserData({});
        }
        completeCashout(userResponse.data, vendor);

        if (vendor === CashoutVendorEnum.PAYPAL) {
          setModalState({ open: true, text: PAYPAL_SUCCESS_MSG });
        }

        if (vendor === CashoutVendorEnum.VENMO) {
          setModalState({ open: true, text: VENMO_SUCCESS_MSG });
        }

        if (vendor === CashoutVendorEnum.GIFT_CARD) {
          setModalState({ open: true, text: GIFTCARD_SUCCESS_MSG });
        }
        let message = `Redeemed ${points} points for $${dollarValue}`;
        if (vendor !== CashoutVendorEnum.GIFT_CARD) {
          message = `${points} points redemption request for $${dollarValue} received, we will process it shortly.`;
        }
        enqueueSnackbar(message, {
          variant: "success",
        });
      })
      .catch((error: any) => {
        if (setRedeemUserData) {
          setRedeemUserData({});
        }
        enqueueSnackbar(error?.response?.data?.message, { variant: "error" });
      })
      .finally(() => setIsLoading(false));

    if (refreshUser) {
      setNeedToRefresh(true);
    } else {
      enqueueSnackbar("Failed to refresh data. Try refreshing the page.", {
        variant: "warning",
      });
    }
  };

  const onSubmitQuestionDialog = (submit: boolean) => {
    if (!redeemUserData) {
      return;
    }
    const { points, vendor, method, dollarValue } = redeemUserData;
    if (!(points && vendor && method && dollarValue)) {
      return;
    }
    if (submit) {
      redeemPoints(points, vendor, method, dollarValue).catch((e) => console.error(e));
    } else {
      setIsCaptchaCaptured(false);
      navigation("/profile/redeem-attributes");
    }
    setOpenQuestionDialog(false);
  };

  const onCloseQuestionDialog = () => {
    setOpenQuestionDialog(false);
  };

  const onSubmitCaptchaDialog = (submit: boolean) => {
    setIsCaptchaCaptured(false);
    setOpenCaptchaDialog(false);
    if (submit) {
      setOpenQuestionDialog(true);
    }
  };

  const onCloseCaptchaDialog = () => {
    setIsCaptchaCaptured(false);
    setOpenCaptchaDialog(false);
  };

  const verifyCaptcha = async (token: string | null) => {
    if (captchaRef.current) {
      token = captchaRef.current.getValue();
      try {
        if (token?.length) {
          setReCaptchaToken(token);
          if (setRedeemUserData)
            setRedeemUserData({
              ...redeemUserData,
              recaptchaToken: token,
            });
          setIsCaptchaCaptured(true);
        } else {
          captchaRef.current.reset();
          setIsCaptchaCaptured(false);
        }
      } catch (error) {
        captchaRef.current.reset();
        setIsCaptchaCaptured(false);
      }
    }
  };

  const onCaptchaExpired = () => {
    if (captchaRef.current) {
      captchaRef.current.reset();
    }
    setIsCaptchaCaptured(false);
  };

  const userPointsBalance =
    user?.totalPoints != null && user?.redeemedPointsSum != null ? user.totalPoints - user.redeemedPointsSum : 0;
  const isNonGCAllowed = user?.targetingTagsList?.find(
    (tag) => tag?.tag?.toLowerCase() === PREDEFINED_TAGS.allowNonGiftCardCashouts.toLowerCase()
  );

  return (
    <>
      <Grid container direction="row" spacing={4} px={4} data-testid="rewards-page" justifyContent="center">
        <Backdrop
          open={user == null}
          transitionDuration={300}
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="primary" />
        </Backdrop>
        {vendorAmounts.map((val, i) => {
          if (!isNonGCAllowed && val.vendor !== CashoutVendorEnum.GIFT_CARD) {
            return null;
          }
          return (
            <Grid item key={i} data-testid={`card-${i}`}>
              <RewardsCard
                dollarValue={val.amount}
                vendor={val.vendor}
                userPointsBalance={userPointsBalance}
                pointsValue={val.amount * pointsPerDollar}
                onRedeemPointsClicked={onRedeem}
              />
            </Grid>
          );
        })}
        <Dialog open={modalState.open}>
          <DialogContent>
            {
              <Box display="flex" justifyContent="center">
                <SvgIcon component={CheckCircleIcon} color="success" fontSize="large" />
              </Box>
            }
            <Box display="flex" flexDirection="column" alignItems="center">
              <h4>Success!</h4>
              <p style={{ textAlign: "center", overflowWrap: "normal" }}>{modalState.text}</p>
              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                disableElevation
                onClick={closeModal}
                data-testid="cashout-success-modal-close"
              >
                Close
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Grid>
      <QuestionDialog
        onClose={onCloseQuestionDialog}
        onSubmit={onSubmitQuestionDialog}
        open={openQuestionDialog}
        text={redeemQuestionText}
        data-testid="redeem-dialog"
        disabledYesButton={isLoading}
      >
        {redeemDataText}
      </QuestionDialog>
      {isCaptchaEnabled() && (
        <QuestionDialog
          onClose={onCloseCaptchaDialog}
          onSubmit={onSubmitCaptchaDialog}
          open={openCaptchaDialog}
          text="Please confirm you are not a robot!"
          disabledYesButton={!isCaptchaCaptured}
          data-testid="captcha-dialog"
        >
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_SITE_ID ?? ""}
            ref={captchaRef}
            onChange={verifyCaptcha}
            onExpired={onCaptchaExpired}
            style={{ margin: "35px auto 0", width: "min-content" }}
          />
        </QuestionDialog>
      )}
    </>
  );
};

export default RewardsPage;
