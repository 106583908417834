import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import { TargetingSurveyToUsersEnum } from "../../../../types/Survey";
import { TargetingTag } from "../../../../types/TargetingTag";

type TargetingTagInputProps = {
  targetingTags: any;
  setTargetingTags: (targetingTags: TargetingTag[]) => void;
  availableTargetingTags: any;
  targetingSurveyToUsers: TargetingSurveyToUsersEnum;
  csvTag: string | null;
  onChangeCsvTag: any;
};

const TargetingTagInput = ({
  targetingTags,
  setTargetingTags,
  availableTargetingTags,
  targetingSurveyToUsers,
  csvTag,
  onChangeCsvTag,
}: TargetingTagInputProps) => {
  const onTagRemove = (e: Event, tagObj: TargetingTag) => {
    const newValue = targetingTags
      .filter((targetingTag: TargetingTag) => targetingTag.tag !== tagObj.tag)
      .map((targetingTag: TargetingTag) => targetingTag.tag);

    if (csvTag === tagObj.tag && tagObj.surveyId) {
      onChangeCsvTag(null, undefined);
    }

    onChangeTargetingTags(e, newValue, "removeOption");
  };

  const onChangeTargetingTags = (_event: any, newValue: any, reason: string) => {
    let targetingTagsResult = [...targetingTags];

    switch (reason) {
      case "removeOption":
        targetingTagsResult = targetingTagsResult.filter((tag: TargetingTag) => newValue.includes(tag.tag));
        break;
      case "selectOption":
        targetingTagsResult.push(newValue[newValue.length - 1]);
        break;
      case "clear":
        targetingTagsResult = [];
        break;
      default:
        break;
    }

    setTargetingTags(targetingTagsResult);
  };

  const decorateTagTitle = (tag: any) => {
    return `${tag.tag}${tag.surveyId ? "" : " 🌐"}`;
  };

  return (
    <Box>
      <Autocomplete
        multiple
        disabled={targetingSurveyToUsers !== TargetingSurveyToUsersEnum.TAG}
        id="targetingTags"
        options={availableTargetingTags}
        getOptionLabel={(option) => decorateTagTitle(option)}
        isOptionEqualToValue={(option, value) => option.tag === value}
        value={targetingTags.map(
          (targetingTag: TargetingTag) => targetingTag.tag + (targetingTag.surveyId ? "" : "") /*' 🌐'*/
        )}
        renderTags={() => null}
        renderInput={(params) => <TextField {...params} id={`${params}`} placeholder="Enter the tag" size="small" />}
        size="small"
        onChange={onChangeTargetingTags}
      />
      {targetingSurveyToUsers === TargetingSurveyToUsersEnum.TAG && (
        <div>
          {targetingTags.map((option: any) => (
            <Chip
              disabled={targetingSurveyToUsers !== TargetingSurveyToUsersEnum.TAG}
              variant="filled"
              id={option._id}
              key={option._id}
              size="small"
              label={`#${decorateTagTitle(option)}`}
              onDelete={(e) => onTagRemove(e, option)}
            />
          ))}
        </div>
      )}
    </Box>
  );
};

export default TargetingTagInput;
