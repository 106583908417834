import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import { authOptions } from "src/config/auth-config";
import * as Sentry from "@sentry/react";
import './tiktok';

Amplify.configure({ Auth: authOptions });

Sentry.init({
  dsn: "https://5abca2598b7424ef0f190d5be7b9f2e7@o4505585572904960.ingest.us.sentry.io/4507255827070976",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/backend\.verasight\.io/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function FallbackComponent() {
  return <div style={{ padding: "0 20px" }}>
    <h1>Something went wrong</h1>
    <p>Our team has been notified and we will look into the issue.</p>
  </div>;
}

const myFallback = <FallbackComponent />;

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
