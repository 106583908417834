import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import { Box, ListItemIcon } from "@mui/material";
import { InvitationCodeStatusEnum } from "../../../types/InvitationCode";
import { InvitationCodeFiltersType } from "./InvitationsList";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import styled from "@emotion/styled";
import FilterWrapper from "../../../components/common/FilterWrapper";
import SearchWrapper from "../../../components/common/SearchWrapper";

const DateRangeWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
});

type InvitationCodesFilterProps = {
  setPage: (page: number) => void;
  setFilters: (payload: InvitationCodeFiltersType) => void;
  setSearch: (search: string) => void;
};

const InvitationCodesFilter = ({ setPage, setFilters, setSearch }: InvitationCodesFilterProps) => {
  const [openFilters, setOpenFilters] = useState(false);

  const [statuses, setStatuses] = useState<InvitationCodeStatusEnum[]>([]);
  const [createdAtAfterDate, setCreatedAtAfterDate] = useState<string | null>(null);
  const [createdAtBeforeDate, setCreatedAtBeforeDate] = useState<string | null>(null);

  const [searchValue, setSearchValue] = useState<string>("");
  const [searchIsActive, setSearchIsActive] = useState<boolean>(false);

  const changeStatusFilter = (value: InvitationCodeStatusEnum) => {
    setStatuses((prev) => {
      if (prev.includes(value)) {
        return prev.filter((status) => status !== value);
      }
      return [...prev, value];
    });
  };

  const applyFilters = () => {
    setPage(1);
    setFilters({
      ...(statuses.length && { statuses }),
      ...(createdAtAfterDate && { createdAtAfterDate }),
      ...(createdAtBeforeDate && { createdAtBeforeDate }),
    });
    setOpenFilters(false);
  };

  const clearFilters = () => {
    setStatuses([]);
    setFilters({});
    setPage(1);
    setOpenFilters(false);
  };

  const onSearchClick = () => {
    setSearchIsActive(!!searchValue);
    setSearch(searchValue);
  };

  const onClearSearch = () => {
    setSearchValue("");
    setSearchIsActive(false);
    setSearch("");
  };

  return (
    <div className="filters-container">
      <FilterWrapper
        openFilters={openFilters}
        clearFilters={clearFilters}
        applyFilters={applyFilters}
        setOpenFilters={setOpenFilters}
      >
        <Box>
          <p className="option-title">Status</p>
          <ListItem key={InvitationCodeStatusEnum.Active} disablePadding sx={{ height: "35px" }}>
            <ListItemButton
              onClick={() => changeStatusFilter(InvitationCodeStatusEnum.Active)}
              dense
              sx={{ height: "100%" }}
            >
              <ListItemIcon sx={{ minWidth: "auto" }}>
                <Checkbox
                  edge="start"
                  checked={statuses.includes(InvitationCodeStatusEnum.Active)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={InvitationCodeStatusEnum.Active} />
            </ListItemButton>
          </ListItem>
          <ListItem key={InvitationCodeStatusEnum.Claimed} disablePadding sx={{ height: "35px" }}>
            <ListItemButton
              onClick={() => changeStatusFilter(InvitationCodeStatusEnum.Claimed)}
              dense
              sx={{ height: "100%" }}
            >
              <ListItemIcon sx={{ minWidth: "auto" }}>
                <Checkbox
                  edge="start"
                  checked={statuses.includes(InvitationCodeStatusEnum.Claimed)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={InvitationCodeStatusEnum.Claimed} />
            </ListItemButton>
          </ListItem>
          <ListItem key={InvitationCodeStatusEnum.Used} disablePadding sx={{ height: "35px" }}>
            <ListItemButton
              onClick={() => changeStatusFilter(InvitationCodeStatusEnum.Used)}
              dense
              sx={{ height: "100%" }}
            >
              <ListItemIcon sx={{ minWidth: "auto" }}>
                <Checkbox
                  edge="start"
                  checked={statuses.includes(InvitationCodeStatusEnum.Used)}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={InvitationCodeStatusEnum.Used} />
            </ListItemButton>
          </ListItem>
        </Box>

        <Box>
          <p className="option-title">Created at</p>

          <DateRangeWrapper>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={createdAtAfterDate && new Date(createdAtAfterDate)}
                onChange={(newValue: any) => {
                  setCreatedAtAfterDate(newValue);
                }}
              />
            </LocalizationProvider>

            <span>-</span>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={createdAtBeforeDate && new Date(createdAtBeforeDate)}
                onChange={(newValue: any) => {
                  setCreatedAtBeforeDate(newValue);
                }}
              />
            </LocalizationProvider>
          </DateRangeWrapper>
        </Box>
      </FilterWrapper>

      <SearchWrapper
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onSearchClick={onSearchClick}
        onClearSearch={onClearSearch}
        searchIsActive={searchIsActive}
      />
    </div>
  );
};

export default InvitationCodesFilter;
