import * as React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Stack from "@mui/material/Stack";
import { PickersTimezone } from "@mui/x-date-pickers/models";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Los_Angeles");

const TIMEZONES: PickersTimezone[] = ["system", "America/New_York", "America/Chicago", "America/Los_Angeles"];

interface Props {
  onTZChange: (zone: PickersTimezone) => void;
  customZones?: string[];
}

export default function TZPicker({ onTZChange, customZones = [] }: Props) {
  const [currentTimezone, setCurrentTimezone] = React.useState<string>("America/Los_Angeles");

  React.useEffect(() => {
    onTZChange(currentTimezone);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimezone]);

  return (
    <Stack spacing={2}>
      <ToggleButtonGroup
        value={currentTimezone}
        exclusive
        fullWidth
        onChange={(event, newTimezone) => {
          if (newTimezone != null) {
            setCurrentTimezone(newTimezone);
          }
        }}
      >
        {TIMEZONES.concat(customZones).map((timezoneName) => (
          <ToggleButton key={timezoneName} value={timezoneName}>
            {timezoneName}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
}
