import React, { useRef } from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import VeraButton from "../../../../components/VeraButton";
import CloseIcon from "@mui/icons-material/Close";

const UploadCSVFileDialog = (props: any) => {
    const { onClose, uploadCSVTitle, userBulkUpdateTitle,onUpload , setCSVFile, CSVFile, open } = props;
    const inputRef = useRef<any>(null);

    const handleClose = () => {
        onClose();
    };
    const handleFileChange = (e: any) => {
        const allowedExtensions = ["csv"];
        // setError("");

        if (e.target.files.length) {
            const inputFile = e.target.files[0];
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                // setError("Please input a csv file");
                return;
            }
            setCSVFile(inputFile);
        }
    };
    const resetFileInput = () => {
        inputRef.current.value = null;
        setCSVFile(undefined)
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            PaperProps={{
                style: { borderRadius: 28 }
            }}
        >
            <div className="dialog-content">
                <p className="dialog-title">{uploadCSVTitle}</p>
                <p className="dialog-add-points-subtitle">Please upload a CSV file with users from your storage.</p>
                <label className={!CSVFile ? "dialog-add-csv-file dialog-add-csv-file-hover" : "dialog-add-csv-file"} onChange={handleFileChange}>
                    Add CSV File
                    <input
                        disabled={CSVFile}
                        id="csvInput"
                        name="file"
                        type="File"
                        hidden
                        ref={inputRef}
                    />
                </label>
                <div>
                    {CSVFile && CSVFile.name}
                    <IconButton
                        style={{
                            visibility: CSVFile ? "visible" : "hidden",
                        }}
                        onClick={() => resetFileInput()}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className="buttons-wrapper">
                    <VeraButton variant="text" onClick={onClose} sx={{ border: "none" }}>
                        Cancel
                    </VeraButton>
                    <VeraButton variant="outlined" onClick={() => onUpload(userBulkUpdateTitle)} disabled={!CSVFile} className="dialog-upload-button">
                        Upload
                    </VeraButton>
                </div>
            </div>
        </Dialog>
    );
}
export default UploadCSVFileDialog;
