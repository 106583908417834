import { Amplify, Auth } from "aws-amplify";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import { IAuthAWS, IOAuth } from "../interfaces/IAuthAWS";
import { FederatedSignInOptions } from "@aws-amplify/auth/lib-esm/types";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

import { SocialMedia } from "../interfaces/IAuthFunctionalities";
import { IUserAuth } from "../interfaces/IUserAuth";
import { confirmChangePhone, confirmChangeEmail, changePhone } from "../services/cognito";

let userPool: CognitoUserPool;
let currentUser: CognitoUser;

export const initCognito = async (authProps: IAuthAWS) => {
  const poolData: any = {
    UserPoolId: authProps.userPoolId,
    ClientId: authProps.userPoolWebClientId,
  };
  Amplify.configure(authProps);
  userPool = new CognitoUserPool(poolData);

  if (userPool) currentUser = userPool.getCurrentUser() as CognitoUser;
  else currentUser = await Auth.currentAuthenticatedUser();
};

export const getCurrentUser = async () => {
  if (!currentUser) currentUser = await Auth.currentAuthenticatedUser();
  return currentUser;
};

export function getCognitoUser(username: string) {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  if (!currentUser) {
    const cognitoUser = new CognitoUser(userData);
    return cognitoUser;
  } else {
    if (currentUser.getUsername() !== username) {
      const cognitoUser = new CognitoUser(userData);
      return cognitoUser;
    }
  }
  return currentUser;
}

export async function getSession() {
  return new Promise(function (resolve, reject) {
    Auth.currentSession()
      .then((session: any) => {
        resolve(session);
      })
      .catch((err: any) => {
        reject(err);
      });
  }).catch((err) => {
    throw err;
  });
}

export async function refreshSession(refreshToken: string) {
  return new Promise<any>((resolve, reject) => {
    function completeRefresh(currentUser: CognitoUser) {
      currentUser.refreshSession(
        new CognitoRefreshToken({ RefreshToken: refreshToken }),
        function (err: Error, result: any) {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        }
      );
    }

    if (currentUser) completeRefresh(currentUser);
    else getCurrentUser().then((user) => completeRefresh(user)).catch((err) => reject(err));
  });
}

export async function signUpUserWithEmail(
  username: string,
  email: string,
  password: string,
  phone: string,
  name?: string,
  firstName?: string,
  lastName?: string
) {
  return new Promise(function (resolve, reject) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: email,
      }),
      new CognitoUserAttribute({
        Name: "phone_number",
        Value: phone,
      }),
      new CognitoUserAttribute({
        Name: "name",
        Value: firstName as string,
      }),
      new CognitoUserAttribute({
        Name: "family_name",
        Value: lastName as string,
      }),
    ];

    userPool.signUp(username, password, attributeList, [], function (err: any, res: any) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err: any) => {
    throw err;
  });
}
// export async function removeUserFromCognito(cognitoUser: any) {//TODO: remove this later if we decide not to use it

//   try {
//     await Auth.currentAuthenticatedUser();

//     await removeUser(cognitoUser.username);
//     await Auth.currentAuthenticatedUser({ bypassCache: true });
//     return true;
//   } catch (e: any) {
//     throw e;
//   }
// }

export async function signUpUserWithPhone(
  username: string,
  email: string,
  password: string,
  phone: string,
  name?: string,
  firstName?: string,
  lastName?: string
) {
  return new Promise(function (resolve, reject) {
    const attributeList = {
      email: email,
      phone_number: phone,
      name: firstName,
      family_name: lastName,
    };

    Auth.signUp({ username, password, attributes: { ...attributeList } })
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  }).catch((err) => {
    throw err;
  });
}

export async function verifyCode(username: string, code: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    cognitoUser.confirmRegistration(code, true, function (err: any, result: any) {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export let mfaRequired = false;
let authenticationDetails: AuthenticationDetails;

export async function signInWithEmail(
  username: string,
  password: string,
  callback: () => void,
  setMfaRequired: (required: boolean) => void
) {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password,
    };

    authenticationDetails = new AuthenticationDetails(authenticationData);

    currentUser = getCognitoUser(username);

    currentUser.authenticateUser(authenticationDetails, {
      mfaRequired() {
        // Implement you functionality to show UI for MFA form
        mfaRequired = true;
        setMfaRequired(true);
        callback();
      },
      onSuccess: function (res: any) {
        resolve(res);
      },
      onFailure: function (err: any) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export const signInWithEmailNew = async (username: string, password: string) => {
  const cognitoUser = await Auth.signIn(username, password);
  currentUser = cognitoUser;
  return cognitoUser;
};

export const sendMfaCode = (username: string, MFACode: string) => {
  return new Promise(function (resolve, reject) {
    currentUser.sendMFACode(MFACode, {
      onSuccess: function (res: any) {
        mfaRequired = false;
        resolve(res);
      },
      onFailure: function (err: any) {
        reject(err);
      },
    });
  });
};

export const reSendMfaCode = (callback: (sended: boolean) => void) => {
  if (!currentUser || !authenticationDetails) {
    callback(false);
  }
  return new Promise(function (resolve, reject) {
    currentUser.authenticateUser(authenticationDetails, {
      mfaRequired() {
        // Implement you functionality to show UI for MFA form
        mfaRequired = true;
        callback(true);
      },
      onSuccess: function (res: any) {
        resolve(res);
      },
      onFailure: function (err: any) {
        reject(err);
      },
    });
  });
};

export const federatedSignIn = (configAWS: IAuthAWS, provider: SocialMedia) => {
  const updatedConfig: IAuthAWS = {
    ...configAWS,
    oauth: {
      ...(configAWS.oauth as IOAuth),
      urlOpener: federatedSignInUrlOpener,
    },
  };
  Amplify.configure(updatedConfig);
  const signInOption: FederatedSignInOptions = {
    provider: provider as unknown as CognitoHostedUIIdentityProvider,
  };
  Auth.federatedSignIn(signInOption);
};

const federatedSignInUrlOpener = (url: any, redirectUrl: any) => {
  // try {
  //   if (await InAppBrowser.isAvailable()) {
  //     const result = await InAppBrowser.openAuth(url, getDeepLink(), {
  //       // iOS Properties
  //       ephemeralWebSession: false,
  //       // Android Properties
  //       showTitle: false,
  //       enableUrlBarHiding: true,
  //       enableDefaultShare: false,
  //     });
  //     // Alert.alert('Response', JSON.stringify(result));
  //   } else {
  //     // Alert.alert('InAppBrowser is not supported :/');
  //   }
  // } catch (err) {
  //   // Alert.alert('Something’s wrong with the app :(');
  // }
};

export const getDeepLink = (path = "") => {
  // const prefix = Platform.OS == 'android' ? `${scheme}://authentication/` : `${scheme}://`
  // return prefix + path
  return path;
};

export function signOut() {
  if (currentUser) {
    currentUser.signOut();
  }
}

export async function getAttributes() {
  if (!currentUser) currentUser = await getCurrentUser();

  return new Promise(function (resolve, reject) {
    currentUser.getUserAttributes(function (err: any, attributes: any) {
      if (err) {
        reject(err);
      } else {
        resolve(attributes);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function setAttribute(attribute: any) {
  return new Promise(function (resolve, reject) {
    const attributeList = [];
    const res = new CognitoUserAttribute(attribute);
    attributeList.push(res);

    currentUser.updateAttributes(attributeList, (err: any, res: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function setAttributes(attributes: IUserAuth) {
  return new Promise(function (resolve, reject) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: "email",
        Value: attributes.email,
      }),
      new CognitoUserAttribute({
        Name: "phone_number",
        Value: attributes.phone_number,
      }),
      new CognitoUserAttribute({
        Name: "name",
        Value: attributes.name as string,
      }),
    ];

    currentUser.updateAttributes(attributeList, (err: any, res: any) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function sendCodeForgotPassword(username: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res: any) {
        resolve(res);
      },
      onFailure: function (err: any) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export async function sendCodeRecoverPassword(username: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);
    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res: any) {
        resolve(res);
      },
      onFailure: function (err: any) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export async function forgotPassword(username: string, code: string, password: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);
    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve("password updated");
      },
      onFailure: function (err: any) {
        reject(err);
      },
    });
  });
}

export async function forgotPasswordToken(username: string, password: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);
    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    /* cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve('password updated')
      },
      onFailure: function (err) {
        reject(err)
      },
    }) */
  });
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise(function (resolve, reject) {
    currentUser.changePassword(oldPassword, newPassword, function (err: any, res: any) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  });
}

export const sendCode = (username: string, code: string) => {
  let cognitoUser = getCognitoUser(username);
  return new Promise(function (resolve, reject) {
    cognitoUser.confirmRegistration(code, true, function (err: any, result: any) {
      if (err) {
        reject(err);
      }
      resolve(result);
    });
  });
};

export const reSendCode = async (username: string) => {
  let cognitoUser = getCognitoUser(username);
  return new Promise(function (resolve, reject) {
    cognitoUser.resendConfirmationCode(function (err: any, result: any) {
      if (err) {
        reject(err);
      }
      resolve(result);
    });
  });
};

export const enableMFA = (username: string) => {
  const cognitoUser = getCognitoUser(username);
  let smsMfaSettings = {
    PreferredMfa: true,
    Enabled: true,
  };
  return new Promise(function (resolve, reject) {
    cognitoUser.setUserMfaPreference(smsMfaSettings, null, function (err: any, result: any) {
      if (err) {
        alert(err.message || JSON.stringify(err));
        reject(err);
      }
      resolve(result);
    });
  });
};

export const rememberDevice = () => {
  return new Promise(function (resolve, reject) {
    try {
      currentUser.setDeviceStatusRemembered({
        onSuccess: function (result: any) {
          resolve(result);
        },
        onFailure: function (err: any) {
          reject(err);
        },
      });
    } catch (err: any) {
      reject(err);
    }
  });
};

export const forgotDevice = () => {
  return new Promise(function (resolve, reject) {
    try {
      currentUser.forgetDevice({
        onSuccess: function (result: any) {
          resolve(result);
        },
        onFailure: function (err: any) {
          reject(err);
        },
      });
    } catch (err: any) {
      reject(err);
    }
  });
};

export const onChangePhoneSubmitted = async (phone: string, email: string) => {
  try {
    const user = await Auth.currentAuthenticatedUser();

    if (user.attributes.phone_number === phone) {
      throw new Error(`${phone} is already your phone number`);
    }
    await changePhone(phone, email);
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    return true;
  } catch (e) {
    throw e;
  }
};

export const onConfirmCodeResendCodePhone = async (phone: string, email: string) => {
  try {
    await changePhone(phone, email);
    return true;
  } catch (e: any) {
    throw e;
  }
};

export const onConfirmCodeSubmitted = async (code: string, NewEmail: string, email: string) => {
  try {
    await Auth.currentAuthenticatedUser();

    await confirmChangeEmail(code, NewEmail, email);
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    return true;
  } catch (e: any) {
    throw e;
  }
};

export const onConfirmCodeSubmittedPhone = async (code: string, phone: string, email: string) => {
  try {
    await confirmChangePhone(code, phone, email);
    await Auth.currentAuthenticatedUser({ bypassCache: true });
    return true;
  } catch (e: any) {
    throw e;
  }
};
