import { Dialog, Button, Paper, Container } from "@mui/material";
import closeIcon from "src/assets/icon-close.svg";
import backIcon from "src/assets/icon-back-arrow.svg";
import React, { useState } from "react";
import ContactUsDialog from "src/components/ContactUsDialog/ContactUsDialog";
import { useNavigate } from "react-router-dom";
import "./SupportDialog.scss";

const SupportDialog = (props: any) => {
  const [isSupportDialog, setSupportDialog] = useState(true);
  const navigate = useNavigate();
  const { onClose, open } = props;
  const mobileSize = window.innerWidth < 600;
  const handleClose = () => {
    onClose();
  };
  const onContactUsClose = () => {
    setSupportDialog(true);
    onClose();
  };
  const onFaqClick = () => {
    handleClose();
    navigate("/faq");
  };
  if (!isSupportDialog) {
    return <ContactUsDialog onClose={onContactUsClose} open={open} />;
  }

  return (
    <>
      <Dialog
        fullScreen={mobileSize}
        onClose={handleClose}
        open={open}
        PaperProps={{
          style: { borderRadius: mobileSize ? 0 : 28 },
        }}
      >
        <Paper className="contact-us-paper">
          <div className="contact-us-dialog">
            <div className="contact-us-close" onClick={handleClose}>
              <img src={mobileSize ? backIcon : closeIcon} alt="ContactUs" />
            </div>
            <div className="contact-us-header">Need Help?</div>
            <Container className="support-body">
              <Button className="support-body-button" variant="outlined" onClick={() => onFaqClick()}>
                Frequently Asked Questions
              </Button>
              <Button
                className="support-body-button"
                sx={{ py: 2 }}
                variant="outlined"
                onClick={() => setSupportDialog(false)}
              >
                Contact Support
              </Button>
            </Container>
          </div>
        </Paper>
      </Dialog>
    </>
  );
};

export default SupportDialog;
