/* eslint-disable */
import axios from "axios";
import { getData } from "../contexts/authContext";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
});

instance.interceptors.request.use(async (config: any) => {
  const token = await getData("idToken");
  // if (token) {
  config.headers.Authorization = token;
  // }
  return config;
});

export const changeEmail = async (newEmail: string, email: string) => {
  const tokenAccess = await getData("accessToken");
  const token = await getData("idToken");
  return instance.post(
    "/change-email?email=" + email,
    { newEmail: newEmail, accessToken: tokenAccess },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const confirmChangeEmail = async (code: string, newEmail: string, email: string) => {
  const tokenAccess = await getData("accessToken");
  const token = await getData("idToken");
  const data = JSON.stringify({
    accessToken: tokenAccess,
    code: code,
    newEmail: email,
  });
  return instance.post("/change-email-confirm?email=" + newEmail, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const changePhone = async (phone: string, email: string) => {
  const tokenAccess = await getData("accessToken");
  const token = await getData("idToken");
  return instance.post(
    "/change-email?email=" + email,
    { phone_number: phone, accessToken: tokenAccess },
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const confirmChangePhone = async (code: string, phone: string, email: string) => {
  const tokenAccess = await getData("accessToken");
  const token = await getData("idToken");
  const data = JSON.stringify({
    accessToken: tokenAccess,
    code: code,
    phone_number: phone,
  });
  return instance.post("/change-email-confirm?email=" + email, data, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
