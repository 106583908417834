import { AxiosResponse } from "axios";
import {
  NewSurveyNotificationsPayload,
  QuerySurveysDto,
  SurveyIncludesEnum,
  SurveysSyncSource,
} from "../../types/Survey";
import apiService from "./apiService";
import endpoints from "./endpoints";

export default class SurveysService {
  static getSurveys(params = {}) {
    return apiService.get(endpoints.surveys, {
      params,
      paramsSerializer(params) {
        const { categories, ...rest } = params;
        let paramsString = new URLSearchParams(JSON.parse(JSON.stringify(rest))).toString();

        if (categories) {
          categories.forEach((category: string) => {
            paramsString += `&categories=${category}`;
          });
        }
        return paramsString;
      },
    });
  }

  static getSurveyNotificationLogs(id: string, params = {}) {
    params = { limit: 20, ...params };
    return apiService.get(endpoints.surveyNotificationLogs(id), {
      params,
      paramsSerializer(params) {
        let paramsString = new URLSearchParams(JSON.parse(JSON.stringify(params))).toString();
        return paramsString;
      },
    });
  }

  static getSurveysFeed(params = {}) {
    return apiService.get(endpoints.surveysFeed, {
      params,
      paramsSerializer(params) {
        const { categories, ...rest } = params;
        let paramsString = new URLSearchParams(JSON.parse(JSON.stringify(rest))).toString();

        if (categories) {
          categories.forEach((category: string) => {
            paramsString += `&categories=${category}`;
          });
        }
        return paramsString;
      },
    });
  }

  static getSurvey(id: string, { includes }: { includes: SurveyIncludesEnum[] } = { includes: [] }) {
    return apiService.get(endpoints.survey(id) + `?includes=${includes.join(",")}`);
  }

  static cancelScheduledNotification(id: string, scheduleId: string) {
    return apiService.delete(endpoints.cancelScheduledNotification(id, scheduleId));
  }

  static confirmPayment(id: string, payload: { paymentSessionId: string }) {
    return apiService.post(endpoints.confirmPayment(id), payload);
  }

  static getCompleteSurveys() {
    return apiService.get(endpoints.completeSurveys);
  }

  static getExternalSurveyRedirectUrl(surveyId: string) {
    return apiService.get(endpoints.externalSurveyRedirectUrl(surveyId));
  }

  static createSurvey(data: object) {
    return apiService.post(endpoints.surveys, data);
  }

  static updateSurvey(id: string, data: object) {
    return apiService.patch(endpoints.survey(id), data);
  }

  static syncSurveys(source: SurveysSyncSource) {
    return apiService.post(endpoints.syncSurveys, { source });
  }

  static getPaymentLink(id: string): Promise<AxiosResponse<{ paymentSessionId: string }>> {
    return apiService.post(endpoints.getPaymentLink(id), {});
  }

  static getTags() {
    return apiService.get(endpoints.tags);
  }

  static querySurveys(query: QuerySurveysDto) {
    return apiService.get(endpoints.querySurveys, {
      params: query,
    });
  }

  static updateSurveyImage(id: string, data: object) {
    return apiService.patch(endpoints.survey(id), data, {
      headers: {
        "Content-Type": 'multipart/form-data; boundary=""',
      },
    });
  }

  static likeSurvey(id: string) {
    return apiService.patch(endpoints.likeSurvey(id));
  }

  static unlikeSurvey(id: string) {
    return apiService.patch(endpoints.unlikeSurvey(id));
  }

  static removeSurveyImage(id: string) {
    return apiService.delete(endpoints.removeSurveyImage(id));
  }

  static newSurveyNotification(id: string, payload: NewSurveyNotificationsPayload) {
    return apiService.post(endpoints.newSurveyNotification(id), payload);
  }

  static uploadUsersFile(id: string, data: object) {
    return apiService.patch(endpoints.uploadUsers(id), data, {
      headers: {
        // 'Content-Type': 'multipart/form-data; boundary=""'
        "Content-Type": "text/csv",
      },
    });
  }

  static clearTargetUsers(id: string) {
    return apiService.delete(endpoints.clearTargetUsers(id));
  }

  static pinSurvey(id: string) {
    return apiService.patch(endpoints.pinSurvey(id));
  }

  static unpinSurvey(id: string) {
    return apiService.patch(endpoints.unpinSurvey(id));
  }

  static getAvailableUsers(id: string, params = {}) {
    return apiService.get(endpoints.getAvailableUsers(id), {
      params,
      paramsSerializer(params) {
        const { categories, ...rest } = params;
        let paramsString = new URLSearchParams(JSON.parse(JSON.stringify(rest))).toString();

        if (categories) {
          categories.forEach((category: string) => {
            paramsString += `&categories=${category}`;
          });
        }
        return paramsString;
      },
    });
  }

  static downloadRedirectParams(id: string) {
    return apiService.get(endpoints.downloadRedirectParams(id));
  }
  static getReportUrl(id: string) {
    return apiService.get(endpoints.reportUrl(id));
  }

  static downloadAvailableUsers(id: string) {
    return apiService.post(endpoints.downloadAvailableUsers(id));
  }

  static getCsvUploadStatus(id: string) {
    return apiService.get(endpoints.csvUploadStatus(id));
  }

  static getSurveysSyncStatus() {
    return apiService.get(endpoints.surveysSyncStatus);
  }
}
