import React, { useMemo, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Button from "@mui/material/Button";
import InformativeDialog from "./InformativeDialog";
import { Typography, Box, Divider } from "@mui/material";

const UserBulkUpdateDialog = (props: any) => {
  const { onClose, onSubmit, setCSVFileParsed, CSVFileParsed, open, userBulkUpdateTitle } = props;
  const [openInformativeDialog, setOpenInformativeDialog] = useState<string>("");
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);

  const handleClose = () => {
    onClose();
  };

  const removeRow = () => {
    if (selectedEmail?.length) {
      let newCSVFileParsed = [...CSVFileParsed];
      const selectedIndex = newCSVFileParsed.findIndex((rec) => rec.email === selectedEmail);
      newCSVFileParsed.splice(selectedIndex, 1);
      setCSVFileParsed(newCSVFileParsed);
      if (newCSVFileParsed.length === 0) {
        handleClose();
      }
      setSelectedEmail(null);
      setOpenInformativeDialog("");
    }
  };

  const handleOpenInformativeDialog = (index: number, email: string) => {
    setSelectedEmail(email);
    setOpenInformativeDialog("removeRow");
  };

  const handleCloseInformativeDialog = () => {
    setOpenInformativeDialog("");
  };

  const { validUsers, invalidUsers } = useMemo(() => {
    return CSVFileParsed.reduce(
      (acc: { validUsers: any[]; invalidUsers: any[] }, user: any) => {
        if (user.valid) {
          acc.validUsers.push(user);
        } else {
          acc.invalidUsers.push(user);
        }
        return acc;
      },
      { validUsers: [], invalidUsers: [] }
    );
  }, [CSVFileParsed]);

  const handleSubmitValidOnly = async () => {
    setCSVFileParsed(validUsers);
  };

  const renderUserTable = (users: any[]) => (
    <TableContainer component={Paper} sx={{ mb: 4 }}>
      <Table aria-label="user table">
        <TableHead sx={{ backgroundColor: "grey.100" }}>
          <TableRow>
            <TableCell>No.</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>{userBulkUpdateTitle}</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user: any, index: number) => {
            if (user.email.length <= 0) return null;
            const isDelete = String(user.delete) === "1" || String(user.delete).toLowerCase() === "true";
            return (
              <TableRow key={index + user.tag}>
                <TableCell>{index + 1}</TableCell>
                <TableCell component="th" scope="row">
                  <Typography fontWeight="bold">{user.email}</Typography>
                </TableCell>
                <TableCell sx={isDelete ? { textDecoration: "line-through" } : {}}>
                  {userBulkUpdateTitle === "Tags" ? user.tag : user.points}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenInformativeDialog(index, user.email)} color="error">
                    <DeleteOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            borderRadius: 4,
            maxWidth: "1100px",
            width: "950px",
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: "primary.main",
            color: "primary.contrastText",
            p: 3,
          }}
        >
          {userBulkUpdateTitle === "Tags" ? "Add or Remove" : "Add"} {userBulkUpdateTitle}
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          {validUsers.length > 0 && (
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Valid Users
              </Typography>
              {renderUserTable(validUsers)}
            </Box>
          )}

          {invalidUsers.length > 0 && (
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Invalid Users
              </Typography>
              {renderUserTable(invalidUsers)}
            </Box>
          )}

          <Divider sx={{ mb: 3 }} />

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
            {invalidUsers.length > 0 ? (
              <Button variant="contained" onClick={handleSubmitValidOnly} disabled={!validUsers.length} color="primary">
                Proceed with Valid Users
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => onSubmit()}
                disabled={CSVFileParsed.length === 0}
                color="primary"
              >
                Submit All
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <InformativeDialog
        onClose={handleCloseInformativeDialog}
        userId={""}
        userName={selectedEmail}
        onAction={removeRow}
        open={openInformativeDialog}
      />
    </>
  );
};

export default UserBulkUpdateDialog;
