import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, IconButton } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";

import pointsIcon from "../../../../assets/icon-points.svg";
import "../ProfileScreen.scss";
import { formatNumber } from "../../../../helpers/generic";
import { clickHomeIcon } from "../../../../helpers/dataLayers";
import { useMobileSize } from "../../../../hooks/useMobileSize";
import { User, UserRoles } from "src/types/User";

type UserBannerProps = {
  userData?: Partial<User>;
};

const UserBanner = (props: UserBannerProps) => {
  const navigate = useNavigate();
  const { userData } = props;
  const [image, setImage] = React.useState<string>("");
  const [name, setName] = React.useState<string>("");
  const [availablePoints, setAvailablePoints] = React.useState<number>(0);
  const [availableDollars, setAvailableDollars] = React.useState<number>(0);
  const { isMobile } = useMobileSize();
  const isResearcher = userData?.role === UserRoles.RESEARCHER;

  React.useEffect(() => {
    if (!userData) return;
    setName(userData.firstName + " " + userData.lastName);
    setImage(userData.image || "");
    setAvailablePoints(userData.totalPoints! - userData?.redeemedPointsSum!);
    setAvailableDollars(userData.availableDollars!);
  }, [userData]);

  const availableDollarsFormatted = availableDollars?.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  function goHome() {
    navigate("/");
    clickHomeIcon();
  }
  function goHomeMobile() {
    navigate("/");
  }

  return (
    <div className="user-banner">
      {isMobile && (
        <IconButton onClick={() => goHomeMobile()}>
          <ArrowBackIosOutlinedIcon />
        </IconButton>
      )}
      <Box className="user-profile-image-wrapper">
        {image ? (
          <div className="image-wrapper">
            <img src={image} alt="survey-cover" />
          </div>
        ) : (
          <div className="image-wrapper default-image">
            <PersonOutlineOutlinedIcon />
          </div>
        )}
      </Box>
      <p className="user-name">{name}</p>

      {isResearcher ? null : (
        <div className="user-conversion-points">
          <img src={pointsIcon} alt="Points" />
          <p>
            <b>{formatNumber(availablePoints)}</b> / {availableDollarsFormatted}
          </p>
        </div>
      )}

      <Box
        sx={{
          display: "flex",
          marginTop: "20px",
          justifyContent: "center",
        }}
      >
        {!isMobile && (
          <Button variant="contained" disableElevation onClick={() => goHome()}>
            {isResearcher ? "Back to home" : "Back to survey home"}
          </Button>
        )}
      </Box>
    </div>
  );
};

export default UserBanner;
