import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import "./NotificationsBlock.scss";
import {
  Dialog,
  Tooltip,
  Stack,
  IconButton,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { NotificationSchedule } from "../../../../types/Survey";
import CustomDateTimePicker from "src/components/DateTimePicker/DateTimePicker";
import { PickersTimezone } from "@mui/x-date-pickers";

type PropTypes = {
  notificationSchedule: NotificationSchedule;
  onDelete?: (notificationSchedule: NotificationSchedule) => void;
  onEdit?: (notificationSchedule: NotificationSchedule) => void;
  timezone: PickersTimezone;
};

const ScheduledNotification = ({ notificationSchedule, onEdit, onDelete, timezone }: PropTypes) => {
  const [open, setOpen] = useState<boolean>(false);
  const onDeleteClick = () => {
    setOpen(true);
  };
  return (
    <>
      <Stack justifyContent="center" direction="row" spacing={1}>
        <CustomDateTimePicker
          disabled
          currentTimezone={timezone}
          value={notificationSchedule.scheduledAt ? new Date(notificationSchedule.scheduledAt) : null}
          setValue={() => {}}
        />
        {typeof onEdit === "function" && (
          <Tooltip title="Click here and modify in notification section">
            <IconButton>
              <EditIcon onClick={() => onEdit(notificationSchedule)} />
            </IconButton>
          </Tooltip>
        )}
        {typeof onDelete === "function" && (
          <IconButton onClick={() => onDeleteClick()}>
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <Dialog maxWidth="xs" open={open}>
        <DialogTitle>Are you sure you want to delete the scheduled notification</DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)}>
            No
          </Button>
          <Button
            onClick={() => {
              onDelete && onDelete(notificationSchedule);
              setOpen(false);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScheduledNotification;
