import { Button, Popover } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import VeraButton from "../VeraButton";
import { useState } from "react";

type FilterWrapperProps = {
  openFilters: boolean;
  clearFilters: () => void;
  applyFilters: () => void;
  setOpenFilters: (value: boolean) => void;
  children: React.ReactNode;
};

export default function FilterWrapper({
  openFilters,
  clearFilters,
  applyFilters,
  setOpenFilters,
  children,
}: FilterWrapperProps) {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const toggleFilter = (evt: any) => {
    setOpenFilters(!openFilters);
    setPopoverAnchorEl(evt.currentTarget);
  };

  const closeFilter = () => {
    setOpenFilters(false);
    setPopoverAnchorEl(null);
  };

  return (
    <div className="filter-wrapper">
      <Button
        variant="contained"
        endIcon={openFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        onClick={toggleFilter}
        disableElevation={true}
        style={{
          marginRight: "20px",
          backgroundColor: "#fefefe",
          color: "#000",
          border: "1px solid #bac6ca",
          fontWeight: "bold",
          textTransform: "capitalize",
        }}
      >
        Filter
      </Button>

      <Popover
        open={openFilters}
        onClose={closeFilter}
        anchorEl={popoverAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="filter-options">
          {children}

          <div className="buttons-wrapper">
            <VeraButton variant="text" onClick={clearFilters} sx={{ border: "none" }}>
              Clear
            </VeraButton>
            <VeraButton variant="outlined" onClick={applyFilters}>
              Apply
            </VeraButton>
          </div>
        </div>
      </Popover>
    </div>
  );
}
