import { Box, Button, Container, FormControl, Typography } from "@mui/material";
import VeraLink from "../../../components/VeraLink";
import { useEffect, useState } from "react";
import BootstrapInput from "../../../components/BootstrapInput";
import { useSnackbar } from "notistack";
import InvitationsService from "../../../services/api/invitationsService";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import CustomDatePicker from "../../../components/DateTimePicker/DatePicker";
import PaperDefault from "../../../components/common/PaperDefault";

const CreateInvitationForm = styled.form({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",

  "& button.create-invitations-button": {
    alignSelf: "flex-start",
  },
});

const CreateInvitationCodes = () => {
  const [singleCode, setSingleCode] = useState<string>("");
  const [numRandomCodes, setNumRandomCodes] = useState<number>(0);
  const [discount, setDiscount] = useState<number>(0);
  const [expireDate, setExpireDate] = useState<Date | null>(null);
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true);
  const [singleCodeValid, setSingleCodeValid] = useState<boolean>(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const generateInvitations = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    try {
      await InvitationsService.generate({
        ...(singleCode && { singleCode }),
        numRandomCodes,
        discount,
        expireDate,
      });

      enqueueSnackbar("Invitations generated successfully.", { variant: "success" });

      navigate("/admin/invitations");
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message, { variant: "error" });
    }
  };

  useEffect(() => {
    const bothEmpty = singleCode === "" && numRandomCodes <= 0;
    const bothPresent = singleCode !== "" && numRandomCodes > 0;

    setBtnDisabled(bothEmpty || !singleCodeValid || bothPresent);
  }, [singleCode, numRandomCodes, singleCodeValid]);

  useEffect(() => {
    if (singleCode.length === 0) {
      setSingleCodeValid(true);
    } else {
      const regex = /^[a-zA-Z0-9!%-]{6,12}$/;
      setSingleCodeValid(RegExp(regex).exec(singleCode) !== null);
      setNumRandomCodes(0);
    }
  }, [singleCode]);

  useEffect(() => {
    if (numRandomCodes > 0) {
      setSingleCode("");
    }
  }, [numRandomCodes]);

  return (
    <Container
      sx={{
        marginTop: "60px",
      }}
    >
      <Box>
        <Typography variant="h6">
          <VeraLink to="/admin/invitations">Back</VeraLink>
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: "2rem",
        }}
      >
        <h4>Create Invitation Codes</h4>

        <PaperDefault>
          <p>
            Create invitation codes that allow access to a protected page, typically a sign-up page. If you want a
            specific short code enter it, otherwise choose the number of randomly generated short-codes to create.
          </p>

          <CreateInvitationForm id="create-invitations-form">
            <Box>
              <FormControl variant="standard">
                <div className="input-label">Single short code</div>
                <BootstrapInput value={singleCode} onChange={(event) => setSingleCode(event.target.value)} />
                <Typography variant="caption" display="block" gutterBottom>
                  6-12 characters, alpha, num, spaces allowed, special characters allowed !,%,-
                </Typography>
              </FormControl>
            </Box>

            <Box>
              <FormControl variant="standard">
                <div className="input-label">Generate random short codes</div>
                <BootstrapInput
                  value={numRandomCodes}
                  type="number"
                  onChange={(event) => setNumRandomCodes(+event.target.value)}
                />
              </FormControl>
            </Box>

            <Box>
              <FormControl variant="standard">
                <div className="input-label">Discount</div>
                <BootstrapInput value={discount} type="number" onChange={(event) => setDiscount(+event.target.value)} />
              </FormControl>
            </Box>

            <Box>
              <FormControl variant="standard">
                <div className="input-label">Expiration date</div>

                <CustomDatePicker value={expireDate} setValue={setExpireDate} currentTimezone={"system"} />
              </FormControl>
            </Box>

            <Button
              className="create-invitations-button"
              type="submit"
              variant="contained"
              onClick={generateInvitations}
              disabled={btnDisabled}
            >
              Generate Invitations
            </Button>
          </CreateInvitationForm>
        </PaperDefault>
      </Box>
    </Container>
  );
};

export default CreateInvitationCodes;
