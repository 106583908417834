import { User } from "src/types/User";
import apiService from "./apiService";
import endpoints from "./endpoints";
import { QualtricsApiToken } from "src/interfaces/QualtricsApiToken";
import { CashoutVendorEnum } from "../../types/generic";

export default class UsersService {
  static getAuthenticatedUser() {
    return apiService.get(endpoints.authenticatedUser);
  }

  static getUsers(params = {}) {
    return apiService.get(endpoints.users, {
      params,
      paramsSerializer(params) {
        const { interests, ...rest } = params;
        let paramsString = new URLSearchParams(JSON.parse(JSON.stringify(rest))).toString();

        if (interests) {
          interests.forEach((interest: string) => {
            paramsString += `&interests=${interest}`;
          });
        }
        return paramsString;
      },
    });
  }

  static getUser(id: string) {
    return apiService.get(endpoints.user(id)).then((res) => {
      return res;
    });
  }

  static updateUser(id: string, data: object) {
    return apiService.patch(endpoints.user(id), data);
  }

  static getUserData() {
    return apiService.get(endpoints.userData);
  }

  static updateUserData(data: object) {
    return apiService.patch(endpoints.userData, data, {
      headers: {
        "Content-Type": 'multipart/form-data; boundary=""',
      },
    });
  }

  static redeemPoints(
    numPointsToRedeem: number,
    vendor: CashoutVendorEnum,
    method: "email" | "phone",
    recaptchaToken?: string
  ) {
    return apiService.patch<User>(endpoints.redeemPoints, {
      amount: numPointsToRedeem,
      vendor,
      method,
      recaptchaToken,
    });
  }

  static getUserActivity({ page = 1, ...rest }: { page?: number; userId?: string }) {
    let query = new URLSearchParams(JSON.parse(JSON.stringify({ page, ...rest }))).toString();
    return apiService.get(endpoints.userActivity + "?" + query);
  }

  static downloadCSV(params = {}) {
    return apiService.get(endpoints.usersCSV, {
      params,
      paramsSerializer(params) {
        const { interests, ...rest } = params;
        let paramsString = new URLSearchParams(JSON.parse(JSON.stringify(rest))).toString();

        if (interests) {
          interests.forEach((interest: string) => {
            paramsString += `&interests=${interest}`;
          });
        }
        return paramsString;
      },
    });
  }

  static completeSurvey(surveyId: string) {
    return apiService.patch(endpoints.completeSurvey(surveyId));
  }

  static uncompleteSurvey(surveyId: string) {
    return apiService.patch(endpoints.uncompleteSurvey(surveyId));
  }

  static addPoints(id: string, data: object) {
    console.log(data);
    return apiService.patch(endpoints.userAddPoints(id), data);
  }
  static addPointsToBatchOfUsers(data: object) {
    return apiService.post(endpoints.addPointToBatchOfUsers, data);
  }

  static usersBulkDelete(data: object) {
    return apiService.patch(endpoints.usersBulkDelete, data);
  }

  static verifyBatchOfUsers(data: object) {
    return apiService.post(endpoints.verifyBatchOfUsers, data);
  }

  static checkIfPhoneOrEmailExist(data: any) {
    //Stashed changes
    return apiService.get(endpoints.checkIfPhoneOrEmailExist(data.phone, data.email), data);
  }

  static registerCognitoUser(data: object) {
    return apiService.post(endpoints.registerCognitoUser, data);
  }

  static getInterests() {
    return apiService.get(endpoints.interests);
  }

  static setInterests(id: string, data: object) {
    return apiService.patch(endpoints.userInterests(id), data);
  }

  static getInviteByRef(ref: string) {
    return apiService.get(endpoints.userInvite(ref));
  }

  static isUserDisabled(id: string) {
    return apiService.get(endpoints.isUserDisabled(id));
  }

  static generateTestUsers(data: Record<"numberOfUsers", number>) {
    return apiService.patch(endpoints.generateTestUsers, data);
  }

  static deleteGeneratedTestUsers() {
    return apiService.patch(endpoints.deleteGeneratedTestUsers);
  }

  static getQualtricsApiToken(userId: string) {
    return apiService.get(endpoints.qualtricsApiToken(userId));
  }

  static postQualtricsApiToken(userId: string, data: QualtricsApiToken) {
    return apiService.post(endpoints.qualtricsApiToken(userId), data);
  }
}
