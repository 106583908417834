import React, { useContext, useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, CircularProgress, FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import { Grid, Paper, Button } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";

import { AuthContext } from "../../contexts/authContext";
import { useValidEmail } from "../../hooks/useValidEmail";
import {
  backgroundStyle,
  btnStyle,
  h1Style,
  inputStyle,
  logoStyle,
  paperStyle,
  plabelStyle,
  phoneErrorStyle,
  rowStyle,
  columnStyle,
  containerStyle,
  btnStyleDisabled,
  notice,
  mfaBlockStyle,
  mfaCheckboxStyle,
  mfaTextStyle,
  phoneButtonStyle,
  mfaAdditionalInfo,
  hiddenStyle,
} from "./styles";
import verasightLogo from "../../assets/landing-page/verasight-logo-community.svg";
import LinearProgress from "@mui/material/LinearProgress";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useValidPhone } from "src/hooks/useValidPhone";
import { useValidZip } from "src/hooks/useValidZip";
import SideCarousel from "../../components/SignUpSideCarousel/SignUpSideCarousel";
import UsersService from "../../services/api/usersService";
import { visitRegistrationScreen } from "../../helpers/dataLayers";
import { Gender, genderOptionsArr, isGender, UTMCodeType } from "src/interfaces/IAuth";
import { capitalize } from "lodash";
import { getItemIDB } from "../../services/indexedDb";
import QuestionDialog from "../../components/QuestionDialog/QuestionDialog";
import AuthService from "../../services/api/authService";
import InvitationsService from "src/services/api/invitationsService";
import { useSnackbar } from "notistack";
import SignUpSideResearchers from "../../components/SIgnUpSideResearchers/SIgnUpSideResearchers";

const SignUp: React.FunctionComponent = () => {
  /* States and context declaration */
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  const initialData = authContext.signUpUserData;
  const { email, setEmail, emailError } = useValidEmail(initialData?.email ?? "");
  const { phone, setPhone, phoneError, parsedPhone, setPhoneError } = useValidPhone(initialData?.phone ?? "");
  const [firstName, setFirstName] = useState(initialData?.firstName ?? "");
  const [lastName, setLastName] = useState(initialData?.lastName ?? "");
  const [dob, setDOB] = useState(initialData?.dob ?? null);
  const [organization, setOrganization] = useState<string | undefined>(initialData?.organization);
  const [researcherInviteCode, setResearcherInviteCode] = useState<string | undefined>(initialData?.inviteCode);
  const [allFieldsValid, setAllFieldsValid] = useState(false);
  const [mfaChecked, setMfaChecked] = useState(false);
  const [openQuestionDialog, setOpenQuestionDialog] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    visitRegistrationScreen();
  }, []);

  const { zip, setZip, zipError } = useValidZip(initialData?.zip ?? "");
  const initialGender = initialData?.gender!;
  const [gender, setGender] = useState<Gender | null>(genderOptionsArr.includes(initialGender) ? initialGender : null);

  const navigation = useNavigate();
  const [searchParams] = useSearchParams();

  const getUtmData = () => {
    const utmSource = searchParams.get("utm_source");
    const utmMedium = searchParams.get("utm_medium");
    const utmCampaign = searchParams.get("utm_campaign");
    const utmContent = searchParams.get("utm_content");
    const utmTerm = searchParams.get("utm_term");

    if (!utmSource && !utmMedium && !utmCampaign && !utmContent && !utmTerm) {
      return null;
    }

    const utmCode: UTMCodeType = {
      utmSource: utmSource ?? "no data provided",
      utmMedium: utmMedium ?? "no data provided",
      utmCampaign: utmCampaign ?? "no data provided",
      utmContent: utmContent ?? "no data provided",
      utmTerm: utmTerm ?? "no data provided",
    };

    return utmCode;
  };

  /* Callback and Memo, Optimization Section */
  const saveSignUpUserData = () => {
    if (authContext.setSignUpUserData == null) {
      console.error("Failed to set the users's signup data");
      return;
    }

    const utmCode = getUtmData();

    authContext.setSignUpUserData({
      firstName,
      lastName,
      phone: parsedPhone,
      email: email ?? undefined,
      dob: dob ?? undefined,
      zip,
      gender: gender ?? undefined,
      venmoPhone: parsedPhone,
      payPalEmail: email ?? undefined,
      giftCardEmail: email ?? undefined,
      utmCode,
      inviteCode: researcherInviteCode,
      organization,
    });
  };

  const onNextClicked = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    try {
      await AuthService.checkIsPhoneNumberVoIP({ phoneNumber: parsedPhone });
    } catch (err: any) {
      if (axios.isAxiosError(err) && err.response?.status === 403) {
        setPhoneError(err.response?.data.message || "You must enter a valid US phone number");
        return;
      }
    }
    if (!researcherInviteCode?.length) {
      setOpenQuestionDialog(true);
    } else {
      onSubmitQuestionDialog(true);
    }
  };

  const onSubmitQuestionDialog = (accept: boolean) => {
    saveSignUpUserData();
    const ref = searchParams.get("ref");
    let url;
    const nextPage = accept ? "select-password" : "additional-attributes";
    if (ref) {
      url = `/register/${nextPage}?ref=${ref}`;
    } else {
      url = `/register/${nextPage}`;
    }
    navigation(url);
    setOpenQuestionDialog(false);
  };

  const onCloseQuestionDialog = () => {
    setOpenQuestionDialog(false);
  };

  const handleGenderChange = (event: SelectChangeEvent) => {
    if (!isGender(event.target.value)) {
      return;
    }
    setGender(event.target.value);
  };

  const handleMfaCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMfaChecked(event.target.checked);
  };

  React.useEffect(() => {
    const commonFieldsValid = !!firstName && !!lastName && emailError == null && phoneError == null;
    if (researcherInviteCode?.length) {
      //researcher
      setAllFieldsValid(commonFieldsValid && !!organization);
    } else {
      setAllFieldsValid(
        commonFieldsValid &&
          !!gender &&
          dob != null &&
          new Date("1900-01-01") < dob &&
          dob < new Date() &&
          zipError == null
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, parsedPhone, firstName, lastName, dob, zip, gender, researcherInviteCode, organization]);

  React.useEffect(() => {
    const ref = searchParams.get("ref");
    if (ref) {
      UsersService.getInviteByRef(ref).then((response) => {
        if (!response.data.accountCreated) {
          setEmail(response.data.email || "");
        }
      });
    } else {
      const researcherInviteCode = searchParams.get("researcherInviteCode");
      if (researcherInviteCode?.length) {
        setIsLoading(true);
        InvitationsService.validate(researcherInviteCode)
          .then(() => {
            setIsLoading(false);
            setResearcherInviteCode(researcherInviteCode);
          })
          .catch(() => {
            setIsLoading(false);
            enqueueSnackbar("Invalid Invite code", { variant: "error" });
            navigation("/register/researcher", { replace: true });
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setEmail]);

  React.useEffect(() => {
    getStorageData().catch((e) => console.error(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStorageData = async () => {
    const isOTPScreenOn = await getItemIDB("isOTPScreenOn");
    const signUpUserData = await getItemIDB("signUpUserData");
    if (isOTPScreenOn === "1" && signUpUserData && authContext.setSignUpUserData) {
      authContext.setSignUpUserData(JSON.parse(signUpUserData));
      navigation(`/register/verify-phone`);
    }
  };

  return isLoading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress sx={{ color: "pink" }} />
    </Box>
  ) : (
    <div style={backgroundStyle}>
      <div style={containerStyle} data-testid="sign-up">
        <Paper elevation={1} style={{ ...paperStyle, borderRadius: "0px" }}>
          <Grid style={{ ...logoStyle, justifyContent: "start" }}>
            <img src={verasightLogo} alt="Verasight Logo" style={{ width: "150px" }} />
          </Grid>
          <div style={notice}>
            {researcherInviteCode?.length
              ? "Welcome to Verasight's Research Portal! Register below to launch your survey."
              : "Welcome to Verasight! Register below to earn Venmo payments or gift cards for taking short surveys!"}
          </div>
          <LinearProgress variant="determinate" value={20} style={{ color: "#2EBDEE" }} />
          <h1 style={h1Style}>Sign Up</h1>

          <div style={rowStyle}>
            <div style={columnStyle}>
              <p style={plabelStyle}>First Name</p>
              <OutlinedInput
                size="small"
                placeholder="First Name"
                fullWidth
                required
                value={firstName}
                style={inputStyle}
                inputProps={{ maxLength: 50 }}
                onChange={(e: any) => setFirstName(e.target.value)}
                data-testid="first-name-input"
              />
            </div>
            <div style={columnStyle}>
              <p style={plabelStyle}>Last Name</p>
              <OutlinedInput
                size="small"
                placeholder="Last Name"
                fullWidth
                required
                value={lastName}
                style={inputStyle}
                inputProps={{ maxLength: 50 }}
                onChange={(e: any) => setLastName(e.target.value)}
                data-testid="last-name-input"
              />
            </div>
          </div>
          <div style={rowStyle}>
            <div style={columnStyle}>
              <p style={plabelStyle}>Email Address</p>
              <OutlinedInput
                size="small"
                placeholder="Email"
                fullWidth
                required
                value={email}
                style={inputStyle}
                onChange={(e: any) => setEmail(e.target.value)}
                error={emailError != null}
                data-testid="email-input"
              />
            </div>
            <div style={researcherInviteCode?.length ? hiddenStyle : columnStyle} data-testid="dob-input">
              <p style={plabelStyle}>Date of Birth</p>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  sx={{ width: "100%" }}
                  value={dob ? new Date(dob) : null}
                  views={["year", "month", "day"]}
                  onChange={(newValue: React.SetStateAction<Date | null>) => {
                    setDOB(newValue);
                  }}
                  disableFuture={true}
                  slotProps={{ textField: { size: "small" } }}
                />
              </LocalizationProvider>
            </div>
          </div>
          <div style={rowStyle}>
            <div
              style={{
                ...rowStyle,
                ...columnStyle,
                flexWrap: "nowrap",
                minWidth: "275px",
              }}
            >
              <div style={researcherInviteCode?.length ? hiddenStyle : columnStyle}>
                <p style={plabelStyle}>Zip Code</p>
                <OutlinedInput
                  size="small"
                  placeholder="Zip Code"
                  fullWidth
                  required
                  value={zip}
                  error={zipError != null}
                  style={{ ...inputStyle, minWidth: "unset" }}
                  onChange={(e) => setZip(e.target.value)}
                  data-testid="zip-input"
                />
              </div>
              <div style={researcherInviteCode?.length ? columnStyle : hiddenStyle}>
                <p style={plabelStyle}>Organization</p>
                <OutlinedInput
                  size="small"
                  placeholder="Organization"
                  fullWidth
                  required
                  value={organization}
                  style={inputStyle}
                  inputProps={{ maxLength: 50 }}
                  onChange={(e: any) => setOrganization(e.target.value)}
                  data-testid="organization-input"
                />
              </div>
              <div style={researcherInviteCode?.length ? hiddenStyle : columnStyle}>
                <p style={plabelStyle}>Gender</p>
                <FormControl fullWidth>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={gender ?? ""}
                    onChange={handleGenderChange}
                    data-testid="gender-input"
                  >
                    {genderOptionsArr.map((genderOption) => (
                      <MenuItem value={genderOption} key={genderOption} data-testid="gender-input-options">
                        {capitalize(genderOption)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div style={columnStyle} data-testid="phone-input">
              <p style={plabelStyle}>Mobile Number</p>
              <PhoneInput
                data-testid="phone-input"
                country={"us"}
                onlyCountries={["us"]}
                value={phone}
                inputStyle={inputStyle}
                buttonStyle={phoneButtonStyle}
                onChange={(phone) => setPhone(phone)}
                placeholder="Mobile Number"
                countryCodeEditable={false}
              />
              {phoneError && <p style={phoneErrorStyle}>{phoneError}</p>}
            </div>
          </div>
          <div style={mfaBlockStyle}>
            <Checkbox data-testid="check-mfa" checked={mfaChecked} onChange={handleMfaCheckbox} sx={mfaCheckboxStyle} />
            <div style={mfaTextStyle}>
              Allow to receive a verification code to the number ****{!phoneError && phone.slice(-4)}
            </div>
          </div>
          <p style={mfaAdditionalInfo}>
            By providing your phone number, you are consenting to receive calls and text messages, including autodialed
            and automated calls and texts, to that number from Verasight. Message and data rates may apply. Reply “STOP”
            to opt-out. Terms & conditions/privacy policy apply:{" "}
            <a style={{ textDecoration: "none", color: "rgb(46, 189, 238)" }} href="https://www.verasight.io/privacy?">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a style={{ textDecoration: "none", color: "rgb(46, 189, 238)" }} href="https://www.verasight.io/terms?">
              Terms and Condition
            </a>
            .
          </p>
          <div style={{ ...rowStyle, marginTop: "60px" }}>
            <div style={{ flex: "1 1 30%" }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={{
                  ...btnStyle,
                  backgroundColor: "white",
                  color: "#5F6F74",
                }}
                fullWidth
                onClick={(e) => {
                  navigation(-1);
                }}
                data-testid="back-button"
              >
                Back
              </Button>
            </div>
            <div style={{ flex: "1 1 60%" }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={allFieldsValid && mfaChecked ? btnStyle : btnStyleDisabled}
                disabled={!allFieldsValid || !mfaChecked}
                onClick={onNextClicked}
                data-testid="submit-button"
              >
                Next
              </Button>
            </div>
          </div>
        </Paper>
      </div>

      {researcherInviteCode?.length ? <SignUpSideResearchers /> : <SideCarousel />}

      <QuestionDialog
        onClose={onCloseQuestionDialog}
        onSubmit={onSubmitQuestionDialog}
        open={openQuestionDialog}
        noBtnStyle={{
          ...btnStyle,
          backgroundColor: "white",
          color: "#5F6F74",
        }}
        data-testid="dialog-paypal-email-confirm"
        yesBtnStyle={btnStyle}
        text="Verasight sends cash rewards to your PayPal or Venmo accounts. Are these the email and phone number you use for your PayPal and Venmo accounts? If not, let us know what email and phone you use for your PayPal and Venmo accounts."
      />
    </div>
  );
};

export default SignUp;
