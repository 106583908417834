import React, { useEffect, useState } from "react";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Select,
  MenuItem,
  Switch,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Chip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

import { ScheduledEmailConfig, ScheduledEmailSetting, TimeUnitEnum } from "../../types";
import SettingsService, { SettingType } from "src/services/api/settingsService";
import { settingKeys } from "src/types/Settings";
import EmailTemplateSelector from "src/components/EmailTemplateSelector/EmailTemplateSelector";
import { hours2TimeUnit, timeUnit2Hours } from "../../helper";
import _ from "lodash";
import { EmailTemplate, EmailTemplateParameterEnum } from "src/types/Email";

const ScheduledEmail = () => {
  const [scheduledEmailSetting, setScheduledEmailSetting] = useState<ScheduledEmailSetting>();
  const [originalScheduledEmailSetting, setOriginalScheduledEmailSetting] = useState<ScheduledEmailSetting>();
  const [selectedRowKey, setSelectedRowKey] = React.useState<keyof ScheduledEmailSetting>();
  const [validState, setStateValid] = React.useState<boolean>();
  const [templateValid, setTemplateValid] = React.useState<boolean>();

  useEffect(() => {
    (async () => {
      const { data } = (await SettingsService.getSetting(settingKeys.SCHEDULED_EMAILS)) as { data: SettingType };
      setScheduledEmailSetting(data.payload?.value);
      setOriginalScheduledEmailSetting(_.cloneDeep(data.payload?.value));
    })();
  }, []);

  useEffect(() => {
    const res = _.isEqual(originalScheduledEmailSetting, scheduledEmailSetting);
    setStateValid(res);
  }, [originalScheduledEmailSetting, scheduledEmailSetting]);

  const handleSwitchChange = (status: boolean, key: keyof ScheduledEmailSetting) => {
    if (scheduledEmailSetting) {
      scheduledEmailSetting[key]!.enabled = status;
      setScheduledEmailSetting({ ...scheduledEmailSetting });
    }
  };

  const handleDescriptionChange = (text: string, key: keyof ScheduledEmailSetting) => {
    if (scheduledEmailSetting) {
      scheduledEmailSetting[key]!.description = text;
      setScheduledEmailSetting({ ...scheduledEmailSetting });
    }
  };

  const handleHourSinceChange = (time: number, key: keyof ScheduledEmailSetting) => {
    if (scheduledEmailSetting) {
      scheduledEmailSetting[key]!.hourSince = timeUnit2Hours({ unit: scheduledEmailSetting[key]!.timeUnit, time });
      setScheduledEmailSetting({ ...scheduledEmailSetting });
    }
  };
  const handleTimeUnitChange = (key: keyof ScheduledEmailSetting, unit: TimeUnitEnum) => {
    if (scheduledEmailSetting) {
      scheduledEmailSetting[key]!.timeUnit = unit;
      setScheduledEmailSetting({ ...scheduledEmailSetting });
    }
  };
  const handleTemplateChage = (key: keyof ScheduledEmailSetting, template?: EmailTemplate) => {
    if (scheduledEmailSetting && template && scheduledEmailSetting[key]!.templateMeta.templateId !== template.id) {
      scheduledEmailSetting[key]!.templateMeta.templateId = template.id;
      setScheduledEmailSetting({ ...scheduledEmailSetting });
    }
  };

  // Function to open edit dialog
  const handleEditClick = (key: keyof ScheduledEmailSetting) => {
    setSelectedRowKey(key);
  };

  // Function to handle dialog close
  const handleDialogClose = () => {
    setSelectedRowKey(undefined);
  };

  const handleTemplateParamDelete = (key: keyof ScheduledEmailSetting, param: EmailTemplateParameterEnum) => {
    if (scheduledEmailSetting) {
      const idx = scheduledEmailSetting[key]!.templateMeta.parameters.indexOf(param);
      if (idx >= 0) {
        scheduledEmailSetting[key]!.templateMeta.parameters.splice(idx, 1);
        setScheduledEmailSetting({ ...scheduledEmailSetting });
      }
    }
  };
  const handleTemplateParamAdd = (key: keyof ScheduledEmailSetting, param: EmailTemplateParameterEnum) => {
    if (scheduledEmailSetting && !scheduledEmailSetting[key]!.templateMeta.parameters.includes(param)) {
      scheduledEmailSetting[key]!.templateMeta.parameters.push(param);
      setScheduledEmailSetting({ ...scheduledEmailSetting });
    }
  };

  const onUpdateClick = async () => {
    const body = {
      key: settingKeys.SCHEDULED_EMAILS,
      payload: {
        value: scheduledEmailSetting,
      },
    };
    setStateValid(false);
    const { data } = (await SettingsService.postSetting(body)) as { data: SettingType };
    setScheduledEmailSetting(data.payload?.value);
    setOriginalScheduledEmailSetting(_.cloneDeep(data.payload?.value));
  };

  if (!scheduledEmailSetting) {
    return <></>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography align="center" variant="h6">
          Scheduled Email Setting
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Enabled</TableCell>
              <TableCell>Event Type</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {/* Map through your data to render table rows */}
          {Object.keys(scheduledEmailSetting).map((key) => {
            const k = key as keyof ScheduledEmailSetting;
            const row = scheduledEmailSetting[k] as ScheduledEmailConfig;
            return (
              <TableRow key={key}>
                <TableCell>
                  <TextField value={row.description} onChange={(e) => handleDescriptionChange(e.target.value, k)} />
                </TableCell>
                <TableCell>
                  <Switch checked={row.enabled} onChange={(e) => handleSwitchChange(e.target.checked, k)} />
                </TableCell>
                <TableCell>
                  <TextField disabled value={key} />
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(k)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell align="right" colSpan={4} sx={{ border: "none" }}>
              <Button onClick={onUpdateClick} disabled={validState || !templateValid}>
                Update
              </Button>
            </TableCell>
          </TableRow>
        </Table>
      </Grid>
      {/* Edit Dialog */}
      <Dialog fullWidth maxWidth="md" open={selectedRowKey !== undefined} onClose={handleDialogClose}>
        {selectedRowKey ? (
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Parameter Name</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableRow>
                <TableCell>Time Since Unit</TableCell>

                <TableCell>
                  <Select
                    id="time-unit"
                    onChange={(event) => handleTimeUnitChange(selectedRowKey, event.target.value as TimeUnitEnum)}
                    label="Select Unit"
                    defaultValue={scheduledEmailSetting[selectedRowKey]?.timeUnit}
                  >
                    {Object.values(TimeUnitEnum).map((item) => (
                      <MenuItem selected={scheduledEmailSetting[selectedRowKey]?.timeUnit === item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Time Since Value</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    value={
                      scheduledEmailSetting[selectedRowKey]?.hourSince
                        ? hours2TimeUnit({
                            unit: scheduledEmailSetting[selectedRowKey]!.timeUnit,
                            hour: scheduledEmailSetting[selectedRowKey]!.hourSince,
                          })
                        : ""
                    }
                    onChange={(e) => handleHourSinceChange(+e.target.value, selectedRowKey)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email Template</TableCell>
                <TableCell>
                  <EmailTemplateSelector
                    isReady={(val) => setTemplateValid(val)}
                    onTemplateSelected={(template) => handleTemplateChage(selectedRowKey, template)}
                    selectedTemplateId={scheduledEmailSetting[selectedRowKey]?.templateMeta.templateId}
                    expectedParams={scheduledEmailSetting[selectedRowKey]?.templateMeta.parameters || []}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email Template Params</TableCell>
                <TableCell>
                  <Select
                    labelId="add-item-select-label"
                    id="add-item-select"
                    onChange={(event) =>
                      handleTemplateParamAdd(selectedRowKey, event.target.value as EmailTemplateParameterEnum)
                    }
                    label="Add Item"
                  >
                    {Object.values(EmailTemplateParameterEnum).map((item) => (
                      <MenuItem
                        disabled={scheduledEmailSetting[selectedRowKey]?.templateMeta.parameters.includes(item)}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  {scheduledEmailSetting[selectedRowKey]?.templateMeta.parameters.map((p) => (
                    <Chip
                      sx={{ ml: 1 }}
                      key={p}
                      label={p}
                      onDelete={() => handleTemplateParamDelete(selectedRowKey, p)}
                      deleteIcon={<CloseIcon />}
                    />
                  ))}
                </TableCell>
              </TableRow>
            </Table>
          </DialogContent>
        ) : null}
        <DialogActions>
          <Button onClick={handleDialogClose}>Done</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ScheduledEmail;
