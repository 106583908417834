const initReferralFactory = (rfWidgetUrl: string) => {
  const body = document.querySelector("body") as HTMLHeadElement;
  const script = document.createElement("script");

  script.setAttribute("src", rfWidgetUrl);
  script.setAttribute("id", "rf-script");
  body.appendChild(script);

  return () => {
    body.removeChild(script);
  };
};

export default initReferralFactory;
