import { AxiosResponse } from "axios";
import { TargetingTag } from "../../types/TargetingTag";
import apiService from "./apiService";
import endpoints from "./endpoints";

export default class TargetingTagsService {
  static getSurveyTargetingTags(surveyId: string) {
    return apiService.get(endpoints.surveyTargetingTags(surveyId));
  }

  static addTagsToBatchOfUsers(data: object) {
    return apiService.post(endpoints.addTagsToBatchOfUsers, data);
  }

  static getGlobalTargetingTags(query?: { sort?: string; order?: "1" | "-1" }) {
    let q = new URLSearchParams(JSON.parse(JSON.stringify(query))).toString();
    return apiService.get(endpoints.globalTargetingTags + "?" + q);
  }

  static setReferralTags(data: TargetingTag[]) {
    return apiService.post(endpoints.setReferralTags, data);
  }

  static removeTargetingTagFromUser(targetingTagId: string, userId: string) {
    return apiService.post(endpoints.removeTargetingTagFromUser(targetingTagId), {
      userId,
    });
  }

  static getUtmTargetingTags() {
    return apiService.get(endpoints.utmTargetingTags);
  }

  static createUtmTag(tag: string): Promise<AxiosResponse<TargetingTag>> {
    return apiService.post(endpoints.createUtmTag, { tag });
  }
}
