import React from "react";

import earnRewardsImage from "../../assets/earn-rewards.svg";
import exchangePointsImage from "../../assets/exchange-points.svg";
import earnBonusPointsImage from "../../assets/earn-bonus-points.svg";
import verasightReviewImage1 from "../../assets/verasight-reviews-1.png";
import verasightReviewImage2 from "../../assets/verasight-reviews-2.png";
import verasightReviewImage3 from "../../assets/verasight-reviews-3.png";
import verasightReviewImage4 from "../../assets/verasight-reviews-4.png";
import verasightReviewImage5 from "../../assets/verasight-reviews-5.png";
import verasightReviewImage6 from "../../assets/verasight-reviews-6.png";

import "./SignUpSideCarousel.scss";

const reviewImages = [
  verasightReviewImage1,
  verasightReviewImage2,
  verasightReviewImage3,
  verasightReviewImage4,
  verasightReviewImage5,
  verasightReviewImage6,
];

const slides = [
  {
    text: (
      <p>
        Earn <b>rewards</b> for taking short surveys!
      </p>
    ),
    image: earnRewardsImage,
  },
  {
    text: (
      <p>
        Exchange those points for <b>rewards</b> or <b>cash!</b>
      </p>
    ),
    image: exchangePointsImage,
  },
  {
    text: (
      <p>
        {" "}
        Earn <b>bonus points</b> on your birthday or for sharing and referring!
      </p>
    ),
    image: earnBonusPointsImage,
  },
  ...reviewImages.map((image) => {
    return {
      text: <p>And our Community ❤️ us!</p>,
      image: image,
    };
  }),
];

const Carousel = () => {
  const [activeSlide, setActiveSlide] = React.useState<number>(0);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setActiveSlide((activeSlide + 1) % slides.length);
    }, 4000);
    return () => clearTimeout(timer);
  }, [activeSlide]);

  return (
    <div className="carousel">
      <div className="carousel-wrapper">
        {slides[activeSlide].text}
        <div className="carousel-navigator">
          {slides.map((slide, index) => {
            return (
              <div
                key={index}
                className={"carousel-navigator-dot" + (index === activeSlide ? " active" : "")}
                onClick={() => setActiveSlide(index)}
              />
            );
          })}
        </div>
        <img src={slides[activeSlide].image} alt="Carousel" />
      </div>
    </div>
  );
};

export default Carousel;
