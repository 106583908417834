import _ from "lodash";

export interface PaginateOptions {
  sort?: object | string | undefined;
  sortDirection?: "asc" | "desc"; 
  offset?: number | undefined;
  page?: number | undefined;
  limit?: number | undefined;
}

export function manualPagination(
  docs: any[],
  options: PaginateOptions = {},
) {
  let direction: ("asc" | "desc")[] = [];
  let fields: string[] = [];
  if (options.sort) {
    direction = [options.sortDirection || 'desc'];
    fields = [(options.sort as string).replace('-', '')];
  }
  const sorted = _.orderBy(docs, fields, direction);
  const total = sorted.length;
  const page = options.page ?? 1;
  const limit = options.limit ?? 10;
  const start = (page - 1) * limit;
  const end = Math.min(start + limit - 1, total);
  const pages = Math.ceil(sorted.length / limit);
  const subset = sorted.slice(start, end + 1);
  return {
    docs: subset,
    total,
    limit,
    page,
    pages,
    offset: start,
  };
}
