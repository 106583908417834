import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clickSurveyLink } from "../../../helpers/dataLayers";
import SurveysService from "../../../services/api/surveysService";
import "./HomeScreen.scss";
import { AuthContext } from "../../../contexts/authContext";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import initReferralFactory from "../../../referral-factory";
import SettingsService from "../../../services/api/settingsService";
import { settingKeys } from "src/types/Settings";
import ContactUsButton from "src/components/ContactUsButton/ContactUsButton";
import SupportDialog from "src/components/SupportDialog/SupportDialog";
import SnackBarClose from "../../../components/common/SnackbarClose";
import { SearchParams } from "src/constants/url";
import { UserRoles } from "src/types/User";
import { enqueueSnackbar } from "notistack";
import SurveyFeed from "src/components/SurveyFeed/SurveyFeed";

const HomeScreen = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [isOverLay, setIsOverLay] = React.useState<boolean>(false);
  const [contactUsDialogOpen, setContactUsDialogOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
  const [snackbarMessageType, setSnackbarMessageType] = React.useState<AlertColor>("info");
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);

  const displayErrorSnackbar = (error_message: string) => {
    setSnackbarMessageType("error");
    setSnackbarMessage(error_message);
    setSnackbarOpen(true);
  };

  const displaySuccessSnackbar = (message: string) => {
    setSnackbarMessageType("success");
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const error_message = searchParams.get("error_message");
    const successMessage = searchParams.get(SearchParams.successMessage);

    if (error_message) {
      displayErrorSnackbar(error_message);
    }
    if (successMessage) {
      displaySuccessSnackbar(successMessage);
    }
  }, []);

  useEffect(() => {
    if (!user?.referralAvailable) return;

    window.RF = {
      user: {
        first_name: user?.firstName,
        email: user?.email,
      },
    };

    setupReferralFactory();
  }, []);

  const setupReferralFactory = async () => {
    const setting = await SettingsService.getSetting(settingKeys.RF_WIDGET_URL);
    const rfWidgetUrl = setting.data?.payload?.value;
    if (!rfWidgetUrl) return;

    initReferralFactory(rfWidgetUrl);
  };

  const onCardClick = (
    surveyId: string,
    click_survey_points: number,
    click_survey_category: string,
    createdByRole: UserRoles
  ) => {
    if (createdByRole === UserRoles.RESEARCHER) {
      setIsOverLay(true);
      SurveysService.getExternalSurveyRedirectUrl(surveyId)
        .then((res) => {
          window.open(res.data.url, "_blank");
        })
        .catch(() => {
          enqueueSnackbar("Failed to redirect to survey, please contact support", { variant: "error" });
        })
        .finally(() => {
          setIsOverLay(false);
        });
    } else {
      navigate(`/survey/${surveyId}`);
    }
    clickSurveyLink(click_survey_points, click_survey_category);
  };

  const onLikeClick = (surveyId: string, like: boolean, callback: () => void) => {
    if (like) {
      SurveysService.likeSurvey(surveyId).then(() => callback());
    } else {
      SurveysService.unlikeSurvey(surveyId).then(() => callback());
    }
  };

  const onClickContactUs = () => {
    setContactUsDialogOpen(true);
  };

  const onCloseContactUs = () => {
    setContactUsDialogOpen(false);
  };
  const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      <SurveyFeed onCardClick={onCardClick} onLikeClick={onLikeClick} isOverLay={isOverLay} />
      <ContactUsButton onClick={onClickContactUs} />
      <SupportDialog onClose={onCloseContactUs} open={contactUsDialogOpen} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        action={<SnackBarClose handleClose={handleCloseSnackbar} />}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarMessageType} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default HomeScreen;
