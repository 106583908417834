import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, IconButton, styled } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import StarsOutlinedIcon from "@mui/icons-material/StarsOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

import UserBanner from "./components/UserBanner";
import AccountDetails from "./components/AccountDetailsTab";
import ChangePasswordForm from "../../../components/ChangePasswordForm/ChangePasswordForm";
import RewardsPage from "..//RewardsPage/RewardsPage";
import { AuthContext } from "../../../contexts/authContext";
import SettingsService from "../../../services/api/settingsService";

import { useMobileSize } from "../../../hooks/useMobileSize";
import "./ProfileScreen.scss";
import UserActivityTab from "./components/UserActivityTab";
import { UserRoles } from "src/types/User";
import { CashoutVendorEnum } from "../../../types/generic";

type Tab = {
  name: string;
  key: string;
  icon: any;
};

const StyledLink = styled(Button)({
  display: "flex",
  justifyContent: "center",
  columnGap: "12px",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "24px",
  letterSpacing: "1px",
  color: "#384144",
  textTransform: "capitalize",
  textDecoration: "none",
  position: "relative",

  "&:hover, &.active": {
    color: "#2EBDEE",
  },

  "& > svg": {
    width: "20px",
    height: "20px",
  },
});

const ProfileScreen = () => {
  const navigate = useNavigate();
  const { tab } = useParams();
  const { user, refreshUser } = React.useContext(AuthContext);
  const [activeTab, setActiveTab] = React.useState<string | null>(null);
  const { isMobile } = useMobileSize();

  const [pointsPerDollar, setPointsPerDollar] = React.useState<number>(1000);
  const isResearcher = user?.role === UserRoles.RESEARCHER;

  React.useEffect(() => {
    if (refreshUser != null && !user) {
      refreshUser(false);
    }
  }, [refreshUser, user]);

  React.useEffect(() => {
    if (tab !== activeTab) setActiveTab(tab || null);
  }, [tab, activeTab]);

  React.useEffect(() => {
    if (user == null || isResearcher) {
      return;
    }
    if (user.settings && user.settings.pointToUSDConversion) {
      setPointsPerDollar(user.settings.pointToUSDConversion);
    } else {
      SettingsService.getSetting("point-conversion").then((res) => {
        const _pointsPerDollar = res.data.payload.value;
        if (_pointsPerDollar != null) {
          setPointsPerDollar(_pointsPerDollar);
        }
      });
    }
  }, [user]);

  const updateUserData = () => {
    if (refreshUser != null) refreshUser(false);
  };

  const tabs: Tab[] = [
    {
      name: "Account Details",
      key: "account-details",
      icon: <PersonOutlineOutlinedIcon />,
    },
    {
      name: "Security",
      key: "security",
      icon: <LockOutlinedIcon />,
    },
  ].concat(
    isResearcher
      ? []
      : [
          {
            name: "Cash Out",
            key: "rewards",
            icon: <StarsOutlinedIcon />,
          },
          {
            name: "Activity",
            key: "activity",
            icon: <AccessTimeIcon />,
          },
        ]
  );

  return (
    <div className="user-profile-container">
      {isMobile && activeTab !== null ? (
        <Box className="tab-title-wrapper">
          <IconButton onClick={() => navigate(isResearcher ? "/researcher" : "" + `/profile`)}>
            <ArrowBackIosOutlinedIcon />
          </IconButton>
          <span>{tabs.filter((tab) => tab.key === activeTab)[0].name}</span>
        </Box>
      ) : (
        <>
          <UserBanner userData={user} />

          <div className={isMobile ? "profile-nav-menu mobile" : "profile-nav-menu desktop"}>
            {tabs.map((tab) => (
              <StyledLink
                variant={"text"}
                key={tab.key}
                className={activeTab === tab.key ? "active" : ""}
                onClick={() => navigate(`${isResearcher ? "/researcher" : ""}/profile/${tab.key}`)}
              >
                {tab.icon}
                {tab.name}
                {isMobile ? <ArrowForwardIosOutlinedIcon sx={{ position: "absolute", right: "0" }} /> : null}
              </StyledLink>
            ))}
          </div>
        </>
      )}

      {(() => {
        switch (activeTab) {
          case "account-details":
            return <AccountDetails userData={user} onUpdate={updateUserData} />;
          case "security":
            return <ChangePasswordForm />;
          case "rewards":
            return (
              <RewardsPage
                pointsPerDollar={pointsPerDollar}
                vendorAmounts={[
                  { vendor: CashoutVendorEnum.PAYPAL, amount: 10 },
                  { vendor: CashoutVendorEnum.VENMO, amount: 10 },
                  { vendor: CashoutVendorEnum.GIFT_CARD, amount: 10 },
                ]}
              />
            );
          case "activity":
            return <UserActivityTab />;
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default ProfileScreen;
