import politicsIcon from "../../../assets/politics.svg";
import artsIcon from "../../../assets/arts.svg";
import economyIcon from "../../../assets/economy.svg";
import environmentIcon from "../../../assets/environment.svg";
import scienceIcon from "../../../assets/science.svg";
import sportsIcon from "../../../assets/sports.svg";
import type { ITopic } from "./types";

const TOPICS: { [key: string]: ITopic } = {
  politics: {
    name: "Politics",
    icon: politicsIcon,
  },
  arts: {
    name: "Arts",
    icon: artsIcon,
  },
  economy: {
    name: "Economy",
    icon: economyIcon,
  },
  environment: {
    name: "Environment",
    icon: environmentIcon,
  },
  science: {
    name: "Science",
    icon: scienceIcon,
  },
  sports: {
    name: "Sports",
    icon: sportsIcon,
  },
};

export { TOPICS };
