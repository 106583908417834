import { IconButton, InputAdornment, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

type SearchWrapperProps = {
  searchValue: string;
  setSearchValue: (value: string) => void;
  onSearchClick: () => void;
  onClearSearch: () => void;
  searchIsActive: boolean;
};

export default function SearchWrapper({
  searchValue,
  setSearchValue,
  onSearchClick,
  onClearSearch,
  searchIsActive,
}: SearchWrapperProps) {
  return (
    <TextField
      label="Search"
      size="small"
      value={searchValue}
      onChange={(event) => setSearchValue(event.target.value)}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          onSearchClick();
        }
      }}
      sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              style={{
                visibility: searchIsActive ? "visible" : "hidden",
              }}
              onClick={onClearSearch}
            >
              <CloseIcon />
            </IconButton>
            <IconButton onClick={onSearchClick}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
