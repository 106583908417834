import { useState, useEffect } from "react";

export const useMobileSize = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isProfileUrl, setIsProfileUrl] = useState<boolean>(false);

  useEffect(() => {
    
    setIsProfileUrl(window.location.href.includes('profile'));
    if (window.innerWidth < 768){
      setIsMobile(true);
    }
    else{
      setIsMobile(false);
    } 

    function handleResize() {
      if (window.innerWidth < 768){
        setIsMobile(true);
        setIsProfileUrl(window.location.href.includes('profile'));
      } 

      else setIsMobile(false);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      return window.removeEventListener("resize", handleResize);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return { isMobile, isProfileUrl,setIsProfileUrl };
};
