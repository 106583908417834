/* eslint-disable no-template-curly-in-string */
import { Box, Container } from "@mui/material";
import "./SelfServeGuide.scss";
import logo from "../../../assets/logo.svg";
import availableSurveys from "../../../assets/available-surveys.png";
import setUpYourStudy from "../../../assets/set-up-your-study.png";
import surveyPlatformQualtrics  from "../../../assets/survey-platform-qualtrics.png";
import surveyPlatformOther from "../../../assets/survey-platform-other.png";
import doesNotConsent from "../../../assets/does-not-consent.png";
import doesConsent from "../../../assets/does-consent.png";
import attentionCheckFailures from "../../../assets/attention-check-failures.png";
import testRedirectLinks from "../../../assets/test-redirect-links.png";
import testRedirectLinksSuccess from "../../../assets/test-redirect-links-success.png";
import embeddedData from "../../../assets/embedded-data.png";
import terminationPoints from "../../../assets/termination-points.png";
import endOfSurvey from "../../../assets/end-of-survey.png";

export default function SelfServeGuide() {
  return (
    <Container>
      <Box sx={{ marginBottom: "3rem" }}>
        <div className="header">
          <img src={logo} alt="Verasight Logo" style={{ width: "100px" }} />
          <h1>Verasight's Self-Serve Survey Integration Guide</h1>  
        </div> 
      </Box>
      <Box sx={{ marginBottom: "4rem" }}>
        <Box>
          <h2>General set-up instructions for surveys programmed in any platform</h2>

          <p>
            After programming your survey in your preferred survey software, there are 3 steps to setting up your survey
            to use with Verasight's self-serve platform:
          </p>

          <ol>
            <li>Directing respondents into your survey</li>
            <li>Redirecting respondents back to Verasight</li>
            <li>Testing your redirects</li>
          </ol>
        </Box>
        <Box>
          <h3>1. Directing respondents into your survey</h3>       
          <ol>
            <li>
              <p>
                In your Verasight account, find the survey that you would like to launch. Click the hamburger menu (three dots) on the far right side of the screen.
              </p>
              <img src={availableSurveys} alt="Available Surveys" />
              <ol type="a">
                <li>Click “Edit”</li>
                <li>
                  <p>Copy and paste the distribution link survey in the box titled “What’s the URL of your study?”:</p>
                  <img src={setUpYourStudy} alt="Set Up Your Study" />
                </li>
                <li>
                  <p>Select your survey platform in the next menu.</p>
                  <ol type="i">
                    <li>
                      <p>
                        If you are using Qualtrics, the redirect links below will pre-populate for you exactly as 
                        needed for Qualtrics. If you are using another platform, you will need to edit the “vsid” 
                        parameter of the links as described below.
                      </p>
                      <img src={surveyPlatformQualtrics} alt="Survey Platform Qualtrics" />
                    </li>
                    <li>
                      <p>
                        If you are not using Qualtrics, you will see this “Custom user_id token” box pop up. Insert the 
                        token that you will need in the URL to pass the unique ID for each user back to the Verasight platform. 
                        If you have questions, see section 2c below. 
                      </p>
                      <img src={surveyPlatformOther} alt="Survey Platform Other" />
                    </li>
                  </ol>
                </li>
                <li>Click the blue “Save Information” button</li>
              </ol>       
            </li>
            <li>
              <p>
                Open your programmed survey. In this step, we will ensure that your survey captures the elements
                necessary to record the survey and respondent IDs so that Verasight respondents are compensated when they
                submit your survey. This means that we need to know which survey they took (with the URL parameter{" "}
                <b>surveyid</b>) and which panelist took the survey (with the URL parameter <b>vsid</b>). These elements
                will be passed into your survey from Verasight automatically. Ensure that you are capturing these
                variables:
              </p>

              <ol type="a">
                <li>
                  <p>
                    The variable names that will be appended to your inbound URL are:
                  </p>
                  <ol type="i">
                    <li>
                      <p>vsid</p>
                    </li>
                    <li>
                      <p>surveyid</p>
                    </li>
                    <li>
                      <p>
                        As an example, your link to <span className="link-example">www.survey.com</span> would look like this: <span className="link-example">www.survey.com?surveyid=123&vsid=user123</span>. 
                        The parameters “surveyid” and “vsid” are automatically appended to your survey URL when respondents begin 
                        your survey in their Verasight account. 
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    The way that you capture these URL parameters will depend on the survey software that you are using. If you are 
                    using Qualtrics, click to jump to the <a href="#qualtrics-instructions">instructions for Qualtrics set up</a> section. 
                  </p>
                </li>
                <li>
                  <p>
                    If you are using another platform, here are links to support for adding URL parameters in other platforms: 
                  </p>
                  <ol type="i">
                    <li>
                      <p>
                        <a 
                          href="https://help.redcap.ualberta.ca/help-and-faq/survey-parameters" 
                          target="_blank" 
                          rel="noreferrer nofollow"
                        >
                          REDCAP
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <a 
                          href="https://www.typeform.com/help/a/dynamically-pass-hidden-fields-from-a-url-to-an-embedded-form-4404652303892/" 
                          target="_blank" 
                          rel="noreferrer nofollow"
                        >
                          Typeform
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <a 
                          href="https://help.surveymonkey.com/en/surveymonkey/send/custom-variables/" 
                          target="_blank" 
                          rel="noreferrer nofollow"
                        >
                          SurveyMonkey
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        <a 
                          href="https://forstasurveys.zendesk.com/hc/en-us/articles/4409469904411-Capturing-the-Referer-URL" 
                          target="_blank" 
                          rel="noreferrer nofollow"
                        >
                          Forsta
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>Using another platform? Reach out to our team with any questions.</p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </Box>
        <Box>
          <h3>2. Redirecting respondents back to Verasight on survey completion</h3>

          <ol>
            <li>
              <p>
                Identify the termination points in your survey.  We need to direct respondents back to the survey at 3 termination 
                points: completes, those who do not consent to participate, and (if applicable) those who fail your attention check.
              </p>
              <ol type="a">
                <li>
                  <p>
                    Those who do <u>not</u> consent should be terminated
                  </p>
                  <ol type="i">
                    <li>
                      <p>
                        Insert the termination link from your Verasight account page by copying the link titled “Study Redirect URL: 
                        Does not consent.”<br /> 
                        <strong>Important:</strong> ensure that you insert the appropriate variable that passes back in the vsid at 
                        the end of the URL
                      </p>
                      <img src={doesNotConsent} alt="Does Not Consent" />
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Those who <u>do</u> consent and pass your attention check (if any) should be redirected back to Verasight upon 
                    completing your survey
                  </p>
                  <ol type="i">
                    <li>
                      <p>
                        Insert the complete link from your Verasight account page by copying the link titled “Study Redirect URL: 
                        Complete.”<br /><strong>Important:</strong> ensure that you insert the appropriate variable that passes back in 
                        the vsid at the end of the URL
                      </p>
                      <img src={doesConsent} alt="Does Consent" />
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Those who <u>do</u> consent and <u>do not</u> pass your attention check should be redirected back to Verasight upon 
                    completing your survey. As a reminder, if you use an attention check,  you must select one from Verasight’s {" "}
                    <a href="https://docs.google.com/document/d/1Ydu_hViRGTd4d3ZVpZN3Gw2NyUxRzHp8DR_orGeLlxA/edit" target="_blank" rel="noreferrer nofollow">
                      pre-approved list
                    </a>.
                  </p>
                  <ol type="i">
                    <li>
                      <p>
                        Insert the complete link from your Verasight account page by copying the link titled “Study Redirect URL: Attention 
                        check failures.” <br /> <strong>Important:</strong> ensure that you insert the appropriate variable that passes back 
                        in the vsid at the end of the URL
                      </p>
                      <img src={attentionCheckFailures} alt="Attention Check Failures" />
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </Box>
        <Box>
          <h3>3. Testing your redirect links</h3>
          <ol type="a">
            <li>
              <p>
                The final step to complete before your survey can be fielded is testing your redirect links. You will need to successfully test 
                each link before your survey is approved for fielding. To test your links, click “Complete to activate” next to each link. Then, 
                take the appropriate actions in your survey. For example, to test the “Survey redirect URL: Does not consent” successfully, you 
                should click the link in your “does not consent” box and decline to participate in your consent form.
              </p>
              <img src={testRedirectLinks} alt="Test Redirect Links" />
            </li>
            <li>
              <p>
                Once you successfully test each link, you will see a green check mark appear next to the link. For example, this is what you 
                should see after successfully verifying your “Does not consent” redirect:
              </p>
              <img src={testRedirectLinksSuccess} alt="Test Redirect Links Success" />
            </li>
          </ol>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "4rem" }}>
        <h2 id="qualtrics-instructions">Additional set-up instructions for surveys programmed in Qualtrics</h2>

        <Box>
          <h3>1. Directing respondents into your survey</h3>

          <ol>
            <li>
              <p>
                Navigate to your Qualtrics survey. Ensure that you are recording the necessary inbound variables by adding the following embedded data 
                elements in the Survey Flow of your Qualtrics survey.
              </p>
              <p>
                At the very beginning of your Survey Flow, add an embedded data element with the following variables: 
              </p>
              <ul className="query-params">
                <li><strong>vsid</strong></li>
                <li><strong>surveyid</strong></li>
              </ul>
              <img src={embeddedData} alt="Embedded Data" />
            </li>
          </ol>
        </Box>   
        <Box>
          <h3>2. Redirecting respondents back to Verasight on survey completion</h3>
          <ol>
            <li>
              <p>
                Identify the termination points in your survey.  At each termination point, add a branch with the appropriate logic. For example, to 
                terminate those who do not consent, your branch might look like this:
              </p>
              <img src={terminationPoints} alt="Termination Points" />
            </li>
            <li>
              <p>
                Click “Customize” in the End of Survey Element. Then click “Override Survey Options.” Finally, click “Redirect to a URL…”
              </p>
              <img src={endOfSurvey} alt="End of Survey" />
            </li>
            <li>
              <p>
                Insert the termination link from your Verasight account page. Ensure that you have selected “Qualtrics” as your survey platform. Then, 
                use the provided links without changing or inserting any additional URL parameters. For example, your redirect link should look something like this: 
              </p>
              <p className="link-example">
                {"https://backend-staging.verasight.io/surveys/redirect?surveyid=__vskey__66cdf1f2b83c9b77e53bea6e&vsref=2&vsid=${e://Field/vsid}"}
              </p>
            </li>
          </ol>
        </Box>    
      </Box>
    </Container>
  );
}
