import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  styled,
} from "@mui/material";
import "./AvailableUsers.scss";
import SurveysService from "../../../../services/api/surveysService";
import { convertDatetimeStringToReadable } from "../../../../helpers/date";

export type AvailableUserType = {
  id: string;
  name: string;
  email: string;
  status: boolean;
  // TODO --- change isReceived when after adding Twilio
  isReceived: string;
  completionDate: string;
};

const headCells = [
  {
    id: "name",
    label: "Member Name",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "isReceived",
    label: "Notification Sent",
  },
  {
    id: "completionDate",
    label: "Date Survey Completed",
  },
  {
    id: "status",
    label: "Status",
  },
];

const StyledTableHeadCell = styled(TableCell)({
  fontSize: "14px",
  lineHeight: "16px",
  fontWeight: "bold",
  color: "#91a3a9",
  textAlign: "left",
  padding: "10px 15px",
});

const TableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableHeadCell key={headCell.id}>
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </StyledTableHeadCell>
        ))}
        <StyledTableHeadCell />
      </TableRow>
    </TableHead>
  );
};

const AvailableUsers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { id: surveyId } = useParams();
  const [availableUsers, setAvailableUsers] = React.useState<AvailableUserType[]>([]);
  const [page, setPage] = React.useState<number>(0);
  const [limit, setLimit] = React.useState<number>(10);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [downloadButtonText, setDownloadButtonText] = React.useState<string>("Loading...");

  React.useEffect(() => {
    if (!surveyId) return;

    SurveysService.getAvailableUsers(surveyId, {
      page: page + 1,
      limit,
      offset: limit * page,
    })
      .then((response) => {
        setAvailableUsers(response.data.users);
        setTotalCount(response.data.totalCount);
      })
      .catch(() => {
        enqueueSnackbar("Failed to download available users", { variant: "error" });
      });
  }, [page, limit, surveyId, enqueueSnackbar]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = parseInt(event.target.value, 10);
    setLimit(value);
    setPage(0);
  };

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage);
  };

  const downloadAvailableUsers = async () => {
    if (!surveyId) return;
    let prevState = downloadButtonText;
    try {
      setDownloadButtonText("downloading...");
      enqueueSnackbar("File is downloading please dont refresh or leave the page", { variant: "info" });
      const data = await SurveysService.downloadAvailableUsers(surveyId);
      const blob = new Blob([data.data], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `Available users for survey ${surveyId}.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      enqueueSnackbar("File downloaded", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to download available users", { variant: "error" });
    } finally {
      setDownloadButtonText(prevState);
    }
  };

  useEffect(() => {
    setDownloadButtonText(availableUsers.length > 0 ? "Download CSV" : "Not available to users");
  }, [availableUsers]);

  return (
    <Paper className="general-block">
      <div className="general-block__header">
        <div className="general-block__title">List of Members who have had this survey published to them</div>
        <button
          disabled={downloadButtonText === "downloading..."}
          className="last-container__button general-block__csv"
          onClick={downloadAvailableUsers}
        >
          {downloadButtonText}
        </button>
      </div>

      <Paper>
        <Table>
          <TableHeader />
          <TableBody style={{ borderRadius: "16px" }}>
            {availableUsers.map((user) => (
              <TableRow key={user.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <span className="general-block__row-text">
                    <b>{user.name}</b>
                  </span>
                </TableCell>
                <TableCell>
                  <span className="general-block__row-text">{user.email}</span>
                </TableCell>
                {/* TODO --- change logic after adding Twilio */}
                <TableCell>{user.isReceived}</TableCell>
                <TableCell>{user.completionDate ? convertDatetimeStringToReadable(user.completionDate) : ""}</TableCell>
                <TableCell>{`${user.status ? "completed" : "available"}`}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Paper>
  );
};

export default AvailableUsers;
