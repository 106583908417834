import {
  Box,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
} from "@mui/material";
import BootstrapInput from "../BootstrapInput";
import styled from "@emotion/styled";
import CopyToClipboardButton from "../CopyToClipboardButton";
import { apiURL } from "../../services/api/apiService";
import { ActivationRedirect, RedirectLinksData, SurveyLinkTableParamEnum } from "src/types/Survey";
import InfoIcon from "@mui/icons-material/Info";
import RedirectVerification from "./RedirectVerification";
import { useEffect, useState } from "react";
import { SurveyProvider2UserIdToken, SurveyProviders } from "src/constants/survey";

const InputWrapper = styled.div({
  display: "flex",
  flexDirection: "column",

  input: {
    margin: "5px 4px 4px 0",
  },
});

const Header = styled.h3({
  margin: "0",
});

const iconStyle = {
  position: "absolute",
  fontSize: "1.5rem",
  color: "blue",
  paddingLeft: "3px",
  margin: "-3px 0 0 0",
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "1rem", // Change the font size here
    borderRadius: "4px", // Optional: change border radius
  },
}));

interface ResearcherStudyFormProps {
  readonly title: string;
  readonly setTitle: (title: string) => void;
  readonly externalUrl: string;
  readonly setExternalUrl: (externalUrl: string) => void;
  readonly surveyId?: string;
  externalSurveyRedirectLinksData?: RedirectLinksData | null;
  activationRedirects?: ActivationRedirect[];
}

export default function ResearcherStudyForm({
  title,
  setTitle,
  externalUrl,
  setExternalUrl,
  surveyId,
  externalSurveyRedirectLinksData,
  activationRedirects,
}: Readonly<ResearcherStudyFormProps>) {
  const [selectedPlatform, setSelectedPlatform] = useState<SurveyProviders>(SurveyProviders.Qualtrics);
  const [customUserId, setCustomUserId] = useState<string>("");
  const [redirectUrls, setRedirectUrls] = useState({
    complete: "",
    optOut: "",
    noAttention: "",
  });

  const buildRedirectUrlQuery = (vsRef: string) => {
    let query = "";
    externalSurveyRedirectLinksData?.parameters
      .sort((a, b) => +a.orderId - +b.orderId)
      .forEach((param) => {
        const type = param.parameterType.toUpperCase();
        if (type === SurveyLinkTableParamEnum.SURVEY_ID) {
          query += `${param.parameterName}=__vskey__${surveyId}&`;
        } else if (type === SurveyLinkTableParamEnum.USER_ID) {
          query += `${param.parameterName}=`;
        } else if (param.parameterName === "vsref") {
          query += `vsref=${vsRef}&`;
        }
      });
    if (query.endsWith("&")) {
      return query.substring(0, query.length - 1); // remove last &
    }
    return query;
  };

  const getActivationRedirect = (vsRef: string) => activationRedirects?.find((redirect) => redirect.vsref === vsRef);

  const getUserIdToken = () => {
    return SurveyProvider2UserIdToken[selectedPlatform] || customUserId;
  };
  const completeActivation = getActivationRedirect("1");
  const optOutActivation = getActivationRedirect("2");
  const noAttentionActivation = getActivationRedirect("3");

  useEffect(() => {
    const token = getUserIdToken();
    const complete = externalSurveyRedirectLinksData?.baseUrl
      ? `${externalSurveyRedirectLinksData?.baseUrl}?${buildRedirectUrlQuery("1")}${token}`
      : `${apiURL}/surveys/redirect?surveyid=__vskey__${surveyId}&vsref=1&user_id=${token}`;
    const optOut = externalSurveyRedirectLinksData?.baseUrl
      ? `${externalSurveyRedirectLinksData?.baseUrl}?${buildRedirectUrlQuery("2")}${token}`
      : `${apiURL}/surveys/redirect?surveyid=__vskey__${surveyId}&vsref=2&user_id=${token}`;
    const noAttention = externalSurveyRedirectLinksData?.baseUrl
      ? `${externalSurveyRedirectLinksData?.baseUrl}?${buildRedirectUrlQuery("3")}${token}`
      : `${apiURL}/surveys/redirect?surveyid=__vskey__${surveyId}&vsref=3&user_id=${token}`;

    setRedirectUrls({
      complete,
      optOut,
      noAttention,
    });
  }, [selectedPlatform, customUserId, surveyId, externalSurveyRedirectLinksData]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
      <Header>Set Up Your Study</Header>

      <Box>
        <InputWrapper>
          <div>
            Study Name (internal only)
            <CustomTooltip title="This is the name by which you can identify your study. Respondents will not see this name.">
              <InfoIcon sx={iconStyle} />
            </CustomTooltip>
          </div>
          <BootstrapInput value={title} onChange={(e) => setTitle(e.target.value)} />
        </InputWrapper>
      </Box>

      <Box>
        <InputWrapper>
          <div>
            What's the URL of your study?
            <CustomTooltip title="Follow the instructions in the Integration Guide to set-up your study URL.">
              <InfoIcon sx={iconStyle} />
            </CustomTooltip>
          </div>
          <BootstrapInput value={externalUrl} onChange={(e) => setExternalUrl(e.target.value)} />
        </InputWrapper>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="platform-select-label">Survey Platform</InputLabel>
            <Select
              labelId="platform-select-label"
              value={selectedPlatform}
              onChange={(e) => setSelectedPlatform(e.target.value as SurveyProviders)}
              label="Survey Platform"
            >
              {Object.values(SurveyProviders).map((provider) => (
                <MenuItem key={provider} value={provider}>
                  {provider}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {selectedPlatform === SurveyProviders.Other && (
          <Grid item xs={12}>
            <Grid item xs={12} sm={6}>
              <InputWrapper>
                <div>
                  Custom user_id token
                  <CustomTooltip title="Enter the user_id token specific to your survey platform.">
                    <InfoIcon sx={iconStyle} />
                  </CustomTooltip>
                </div>

                <BootstrapInput value={customUserId} onChange={(e) => setCustomUserId(e.target.value)} />
              </InputWrapper>
            </Grid>
          </Grid>
        )}
      </Grid>

      {surveyId && (
        <Box sx={{ fontSize: "1rem" }}>
          <Box sx={{ marginBottom: "25px" }}>
            <div style={{ paddingBottom: "5px" }}>
              Study Redirect URL: Complete
              <CustomTooltip
                sx={{ fontSize: "24px" }}
                title="Use this URL for respondents who completely finish your study and should be awarded full compensation."
              >
                <InfoIcon sx={iconStyle} />
              </CustomTooltip>
            </div>
            <Box component="span" sx={{ p: 1, border: "1px dashed grey", display: "flex", alignItems: "center" }}>
              <span>{redirectUrls.complete}</span>
              <CopyToClipboardButton copyText={redirectUrls.complete} />
              <RedirectVerification activationRedirect={completeActivation} />
            </Box>
          </Box>

          <Box sx={{ marginBottom: "25px" }}>
            <div style={{ paddingBottom: "5px" }}>
              Study Redirect URL: Does not consent
              <CustomTooltip title="Use this URL for respondents who decline to participate on your consent form.">
                <InfoIcon sx={iconStyle} />
              </CustomTooltip>
            </div>
            <Box component="span" sx={{ p: 1, border: "1px dashed grey", display: "flex", alignItems: "center" }}>
              <span>{redirectUrls.optOut}</span>
              <CopyToClipboardButton copyText={redirectUrls.optOut} />
              <RedirectVerification activationRedirect={optOutActivation} />
            </Box>
          </Box>

          <Box>
            <div style={{ paddingBottom: "5px" }}>
              Study Redirect URL: Attention check failures (optional)
              <CustomTooltip title="Use this URL for respondents who fail one of Verasight's pre-approved attention checks.">
                <InfoIcon sx={iconStyle} />
              </CustomTooltip>
            </div>
            <Box component="span" sx={{ p: 1, border: "1px dashed grey", display: "flex", alignItems: "center" }}>
              <span>{redirectUrls.noAttention}</span>
              <CopyToClipboardButton copyText={redirectUrls.noAttention} />
              <RedirectVerification activationRedirect={noAttentionActivation} />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
