import { AxiosResponse } from "axios";
import apiService from "./apiService";
import endpoints from "./endpoints";
import { InvitationCode } from "../../types/InvitationCode";
import { GetInvitationCodeParamsType } from "../../screens/admin/Researchers/InvitationsList";

type GenerateInvitationsPayload = {
  singleCode?: string;
  numRandomCodes?: number;
  discount?: number;
  expireDate?: Date | null;
};

export default class InvitationsService {
  static getInvitationCodes(
    params: GetInvitationCodeParamsType = {}
  ): Promise<AxiosResponse<{ docs: InvitationCode[]; total: number }>> {
    return apiService.get(endpoints.invitationCodes, {
      params,
      paramsSerializer({ statuses, ...params }) {
        const searchParams = new URLSearchParams(params);
        statuses?.forEach((status: string) => searchParams.append("statuses", status));
        return searchParams.toString();
      },
    });
  }

  static generate(payload: GenerateInvitationsPayload) {
    return apiService.post(endpoints.generateInvitations, payload);
  }

  static getAppliedInviteCode(): Promise<AxiosResponse<InvitationCode | null>> {
    return apiService.get(endpoints.getAppliedInviteCode);
  }

  static validateDiscount(shortCode: string): Promise<AxiosResponse<InvitationCode>> {
    return apiService.post(endpoints.validateDiscount, { shortCode });
  }

  static deleteInvitationCode(id: string) {
    return apiService.delete(endpoints.invitationCode(id));
  }

  static validate(shortCode: string) {
    return apiService.post(endpoints.validateInvitation, { shortCode });
  }
}
