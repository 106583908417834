import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress, Container } from "@mui/material";
import winGif from "src/assets/hit-multiplier-win.gif";
import loseGif from "src/assets/hit-multiplier-lose.gif";
import winImgEnd from "src/assets/hit-multiplier-win-end.png";
import loseImgEnd from "src/assets/hit-multiplier-lose-end.png";
import "./SurveyComplete.scss";
import { BorderLinearProgress } from "../ProgressBanner/ProgressBanner";
import { btnStyle } from "../../screens/auth/styles";
import { formatNumber, getSiteEnvironment } from "../../helpers/generic";
import SettingsService from "../../services/api/settingsService";
import UsersService from "../../services/api/usersService";
import ConfettiAndInfoGrid from "./components/ConfettiAndInfoGrid";

const REQUIRED_SURVEYS = 10;

type SurveyCompleteProps = {
  surveyId: string;
  points: number;
  awardedPoints: number;
  completedSurveys: number;
  spinnerBonus: number;
  completedSurvey?: any;
};

const SurveyComplete = (props: SurveyCompleteProps) => {
  const navigation = useNavigate();
  const [bonusPoints, setBonusPoints] = React.useState(200);
  const [stillAnimating, setStillAnimating] = React.useState(true);
  const calculateProgress = (value: number) => {
    return (value * 100) / REQUIRED_SURVEYS;
  };
  const vsref = parseInt(props.completedSurvey?.externalSurveyRedirectLinkParameters?.vsref);

  React.useEffect(() => {
    // The GIF takes about 6.5 seconds to run. When it's done, reveal the reward.
    const twelveSeconds = 6.5 * 1_000;
    setTimeout(() => setStillAnimating(false), twelveSeconds);
    SettingsService.getSetting("points-per-bonus").then((res) => {
      if (res.data.payload.value) {
        setBonusPoints(res.data.payload.value);
      }
    });
  }, []);

  const notProduction = getSiteEnvironment() !== "production";

  const uncompleteSurvey = () => {
    UsersService.uncompleteSurvey(props.surveyId).then(() => {
      window.location.reload();
    });
  };

  return (
    <Container sx={{ paddingBottom: "100px" }}>
      <Box display="flex" justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          {props.spinnerBonus !== 0 && (
            <Box
              sx={{
                margin: 2,
                maxWidth: 250,
                flexDirection: "column",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              data-testid="spinner-bonus"
            >
              <h2 className="title-survey-completed">Bonus Point Spinner</h2>
              {props.spinnerBonus === 10_000 && (
                <img
                  src={stillAnimating ? winGif : winImgEnd}
                  alt="Spinning wheel showing that user won the spinner bonus"
                  style={{ width: "85%" }}
                />
              )}
              {props.spinnerBonus === 10 && (
                <img
                  src={stillAnimating ? loseGif : loseImgEnd}
                  alt="Spinning wheel showing user did not win the spinner bonus"
                  style={{ width: "85%" }}
                />
              )}
            </Box>
          )}
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height={413}>
            {stillAnimating && props.spinnerBonus !== 0 ? (
              <CircularProgress sx={{ color: "pink" }} />
            ) : (
              <ConfettiAndInfoGrid surveyPoints={props.points} spinnerBonusPoints={props.spinnerBonus} vsref={vsref} />
            )}
          </Box>
          <Box sx={{ width: "100%", marginY: 4 }}>
            {props.awardedPoints > 0 ? (
              <div className="awarding-bonus-text">
                In addition, we're awarding you
                <br />
                <span>{formatNumber(props.awardedPoints)} bonus</span> points because
                <br />
                you've completed 10 surveys!
              </div>
            ) : vsref && vsref > 1 ? (
              <>
                <span className="bonus-text bonus-text__noqualify">
                  Thank you for participating! Although you didn’t qualify for our survey this time, to thank you for
                  your time, we have credited your account with {props.points} points.
                </span>
              </>
            ) : (
              <>
                <span>{props.completedSurveys} / 10</span>
                <BorderLinearProgress variant="determinate" value={calculateProgress(props.completedSurveys)} />
                <span className="bonus-text">
                  Complete {10 - props.completedSurveys} surveys to earn an additional {formatNumber(bonusPoints)} Bonus
                  Points!
                </span>
              </>
            )}
          </Box>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={btnStyle}
            fullWidth
            onClick={() => navigation("/")}
          >
            Back to Home
          </Button>
          {notProduction && (
            <Button type="submit" color="error" variant="contained" fullWidth onClick={uncompleteSurvey}>
              Mark as Incomplete
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default SurveyComplete;
