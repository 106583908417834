import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import BootstrapInput from "../../../../components/BootstrapInput";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import { Fragment, useEffect, useState } from "react";
import { TableData } from "src/types/Survey";
import CopyToClipboardButton from "../../../../components/CopyToClipboardButton";
import ParameterTable, { ParameterChoice } from "../../../../components/ParameterTable/ParameterTable";
import { apiURL } from "../../../../services/api/apiService";

type RedirectLinksTabProps = {
  redirectLinksData: any;
  setRedirectLinksData: (data: any) => void;
  redirectPointsData: any;
  setRedirectPointsData: (data: any) => void;
  surveyId?: string;
  multiPointPrefixDisabled?: boolean;
  setMultiPointPrefixDisabled?: (val: boolean) => void;
};

type Param = {
  name: string;
  type: string;
};

const RedirectLinksTab = ({
  redirectLinksData,
  setRedirectLinksData,
  redirectPointsData,
  setRedirectPointsData,
  surveyId,
  setMultiPointPrefixDisabled,
  multiPointPrefixDisabled,
}: RedirectLinksTabProps) => {
  const [redirectBaseUrl, setRedirectBaseUrl] = useState<string>("");
  const [tableRows, setTableRows] = useState<TableData[]>([]);
  const [rowName, setRowName] = useState<string>("");
  const [rowType, setRowType] = useState<string>("");
  const [rowValue, setRowValue] = useState<string>("");
  const [rowValueInputVisible, setRowValueInputVisible] = useState<boolean>(false);
  const showRowValueInputFor = ["SHA1_HASH"];
  const [redirectLinkDisplay, setRedirectLinkDisplay] = useState<string | undefined>(undefined);
  const [parameterChoices, setParameterChoices] = useState<ParameterChoice[]>([]);
  const params: Array<Param> = [
    { type: "LITERAL_STRING", name: "Literal" },
    { type: "LITERAL_NUMERIC", name: "Numeric" },
    { type: "SHA1_HASH", name: "SHA1 Hash" },
    { type: "USER_ID", name: "User ID" },
    { type: "SURVEY_ID", name: "Survey ID" },
  ];

  const paramTypeToNameMap = params.reduce((acc, param) => {
    acc[param.type] = param.name;
    return acc;
  }, {} as Record<string, string>);

  useEffect(() => {
    setRedirectBaseUrl(redirectLinksData?.baseUrl || `${apiURL}/surveys/redirect`);
    const tableData = redirectLinksData?.parameters?.map((row: any) => {
      return {
        orderId: row.orderId,
        parameterName: row.parameterName,
        parameterType: row.parameterType.toUpperCase(),
        value: row.arguments?.join(", "),
      };
    });
    setTableRows(tableData || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateRedirectLinkDisplay = () => {
    if (redirectBaseUrl && surveyId) {
      let redirectUrl = redirectBaseUrl;
      if (redirectLinksData?.parameters && redirectLinksData.parameters.length > 0) {
        const params = redirectLinksData?.parameters?.map((row: any) => {
          if (row.parameterType.toUpperCase() === "SURVEY_ID") {
            return `${row.parameterName}=__vskey__${surveyId}`;
          } else {
            return `${row.parameterName}={{${row.parameterType}}}`;
          }
        });
        setRedirectLinkDisplay(`${redirectUrl}?${params.join("&")}`);
      } else {
        setRedirectLinkDisplay(`${redirectUrl}`);
      }
    } else {
      setRedirectLinkDisplay(undefined);
    }
  };

  useEffect(() => {
    setRedirectLinksData({
      baseUrl: redirectBaseUrl,
      parameters: tableRows.map((row) => {
        return {
          ...row,
          parameterType: row.parameterType.toUpperCase(),
          arguments: row.value ? [row.value] : [],
        };
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectBaseUrl, tableRows]);

  useEffect(() => {
    updateRedirectLinkDisplay();
    setParameterChoices(
      redirectLinksData?.parameters?.map((param: any) => {
        return {
          value: param.parameterName,
          text: param.parameterName,
        };
      }) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectLinksData]);

  const [genericErrorMessage, setGenericErrorMessage] = useState<string>("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const handleAlertClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;

    setAlertOpen(false);
  };

  const addTableRow = () => {
    const errorMessage = tableRowErrorMessage();
    if (errorMessage) {
      setGenericErrorMessage(errorMessage);
      setAlertOpen(true);
      return;
    }

    const nextId = tableRows.length > 0 ? tableRows[tableRows.length - 1].orderId + 1 : 1;

    setTableRows([
      ...tableRows,
      {
        orderId: nextId,
        parameterName: rowName,
        parameterType: rowType,
        value: rowValue,
      },
    ]);

    clearRowFields();
  };

  const rowErrorMessages = {
    requiredParams: "Please fill in the Parameter Name and Type fields",
    requiredValue: "Please fill in the Value field",
    valueShouldBeNumeric: "Value should be numeric",
  };

  const tableRowErrorMessage = (): string | void => {
    if (rowName === "" || !rowType) return rowErrorMessages.requiredParams;

    if (showRowValueInputFor.includes(rowType)) {
      if (!rowValue) return rowErrorMessages.requiredValue;
    }
  };

  const clearRowFields = () => {
    setRowName("");
    setRowType("");
    setRowValue("");
    setRowValueInputVisible(false);
  };

  const deleteTableRow = (id: number) => {
    setTableRows(
      tableRows
        .filter((row) => row.orderId !== id)
        .map((row, idx) => ({
          ...row,
          orderId: idx,
        }))
    );
  };

  const handleChangeName = (event: any) => {
    setRowName(event.target.value);
  };

  const handleChangeType = (event: any) => {
    setRowType(event.target.value);
    setRowValueInputVisible(showRowValueInputFor.includes(event.target.value));
  };

  const handleChangeValue = (event: any) => {
    setRowValue(event.target.value);
  };

  const alertAction = (
    <Fragment>
      <Button style={{ color: "#2ebdee" }} size="small" onClick={handleAlertClose}>
        Close
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleAlertClose} />
    </Fragment>
  );

  const pointScaleActionComponent =
    typeof setMultiPointPrefixDisabled === "function" ? (
      <FormControlLabel
        control={
          <Switch
            checked={!multiPointPrefixDisabled}
            onChange={(event) => setMultiPointPrefixDisabled(!event.target.checked)}
          />
        }
        label="Enable Prefix"
      />
    ) : null;

  return (
    <>
      <Box
        className="source-tab-container"
        sx={{
          display: "flex",
          marginTop: "20px",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            minWidth: 700,
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <FormControl fullWidth variant="standard">
            <div className="input-label">Redirect Base URL</div>
            <BootstrapInput
              value={redirectBaseUrl}
              onChange={(event) => {
                setRedirectBaseUrl(`${event.target.value}`);
              }}
            />
            {redirectLinkDisplay && surveyId && (
              <Box component="span" sx={{ p: 0, border: "1px dashed grey" }}>
                {redirectLinkDisplay}
                {redirectLinkDisplay && <CopyToClipboardButton copyText={redirectLinkDisplay} />}
              </Box>
            )}
          </FormControl>
        </Box>
        <Box>
          <FormControl variant="standard">
            <div className="source-tab-title">Parameters</div>
          </FormControl>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="source-tab-name-col">Parameter Name</TableCell>
                  <TableCell className="source-tab-type-col">Type</TableCell>
                  <TableCell className="source-tab-value-col" align="right">
                    Value
                  </TableCell>
                  <TableCell align="right">&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRows.map((row) => (
                  <TableRow key={row.orderId} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.parameterName}
                    </TableCell>
                    <TableCell align="right">{paramTypeToNameMap[row.parameterType]}</TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                    <TableCell align="right">
                      <DeleteOutlineIcon
                        className="source-tab-action-icon"
                        onClick={() => deleteTableRow(row.orderId)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <FormControl>
                      <BootstrapInput value={rowName} onChange={handleChangeName} />
                    </FormControl>
                  </TableCell>
                  <TableCell align="right">
                    <FormControl fullWidth>
                      <InputLabel id="row-type-label">Row Type</InputLabel>
                      <Select
                        labelId="row-type-label"
                        id="row-type"
                        value={rowType}
                        label="Row Type"
                        onChange={handleChangeType}
                      >
                        {params.map((param) => (
                          <MenuItem key={param.type} value={param.type}>
                            {param.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell align="right">
                    {rowValueInputVisible ? (
                      <FormControl>
                        <BootstrapInput value={rowValue} onChange={handleChangeValue} />
                      </FormControl>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <AddIcon className="source-tab-action-icon" onClick={() => addTableRow()} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <ParameterTable
        actionComponent={pointScaleActionComponent}
        tableTitle="Point Scales"
        typeChoices={parameterChoices}
        setTableData={setRedirectPointsData}
        tableData={redirectPointsData}
        headers={["Point Value", "When Parameter", "Equals"]}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertOpen}
        autoHideDuration={3000}
        onClose={handleAlertClose}
        message={genericErrorMessage}
        action={alertAction}
      />
    </>
  );
};

export default RedirectLinksTab;
