import React, { useState, useEffect } from "react";
import {
  Box,
  Chip,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Paper,
} from "@mui/material";
import { UTM_TARGETING_TAG_PREFIX } from "../../../../types/TargetingTag";
import TargetingTagsService from "../../../../services/api/targetingTagsService";
import { UtmApplyingLogic } from "../EditSurvey";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useAtom } from "jotai";
import { allAvailableUtmTagsAtom } from "src/atoms/utm-tags";

interface UtmCampaignSelectionProps {
  utmCampaignTargetingTags: string[];
  setUtmCampaignTargetingTags: React.Dispatch<React.SetStateAction<string[]>>;
  utmCampaignTargetingTagExcludes: string[];
  setUtmCampaignTargetingTagExcludes: React.Dispatch<React.SetStateAction<string[]>>;
  utmApplyingLogic: UtmApplyingLogic;
  setUtmApplyingLogic: (logic: UtmApplyingLogic) => void;
  utmTargetUsersAfterSignupDate: string;
  setUtmTargetUsersAfterSignupDate: (input: string) => void;
}

const UtmCampaignSelection: React.FC<UtmCampaignSelectionProps> = ({
  utmCampaignTargetingTags,
  setUtmCampaignTargetingTags,
  utmCampaignTargetingTagExcludes,
  setUtmCampaignTargetingTagExcludes,
  utmApplyingLogic,
  setUtmApplyingLogic,
  utmTargetUsersAfterSignupDate,
  setUtmTargetUsersAfterSignupDate,
}) => {
  const [utmCampaignTags, setUtmCampaignTags] = useAtom(allAvailableUtmTagsAtom);
  const [createUtmDialogOpen, setCreateUtmDialogOpen] = useState(false);
  const [newUtmTag, setNewUtmTag] = useState("");
  const [inputValue, setInputValue] = useState("");

  const handleCreateUtm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await TargetingTagsService.createUtmTag(newUtmTag);
      const tag = response.data;
      setUtmCampaignTags([...utmCampaignTags, tag]);
      setUtmCampaignTargetingTags([...utmCampaignTargetingTags, tag._id.toString()]);
      setCreateUtmDialogOpen(false);
      setNewUtmTag("");
    } catch (error) {
      console.error("Error creating UTM tag:", error);
    }
  };

  const getTagLabel = (tagId: string) => {
    if (tagId === "all") return "All";
    if (tagId === "create-utm") return `Create ${inputValue ? `"${inputValue}"` : "UTM"}`;
    const tag = utmCampaignTags.find((t) => t._id.toString() === tagId);
    return tag ? tag.tag.split(UTM_TARGETING_TAG_PREFIX)[1] : "";
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3, minWidth: 300 }}>
      <Paper elevation={1} sx={{ p: 2 }}>
        <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
          Include UTM Campaigns
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Autocomplete
            multiple
            id="include-utm-select"
            options={["all", "create-utm", ...utmCampaignTags.map((tag) => tag._id.toString())]}
            value={utmCampaignTargetingTags}
            onChange={(event, newValue: string[]) => {
              if (newValue.includes("create-utm")) {
                setCreateUtmDialogOpen(true);
                setNewUtmTag(inputValue);
                newValue = newValue.filter((v) => v !== "create-utm");
              }
              if (newValue[newValue.length - 1] == "all") {
                setUtmCampaignTargetingTags(["all"]);
              } else {
                setUtmCampaignTargetingTags(newValue.filter((v) => v !== "all"));
              }
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => <TextField {...params} placeholder="Search campaigns" />}
            renderTags={(value: string[], getTagProps) =>
              value.map((option, index) => <Chip label={getTagLabel(option)} {...getTagProps({ index })} />)
            }
            renderOption={(props, option) => <li {...props} style={{ wordBreak: 'break-all' }}>{getTagLabel(option)}</li>}
            isOptionEqualToValue={(option, value) => option === value}
            filterOptions={(options, params) => {
              const filtered = options.filter((option) =>
                getTagLabel(option).toLowerCase().includes(params.inputValue.toLowerCase())
              );
              if (params.inputValue !== "" && !filtered.includes("all") && !filtered.includes("create-utm")) {
                filtered.push("create-utm");
              }
              return filtered;
            }}
            getOptionLabel={getTagLabel}
          />

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="body2" color="text.secondary" sx={{ minWidth: "fit-content" }}>
              After signup date:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={utmTargetUsersAfterSignupDate ? new Date(utmTargetUsersAfterSignupDate) : null}
                onChange={(newValue: any) => {
                  setUtmTargetUsersAfterSignupDate(newValue);
                }}
                disabled={!utmCampaignTargetingTags.length}
                slotProps={{
                  textField: {
                    size: "small",
                    helperText: !utmCampaignTargetingTags.length ? "Select UTM campaigns first" : undefined,
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </Paper>

      <Paper elevation={1} sx={{ p: 2 }}>
        <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
          Exclude UTM Campaigns
        </Typography>
        <Autocomplete
          multiple
          id="exclude-utm-select"
          options={utmCampaignTags.map((tag) => tag._id.toString())}
          value={utmCampaignTargetingTagExcludes}
          onChange={(event, newValue) => setUtmCampaignTargetingTagExcludes(newValue)}
          renderInput={(params) => <TextField {...params} placeholder="Search campaigns" />}
          renderTags={(value: string[], getTagProps) =>
            value.map((option, index) => <Chip label={getTagLabel(option)} {...getTagProps({ index })} />)
          }
          renderOption={(props, option) => <li {...props} style={{ wordBreak: 'break-all' }}>{getTagLabel(option)}</li>}
          isOptionEqualToValue={(option, value) => option === value}
        />
      </Paper>

      <Paper elevation={1} sx={{ p: 2 }}>
        <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
          UTM Targeting Logic
        </Typography>
        <FormControl fullWidth size="small">
          <Select
            id="utm-tag-logic"
            value={utmApplyingLogic}
            onChange={(event) => setUtmApplyingLogic(event.target.value as UtmApplyingLogic)}
          >
            <MenuItem value={UtmApplyingLogic.AND}>AND</MenuItem>
            <MenuItem value={UtmApplyingLogic.OR}>OR</MenuItem>
          </Select>
        </FormControl>
      </Paper>

      <Dialog open={createUtmDialogOpen} onClose={() => setCreateUtmDialogOpen(false)}>
        <form onSubmit={handleCreateUtm}>
          <DialogTitle>Create UTM Targeting Tag</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              required
              margin="dense"
              id="utm-campaign-tag-name"
              name="utmTag"
              label="UTM Campaign Tag Name"
              type="text"
              fullWidth
              variant="standard"
              value={newUtmTag}
              onChange={(e) => setNewUtmTag(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCreateUtmDialogOpen(false)}>Cancel</Button>
            <Button type="submit">Create</Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default UtmCampaignSelection;
