import pointsIcon from "../../../assets/icon-points.svg";

import { formatNumber } from "../../../helpers/generic";

import "./ConfettiAndInfoGrid.scss";


type PointsProps = {
  surveyPoints: number
  spinnerBonusPoints: number
  vsref?: number | null
};

const ConfettiAndInfoGrid = (props: PointsProps) => {

  return (
  <div className="container">
    <div className="confetti-1"></div>
    <div className="confetti-2"></div>
    <div className="confetti-3"></div>
    <h2 className="title-survey-completed">{props.vsref && props.vsref > 1 ? '' : 'Survey Completed'}</h2>
    <div className="confetti-4"></div>
    <div className="confetti-5"></div>
    <div className="confetti-6"></div>
    <div className="confetti-7"></div>
    <div className="confetti-8"></div>
    <div className="confetti-9"></div>
    <div className="confetti-10"></div>
    <div className="confetti-11"></div>
    <img className="points-icon" src={pointsIcon} alt="Points Icon" />
      {props.spinnerBonusPoints > 0 ? (
        <>
          <span className="spinner-points-small">Bonus Points - {formatNumber(props.spinnerBonusPoints)}</span>
          <span className="survey-points-small">Survey Points - {formatNumber(props.surveyPoints)}</span>
          <span className="points-total-sum-large">Total - {formatNumber(props.surveyPoints + props.spinnerBonusPoints)}</span>
        </>
      ) : (
        <>
          <span className="points-amount-single">{formatNumber(props.surveyPoints)}</span>
          <span className="points-text-single">Points</span>
        </>
      )}
    <div className="confetti-12"></div>
    <div className="confetti-13"></div>
    <div className="confetti-14"></div>
    <div className="confetti-15"></div>
    <div className="confetti-16"></div>
    <div className="confetti-17"></div>
    <div className="confetti-18"></div>
    <div className="confetti-19"></div>
    <div className="confetti-20"></div>
    <div className="confetti-21"></div>
  </div>
  );
};

export default ConfettiAndInfoGrid;
