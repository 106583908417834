import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import VeraButton from "../../../../components/VeraButton";
import { convertDatetimeStringToReadable } from "../../../../helpers/date";
import { PaginateOptions, manualPagination } from "../../../../helpers/manualPagination";
import TableHeadSortable from "../../../../components/common/TableHeadSortable";
import FilterWrapper from "../../../../components/common/FilterWrapper";
import SearchWrapper from "../../../../components/common/SearchWrapper";

const tableHeadCells = [
  {
    id: "_id",
    label: "ID",
  },
  {
    id: "firstName",
    label: "User",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "points",
    label: "Points",
  },
  {
    id: "completionDate",
    label: "Survey completion date",
  },
  {
    id: "status",
    label: "Status",
  },
];

const filterCompleted = (docs: any[], status: string, searchValue: string) => {
  if (status === "all" && searchValue === "") {
    return docs;
  }
  const results = docs.filter((doc) => {
    const search = searchValue.toLowerCase();
    const isFinded =
      doc.firstName.toLowerCase().includes(search) ||
      doc.lastName.toLowerCase().includes(search) ||
      doc.email.toLowerCase().includes(search);
    return isFinded && (doc.status === status || status === "all");
  });
  return results;
};

const TargetUsersList = (props: any) => {
  const [targetUsers, setTargetUsers] = React.useState<any[]>([]);
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState("firstName");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);

  const [openFilters, setOpenFilters] = React.useState(false);
  const [completeStatus, setCompleteStatus] = React.useState("all");
  const [statusFilter, setStatusFilter] = React.useState<boolean | undefined>(undefined);

  const [searchValue, setSearchValue] = React.useState("");
  const [searchIsActive, setSearchIsActive] = React.useState(false);

  useEffect(() => {
    if (openFilters) return;
    const options: PaginateOptions = {
      page: page + 1,
      limit: rowsPerPage,
      sort: orderBy,
      sortDirection: order,
    };
    const filteredDocs = filterCompleted(props.targetUsers, completeStatus, searchValue);
    const paginatedUsers = manualPagination(filteredDocs, options);
    setTotalCount(paginatedUsers.total);
    setPage(paginatedUsers.page - 1);
    setTargetUsers(paginatedUsers.docs);
    searchIsActive && setSearchIsActive(false);
  }, [page, order, orderBy, rowsPerPage, openFilters, searchIsActive, completeStatus, searchValue, props.targetUsers]);

  const handleRequestSort = (_event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (_event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const value = parseInt(event.target.value, 10);
    setRowsPerPage(value);
    setPage(0);
  };

  const changeStatusFilter = (value: any) => {
    if (value === statusFilter) {
      setStatusFilter(undefined);
      setCompleteStatus("all");
    } else {
      setStatusFilter(value);
      setCompleteStatus(value ? "completed" : "pending");
    }
  };

  const applyFilters = () => {
    setPage(0);
    setOpenFilters(false);
  };

  const clearFilters = () => {
    setStatusFilter(undefined);
    setCompleteStatus("all");
    setPage(0);
    setOpenFilters(false);
  };

  const onSearchClick = () => {
    setSearchIsActive(true);
  };

  const onClearSearch = () => {
    setSearchValue("");
    setSearchIsActive(false);
  };

  return (
    <div className="content-wrapper">
      <div className="header">
        <div className="text-wrapper">
          <p className="title">Targeting users</p>
          <p className="subtitle">Showing {targetUsers.length} users</p>
        </div>
      </div>

      <div className="filters-container">
        <FilterWrapper
          openFilters={openFilters}
          clearFilters={clearFilters}
          applyFilters={applyFilters}
          setOpenFilters={setOpenFilters}
        >
          <p className="option-title">Status</p>
          <ListItem key={"active"} disablePadding sx={{ height: "35px" }}>
            <ListItemButton onClick={() => changeStatusFilter(true)} dense sx={{ height: "100%" }}>
              <ListItemIcon sx={{ minWidth: "auto" }}>
                <Checkbox edge="start" checked={statusFilter === true} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText primary={`Completed`} />
            </ListItemButton>
          </ListItem>
          <ListItem key={"inactive"} disablePadding sx={{ height: "35px" }}>
            <ListItemButton onClick={() => changeStatusFilter(false)} dense sx={{ height: "100%" }}>
              <ListItemIcon sx={{ minWidth: "auto" }}>
                <Checkbox edge="start" checked={statusFilter === false} tabIndex={-1} disableRipple />
              </ListItemIcon>
              <ListItemText primary={`Pending`} />
            </ListItemButton>
          </ListItem>
        </FilterWrapper>

        <SearchWrapper
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onSearchClick={onSearchClick}
          onClearSearch={onClearSearch}
          searchIsActive={searchIsActive}
        />

        <VeraButton
          variant="outlined"
          onClick={() => props.setShowTargetingResults(false)}
          sx={{
            width: "fit-content",
            fontWeight: "bold",
            margin: "0 0 0 10px",
          }}
        >
          Close
        </VeraButton>
      </div>

      <Table>
        <TableHeadSortable
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={tableHeadCells}
        />

        <TableBody style={{ borderRadius: "16px" }}>
          {targetUsers.map((user: any) => (
            <TableRow key={user._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell>{user._id}</TableCell>
              <TableCell component="th" scope="row">
                <b>
                  {user.firstName} {user.lastName}
                </b>
              </TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.points}</TableCell>
              <TableCell>{user.completionDate ? convertDatetimeStringToReadable(user.completionDate) : "--"}</TableCell>
              <TableCell>{user.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default TargetUsersList;
