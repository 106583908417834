import React, { useContext, useState } from "react";

import { Grid, Paper, TextField, Button, Typography } from "@material-ui/core";
import { AuthContext } from "../../contexts/authContext";

import { useValidEmail } from "../../hooks/useValidEmail";
import { useNavigate, Link } from "react-router-dom";
import {
  backgroundStyle,
  backgroundStyleCenter,
  btnStyle,
  btnStyleDisabled,
  h1Style,
  inputStyle,
  logoStyle,
  forgotPaswordTitleContainerStyle,
  forgotPaswordSubtitleContainerStyle,
  forgotPaswordSubtitleStyle,
  paperStyle,
  TypographyStyle,
} from "./styles";
import verasightLogo from "../../assets/logo.svg";
import { Snackbar } from "@mui/material";
import SnackBarClose from "../../components/common/SnackbarClose";

const ForgotPasswordEmail: React.FC = () => {
  /* States and context declaration */
  const { email, setEmail, emailError } = useValidEmail("");
  const authContext = useContext(AuthContext);
  const navigation = useNavigate();
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const [open, setOpen] = React.useState(false);

  const onRecoverPressed = async (e: any) => {
    e.stopPropagation();
    if (email) {
      try {
        await authContext.sendCodeRecoverPassword(email);
        if (authContext.setSignUpUserData) {
          authContext.setSignUpUserData((prevData) => ({ ...prevData, email }));
        } else {
          throw new Error("Unable to set the user's email");
        }
        navigation(`/recovery/select-password`, {
          state: {
            text: "You should receive an email with an OTP code to change your password in about 5-10 minutes. Make sure you check your spam folder!",
          },
        });
      } catch (error: any) {
        let message = error.message;
        if (error.code === "UserNotFoundException") {
          message =
            "We couldn't find an account associated with that email address. Please double-check the email address and try again.";
        }
        setGenericErrorMessage(message);

        setOpen(true);
      }
    }
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const validationErrors = emailError !== null || email === "";

  return (
    <div
      style={{
        ...backgroundStyle,
        ...backgroundStyleCenter,
      }}
    >
      <Paper elevation={1} style={{ ...paperStyle, maxWidth: "600px" }}>
        <div style={{ margin: "0px auto", width: "100%" }}>
          <Grid style={logoStyle}>
            <img src={verasightLogo} alt="Verasight Logo" />
          </Grid>
          <Grid style={forgotPaswordTitleContainerStyle}>
            <h1 style={h1Style}>Forgot Password</h1>
          </Grid>
          <Grid style={forgotPaswordSubtitleContainerStyle}>
            <p style={forgotPaswordSubtitleStyle}>Enter your email to receive an email to recover your password</p>
          </Grid>
          <TextField
            size="small"
            variant="outlined"
            placeholder="Email"
            fullWidth
            required
            error={emailError != null || email === ""}
            style={inputStyle}
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{ ...(validationErrors ? btnStyleDisabled : btnStyle), marginTop: "60px" }}
            disabled={validationErrors}
            fullWidth
            onClick={onRecoverPressed}
          >
            Submit
          </Button>
          <Typography style={TypographyStyle}>
            {" "}
            Have an account? <Link to="/login"> Login</Link>
          </Typography>
        </div>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={genericErrorMessage}
        action={<SnackBarClose handleClose={handleClose} />}
      />
    </div>
  );
};

export default ForgotPasswordEmail;
