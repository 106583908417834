import apiService from "./apiService";
import endpoints from "./endpoints";

export type SettingType = {
  _id: string;
  key: string;
  payload: {
    value: any;
  };
};

export default class SettingsService {
  static getSettings() {
    return apiService.get(endpoints.settings);
  }

  static getSetting(key: string) {
    return apiService.get(endpoints.setting(key));
  }

  static deleteSetting(key: string) {
    return apiService.delete(endpoints.setting(key));
  }

  static postSetting(data: object) {
    return apiService.post(endpoints.settings, data);
  }

  static setSettings(data: object) {
    return apiService.post(endpoints.setSettings, data);
  }
}
