import React from "react";
import { Alert, Box, Button, Paper, Stack, TextField, Snackbar } from "@mui/material";
import UserService from "../../../services/api/usersService";
import "./SettingsTab.scss";
import { AuthContext } from "../../../contexts/authContext";
import SnackBarClose from "../../../components/common/SnackbarClose";

const ApiKeysTab = () => {
  const [userData, setUserData] = React.useState<any>(null);
  const { user, refreshUser } = React.useContext(AuthContext);
  const [needToRefresh, setNeedToRefresh] = React.useState<boolean>(false);
  const [qualtricsApiToken, setQualtricsApiToken] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const [genericErrorMessage, setGenericErrorMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const saveChanges = () => {
    setLoading(true);

    UserService.postQualtricsApiToken(userData._id, { token: qualtricsApiToken })
      .then((res) => {
        setLoading(false);
        setAlertMessage("Information successfully updated!");
      })
      .catch((error) => {
        setGenericErrorMessage(error.response.data.message);
        setOpen(true);
      });
  };

  React.useEffect(() => {
    if (!user) {
      setNeedToRefresh(true);
      setLoading(true);
    } else {
      setUserData(user);
      setLoading(false);
    }
  }, [user]);

  React.useEffect(() => {
    if (refreshUser == null || !needToRefresh) {
      return;
    }
    refreshUser(false);
    setLoading(false);
    setNeedToRefresh(false);
  }, [refreshUser, needToRefresh]);

  React.useEffect(() => {
    setLoading(true);
    if (!userData) return;
    getQualtricsApiToken();
    setLoading(false);
  }, [userData]);

  const getQualtricsApiToken = async () => {
    const res = await UserService.getQualtricsApiToken(userData._id);
    if (res.data) setQualtricsApiToken(res.data.token);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") return;
    setOpen(false);
    setLoading(false);
  };

  return (
    <>
      <Paper
        className="account-details-container"
        sx={{
          boxShadow: "none",
        }}
      >
        {alertMessage ? (
          <Stack sx={{ width: "100%", margin: "-20px 0 40px" }}>
            <Alert variant="filled" severity="success" onClose={() => setAlertMessage("")}>
              {alertMessage}
            </Alert>
          </Stack>
        ) : null}

        <h2>Qualtrics</h2>

        <Box className="form-wrapper justify-left">
          <Box className="column">
            <TextField
              label="API Token"
              variant="outlined"
              size="small"
              value={qualtricsApiToken}
              inputProps={{ maxLength: 50 }}
              onChange={(event) => setQualtricsApiToken(event.target.value)}
            />
          </Box>
        </Box>

        <Button
          variant="contained"
          onClick={() => saveChanges()}
          disabled={loading}
          sx={{
            textTransform: "capitalize",
            backgroundColor: "#2EBDEE",
            border: "1px solid #2EBDEE",
            borderRadius: "4px",
            padding: "10px 70px",
            margin: "20px 0",
            "&:hover": {
              background: "#2EBDEE",
              opacity: "0.7",
            },
            "&:disabled": {
              border: "1px solid #91a3a9",
            },
          }}
        >
          Save
        </Button>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={genericErrorMessage}
        action={<SnackBarClose handleClose={handleClose} />}
      />
    </>
  );
};

export default ApiKeysTab;
