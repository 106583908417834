import { useState } from "react";

export default function usePagination(default_rows_per_page: number) {
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState(default_rows_per_page);

  const handleChangePage = (_event: any, newPage: any) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const value = parseInt(event.target.value, 10);
    setRowsPerPage(value);
    setPage(1);
  };

  return { page, setPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage };
}
