export enum settingKeys {
  POINT_CONVERSION = "point-conversion",
  POINTS_PER_BONUS = "points-per-bonus",
  SURVEYS_PER_BONUS = "min-survey-for-bonus",
  HIT_MULTIPLIER = "hit-multiplier",
  HIT_MULTIPLIER_PROBABILITY = "hit-multiplier-probability",
  REFERRAL_X_POINTS = "referral-x-points",
  REFERRAL_Y_POINTS = "referral-y-points",
  RF_WIDGET_URL = "rf-widget-url",
  REFERRAL_SELECTED_TAGS = "referral-selected-tags",
  WEEKLY_CAHSOUT_THRESHOLD = "weekly-cashout-threshold",
  EMAILS_ENABLED = "emails-enabled",
  SCHEDULED_EMAILS = "scheduled-emails",
}
