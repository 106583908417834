import Dexie from "dexie";

interface IData {
  id: string;
  value: string;
}

class AppIndexedDb extends Dexie {
  data!: Dexie.Table<IData, string>;
  constructor() {
    super("AppIndexedDb");
    this.version(1).stores({
      data: "id,value",
    });
  }
}

const db = new AppIndexedDb();

export const setItemIDB = async (key: string, value: string) => {
  try {
    const existData = await db.data.get(key);
    if (existData) {
      await db.data.update(key, { value });
      return;
    }
    await db.data.add({ id: key,value });
    return;
  } catch (error) {
    console.log("setItem error : ", error);
  }
};
export const getItemIDB = async (key: string): Promise<string> => {
  try {
    const item = await db.data.get(key);
    return item?.value || "";
  } catch (error) {
    console.log("getItem error : ", error);
    return "";
  }
};
export const removeItemIDB = async (key: string) => {
  try {
    await db.data.delete(key);
  } catch (error) {
    console.log("deleteItem error : ", error);
  }
};
export const clearIDB = async () => {
  try {
    await db.data.clear();
    await db.delete();
  } catch (error) {
    console.log("clear Db error : ", error);
  }
};
