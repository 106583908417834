import { AxiosResponse } from "axios";

export const getErrorMessage = (err: Error | AxiosResponse | { response: AxiosResponse }, defaultMessage?: string) => {
  if ("response" in err) {
    //handle axios error
    if (err.response.data?.message) {
      return Array.isArray(err.response.data?.message) ? err.response.data?.message[0] : err.response.data?.message;
    }
  }
  if ("data" in err) {
    //handle axios error
    if (err.data?.message) {
      return err.data?.message;
    }
  }
  if ("message" in err) {
    //handle normal error
    return err.message;
  }
  return defaultMessage;
};
