import React from "react";
import "./RawInformationBlock.scss";
import { Box, FormControl, Typography } from "@mui/material";
import BootstrapInput from "../../../../components/BootstrapInput";
import { convertDatetimeStringToReadable } from "../../../../helpers/date";
import { SurveyType } from "../../../../types/Survey";

type PropsType = {
  surveyData: SurveyType;
};

const RawInformationBlock = (props: PropsType) => {
  const pretifiedEndDate = React.useMemo(() => {
    const endDate = props.surveyData.end ? props.surveyData.end.toLocaleString() : "";
    return convertDatetimeStringToReadable(endDate);
  }, [props.surveyData.end]);

  const pretifiedCreationDate = React.useMemo(() => {
    const creationDate = props.surveyData.payload.created_at || props.surveyData.payload.creationDate;
    return convertDatetimeStringToReadable(creationDate || "");
  }, [props.surveyData.payload.created_at, props.surveyData.payload.creationDate]);

  return (
    <>
      <Typography variant="body1" fontWeight="bold">
        Raw Information
      </Typography>
      <Box className="data-row">
        <FormControl className="data_unit" variant="standard">
          <div className="input-label">Survey Name</div>
          <BootstrapInput disabled defaultValue={props.surveyData.payload.title ?? props.surveyData.payload.name} />
        </FormControl>
        <FormControl className="data_unit" variant="standard">
          <div className="input-label">Date of ending</div>
          <BootstrapInput disabled defaultValue={pretifiedEndDate} />
        </FormControl>
      </Box>

      <Box className="data-row">
        <FormControl className="data_unit" variant="standard">
          <div className="input-label">ID</div>
          <BootstrapInput disabled defaultValue={props.surveyData.externalId} />
        </FormControl>
        <FormControl className="data_unit" variant="standard">
          <div className="input-label">Internal Parameters Configured</div>
          <BootstrapInput disabled defaultValue={props.surveyData.internalParamsConfigured ? "Yes" : "No"} />
        </FormControl>
      </Box>

      <Box className="data-row">
        <FormControl className="data_unit" variant="standard">
          <div className="input-label">Date of Creation</div>
          <BootstrapInput disabled defaultValue={pretifiedCreationDate} />
        </FormControl>
      </Box>
      <Box className="data-row">
        <FormControl className="data_unit data_unit" variant="standard">
          <div className="input-label">Source Platform</div>
          <BootstrapInput className="source-container" disabled defaultValue={props.surveyData.source} />
        </FormControl>
      </Box>
    </>
  );
};

export default RawInformationBlock;
