import { UserRoles } from "src/types/User";

const appHostname = window.location.hostname;

export const getBaseUrl = () => {
  if (appHostname === "localhost") {
    return "http://localhost:3000";
  }
  return `https://${appHostname}`;
};

export const getPathBase = (role?: UserRoles) => {
  return role === UserRoles.SUPER_ADMIN ? "admin" : "researcher";
};
