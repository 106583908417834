import "./SurveyFeed.scss";
import FilterOptions, { FilterParams } from "src/screens/public/HomeScreen/components/FilterOptions";
import OverlayLoader from "src/components/Loader/OverLayLoader";
import SurveyCard from "src/components/SurveyCard/SurveyCard";
import VeraButton from "src/components/VeraButton";
import { useCallback, useEffect, useState } from "react";
import { SurveyFeedType } from "src/types/Survey";
import SurveysService from "src/services/api/surveysService";
import { UserRoles } from "src/types/User";
import { getRedirectLinkPoints } from "src/helpers/surveyHelper";

type Props = {
  userId?: string;
  isOverLay?: boolean;
  onLikeClick?: (surveyId: string, like: boolean, callback: () => void) => void;
  onCardClick?: (surveyId: string, points: number, category: string, createdByRole: UserRoles) => void;
};

const SurveyFeed = ({ userId, isOverLay, onCardClick, onLikeClick }: Props) => {
  const onCardClickFn = onCardClick ?? (() => {});
  const onLikeClickFn = onLikeClick ?? (() => {});

  const [surveys, setSurveys] = useState<SurveyFeedType[]>([]);
  const [page, setPage] = useState<number>(1);
  const [surveyLoading, setSurveyLoading] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [sort, setSort] = useState<string | null>(null);
  const [categories, setCategories] = useState<string[]>([]);
  const [showCompleteSurveys, setShowCompleteSurveys] = useState<boolean>(false);

  const changeSort = (value: string | null) => {
    setSort(value);
  };

  const changeCategories = (value: string[]) => {
    setCategories(value);
  };

  const loadSurveys = useCallback(async (page: number = 1, sort: string | null = null, categories: string[] = []) => {
    const params: Record<string, any> = {
      page: 1,
      limit: page * 10,
      userId,
    };

    if (sort) {
      params["sort"] = sort;
    }

    if (categories.length) {
      params["categories"] = categories;
    }

    const surveysFeed = await SurveysService.getSurveysFeed(params);
    setSurveyLoading(false);
    setSurveys(surveysFeed.data.docs);
    setShowCompleteSurveys(false);

    setShowLoadMore(surveysFeed.data.page < surveysFeed.data.pages);
  }, [userId]);

  const loadCompleteSurveys = useCallback(async () => {
    setSurveyLoading(true);

    const completeSurveys = await SurveysService.getCompleteSurveys();
    setSurveys(completeSurveys.data);
    setShowCompleteSurveys(true);

    setSurveyLoading(false);
    setShowLoadMore(false);
    
  }, []);

  const onLoadMoreClick = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const filter = (params: FilterParams) => {
    if (params.status === "incomplete") {
      loadSurveys();
    } else if (params.status === "complete") {
      loadCompleteSurveys();
    }
  };

  useEffect(() => {
    setSurveyLoading(true);
    loadSurveys(page, sort, categories);
  }, [page, sort, categories, loadSurveys]);

  return (
    <>
      <FilterOptions onChangeFilter={changeCategories} onChangeSort={changeSort} onFilter={filter} />

      <div className="survey-list">
        {isOverLay ? <OverlayLoader /> : null}
        {surveys.length === 0 && surveyLoading && <div className="loading-text">Loading, please wait</div>}
        {surveys.length === 0 && !surveyLoading && (
          <SurveyCard
            key={"empty"}
            name={"Stay Tuned! We will notify you when the next survey is available"}
            image={""}
            reward={0}
            categories={[]}
            completed={false}
            liked={false}
            isEditMode={false}
          />
        )}
        {surveys.map((survey) => (
          <SurveyCard
            key={survey._id}
            surveyId={survey._id}
            cardHref={survey.externalUrl}
            onCardClick={() =>
              onCardClickFn(
                survey._id,
                survey.points ? survey.points : 0,
                survey.category?.toString(),
                survey.createdByRole || UserRoles.SUPER_ADMIN
              )
            }
            name={survey.title}
            image={survey.image ? survey.image : ""}
            reward={survey.points ? survey.points : 0}
            upToReward={
              !showCompleteSurveys && survey.externalSurveyRedirectPointsData
                ? Math.max(...getRedirectLinkPoints(survey.externalSurveyRedirectPointsData))
                : undefined
            }
            categories={survey.tags.length ? survey.tags : []}
            completed={survey.completed ? survey.completed : false}
            liked={survey.isLiked}
            onLikeClick={onLikeClickFn}
            isEditMode={false}
            isPinned={survey.isPinned}
          />
        ))}
      </div>

      <div className="load-more-button-wrapper">
        {showLoadMore && !surveyLoading ? (
          <VeraButton data-testid="btn-load-more" variant="outlined" onClick={() => onLoadMoreClick()}>
            Load more
          </VeraButton>
        ) : null}
      </div>
    </>
  );
};

export default SurveyFeed;
