import React, { useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { AuthContext } from "../../contexts/authContext";
import { Alert } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { Button } from "@mui/material";
import {
  backgroundStyle,
  btnStyle,
  h1Style,
  inputStyle,
  paperStyle,
  plabelStyle,
  containerStyle,
  rowStyle,
  btnStyleDisabled,
  passwordSubtitleStyle,
  phoneButtonStyle,
  phoneErrorStyle,
} from "./styles";
import { PasswordType } from "../../interfaces/IPassType";
import { OutlinedInput, Snackbar } from "@mui/material";
import SideCarousel from "../../components/SignUpSideCarousel/SignUpSideCarousel";
import { useValidEmail } from "../../hooks/useValidEmail";
import { useValidPhone } from "../../hooks/useValidPhone";
import UsersService from "../../services/api/usersService";
import SnackBarClose from "../../components/common/SnackbarClose";
import { CashoutVendorEnum } from "../../types/generic";

type CheckPasswordProps = {
  type: PasswordType;
};

const UpdateAdditionalAttributes: React.FC<any> = ({ type }: CheckPasswordProps) => {
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const { refreshUser, redeemUserData, setRedeemUserData } = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const { email, setEmail, emailError } = useValidEmail("");
  const { phone, setPhone, phoneError, parsedPhone } = useValidPhone("");
  const [allFieldsValid, setAllFieldsValid] = useState(false);

  const navigation = useNavigate();

  React.useEffect(() => {
    if (parsedPhone) {
      setAllFieldsValid(phoneError == null);
    }
    if (email) {
      setAllFieldsValid(emailError == null);
    }
  }, [parsedPhone, email]);

  const onSaveAndRedeemPressed = async (e: any) => {
    e.stopPropagation();
    let params = new FormData();
    try {
      if (redeemUserData?.vendor === CashoutVendorEnum.VENMO) {
        params.append("venmoPhone", parsedPhone);
      }

      if (redeemUserData?.vendor === CashoutVendorEnum.PAYPAL) {
        params.append("payPalEmail", email as string);
      }

      if (redeemUserData?.vendor === CashoutVendorEnum.GIFT_CARD) {
        params.append("giftCardEmail", email as string);
      }

      await UsersService.updateUserData(params);
      refreshUser && refreshUser(false);
    } catch (error: any) {
      setGenericErrorMessage(error.response.data.message);
    }
    if (setRedeemUserData) {
      setRedeemUserData({
        ...redeemUserData,
        isNotRedeemed: true,
      });
    }
    const url = `/profile/rewards`;
    navigation(url);
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div style={backgroundStyle}>
      <div style={containerStyle}>
        <Paper elevation={1} style={paperStyle}>
          {redeemUserData?.vendor === CashoutVendorEnum.PAYPAL && (
            <>
              <h1 style={h1Style}>Enter email for PayPal</h1>
              <p style={passwordSubtitleStyle}>PayPal Email</p>
              <OutlinedInput
                size="small"
                placeholder="PayPal Email"
                value={email}
                name="payPalEmail"
                type="text"
                style={{ ...inputStyle, maxWidth: "400px" }}
                onChange={(e: any) => setEmail(e.target.value)}
                error={emailError != null}
                data-testid="email-input"
              />
            </>
          )}

          {redeemUserData?.vendor === CashoutVendorEnum.VENMO && (
            <>
              <h1 style={h1Style}>Enter phone for Venmo</h1>
              <p style={plabelStyle}>Venmo Phone</p>
              <PhoneInput
                country={"us"}
                onlyCountries={["us"]}
                value={phone}
                inputStyle={{ ...inputStyle, maxWidth: "400px" }}
                buttonStyle={phoneButtonStyle}
                onChange={(phone) => setPhone(phone)}
                placeholder="Mobile Number"
                countryCodeEditable={false}
              />
              {phoneError && <p style={phoneErrorStyle}>You must enter a valid US phone number</p>}
            </>
          )}

          {redeemUserData?.vendor === CashoutVendorEnum.GIFT_CARD && (
            <>
              <h1 style={h1Style}>Enter email for Gift Card</h1>
              <p style={passwordSubtitleStyle}>Email</p>
              <OutlinedInput
                size="small"
                placeholder="Email"
                value={email}
                name="giftCardEmail"
                type="text"
                style={{ ...inputStyle, maxWidth: "400px" }}
                onChange={(e: any) => setEmail(e.target.value)}
                error={emailError != null}
                data-testid="email-input"
              />
            </>
          )}

          <div style={{ ...rowStyle, marginTop: "60px", maxWidth: "400px" }}>
            <div style={{ flex: "1 1 30%" }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={{
                  ...btnStyle,
                  backgroundColor: "white",
                  color: "#5F6F74",
                }}
                fullWidth
                onClick={(e) => {
                  setRedeemUserData && setRedeemUserData({});
                  navigation(-1);
                }}
              >
                Back
              </Button>
            </div>
            <div style={{ flex: "1 1 60%" }}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                style={allFieldsValid ? btnStyle : btnStyleDisabled}
                fullWidth
                onClick={(e) => onSaveAndRedeemPressed(e)}
                disabled={!allFieldsValid}
              >
                Save & Redeem
              </Button>
            </div>
          </div>
        </Paper>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message={genericErrorMessage}
          action={<SnackBarClose handleClose={handleClose} />}
        />

        {alertMessage ? (
          <div style={{ position: "absolute", width: "100%" }}>
            <Alert
              variant="filled"
              severity="success"
              onClose={() => setAlertMessage("")}
              sx={{ maxWidth: "925px", margin: "auto", mt: "25px" }}
            >
              {alertMessage}
            </Alert>
          </div>
        ) : null}
      </div>
      <SideCarousel />
    </div>
  );
};

export default UpdateAdditionalAttributes;
