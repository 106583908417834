import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import FeedIcon from "@mui/icons-material/Feed";

import {
  Alert,
  Box,
  Container,
  Dialog,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import VeraLink from "../../../components/VeraLink";
import VeraButton from "../../../components/VeraButton";
import UsersService from "../../../services/api/usersService";
import surveysIcon from "../../../assets/icon-surveys.svg";
import pointsIcon from "../../../assets/icon-points.svg";
import AddIcon from "@mui/icons-material/Add";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { format } from "date-fns";
import { convertDatetimeStringToReadable } from "../../../helpers/date";

import "./UserDetails.scss";
import { TargetingTag } from "../../../types/TargetingTag";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SurveysService from "../../../services/api/surveysService";
import { SurveyType } from "../../../types/Survey";
import TargetingTagsService from "../../../services/api/targetingTagsService";
import AddPointsToOneUSerDialog from "./AddPointsToOneUserDialog";
import UserActivityTab from "src/screens/public/Profile/components/UserActivityTab";
import SurveyFeed from "src/components/SurveyFeed/SurveyFeed";

const StyledTableHeadCell = styled(TableCell)({
  fontSize: "14px",
  lineHeight: "16px",
  fontWeight: "bold",
  color: "#91a3a9",
  textAlign: "left",
  padding: "10px 15px",
});

type TargetingTagsDataType = {
  _id: string;
  tag: string;
  surveyId: string;
  surveyTitle: string;
};

const UserDetails = () => {
  const { id: userId } = useParams();
  const [userData, setUserData] = useState<any>(null);
  const [targetingTagSurveys, setTargetingTagSurveys] = useState<Partial<SurveyType>[]>([]);
  const [targetingTagsData, setTargetingTagsData] = useState<TargetingTagsDataType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openActivityDialog, setOpenActivityDialog] = useState<boolean>(false);
  const [feedDialogOpen, setFeedDialogOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const processAndSaveUserData = (data: any) => {
    let processedData = data;

    processedData["name"] = `${data.firstName} ${data.lastName}`;
    processedData["currentPoints"] = data.totalPoints - data.redeemedPointsSum;

    if (data.dob) {
      processedData["dateOfBirth"] = convertDatetimeStringToReadable(data.dob, "MM / dd / yyyy");
      let timeDifferece = new Date(Date.now() - new Date(data.dob).getTime());
      processedData["age"] = Math.abs(timeDifferece.getUTCFullYear() - 1970);
    } else {
      processedData["dateOfBirth"] = "N/A";
      processedData["age"] = "N/A";
    }

    if (data.address) {
      const { address, city, state, zip, country } = data.address;

      processedData["location"] = [address, city, [state, zip].filter(Boolean).join(" "), country]
        .filter(Boolean)
        .join(", ");
    }

    setUserData(processedData);
  };

  const getUser = useCallback(async (id: string) => {
    setIsLoading(true);

    const res = await UsersService.getUser(id);

    processAndSaveUserData(res.data);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!userId) return;

    getUser(userId);
  }, [userId, getUser]);

  useEffect(() => {
    if (!userData) return;

    const surveyIds: string[] = userData.targetingTagsList.map((tag: TargetingTag) => tag?.surveyId).filter(Boolean);

    SurveysService.querySurveys({
      ids: surveyIds.join(","),
      fields: "title,_id",
    }).then((res: { data: Partial<SurveyType>[] }) => {
      setTargetingTagSurveys(res.data);
    });
  }, [userData]);

  useEffect(() => {
    if (!userData) return;

    const newTargetingTagsData: TargetingTagsDataType[] = userData.targetingTagsList.map((tag: TargetingTag) => {
      const survey = targetingTagSurveys.find((survey) => survey?._id === tag?.surveyId);

      return {
        _id: tag?._id,
        tag: tag?.tag,
        surveyId: tag?.surveyId,
        surveyTitle: survey?.title,
      };
    });

    setTargetingTagsData(newTargetingTagsData);
  }, [targetingTagSurveys, userData]);

  const addPoints = async (amount: number | null) => {
    if (!amount || !userId) return;

    const res = await UsersService.addPoints(userId, { amount });

    processAndSaveUserData(res.data);
    setOpenDialog(false);
    setAlertMessage(`${amount} Points Added`);
  };

  const removeTagFromUser = async (tagId: string) => {
    if (!window.confirm("Are you sure you want to remove the user from this tag?")) return;

    if (!userId) return;

    try {
      await TargetingTagsService.removeTargetingTagFromUser(tagId, userId);
      setUserData((userData: any) => ({
        ...userData,
        targetingTagsList: userData.targetingTagsList.filter((tag: TargetingTag) => tag._id !== tagId),
      }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container
      sx={{
        marginTop: "60px",
      }}
    >
      {isLoading || !userData ? (
        "Loading..."
      ) : (
        <>
          <Box>
            <Typography variant="h6">
              <VeraLink to="/admin/users">Back</VeraLink>
            </Typography>

            <Typography
              variant="h3"
              sx={{
                fontSize: "27px",
                lineHeight: "32px",
                fontWeight: "bold",
                color: "#384144",
              }}
            >
              User Details
            </Typography>
          </Box>

          <Paper
            sx={{
              flexGrow: 1,
              marginY: "20px",
              padding: "60px",
              boxShadow: "3px 24px 20px rgba(0, 0, 0, 0.06)",
              borderRadius: "16px",
            }}
          >
            {alertMessage ? (
              <Stack sx={{ width: "100%", margin: "0 0 20px" }}>
                <Alert variant="filled" severity="success" onClose={() => setAlertMessage("")}>
                  {alertMessage}
                </Alert>
              </Stack>
            ) : null}

            <Box
              sx={{
                display: "flex",
                margin: "20px 15px",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              {userData.image ? (
                <Box className="user-details-image user-details-column">
                  <img src={userData.image} alt="user" />
                </Box>
              ) : (
                <Box className="user-details-image user-details-column default-image">
                  <PersonOutlineOutlinedIcon />
                </Box>
              )}

              <Box className="user-details-column">
                <div className="user-details-info-line">
                  <p className="user-details-param">Name</p>
                  <p className="user-details-value">{userData.name}</p>
                </div>
                <div className="user-details-info-line">
                  <p className="user-details-param">Email</p>
                  <p className="user-details-value">{userData.email}</p>
                </div>
                <div className="user-details-info-line">
                  <p className="user-details-param">Phone</p>
                  <p className="user-details-value">{userData.phone}</p>
                </div>
              </Box>

              <Box className="user-details-column">
                <div className="user-details-info-line">
                  <p className="user-details-param">Date of birth</p>
                  <p className="user-details-value">{userData.dateOfBirth}</p>
                </div>
                <div className="user-details-info-line">
                  <p className="user-details-param">Age</p>
                  <p className="user-details-value">{userData.age}</p>
                </div>
                <div className="user-details-info-line">
                  <p className="user-details-param">Location</p>
                  <p className="user-details-value">{userData.location}</p>
                </div>
              </Box>

              <Box className="user-details-column">
                <div className="user-details-info-line">
                  <p className="user-details-param">Last Survey</p>
                  <p className="user-details-value">
                    {userData.lastActive ? format(new Date(userData.lastActive), "MM / dd / yyyy") : "Never"}
                  </p>
                </div>
                <div className="user-details-info-line">
                  <p className="user-details-param">Response Rate</p>
                  <p className="user-details-value">{userData.responseRate ? userData.responseRate : "--"}</p>
                </div>
                <div className="user-details-info-line">
                  <p className="user-details-param">Status</p>
                  <p className={`user-details-value ${userData.active ? "active" : "inactive"}`}>
                    {userData.active ? "Active" : "Inactive"}
                  </p>
                </div>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                marginY: "20px",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <div className="surveys-completed-box">
                <p className="number">{userData.completedSurveyCount}</p>
                <p className="title">Surveys Completed</p>
                <img src={surveysIcon} alt="surveys" />
                <VeraButton variant="outlined" startIcon={<FeedIcon />} onClick={() => setFeedDialogOpen(true)}>
                  View Survey Feed
                </VeraButton>
              </div>

              <div className="points-box">
                <div className="row">
                  <div className="column">
                    <p className="number">{userData.currentPoints}</p>
                    <p className="title">Current Points</p>
                  </div>
                  <div className="column">
                    <p className="number-total">{userData.totalPoints}</p>
                    <p className="title">Total All-time Points</p>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    <VeraButton variant="outlined" startIcon={<AddIcon />} onClick={() => setOpenDialog(true)}>
                      Add Points
                    </VeraButton>
                  </div>
                  <div className="column">
                    <VeraButton variant="outlined" startIcon={<AddIcon />} onClick={() => setOpenActivityDialog(true)}>
                      Details
                    </VeraButton>
                  </div>
                </div>

                <img src={pointsIcon} alt="points" />
              </div>
            </Box>

            <Box>
              <Typography variant="h6" fontWeight="bold" margin="20px 15px">
                Tags
              </Typography>

              <Table>
                <TableHead>
                  <TableRow key={"tags-table-head"}>
                    <StyledTableHeadCell key={"tags-title"}>Title</StyledTableHeadCell>
                    <StyledTableHeadCell key={"tags-survey"}>Survey</StyledTableHeadCell>
                    <StyledTableHeadCell key={"tags-actions"}></StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ boxShadow: "0px 4px 20px rgb(0 0 0 / 6%)" }}>
                  {targetingTagsData.map((targetingTag) => (
                    <TableRow
                      key={targetingTag._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{targetingTag.tag}</TableCell>
                      <TableCell>
                        <Link className="table-link" to={`/admin/surveys/${targetingTag.surveyId}`}>
                          {targetingTag.surveyTitle}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <DeleteOutlineIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => removeTagFromUser(targetingTag._id)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>

            <Box>
              <Typography variant="h6" fontWeight="bold" margin="20px 15px">
                Survey History
              </Typography>

              <Table>
                <TableHead>
                  <TableRow key={"table-head"}>
                    <StyledTableHeadCell key={"completionDate"}>Date Complete</StyledTableHeadCell>
                    <StyledTableHeadCell key={"title"}>Survey</StyledTableHeadCell>
                    <StyledTableHeadCell key={"status"}>Status</StyledTableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ boxShadow: "0px 4px 20px rgb(0 0 0 / 6%)" }}>
                  {userData.completedSurveys.map((completedSurvey: any) => (
                    <TableRow
                      key={completedSurvey.survey._id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell>{format(new Date(completedSurvey.createdAt), "MM / dd / yyyy")}</TableCell>
                      <TableCell>
                        <b>{completedSurvey.survey.title}</b>
                        <p>{completedSurvey.survey.payload?.name}</p>
                      </TableCell>
                      <TableCell sx={{ textTransform: "capitalize" }}>{completedSurvey.survey.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>

            <AddPointsToOneUSerDialog onClose={() => setOpenDialog(false)} onAddPoints={addPoints} open={openDialog} />
            <Dialog fullWidth maxWidth="lg" onClose={() => setOpenActivityDialog(false)} open={openActivityDialog}>
              <UserActivityTab userId={userId} />
            </Dialog>
            <Dialog fullWidth maxWidth="lg" onClose={() => setFeedDialogOpen(false)} open={feedDialogOpen}>
              <SurveyFeed userId={userId} />
            </Dialog>
          </Paper>
        </>
      )}
    </Container>
  );
};

export default UserDetails;
