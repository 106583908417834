import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { theme } from "../styles/theme";

export default styled(Button)({
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#5f6f74",
  width: "150px",
  height: "40px",
  border: "1px solid #5f6f74",
  borderRadius: "4px",
  textTransform: "capitalize",

  "&:hover": {
    border: "1px solid #5f6f74",
    backgroundColor: "transparent",
    opacity: "0.6",
  },
});

export const MainButton = styled(Button)({
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#fff",
  backgroundColor: theme.palette.primary.main,
  width: "150px",
  height: "40px",
  border: "1px solid #5f6f74",
  borderRadius: "4px",
  textTransform: "capitalize",

  "&:hover": {
    color: "#5f6f74",
    backgroundColor: "transparent",
    border: "1px solid #5f6f74",
    opacity: "0.6",
  },
});
