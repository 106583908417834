import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { addValidFieldIfCorrrectEmailFormat } from "../../../helpers/generic";
import TablePagination from "@mui/material/TablePagination";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Button, Popover, Snackbar } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Papa from "papaparse";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import UsersService from "../../../services/api/usersService";
import VeraButton from "../../../components/VeraButton";
import { convertDatetimeStringToReadable } from "../../../helpers/date";
import { format } from "date-fns";
import UserBulkUpdateDialog from "./components/UserBulkUpdateDialog";
import UploadCSVFileDialog from "./components/UploadCSVFileDialog";
import InformativeDialog from "./components/InformativeDialog";
import DateTimePicker from "src/components/DateTimePicker/DateTimePicker";
import "./UsersList.scss";
import TargetingTagsService from "../../../services/api/targetingTagsService";
import UsersBulkDeleteDialog from "./components/UsersBulkDeleteDialog";
import { UTMCodeType } from "../../../interfaces/IAuth";
import ProgressDialog, { ProgressBarType } from "../../../components/Progress/ProgressDialog";
import { useSnackbar } from "notistack";
import TableHeadSortable from "../../../components/common/TableHeadSortable";
import SearchWrapper from "../../../components/common/SearchWrapper";
import { UserRoles } from "src/types/User";
import { SearchParams } from "src/constants/url";

const userTableHeaders = [
  {
    id: "_id",
    label: "ID",
  },
  {
    id: "firstName",
    label: "User",
  },
  {
    id: "surveys",
    label: "Surveys",
  },
  {
    id: "lastActive",
    label: "Last Survey",
  },
  {
    id: "points",
    label: "Points",
  },
  {
    id: "email",
    label: "Email",
  },
  {
    id: "active",
    label: "Status",
  },
  {
    id: "utmCode",
    label: "UTM",
  },
];
const researcherTableHeaders = userTableHeaders
  .filter((header) => !["surveys", "lastActive", "points", "utmCode"].includes(header.id))
  .concat({
    id: "organization",
    label: "Organization",
  });

const interests = ["Art", "Economy", "Environment", "Science", "Sports", "Politics"];

const activityOptions = [
  {
    title: "Last 7 days",
    value: format(new Date(new Date().setDate(new Date().getDate() - 7)), "yyyy-MM-dd"),
  },
  {
    title: "Last 30 days",
    value: format(new Date(new Date().setDate(new Date().getDate() - 30)), "yyyy-MM-dd"),
  },
  {
    title: "Last 90 days",
    value: format(new Date(new Date().setDate(new Date().getDate() - 90)), "yyyy-MM-dd"),
  },
  {
    title: "Last 365 days",
    value: format(new Date(new Date().setDate(new Date().getDate() - 365)), "yyyy-MM-dd"),
  },
];

interface Props {
  role?: UserRoles;
}

const UsersList = ({ role = UserRoles.USER }: Props) => {
  const [users, setUsers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [order, setOrder] = React.useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = React.useState("firstName");
  const [sortParam, setSortParam] = React.useState("firstName");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalCount, setTotalCount] = React.useState(0);

  const [openFilters, setOpenFilters] = React.useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  const [statusFilter, setStatusFilter] = React.useState<boolean | undefined>(undefined);
  const [activityFilter, setActivityFilter] = React.useState("");
  const [selectedInterests, setSelectedInterests] = React.useState<any>([]);
  const [minResponseRate, setMinResponseRate] = React.useState(0);
  const [maxResponseRate, setMaxResponseRate] = React.useState(100);
  const [registeredAfter, setRegisteredAfter] = React.useState<string>();
  const [registeredBefore, setRegisteredBefore] = React.useState<string>();

  const [searchValue, setSearchValue] = React.useState("");
  const [searchIsActive, setSearchIsActive] = React.useState(false);
  const [openActionItem, setOpenActionItem] = React.useState("");

  const [openUploadCSVFileDialog, setOpenUploadCSVFileDialog] = React.useState(false);
  const [uploadCSVTitle, setUploadCSVTitle] = React.useState("");
  const [userBulkUpdateTitle, setUserBulkUpdateTitle] = React.useState("");

  const [CSVFile, setCSVFile] = React.useState<Blob | undefined>();
  const [CSVFileParsed, setCSVFileParsed] = React.useState<{ email: string; points: string; valid?: boolean }[]>([]);
  const [openUserBulkUpdateDialog, setOpenUserBulkUpdateDialog] = React.useState(false);
  const [openUserBulkDeleteDialog, setOpenUserBulkDeleteDialog] = React.useState(false);
  const [openInformativeDialog, setOpenInformativeDialog] = React.useState<string | false>();
  const [errorText, setErrorText] = React.useState<string | false>();
  const [userToDisable, setUserToDisable] = React.useState({
    id: "",
    name: "",
  });

  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);

  const [downloadProgressOpen, setDownloadProgressOpen] = React.useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  const isResearcher = role == UserRoles.RESEARCHER;
  const tableHeadCells = isResearcher ? researcherTableHeaders : userTableHeaders;

  const snackBarAction = (
    <React.Fragment>
      <Button style={{ color: "#2ebdee" }} size="small" onClick={handleClose}>
        Close
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} />
    </React.Fragment>
  );

  useEffect(() => {
    if (typeof CSVFile !== "undefined") {
      //  && uploadCSVTitle !== 'UsersBulkDelete'
      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        if (target && target.result) {
          /* @ts-ignore: Unreachable code error */
          const csv = Papa.parse(target?.result, { skipEmptyLines: true, header: true });
          /* @ts-ignore: Unreachable code error */
          const errors = csv?.errors;
          if (errors.length > 0) {
            handleCloseUploadCSVFileDialog();
            setOpenInformativeDialog("error");
            setErrorText(errors[0].message);
          } else {
            /* @ts-ignore: Unreachable code error */
            const CSVFileParsed = addValidFieldIfCorrrectEmailFormat(csv?.data);
            /* @ts-ignore: Unreachable code error */

            await UsersService.verifyBatchOfUsers({ CSVFileParsed })
              .then((res) => {
                setCSVFileParsed(res.data.CSVFileParsed);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      };
      reader.readAsText(CSVFile);
    }
  }, [CSVFile]);

  const navigate = useNavigate();

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortParam(((isAsc ? "-" : "") + property).toString());
    setPage(0);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const value = parseInt(event.target.value, 10);
    setRowsPerPage(value);
    setPage(0);
  };

  const getUsers = (
    page: number,
    limit: number,
    status: boolean | undefined,
    interests: string[],
    activity: string,
    sort: string,
    search = "",
    minResponseRate: number,
    maxResponseRate: number,
    regAfter: string | undefined = registeredAfter,
    regBefore: string | undefined = registeredBefore
  ) => {
    setIsLoading(true);
    const params: any = {};
    params["page"] = page;
    params["limit"] = limit;
    params["sort"] = sort;
    params["role"] = role;
    if (status !== undefined) params["active"] = status;
    if (interests.length) params["interests"] = interests;
    if (activity) params["lastActive"] = activity;
    if (minResponseRate !== 0 && maxResponseRate !== 100) {
      params["minResponseRate"] = minResponseRate;
      params["maxResponseRate"] = maxResponseRate;
    }
    if (regAfter) {
      params["registeredAfter"] = regAfter;
    }
    if (regBefore) {
      params["registeredBefore"] = regBefore;
    }
    if (search) params["search"] = search;

    UsersService.getUsers({ ...params }).then((res) => {
      const processedDocs: any = [];
      res.data.docs.forEach((doc: any) => {
        processedDocs.push({
          ...doc,
          key: doc._id,
          name: `${doc.firstName} ${doc.lastName}`,
        });
      });
      setUsers(processedDocs);
      setTotalCount(res.data.total);
      setIsLoading(false);
    });
  };

  const usersCount = () => {
    return users.length;
  };

  const toggleFilter = (evt: any) => {
    setOpenFilters(!openFilters);
    setPopoverAnchorEl(evt.currentTarget);
  };

  const closeFilter = () => {
    setOpenFilters(false);
    setPopoverAnchorEl(null);
  };

  const changeStatusFilter = (value: any) => {
    if (value === statusFilter) setStatusFilter(undefined);
    else setStatusFilter(value);
  };

  const changeActivityFilter = (value: any) => {
    if (value === activityFilter) setActivityFilter("");
    else setActivityFilter(value);
  };

  const handleInterestChange = (event: any, newValue: any) => {
    setSelectedInterests(newValue);
  };

  const handleMinResponseRateChange = (event: any) => {
    let value = Number(event.target.value);
    if (isNaN(value)) setMinResponseRate(0);
    if (value >= 0 && value <= 100) setMinResponseRate(value);
  };

  const handleMaxResponseRateChange = (event: any) => {
    let value = Number(event.target.value);
    if (isNaN(value)) setMaxResponseRate(100);
    if (value >= 0 && value <= 100) setMaxResponseRate(value);
  };

  const applyFilters = () => {
    if (minResponseRate > maxResponseRate) {
      setMinResponseRate(maxResponseRate);
      setMaxResponseRate(minResponseRate);
    }
    setPage(0);
    setOpenFilters(false);
    getUsers(
      1,
      rowsPerPage,
      statusFilter,
      selectedInterests,
      activityFilter,
      sortParam,
      searchValue,
      minResponseRate,
      maxResponseRate
    );
  };

  const clearFilters = () => {
    setStatusFilter(undefined);
    setActivityFilter("");
    setSelectedInterests([]);
    setMinResponseRate(0);
    setMaxResponseRate(100);
    setPage(0);
    setOpenFilters(false);
    setRegisteredAfter("");
    setRegisteredBefore("");
    getUsers(1, rowsPerPage, undefined, [], "", sortParam, searchValue, 0, 100, "", "");
  };

  const onSearchClick = () => {
    if (searchValue) {
      setSearchIsActive(true);
    } else {
      setSearchIsActive(false);
    }
    getUsers(
      page + 1,
      rowsPerPage,
      statusFilter,
      selectedInterests,
      activityFilter,
      sortParam,
      searchValue,
      minResponseRate,
      maxResponseRate
    );
  };

  const onClearSearch = () => {
    setSearchValue("");
    setSearchIsActive(false);
    getUsers(
      page + 1,
      rowsPerPage,
      statusFilter,
      selectedInterests,
      activityFilter,
      sortParam,
      "",
      minResponseRate,
      maxResponseRate
    );
  };

  const toggleActions = (event: any, key: string) => {
    if (openActionItem === key) {
      setOpenActionItem("");
      setPopoverAnchorEl(null);
    } else {
      setOpenActionItem(key);
      setPopoverAnchorEl(event.currentTarget);
    }
  };

  const handleOpenDisableDialog = (id: string, name: string) => {
    setUserToDisable({ id, name });
    setOpenActionItem("");
    setOpenInformativeDialog("disable");
  };
  const handleCloseDisableDialog = () => {
    setOpenInformativeDialog(false);
  };

  const handleOpenUploadCSVFileDialog = (uploadCSVTitle: string, userBulkUpdateTitle: string) => {
    setUploadCSVTitle(uploadCSVTitle);
    setUserBulkUpdateTitle(userBulkUpdateTitle);
    setOpenUploadCSVFileDialog(true);
  };
  const handleCloseUploadCSVFileDialog = () => {
    setOpenUploadCSVFileDialog(false);
    setUploadCSVTitle("");
    setCSVFile(undefined);
    setCSVFileParsed([]);
  };
  const handleOpenUserBulkUpdateDialog = () => {
    if (uploadCSVTitle !== "UsersBulkDelete") {
      setOpenUserBulkUpdateDialog(true);
    } else {
      setOpenUserBulkDeleteDialog(true);
    }
    setOpenUploadCSVFileDialog(false);
  };
  const handleCloseUserBulkUpdateDialog = () => {
    setOpenUserBulkUpdateDialog(false);
    setOpenUserBulkDeleteDialog(false);
    setUserBulkUpdateTitle("");
    setCSVFile(undefined);
    setCSVFileParsed([]);
  };

  const onSubmit = async () => {
    if (CSVFileParsed.length > 0) {
      if (userBulkUpdateTitle === "Tags") {
        await TargetingTagsService.addTagsToBatchOfUsers({ CSVFileParsed })
          .then((res) => {
            handleCloseUserBulkUpdateDialog();
            setOpenInformativeDialog("addTagsToBatchOfUserSubmitted");
            getUsers(
              1,
              rowsPerPage,
              statusFilter,
              selectedInterests,
              activityFilter,
              sortParam,
              searchValue,
              minResponseRate,
              maxResponseRate
            );
          })
          .catch((err) => {
            handleCloseUserBulkUpdateDialog();
            setOpenInformativeDialog("error");
            console.log(err);
          });
      } else {
        await UsersService.addPointsToBatchOfUsers({ CSVFileParsed })
          .then((res) => {
            handleCloseUserBulkUpdateDialog();
            setOpenInformativeDialog("addPointsToBatchOfUserSubmitted");
            getUsers(
              1,
              rowsPerPage,
              statusFilter,
              selectedInterests,
              activityFilter,
              sortParam,
              searchValue,
              minResponseRate,
              maxResponseRate
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const onDeleteSubmit = async () => {
    if (CSVFileParsed.length > 0) {
      let message: string = "";
      try {
        await UsersService.usersBulkDelete(CSVFileParsed);
        message = "The result file will be sent to your email.";
      } catch (error) {
        console.log(error);
        message = "Something went wrong. Please try again later.";
      }

      setSnackBarMessage(message);
      setSnackBarOpen(true);
    }
    setOpenUserBulkDeleteDialog(false);
  };

  const onUserDisable = (key: string) => {
    UsersService.updateUser(key, { active: false }).then((res) => {
      setOpenInformativeDialog(false);
      getUsers(
        page + 1,
        rowsPerPage,
        statusFilter,
        selectedInterests,
        activityFilter,
        sortParam,
        searchValue,
        minResponseRate,
        maxResponseRate
      );
    });
  };

  const onDownloadCSV = async () => {
    setDownloadProgressOpen(true);

    const params: any = {};
    params["sort"] = sortParam;
    if (statusFilter !== undefined) params["active"] = statusFilter;
    if (selectedInterests.length) params["interests"] = selectedInterests;
    if (activityFilter) params["lastActive"] = activityFilter;
    if (minResponseRate !== 0 && maxResponseRate !== 100) {
      params["minResponseRate"] = minResponseRate;
      params["maxResponseRate"] = maxResponseRate;
    }
    if (registeredAfter) {
      params["registeredAfter"] = registeredAfter;
    }
    if (registeredBefore) {
      params["registeredBefore"] = registeredBefore;
    }
    if (searchValue) params["search"] = searchValue;

    UsersService.downloadCSV({ ...params })
      .then((res) => {
        const blob = new Blob([res.data], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "verasight-users.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        enqueueSnackbar("Error downloading CSV", { variant: "error" });
      })
      .finally(() => {
        setDownloadProgressOpen(false);
      });
  };

  const renderUtmData = (utmData: UTMCodeType | null) => {
    if (!utmData) {
      return <span>No UTM data</span>;
    }

    return (
      <ul className="utmList">
        <li>{`Source: ${utmData.utmSource}`}</li>
        <li>{`Medium: ${utmData.utmMedium}`}</li>
        <li>{`Campaign: ${utmData.utmCampaign}`}</li>
        <li>{`Content: ${utmData.utmContent}`}</li>
        <li>{`Term: ${utmData.utmTerm}`}</li>
      </ul>
    );
  };

  useEffect(() => {
    getUsers(
      page + 1,
      rowsPerPage,
      statusFilter,
      selectedInterests,
      activityFilter,
      sortParam,
      searchValue,
      minResponseRate,
      maxResponseRate
    );
    // eslint-disable-next-line
  }, [page, rowsPerPage, sortParam, role]);

  return (
    <div className="content-wrapper">
      <div className="header">
        <div className="text-wrapper">
          <p className="title">{isResearcher ? "Researchers" : "Users"} </p>
          <p className="subtitle">Showing {usersCount()} users</p>
        </div>
      </div>

      <div className="filters-container">
        <div className="filter-wrapper">
          <Button
            variant="contained"
            endIcon={openFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            onClick={toggleFilter}
            disableElevation={true}
            style={{
              marginRight: "20px",
              backgroundColor: "#fefefe",
              color: "#000",
              border: "1px solid #bac6ca",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            Filter
          </Button>

          <Popover
            open={openFilters}
            onClose={closeFilter}
            anchorEl={popoverAnchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="filter-options">
              <p className="option-title">Status</p>
              <ListItem key={"active"} disablePadding sx={{ height: "35px" }}>
                <ListItemButton onClick={() => changeStatusFilter(true)} dense sx={{ height: "100%" }}>
                  <ListItemIcon sx={{ minWidth: "auto" }}>
                    <Checkbox edge="start" checked={statusFilter === true} tabIndex={-1} disableRipple />
                  </ListItemIcon>
                  <ListItemText primary={`Active`} />
                </ListItemButton>
              </ListItem>
              <ListItem key={"inactive"} disablePadding sx={{ height: "35px" }}>
                <ListItemButton onClick={() => changeStatusFilter(false)} dense sx={{ height: "100%" }}>
                  <ListItemIcon sx={{ minWidth: "auto" }}>
                    <Checkbox edge="start" checked={statusFilter === false} tabIndex={-1} disableRipple />
                  </ListItemIcon>
                  <ListItemText primary={`Inactive`} />
                </ListItemButton>
              </ListItem>

              <p className="option-title">Activity</p>
              {activityOptions.map(({ title, value }) => (
                <ListItem key={value} disablePadding sx={{ height: "35px" }}>
                  <ListItemButton onClick={() => changeActivityFilter(value)} sx={{ height: "100%" }} dense>
                    <ListItemIcon sx={{ minWidth: "auto" }}>
                      <Checkbox edge="start" checked={activityFilter === value} tabIndex={-1} disableRipple />
                    </ListItemIcon>
                    <ListItemText sx={{ textTransform: "capitalize" }} primary={title} />
                  </ListItemButton>
                </ListItem>
              ))}

              <p className="option-title">Response Rate</p>
              <div className="response-rate-fileds">
                <TextField
                  label="From"
                  id="response-rate-start"
                  size="small"
                  type="number"
                  value={minResponseRate}
                  onChange={handleMinResponseRateChange}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  variant="outlined"
                />
                <TextField
                  label="To"
                  id="response-rate-start"
                  size="small"
                  type="number"
                  value={maxResponseRate}
                  onChange={handleMaxResponseRateChange}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  variant="outlined"
                />
              </div>

              <p className="option-title">Interests</p>
              <Autocomplete
                multiple
                id="interests-select"
                options={interests}
                value={selectedInterests}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ textTransform: "capitalize" }}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                style={{ textTransform: "capitalize" }}
                size="small"
                onChange={handleInterestChange}
                renderInput={(params) => <TextField {...params} placeholder="Interests" />}
              />

              <p className="option-title">Registration</p>
              <div className="response-rate-fileds">
                <DateTimePicker
                  label="Registered After(Inclusive)"
                  value={registeredAfter ? new Date(registeredAfter) : null}
                  setValue={(newValue: any) => {
                    setRegisteredAfter(newValue);
                  }}
                  currentTimezone={"system"}
                />
                <DateTimePicker
                  label="Registered Before(Exclusive)"
                  value={registeredBefore ? new Date(registeredBefore) : null}
                  setValue={(newValue: any) => {
                    setRegisteredBefore(newValue);
                  }}
                  currentTimezone={"system"}
                />
              </div>

              <div className="buttons-wrapper">
                <VeraButton variant="text" onClick={clearFilters} sx={{ border: "none" }}>
                  Clear
                </VeraButton>
                <VeraButton variant="outlined" onClick={applyFilters}>
                  Apply
                </VeraButton>
              </div>
            </div>
          </Popover>
        </div>

        <SearchWrapper
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onSearchClick={onSearchClick}
          onClearSearch={onClearSearch}
          searchIsActive={searchIsActive}
        />

        {!isResearcher ? (
          <>
            <VeraButton
              variant="outlined"
              onClick={() => handleOpenUploadCSVFileDialog("Upload CSV File to add/remove tags", "Tags")}
              disabled={isLoading || users.length === 0}
              // startIcon={<FileDownloadOutlinedIcon />}
              sx={{
                width: "fit-content",
                fontWeight: "bold",
                margin: "0 0 0 auto",
              }}
            >
              Add Tags
            </VeraButton>
            <VeraButton
              variant="outlined"
              onClick={() => handleOpenUploadCSVFileDialog("Bulk Reward Points", "Points")}
              disabled={isLoading || users.length === 0}
              // startIcon={<AddIcon />}
              sx={{
                width: "fit-content",
                fontWeight: "bold",
                margin: "0 0 0 10px",
              }}
            >
              Add Points
            </VeraButton>
          </>
        ) : null}

        <VeraButton
          variant="outlined"
          onClick={() => onDownloadCSV()}
          disabled={isLoading || users.length === 0}
          // startIcon={<FileDownloadOutlinedIcon />}
          sx={{
            width: "fit-content",
            fontWeight: "bold",
            margin: "0 0 0 10px",
          }}
        >
          Download CSV
        </VeraButton>
        <VeraButton
          variant="outlined"
          onClick={() => handleOpenUploadCSVFileDialog("UsersBulkDelete", "UsersList")}
          disabled={isLoading || users.length === 0}
          sx={{
            width: "fit-content",
            fontWeight: "bold",
            margin: "0 0 0 10px",
          }}
        >
          Bulk Delete
        </VeraButton>

        <ProgressDialog
          type={ProgressBarType.INFINITE}
          open={downloadProgressOpen}
          onClose={() => setDownloadProgressOpen(false)}
          title="Downloading CSV of users"
        />
      </div>

      {isLoading ? (
        "Loading..."
      ) : (
        <Table>
          <TableHeadSortable
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={tableHeadCells}
          />
          <TableBody style={{ borderRadius: "16px" }}>
            {users.map((user: any) => (
              <TableRow key={user.key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>{user.key}</TableCell>
                <TableCell component="th" scope="row">
                  <b>{user.name}</b>
                </TableCell>
                {!isResearcher ? (
                  <>
                    <TableCell>{user.surveys}</TableCell>
                    <TableCell>{user.lastActive ? convertDatetimeStringToReadable(user.lastActive) : "--"}</TableCell>
                    <TableCell>{user.points}</TableCell>
                  </>
                ) : null}
                <TableCell>{user.email}</TableCell>
                <TableCell className={`status-cell ${user.active ? "active" : "inactive"}`}>
                  {user.active ? "Active" : "Inactive"}
                </TableCell>
                {isResearcher ? (
                  <TableCell>{user.organization}</TableCell>
                ) : (
                  <TableCell>{renderUtmData(user.utmCode)}</TableCell>
                )}
                <TableCell className="actions-button">
                  <IconButton onClick={(event: any) => toggleActions(event, user.key)}>
                    <MoreVertOutlinedIcon />
                  </IconButton>
                  <Popover
                    open={openActionItem === user.key}
                    onClose={(event: any) => toggleActions(event, "")}
                    anchorEl={popoverAnchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <div className="actions-options">
                      {isResearcher ? (
                        <ListItem
                          key={"view_surveys"}
                          disablePadding
                          onClick={() =>
                            navigate(
                              `/admin/surveys?${SearchParams.createdBy}=${user._id}&${SearchParams.creatorName}=${user.firstName} ${user.lastName}`
                            )
                          }
                        >
                          <ListItemButton dense>
                            <ListItemIcon sx={{ minWidth: "auto" }}>
                              <VisibilityIcon sx={{ marginRight: "10px" }} />
                            </ListItemIcon>
                            <ListItemText primary={"View surveys"} />
                          </ListItemButton>
                        </ListItem>
                      ) : (
                        <ListItem key={"edit"} disablePadding onClick={() => navigate(`/admin/users/${user.key}`)}>
                          <ListItemButton dense>
                            <ListItemIcon sx={{ minWidth: "auto" }}>
                              <BorderColorIcon sx={{ marginRight: "10px" }} />
                            </ListItemIcon>
                            <ListItemText primary={"Edit"} />
                          </ListItemButton>
                        </ListItem>
                      )}

                      <ListItem key={"disable"} disablePadding>
                        <ListItemButton
                          disabled={!user.active}
                          onClick={() => handleOpenDisableDialog(user.key, user.name)}
                          dense
                        >
                          <ListItemIcon sx={{ minWidth: "auto" }}>
                            <DoNotDisturbAltIcon sx={{ marginRight: "10px" }} />
                          </ListItemIcon>
                          <ListItemText primary={"Disable"} />
                        </ListItemButton>
                      </ListItem>
                    </div>
                  </Popover>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <InformativeDialog
        onClose={handleCloseDisableDialog}
        userId={userToDisable.id}
        userName={userToDisable.name}
        onAction={onUserDisable}
        open={openInformativeDialog}
        errorText={errorText}
        handleOpenUploadCSVFileDialog={handleOpenUploadCSVFileDialog}
      />
      <UploadCSVFileDialog
        onClose={handleCloseUploadCSVFileDialog}
        onUpload={handleOpenUserBulkUpdateDialog}
        setCSVFile={setCSVFile}
        CSVFile={CSVFile}
        open={openUploadCSVFileDialog}
        uploadCSVTitle={uploadCSVTitle}
        userBulkUpdateTitle={userBulkUpdateTitle}
      />
      <UserBulkUpdateDialog
        onClose={handleCloseUserBulkUpdateDialog}
        onSubmit={onSubmit}
        setCSVFileParsed={setCSVFileParsed}
        CSVFileParsed={CSVFileParsed}
        userBulkUpdateTitle={userBulkUpdateTitle}
        open={openUserBulkUpdateDialog}
      />
      <UsersBulkDeleteDialog
        onClose={handleCloseUserBulkUpdateDialog}
        onSubmit={onDeleteSubmit}
        setCSVFileParsed={setCSVFileParsed}
        CSVFileParsed={CSVFileParsed}
        open={openUserBulkDeleteDialog}
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        message={snackBarMessage}
        action={snackBarAction}
      />
    </div>
  );
};

export default UsersList;
