import { useEffect } from "react";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from "src/styles/theme";

import AppRoutes from "./routes/app-routes";
import TagManager from "react-gtm-module";
import TiktokPixelUtil from "./services/tiktokUtil";
import { TIKTOK_PIXEL_ID } from "./config/config";
import { isLocal, getSiteEnvironment } from "./helpers/generic";
import { initTwitter } from "./twitter";
import apiService, { apiURL } from "./services/api/apiService";
import endpoints from "./services/api/endpoints";

const appServerNodeEnv = process.env.NODE_ENV;
const dotEnvNodeEnv = getSiteEnvironment();
const gitSha = document.getElementById("ui-version")?.getAttribute("content");
console.info("App Status: ");
sessionStorage.setItem("diagAppNodeEnv", dotEnvNodeEnv ?? "NOT_DEFINED");
sessionStorage.setItem("diagAppServerNodeEnv", appServerNodeEnv ?? "NOT_DEFINED");
sessionStorage.setItem("diagAppVersion", gitSha ?? "NOT_DEFINED");
console.info(`\tnodeEnv: ${sessionStorage.getItem("diagAppNodeEnv")}`);
console.info(`\tserverNodeEnv: ${sessionStorage.getItem("diagAppServerNodeEnv")}`);
console.info(`\tversion: ${sessionStorage.getItem("diagAppVersion")}`);

sessionStorage.setItem("diagApiLocation", apiURL);
console.info("Api Status: ");
console.info(`\tlocation: ${sessionStorage.getItem("diagApiLocation")}`);
apiService
  .get(endpoints.apiStatus)
  .then((response: any) => {
    if (response.data) {
      sessionStorage.setItem("diagApiNodeEnv", response.data.APP_NODE_ENV ?? "NOT_DEFINED");
      sessionStorage.setItem("diagApiServerNodeEnv", response.data.SERVER_NODE_ENV ?? "NOT_DEFINED");
      sessionStorage.setItem("diagApiVersion", response.data.VERSION ?? "NOT_DEFINED");
    } else {
      console.error(`API Status is: RETURNED_UNDEFINED`);
      sessionStorage.setItem("diagApiNodeEnv", "NOT_DEFINED");
      sessionStorage.setItem("diagApiServerNodeEnv", "NOT_DEFINED");
      sessionStorage.setItem("diagApiVersion", "NOT_DEFINED");
    }
  })
  .catch(() => {
    console.error(`API Status is: COULD_NOT_FETCH`);
    sessionStorage.setItem("diagApiNodeEnv", "NOT_DEFINED");
    sessionStorage.setItem("diagApiServerNodeEnv", "NOT_DEFINED");
    sessionStorage.setItem("diagApiVersion", "NOT_DEFINED");
  })
  .finally(() => {
    console.info(`\tnodeEnv: ${sessionStorage.getItem("diagApiNodeEnv")}`);
    console.info(`\tserverNodeEnv: ${sessionStorage.getItem("diagApiServerNodeEnv")}`);
    console.info(`\tversion: ${sessionStorage.getItem("diagApiVersion")}`);
  });

export type EnvironmentStatus = {
  app: {
    nodeEnv: string | null;
    serverNodeEnv: string | null;
    version: string | null;
  };
  api: {
    nodeEnv: string | null;
    serverNodeEnv: string | null;
    version: string | null;
  };
};

export const getEnvironmentStatus = (): EnvironmentStatus => {
  return {
    app: {
      nodeEnv: sessionStorage.getItem("diagAppNodeEnv"),
      serverNodeEnv: sessionStorage.getItem("diagAppServerNodeEnv"),
      version: sessionStorage.getItem("diagAppVersion"),
    },
    api: {
      nodeEnv: sessionStorage.getItem("diagApiNodeEnv"),
      serverNodeEnv: sessionStorage.getItem("diagApiServerNodeEnv"),
      version: sessionStorage.getItem("diagApiVersion"),
    },
  };
};

declare global {
  interface Window {
    dataLayer: any;
  }
}

export default function App() {
  useEffect(() => {
    if (!isLocal) {
      TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID ?? "" });
    }
    TiktokPixelUtil.init(TIKTOK_PIXEL_ID);
    initTwitter();
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AppRoutes />
      </ThemeProvider>
    </div>
  );
}
