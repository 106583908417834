import { Dialog, TextField } from "@mui/material";
import { useState } from "react";
import VeraButton from "../../../components/VeraButton";

const AddPointsToOneUSerDialog = (props: any) => {
  const { onClose, onAddPoints, open } = props;
  const [points, setPoints] = useState<number | "">("");

  const handleClose = () => {
    onClose();
  };

  const onChangePoints = (event: any) => {
    let value = Number(event.target.value);
    if (isNaN(value)) setPoints(0);
    if (value >= 0) setPoints(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="add-points-dialog">
        <p className="dialog-title">Add Points</p>
        <p className="dialog-subtitle">How many points are you adding?</p>
        <TextField
          placeholder="Amount of points"
          variant="outlined"
          size="small"
          value={points}
          onChange={onChangePoints}
        />
        <div className="buttons-wrapper">
          <VeraButton variant="text" onClick={onClose} sx={{ border: "none" }}>
            Cancel
          </VeraButton>
          <VeraButton variant="outlined" onClick={() => onAddPoints(points)} className="dialog-add-button">
            Confirm
          </VeraButton>
        </div>
      </div>
    </Dialog>
  );
};

export default AddPointsToOneUSerDialog;
