import { Box } from "@mui/material";
import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <Box id="privacy-policy-wrapper">
      <div className="privacyImportant">
        <p>
          At Verasight, data security and privacy are our top priorities. We will always keep your responses anonymous,
          and we will never share your contact information. To ensure that your data is safe and that your rights are
          protected, we have developed the following privacy policy.
        </p>
      </div>
      <p className="effective-date">Effective as of 08/27/2020</p>
      <p>
        This Privacy Policy describes how Verasight, Inc. and our subsidiaries and affiliates (collectively "
        <span className="bold">Verasight</span>," "<span className="bold">we</span>", "<span className="bold">us</span>"
        or "<span className="bold">our</span>") handle personal information that we collect through our websites and
        other digital properties that link to this Privacy Policy (collectively, the “
        <span className="bold">Service</span>”), participate in our events or research activities, receive our marketing
        communications, or engage with us on our social networks.
      </p>
      <div className="basicContent">
        <h1 className="left">Table of Contents</h1>
        <h4 className="left">Personal information we collect</h4>
        <h4 className="left">How we use your personal information</h4>
        <h4 className="left">How we share your personal information</h4>
        <h4 className="left">Your choices</h4>
        <h4 className="left">Other sites and services</h4>
        <h4 className="left">Security</h4>
        <h4 className="left">International data transfer</h4>
        <h4 className="left">Children</h4>
        <h4 className="left">Changes to this Privacy Policy</h4>
        <h4 className="left">How to contact us</h4>
      </div>
      <div className="basicContent">
        <h2>Personal information we collect</h2>
        <p>
          <span className="bold">Information you provide to us.</span> Personal information you may provide to us
          through the Service or otherwise, includes:
        </p>
        <ul>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Contact data</span>, such as your first and last name, email and physical addresses,
            phone number, professional title, and organizational affiliation.
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Profile data</span>, such as your username and password that you set to establish an
            online account with us, demographic information, interests, preferences, information about your
            participation in our events, promotions, or surveys, and any other information that you add to your account
            profile.
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Communications</span> that we exchange, including when you contact us with questions
            or feedback, or through social media.
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Research Data</span> that you provide when you agree to participate in our surveys or
            research activities, such as your survey responses and audio and/or video recordings that we may capture
            during interviews.
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Marketing data</span>, such as your preferences for receiving communications about
            our products, services, activities, events, and publications, and details about how you engage with our
            communications.
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Other information</span> that we may collect which is not specifically listed here,
            which we will use as described in this Privacy Policy or as otherwise described at the time of collection.
          </li>
        </ul>
        <p>
          <span className="bold">Third party sources.</span> We may combine personal information we receive from you
          with personal information we obtain from other sources, such as:
        </p>
        <ul>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Data providers</span>, such as information services and data licensors, that provide
            demographic and other information.
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Public sources</span>, such as social media platforms, publications, and news sites.
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Marketing and business partners</span>, such as companies that have entered into
            joint marketing relationships or other joint ventures with us, and our clients.
          </li>
        </ul>
        <p>
          <span className="bold">Automatic data collection</span>. We, our service providers, and our business partners
          may automatically log information about you, your computer or mobile device, your activity over time on our
          sites and other online services, and your interactions with our marketing communications such as:
        </p>
        <ul>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Device data</span>, such as your computer's or mobile device's operating system type
            and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device
            type (e.g., phone, tablet), IP address, unique identifiers (including identifiers used for advertising
            purposes), language settings, mobile device carrier, radio/network information (e.g., WiFi, LTE, 3G), and
            general location information such as city, state or geographic area.
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Online activity data</span>, such as pages or screens you viewed, how long you spent
            on a page or screen, the website you visited before browsing to the Service, navigation paths between pages
            or screens, information about your activity on a page or screen, access times, and duration of access, and
            whether you have opened our marketing emails or clicked links within them.
          </li>
        </ul>
        <p>
          <span className="bold">Cookies and similar technologies</span>. Like many online services, we use the
          following technologies:
        </p>
        <ul>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Cookies</span>, which are text files that websites store on a visitor's device to
            uniquely identify the visitor's browser or to store information or settings in the browser for the purpose
            of helping you navigate between pages efficiently, remembering your preferences, enabling functionality,
            helping us understand user activity and patterns, and facilitating analytics and online advertising.
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Web beacons</span>, also known as pixel tags or clear GIFs, which are used to
            demonstrate that a webpage or email was accessed or opened, or that certain content was viewed or clicked.
          </li>
        </ul>
        <p>
          <span className="bold">Data about others</span>. Users of the Service may have the opportunity to refer
          friends or other contacts to us and share their contact information with us. Please do not refer someone to us
          or share their contact information with us unless you have their permission to do so.
        </p>
      </div>
      <div className="basicContent">
        <h2>How we use your personal information</h2>
        <p>
          We use your personal information for the following purposes or as otherwise described at the time we collect
          it:
        </p>
        <p>
          <span className="bold">Service delivery</span>. We use your personal information to:
        </p>
        <ul>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            provide, operate and improve the Service and our business;
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            deliver high-level data on aggregate survey responses to business partners;
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            establish and maintain your user profile on the Service;
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            enable security features of the Service, such as by sending you security codes via email or SMS, and
            remembering devices from which you have previously logged in;
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            communicate with you about the Service, including by sending announcements, updates, security alerts, and
            support and administrative messages;
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            understand your needs and interests, and personalize your experience with the Service and our
            communications; and
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            provide support for the Service, and respond to your requests, questions and feedback.
          </li>
        </ul>
        <p>
          <span className="bold">Research and development</span>. We may use your personal information for research and
          development purposes, including to analyze and improve the Service and our business. As part of these
          activities, we may create aggregated, de-identified or other anonymous data from personal information we
          collect. We make personal information into anonymous data by removing information that makes the data
          personally identifiable to you. We may use this anonymous data and share it with third parties for our lawful
          business purposes, including to analyze and improve the Service and promote our business.
        </p>
        <p>
          <span className="bold">Marketing and advertising</span>. We and our third party advertising partners may
          collect and use your personal information for marketing and advertising purposes:
        </p>
        <ul>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Direct marketing</span>. We may send you Verasight-related or other direct marketing
            communications as permitted by law, including by email and mail. You may opt-out of our marketing
            communications as described in the Opt-out of marketing section below.
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Interest-based advertising</span>. We may contract with third-party advertising and
            social media companies to display ads on our Service and other online services. These companies may use
            cookies and similar technologies to collect information about you (including the data described in the
            automatic data collection section above) over time across our Service and other online services and use that
            information to serve ads that they think will interest you. You can learn more about your choices for
            limiting interest-based advertising in the Advertising choices section below.
          </li>
        </ul>
      </div>
      <div className="basicContent">
        <h2>How we share your personal information</h2>
        <p>
          We may share your personal information with the following parties and as otherwise described in this Privacy
          Policy or at the time of collection:
        </p>
        <p>
          <span className="bold">Marketing and business partners</span>. Companies that have entered into partnerships
          with us, including clients.
        </p>
        <p>
          <span className="bold">Affiliates</span>. Our corporate parent, subsidiaries, and affiliates, for purposes
          consistent with this Privacy Policy.
        </p>
        <p>
          <span className="bold">Service providers</span>. Companies and individuals that provide services on our behalf
          or help us operate the Service or our business (such as information technology, hosting, customer relationship
          management and support, print and mail fulfillment, email delivery, advertising, marketing, and website
          analytics).
        </p>
        <p>
          <span className="bold">Advertising partners</span>. Third party advertising companies that collect information
          about your activity on the Service and other online services to help us advertise our services, and/or use
          customer lists that we share with them to deliver ads on their platforms on our behalf to those customers and
          similar users.
        </p>
        <p>
          <span className="bold">Professional advisors</span>. Professional advisors, such as lawyers, auditors, bankers
          and insurers, where necessary in the course of the professional services that they render to us.
        </p>
        <p>
          <span className="bold">Authorities and others</span>. Law enforcement, government authorities, and private
          parties, as we believe in good faith to be necessary or appropriate for the compliance and protection purposes
          described above.
        </p>
        <p>
          <span className="bold">Business transferees</span>. Acquiring and other relevant parties to business
          transactions (or potential transactions) involving a corporate divestiture, merger, consolidation,
          acquisition, reorganization, sale or other disposition of all or any portion of the business or assets of, or
          equity interests in, Verasight or our affiliates (including, in connection with a bankruptcy or similar
          proceedings).
        </p>
      </div>
      <div className="basicContent">
        <h2>Your Choices</h2>
        <p>You have the following choices with respect to your personal information.</p>
        <p>
          <span className="bold">Access or update your information</span>. If you have registered for an online account
          with us, you may review and update certain account information from your account.
        </p>
        <p>
          <span className="bold">Opt-out of marketing communications</span>. You may opt-out of marketing-related emails
          by following the opt-out or unsubscribe instructions at the bottom of the email, or by contacting us. You may
          continue to receive service-related and other non-marketing emails. If you receive marketing text messages
          from us, you may opt out of receiving further marketing text messages from us by replying STOP to our
          marketing message.
        </p>
        <p>
          <span className="bold">Cookies</span>. Most browsers let you remove and/or stop accepting cookies from the
          websites you visit. To do this, follow the instructions in your browser's settings. Many browsers accept
          cookies by default until you change your settings. If you do not accept cookies, however, you may not be able
          to use all functionality of the Service and our Services may not work properly. For more information about
          cookies, including how to see what cookies have been set on your browser and how to manage and delete them,
          visit{" "}
          <a href="http://www.allaboutcookies.org/" target="_blank" rel="noreferrer">
            www.allaboutcookies.org
          </a>
          . We use Google Analytics to help us understand user activity on the Sites. You can learn more about Google
          Analytics cookies at{" "}
          <a
            href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies"
            target="_blank"
            rel="noreferrer"
          >
            https://developers.google.com/analytics/resources/concepts/gaConceptsCookies
          </a>{" "}
          and about how Google protects your data at{" "}
          <a href="http://www.google.com/analytics/learn/privacy.html" target="_blank" rel="noreferrer">
            http://www.google.com/analytics/learn/privacy.html
          </a>
          . You can prevent the use of Google Analytics relating to your use of our Sites by downloading and installing
          a browser plugin available at{" "}
          <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" rel="noreferrer">
            https://tools.google.com/dlpage/gaoptout?hl=en
          </a>
          .
        </p>
        <p>
          <span className="bold">Mobile location data</span>. You can disable our access to your device's precise
          geolocation in your mobile device settings.
        </p>
        <p>
          <span className="bold">Advertising choices</span>. You can limit use of your information for interest-based
          advertising by:
        </p>
        <ul>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Browser settings</span>. Blocking third party cookies in your browser settings
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Privacy browsers/plug-ins</span>. By using privacy browsers or ad-blocking browser
            plug-ins that let you block tracking technologies.
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Platform settings</span>. Google and Facebook offer opt-out features that let you
            opt-out of use of your information for interest-based advertising:
          </li>
          <li className="indentedItem">
            <span className="listIcon">
              <i className="far fa-circle" aria-hidden="true"></i>
            </span>{" "}
            Google:{" "}
            <a href="https://adssettings.google.com/" target="_blank" rel="noreferrer">
              https://adssettings.google.com/
            </a>
          </li>
          <li className="indentedItem">
            <span className="listIcon">
              <i className="far fa-circle" aria-hidden="true"></i>
            </span>{" "}
            Facebook:{" "}
            <a href="https://www.facebook.com/about/ads" target="_blank" rel="noreferrer">
              https://www.facebook.com/about/ads
            </a>
          </li>
          <li>
            <span className="listIcon">
              <i className="fas fa-circle" aria-hidden="true"></i>
            </span>{" "}
            <span className="bold">Ad industry tools</span>. Opting out of interest-based ads from companies
            participating in the following industry opt-out programs:
          </li>
          <li className="indentedItem">
            <span className="listIcon">
              <i className="far fa-circle" aria-hidden="true"></i>
            </span>{" "}
            Network Advertising Initiative:
            <a href="http://www.networkadvertising.org/managing/opt_out.asp" target="_blank" rel="noreferrer">
              http://www.networkadvertising.org/managing/opt_out.asp
            </a>
          </li>
          <li className="indentedItem">
            <span className="listIcon">
              <i className="far fa-circle" aria-hidden="true"></i>
            </span>{" "}
            Digital Advertising Alliance:{" "}
            <a href="optout.aboutads.info" target="_blank" rel="noreferrer">
              optout.aboutads.info
            </a>
            .
          </li>
          <li className="indentedItem">
            <span className="listIcon">
              <i className="far fa-circle" aria-hidden="true"></i>
            </span>{" "}
            AppChoices mobile app, available at{" "}
            <a href="https://www.youradchoices.com/appchoices" target="_blank" rel="noreferrer">
              https://www.youradchoices.com/appchoices
            </a>
            , which will allow you to opt-out of interest-based ads in mobile apps served by participating members of
            the Digital Advertising Alliance.
          </li>
        </ul>
        <p>You will need to apply these opt-out settings on each device from which you wish to opt-out.</p>
        <p>
          <span className="bold">Do Not Track</span>. Some Internet browsers may be configured to send “Do Not Track”
          signals to the online services that you visit. We currently do not respond to “Do Not Track” or similar
          signals. To find out more about “Do Not Track,” please visit{" "}
          <a href="http://www.allaboutdnt.com" target="_blank" rel="noreferrer">
            http://www.allaboutdnt.com
          </a>
          .
        </p>
        <p>
          <span className="bold">Declining to provide information</span>. We need to collect personal information to
          provide certain services. If you do not provide the information we identify as mandatory, we may not be able
          to provide those services.
        </p>
        <p>
          <span className="bold">Third party platforms</span>. If you choose to connect to the Service through your
          social media account, you may be able to use your settings in your account with that platform to limit the
          information we receive from it. If you revoke our ability to access information from a third party platform,
          that choice will not apply to information that we have already received from that third party.
        </p>
      </div>
      <div className="basicContent">
        <h2>Other Sites and Services</h2>
        <p>
          The Service may contain links to websites, mobile applications, and other online services operated by third
          parties. These links are not an endorsement of, or representation that we are affiliated with, any third
          party. In addition, our content may be included on web pages or in mobile applications or other online
          services that are not associated with us. We do not control websites, mobile applications or online services
          operated by third parties, and we are not responsible for their actions.
        </p>
      </div>
      <div className="basicContent">
        <h2>Security</h2>
        <p>
          We employ a number of technical, organizational and physical safeguards designed to protect the personal
          information we collect. However, security risk is inherent in all internet and information technologies and we
          cannot guarantee the security of your personal information.
        </p>
      </div>
      <div className="basicContent">
        <h2>International data transfer</h2>
        <p>
          We are headquartered in the United States and may use service providers that operate in other countries. Your
          personal information may be transferred to the United States or other locations where privacy laws may not be
          as protective as those in your state, province, or country.
        </p>
      </div>
      <div className="basicContent">
        <h2>Children</h2>
        <p>
          The Service is not intended for use by children under 16 years of age. If we learn that we have collected
          personal information through the Service from a child under 16 without the consent of the child's parent or
          guardian as required by law, we will delete it.
        </p>
      </div>
      <div className="basicContent">
        <h2>Changes to this Privacy Policy</h2>
        <p>
          We reserve the right to modify this Privacy Policy at any time. If we make changes to this Privacy Policy, we
          will notify you by updating the date of this Privacy Policy and posting it on the Service. If required by law
          we will also provide notification of changes in another way that we believe is reasonably likely to reach you,
          such as via email or another manner through the Service. Any modifications to this Privacy Policy will be
          effective upon our posting the modified version (or as otherwise indicated at the time of posting). In all
          cases, your use of the Service after the effective date of any modified Privacy Policy indicates your
          acceptance of the modified Privacy Policy.
        </p>
      </div>
      <div className="basicContent">
        <h2>How to contact us</h2>
        <p>
          <span className="bold">Email:</span>{" "}
          <a href="mailto:ben.leff@realitycheckinsights.com">ben.leff@realitycheckinsights.com</a>
        </p>
        <p>
          <span className="bold">Mail:</span> Verasight, 10 Mountain Ridge Drive, Livingston, NJ 07039, United States
        </p>
        <p>
          <span className="bold">Phone:</span> <a href="tel:+19739547833">(973)954-7833</a>
        </p>
      </div>
    </Box>
  );
};

export default PrivacyPolicy;
