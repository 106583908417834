import { IconButton, Snackbar } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";

type CopyTextProps = {
  copyText?: string;
};

const CopyToClipboardButton = ({ copyText }: CopyTextProps) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(copyText ?? "");
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <ContentCopyIcon />
      </IconButton>
      <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={2000} message="Copied to clipboard" />
    </>
  );
};

export default CopyToClipboardButton;
