import { Paper } from "@mui/material";

interface PaperDefaultProps {
  readonly children: React.ReactNode;
}

export default function PaperDefault({ children }: PaperDefaultProps) {
  return (
    <Paper
      sx={{
        flexGrow: 1,
        marginRight: "76px",
        padding: "24px",
        maxWidth: "1200px",
        width: "100%",
        marginBottom: "40px",
      }}
    >
      {children}
    </Paper>
  );
}
