import { useEffect, useState } from "react";
import SettingsService from "../../../services/api/settingsService";
import { Autocomplete, Box, Checkbox, Divider, Paper, TextField } from "@mui/material";
import { StyledButton } from "./RewardPointsSettings";
import { settingKeys } from "src/types/Settings";
import { AxiosResponse } from "axios";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TargetingTagsService from "../../../services/api/targetingTagsService";
import { TargetingTag } from "../../../types/TargetingTag";

enum saveSettingsCallbackKeys {
  REFERRAL_X_Y_POINTS = "referral-x-y-points",
  RF_WIDGET_URL = "rf-widget-url",
}

const ReferralsTab = () => {
  const [referralXPoints, setReferralXPoints] = useState<number>(0);
  const [referralYPoints, setReferralYPoints] = useState<number>(0);
  const [rfWidgetUrl, setRfWidgetUrl] = useState<string>("");
  const [availableTargetingTags, setAvailableTargetingTags] = useState<TargetingTag[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    SettingsService.getSettings().then((res) => {
      let data: any = {};
      // eslint-disable-next-line
      res.data.map(({ key, payload }: any) => {
        if (payload.value) data[key] = payload.value;
      });
      if (data[settingKeys.REFERRAL_X_POINTS]) {
        setReferralXPoints(data[settingKeys.REFERRAL_X_POINTS]);
      }
      if (data[settingKeys.REFERRAL_Y_POINTS]) {
        setReferralYPoints(data[settingKeys.REFERRAL_Y_POINTS]);
      }
      if (data[settingKeys.RF_WIDGET_URL]) {
        setRfWidgetUrl(data[settingKeys.RF_WIDGET_URL]);
      }
      if (data[settingKeys.REFERRAL_SELECTED_TAGS]) {
        const selectedTags = data[settingKeys.REFERRAL_SELECTED_TAGS] as TargetingTag[];
        setSelectedTags(selectedTags.map((tag) => tag.tag));
      }
    });
  }, []);

  const saveSettings = async (key: saveSettingsCallbackKeys) => {
    setLoading(true);

    try {
      await saveSettingsCallback(key);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const saveSettingsCallback = async (key: saveSettingsCallbackKeys): Promise<AxiosResponse> => {
    switch (key) {
      case saveSettingsCallbackKeys.REFERRAL_X_Y_POINTS:
        return SettingsService.setSettings([
          { key: "referral-x-points", payload: { value: referralXPoints } },
          { key: "referral-y-points", payload: { value: referralYPoints } },
        ]);
      case saveSettingsCallbackKeys.RF_WIDGET_URL:
        return SettingsService.postSetting({
          key: settingKeys.RF_WIDGET_URL,
          payload: { value: rfWidgetUrl },
        });
    }
  };

  useEffect(() => {
    TargetingTagsService.getGlobalTargetingTags({ sort: "tag", order: "1" }).then((res) => {
      setAvailableTargetingTags(res.data);
    });
  }, []);

  const onChangeTags = (event: any, newValue: string[]) => {
    setSelectedTags(newValue);
  };

  const setTags = async () => {
    setLoading(true);

    const tagIds = availableTargetingTags.filter((tag) => selectedTags.includes(tag.tag));

    try {
      await TargetingTagsService.setReferralTags(tagIds);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const notAllowNegative = (value: number) => {
    return value < 0 ? 0 : value;
  };

  return (
    <Paper
      sx={{
        flexGrow: 1,
        marginY: "20px",
        padding: "70px",
        boxShadow: "3px 24px 20px rgba(0, 0, 0, 0.06)",
        borderRadius: "16px",
      }}
    >
      <div>
        <h3 className="subtitle">Point Rewards</h3>

        <div>
          <span className="info-label">
            Points awarded to the referring user when the referral completes their first survey.
          </span>
          <Box className="bonus-points-row">
            <TextField
              variant="outlined"
              size="small"
              type="number"
              value={referralXPoints}
              onChange={(e) => setReferralXPoints(notAllowNegative(+e.target.value))}
            />
          </Box>
        </div>

        <div>
          <span className="info-label">
            Points awarded to the referring user when the referral completes their fifth survey.
          </span>
          <Box className="bonus-points-row">
            <TextField
              variant="outlined"
              size="small"
              type="number"
              value={referralYPoints}
              onChange={(e) => setReferralYPoints(notAllowNegative(+e.target.value))}
            />
          </Box>
        </div>

        <div>
          <StyledButton
            variant="contained"
            onClick={() => saveSettings(saveSettingsCallbackKeys.REFERRAL_X_Y_POINTS)}
            disabled={loading}
          >
            Confirm Change
          </StyledButton>
        </div>
      </div>

      <Divider sx={{ margin: "50px 0 40px", border: "1px solid #dce2e4" }} />

      <div>
        <h3 className="subtitle">Referral-Factory Widget URL</h3>

        <div>
          <Box className="bonus-points-row">
            <TextField
              variant="outlined"
              size="small"
              type="text"
              value={rfWidgetUrl}
              onChange={(e) => setRfWidgetUrl(e.target.value)}
            />
          </Box>
        </div>

        <div>
          <StyledButton
            variant="contained"
            onClick={() => saveSettings(saveSettingsCallbackKeys.RF_WIDGET_URL)}
            disabled={loading}
          >
            Confirm Change
          </StyledButton>
        </div>
      </div>

      <Divider sx={{ margin: "50px 0 40px", border: "1px solid #dce2e4" }} />

      <div>
        <h3 className="subtitle">Tag Picker</h3>

        <div>
          <Box className="bonus-points-row">
            <Autocomplete
              id="tags-select"
              multiple
              options={availableTargetingTags.map((tag: any) => tag.tag)}
              value={selectedTags}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ textTransform: "capitalize" }}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ textTransform: "capitalize" }}
              size="small"
              onChange={onChangeTags}
              renderInput={(params) => <TextField {...params} placeholder="Tags" />}
            />
          </Box>
        </div>

        <div>
          <StyledButton variant="contained" onClick={setTags} disabled={loading}>
            Confirm Change
          </StyledButton>
        </div>
      </div>
    </Paper>
  );
};

export default ReferralsTab;
