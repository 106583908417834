import { Autocomplete, Box, Dialog, TextField } from "@mui/material";
import { TargetingSurveyToUsersEnum } from "../../../../types/Survey";
import VeraButton from "../../../../components/VeraButton";
import CloseIcon from "../../../../assets/icon-close.svg";
import { ChangeEventHandler } from "react";

const CsvDialog = ({
  cancelCsvDialog,
  csvDialogOpen,
  availableTargetingTags,
  csvTagInternal,
  usersFile,
  setUsersFile,
  targetingSurveyToUsers,
  csvDialogOkDisabled,
  setCsvTagInternal,
  setCsvDialogOpen,
  onChangeCsvTag,
}: any) => {
  const closeAndCancelCsvDialog = () => {
    cancelCsvDialog();
    closeCsvDialog();
  };

  const onChangeCsvTagInternal = (_evt: any, newValue: string) => {
    setCsvTagInternal(newValue);
  };

  const closeCsvDialog = () => {
    onChangeCsvTag(null, csvTagInternal);
    setCsvDialogOpen(false);
  };

  const onUploadUsers: ChangeEventHandler<HTMLInputElement> = async (event) => {
    const file = event.target.files?.[0];
    if (file == null) {
      return;
    }
    setUsersFile(file);
  };

  return (
    <Dialog
      onClose={closeCsvDialog}
      open={csvDialogOpen}
      PaperProps={{
        style: { borderRadius: 28 },
      }}
    >
      <div className="dialog-content">
        <Box sx={{ marginBottom: "1rem" }}>
          <Autocomplete
            id="uploadedCsvTag"
            freeSolo
            // we only want survey-specific targeting tags in this list, users are added to global tags elsewhere
            options={availableTargetingTags
              .filter((targetingTag: any) => targetingTag.surveyId)
              .map((targetingTag: any) => targetingTag.tag)}
            getOptionLabel={(option: any) => option || ""}
            renderInput={(params) => <TextField {...params} label="Choose an existing tag or create" />}
            inputValue={csvTagInternal}
            onInputChange={onChangeCsvTagInternal}
          />
        </Box>
        {usersFile && (
          <Box sx={{ marginBottom: "1rem" }}>
            <div className="target-container__uploaded-list">
              <span className="target-container__uploaded-list__text">{usersFile.name}</span>
              <img
                src={CloseIcon}
                alt="close icon"
                className="target-container__csv-remove-icon"
                onClick={() => setUsersFile(undefined)}
              />
            </div>
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <div>
            <div
              className="upload-users-container"
              style={{
                width: "100%",
                justifyContent: "left",
              }}
            >
              <input
                type="file"
                accept={".csv"}
                id="upload-users-button"
                onChange={onUploadUsers}
                onClick={(e: any) => {
                  // without this, if you click the X button to remove
                  // a file, but then choose the same one again, it won't
                  // let you. onChange fires first so we still capture the
                  // file before it's nulled
                  e.target.value = null;
                }}
                style={{ display: "none" }}
                disabled={targetingSurveyToUsers !== TargetingSurveyToUsersEnum.TAG ? true : false}
              />
              <label
                htmlFor="upload-users-button"
                className="upload-users-label"
                style={
                  targetingSurveyToUsers !== TargetingSurveyToUsersEnum.TAG
                    ? { cursor: "default", backgroundColor: "#5f6f74", border: "1px solid #5f6f74" }
                    : {}
                }
              >
                <p>Choose CSV</p>
              </label>
            </div>
          </div>
        </Box>

        <div className="buttons-wrapper">
          <VeraButton variant="text" onClick={closeAndCancelCsvDialog} sx={{ border: "none" }}>
            Cancel
          </VeraButton>
          <VeraButton
            disabled={csvDialogOkDisabled}
            variant="outlined"
            onClick={closeCsvDialog}
            className="dialog-upload-button"
            style={csvDialogOkDisabled ? { cursor: "default", backgroundColor: "#aaadab", color: "#ffffff" } : {}}
          >
            Ok
          </VeraButton>
        </div>
      </div>
    </Dialog>
  );
};

export default CsvDialog;
