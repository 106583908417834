import princeton from "../../assets/princeton.png";
import michigan from "../../assets/michigan.jpeg";
import cornell from "../../assets/cornell.png";
import cnn from "../../assets/cnn.png";
import bloomberg from "../../assets/bloomberg.png";
import stanford from "../../assets/stanford.png";
import duke from "../../assets/duke.png";
import dartmouth from "../../assets/dartmouth.png";

import "./SignUpSideResearchers.scss";

const images = [cnn, bloomberg, stanford, duke, dartmouth, cornell, princeton, michigan];

const SignUpSideResearchers = () => {
  return (
    <div className="carousel">
      <div className="carousel-wrapper">
        <p>Verasight is trusted by leading researchers at:</p>

        <ul>
          {images.map((image) => (
            <li key={image}>
              <img src={image} alt="logo" />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SignUpSideResearchers;
