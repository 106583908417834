import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import { TargetingTag } from "../../../../types/TargetingTag";
import { useEffect, useState } from "react";

type ExcludeTagsInputProps = {
  excludeGlobalTags: boolean;
  allTargetingTags: TargetingTag[];
  excludedTargetingTags: TargetingTag[];
  setExcludedTargetingTags: (excludedTargetingTags: TargetingTag[]) => void;
};

function ExcludeTagsInput({
  excludeGlobalTags,
  allTargetingTags,
  excludedTargetingTags,
  setExcludedTargetingTags,
}: ExcludeTagsInputProps) {
  const [globalTargetingTags, setGlobalTargetingTags] = useState<TargetingTag[]>([]);

  useEffect(() => {
    setGlobalTargetingTags(allTargetingTags.filter((tag) => !tag.surveyId));
  }, [allTargetingTags]);

  const onChangeExcludedTargetingTags = (_evt: any, newValue: any, reason: string) => {
    if (reason === "clear") {
      setExcludedTargetingTags([]);
    } else {
      setExcludedTargetingTags(newValue);
    }
  };

  const onTagRemove = (e: Event, tagObj: TargetingTag) => {
    const newValue = excludedTargetingTags.filter((targetingTag: TargetingTag) => targetingTag.tag !== tagObj.tag);
    setExcludedTargetingTags(newValue);
  };

  return (
    <Box>
      <Autocomplete
        multiple
        disabled={!excludeGlobalTags}
        id="exclude-tags"
        options={globalTargetingTags}
        getOptionLabel={(option) => option.tag}
        isOptionEqualToValue={(option, value) => option.tag === value.tag}
        value={excludedTargetingTags}
        renderTags={() => null}
        renderInput={(params) => <TextField {...params} id={`${params}`} placeholder="Enter the tag" size="small" />}
        size="small"
        onChange={onChangeExcludedTargetingTags}
      />
      {excludeGlobalTags && (
        <div>
          {excludedTargetingTags.map((option: any) => (
            <Chip
              disabled={!excludeGlobalTags}
              variant="filled"
              id={option._id}
              key={option._id}
              size="small"
              label={`#${option.tag}`}
              onDelete={(e) => onTagRemove(e, option)}
            />
          ))}
        </div>
      )}
    </Box>
  );
}

export default ExcludeTagsInput;
