export const SearchParams = {
  createdBy: "createdBy",
  creatorName: "creatorName",
  clientSecret: "clientSecret",
  sessionId: "session_id", //stripe sends it, so dont change
  successMessage: "successMessage",
  errorMessage: "error_message",
};

const attachQuery = (path: string, query?: Record<string, string>) => {
  if (query) {
    return `${path}?${new URLSearchParams(query).toString()}`;
  }
  return path;
};

export const AppPaths = {
  researcher: {
    pathSegment: "researcher",
    surveys: {
      pathSegment: "surveys",
      edit: (id: string, query?: Record<string, string>) => {
        const path = `/researcher/surveys/${id}`;
        return attachQuery(path, query);
      },
    },
    profile: {
      pathSegment: "profile",
    },
  },
  admin: {
    pathSegment: "admin",
    researchers: {
      pathSegment: "researchers",
      list: `/admin/researchers`,
    },
  },
};
