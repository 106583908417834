import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "@mui/material";
import ProgressBanner from "../ProgressBanner/ProgressBanner";
import verasightLogo from "../../assets/landing-page/verasight-logo-community.svg";
import pointsIcon from "../../assets/icon-points.svg";
import LogoutIcon from "@mui/icons-material/Logout";
// import { EventSourcePolyfill } from "event-source-polyfill";

import "./PublicNavLayout.scss";
import { logoutAction, AuthContext } from "src/contexts/authContext";
import { clickCashoutButton, clickLogout, visitSurveyHome } from "../../helpers/dataLayers";
import { useMobileSize } from "../../hooks/useMobileSize";
import CountUp from "react-countup";

const pages = [{ title: "Cash Out", path: "profile/rewards" }];

const PublicNavMenu = (props: any) => {
  const { setIsProfileUrl } = props;
  const navigate = useNavigate();

  const { user, refreshUser } = useContext(AuthContext);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const { isMobile, isProfileUrl } = useMobileSize();
  // const [listening, setListening] = React.useState(false);

  // const baseURL = process.env.REACT_APP_API_BASE_URL;
  // const port = process.env.REACT_APP_API_PORT;
  // const environment = process.env.NODE_ENV;
  // const serverUrl = environment !== "development" ? `https://${baseURL}` : `${baseURL}:${port}`;

  // React.useEffect(() => {
  //   if (listening) {
  //     return;
  //   }

  //   const asyncCall = async () => {
  //     const token = await getData("idToken");
  //     const sseUrl = `${serverUrl}/sse/events/${user?._id}`;
  //     const events = new EventSourcePolyfill(sseUrl, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //     events.onmessage = (event) => {
  //       const sseData = JSON.parse(event.data);
  //       const message = sseData["0"].data ? sseData["0"].data.message : "";
  //       if (message === "success" && refreshUser) {
  //         refreshUser(false);
  //       }
  //     };
  //     setListening(true);
  //   };
  //   asyncCall().catch((e) => console.error(e));
  // }, [listening]);

  React.useEffect(() => {
    if (refreshUser && !user) {
      refreshUser(false);
    }
  }, [refreshUser, user]);

  const onLogoutClick = async () => {
    await logoutAction();
    window.location.reload();
    clickLogout();
  };

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (path: string) => {
    setAnchorElNav(null);
    navigate(path);
    if (path.includes("profile")) {
      setIsProfileUrl(true);
    }
    if (path === "profile/rewards") {
      clickCashoutButton();
    }
  };

  const goHome = () => {
    navigate("/");
    visitSurveyHome();
  };
  const points = (user?.totalPoints || 0) - (user?.redeemedPointsSum || 0);
  const dollars = user?.availableDollars || 0;

  if (isMobile && isProfileUrl) {
    return null;
  }

  return (
    <div className="nav-wrapper">
      <div className="logo-wrapper">
        <img src={verasightLogo} alt="Verasight Logo" onClick={() => goHome()} />
      </div>

      <Box className="nav-menu-items-wrapper">
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={() => handleCloseNavMenu("")}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          {pages.map((page) => (
            <MenuItem key={page.title} onClick={() => handleCloseNavMenu(page.path)}>
              <Typography textAlign="center">{page.title}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Box className="nav-menu-items-wrapper-desktop">
        {pages.map((page) => (
          <Link key={page.title} component={RouterLink} to={page.path} underline="none">
            <p onClick={() => handleCloseNavMenu(page.path)} className="nav-menu-item">
              {page.title}
            </p>
          </Link>
        ))}
      </Box>

      <ProgressBanner handleCloseNavMenu={handleCloseNavMenu} />

      <Link underline="none" data-testid="user-points-btn">
        <div onClick={() => handleCloseNavMenu("profile/rewards")} className="conversion-points-label">
          <img src={pointsIcon} alt="Points" />
          <p>
            <b>
              <CountUp
                end={Math.round(points)}
                delay={0.2}
                duration={1.5}
                separator=","
                useEasing={true}
                preserveValue={true}
              />
            </b>{" "}
            /{" "}
            <span>
              <CountUp end={dollars} delay={0.2} prefix="$" decimals={2} useEasing={true} preserveValue={true} />
            </span>
          </p>
        </div>
      </Link>

      <div className="logout-wrapper" onClick={() => onLogoutClick()}>
        <LogoutIcon />
        <span>Logout</span>
      </div>
    </div>
  );
};
export default PublicNavMenu;
