import { SurveySource } from "./Survey";

type UserSettings = {
  minSurveysForBonus: number;
  pointsPerBonuts: number;
  pointToUSDConversion: number;
};

export enum RedeemStatusEnum {
  PENDING = "pending",
  SUCCESS = "success",
  FAILED = "failed",
}

export type UserActivity = {
  date: Date;
  event: keyof typeof UserActivityEvents;
  summary: string;
  reference?: string;
  points?: number;
  status?: RedeemStatusEnum;
  error?: string;
  surveyTitle?: string;
  runningTotal: number;
};

export enum Gender {
  FEMALE = "female",
  MALE = "male",
  OTHER = "other",
}

export type RedeemedPoints = {
  amount: number;
  createdAt: string;
  batchPayoutLink?: string;
  method?: "phone" | "email";
  vendor?: "paypal" | "venmo";
  error?: string;
};

export type CompletedSurvey = {
  survey: string;
  points: number;
  source: SurveySource;
  createdAt: string;
  spinnerBonus: number;
};

export enum UserRoles {
  USER = "user",
  SUPER_ADMIN = "super_admin",
  RESEARCHER = "researcher",
}

export type TargetingTag = {
  _id: string;
  tag: string;
  surveyId?: string;
};

export type User = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  image?: string;
  organization?: string;
  role: UserRoles;
  dob: string;
  gender: Gender;
  address: { zip: string };
  phone: string;
  active: boolean;
  interests: unknown[];
  points: unknown[];
  completedSurveys: CompletedSurvey[];
  redeemedPoints: RedeemedPoints[];
  completedSurveyCount: number;
  redeemedPointsSum: number;
  settings?: UserSettings;
  totalPoints: number;
  availableDollars: number;
  lastActive: unknown;
  currentCompletedSurveysCounter: number;
  payPalEmail?: string;
  venmoPhone?: string;
  giftCardEmail?: string;
  referralAvailable?: boolean;
  targetingTagsList?: TargetingTag[];
};

export type VerifyCaptchaType = {
  token: string;
};

export type CheckIsPhoneNumberVoIPType = {
  phoneNumber: string;
};

export enum UserActivityEvents {
  survey_completion = "Survey Completion",
  points_awarded = "Points Awarded",
  cash_out = "Cash Out",
  referral_bonus = "Referral Bonus",
}
