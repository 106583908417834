import React from "react";
import { Alert, Box, Grid, IconButton, InputAdornment, OutlinedInput, Stack } from "@mui/material";
import VeraButton from "../VeraButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CheckIcon from "@mui/icons-material/Check";
import { useValidPasswordAdvance } from "../../hooks/useValidPasswordAdvance";

import "./ChangePasswordForm.scss";
import { Auth } from "aws-amplify";
import { CognitoUser } from "@aws-amplify/auth";
import { isError } from "lodash";

const ChangePasswordForm = (props: any) => {
  const { isDialog, hasCancelOption, onCancel } = props;

  const [currentPassword, setCurrentPassword] = React.useState("");
  const { passwordAdvance, setPasswordAdvance, passwordAdvanceIsValid } = useValidPasswordAdvance(null);
  const [confirmPass, setConfirmPass] = React.useState("");
  const [confirmPassError, setConfirmPassError] = React.useState("");
  const [showPassword, setShowPassword] = React.useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [alertMessage, setAlertMessage] = React.useState<string>("");
  const currentPasswordLabel = "Current Password";

  const handleClickShowPassword = (name: "current" | "new" | "confirm") => {
    setShowPassword({ ...showPassword, [name]: !showPassword[name] });
  };

  const handleCancel = () => {
    setPasswordAdvance("");
    setConfirmPass("");
    onCancel();
  };

  const onComparePassword = (confirmPass: string) => {
    if (!confirmPass) {
      setConfirmPassError("");
      return;
    }
    if (confirmPass !== passwordAdvance) {
      setConfirmPassError("Both passwords must match");
    } else {
      setConfirmPassError("Passwords match");
    }
  };

  const changePassword = async (e: any) => {
    e.stopPropagation();
    if (!currentPassword || !passwordAdvance) return;
    setCurrentPassword("");
    setPasswordAdvance("");
    setConfirmPass("");

    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    const result = await Auth.changePassword(user, currentPassword, passwordAdvance).catch((e: Error) => e);
    if (isError(result)) {
      // result.message is too generic - incorrect username or password
      // since we do not use username is is a misleading message
      alert("There was an error changing your password. Please verify and try again.");
      throw result;
    } else {
      setAlertMessage("Password changed successfully");
    }
  };

  React.useEffect(() => {
    onComparePassword(confirmPass);
    // eslint-disable-next-line
  }, [confirmPass, passwordAdvance]);

  return (
    <div className="change-password-form">
      {alertMessage ? (
        <Stack sx={{ width: "100%", margin: "-20px 0 40px" }}>
          <Alert variant="filled" severity="success" onClose={() => setAlertMessage("")}>
            {alertMessage}
          </Alert>
        </Stack>
      ) : null}

      <Box className="input-wrapper">
        <p className="label">{currentPasswordLabel}</p>
        <OutlinedInput
          size="small"
          placeholder={currentPasswordLabel}
          type={showPassword.current ? "text" : "password"}
          className="input-container"
          onChange={(e: any) => setCurrentPassword(e.target.value)}
          value={currentPassword || ""}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                tabIndex={-1}
                aria-label="toggle password visibility"
                onClick={() => handleClickShowPassword("current")}
                edge="end"
              >
                {showPassword.current ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </Box>

      <Box className="new-password-inputs-wrapper">
        <Box className="input-wrapper">
          <p className="label">New Password</p>
          <OutlinedInput
            size="small"
            placeholder="New Password"
            type={showPassword.new ? "text" : "password"}
            className="input-container"
            error={
              !(
                passwordAdvanceIsValid[0].text === "Password Strength: Good" ||
                passwordAdvanceIsValid[0].text.length === 0
              )
            }
            onChange={(e: any) => setPasswordAdvance(e.target.value)}
            value={passwordAdvance || ""}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("new")}
                  edge="end"
                >
                  {showPassword.new ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>

        <Box className="input-wrapper">
          <p className="label">Confirm Password</p>
          <OutlinedInput
            size="small"
            placeholder="Confirm Password"
            type={showPassword.confirm ? "text" : "password"}
            className="input-container"
            error={
              !(
                (passwordAdvanceIsValid[0].text === "Password Strength: Good" &&
                  confirmPassError === "Passwords match") ||
                passwordAdvanceIsValid[0].text.length === 0
              )
            }
            onChange={(e: any) => setConfirmPass(e.target.value)}
            value={confirmPass || ""}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  tabIndex={-1}
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("confirm")}
                  edge="end"
                >
                  {showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Box>
      </Box>
      <Grid className="checklist">
        {passwordAdvanceIsValid
          ? // eslint-disable-next-line
            passwordAdvanceIsValid.map((item: any, index: number) => {
              if (item.text)
                return (
                  <p key={index} className={item.active ? "active" : ""}>
                    <CheckIcon />
                    {item.text}
                  </p>
                );
            })
          : null}

        {confirmPassError ? (
          <p key={"confirm-pass-error"} className={confirmPassError === "Passwords match" ? "active" : ""}>
            <CheckIcon />
            {confirmPassError}
          </p>
        ) : null}
      </Grid>

      <div className={isDialog ? "buttons-wrapper dialog" : "buttons-wrapper"}>
        {hasCancelOption && (
          <VeraButton variant="text" onClick={handleCancel} sx={{ border: "none" }}>
            Cancel
          </VeraButton>
        )}
        <VeraButton
          variant="outlined"
          onClick={changePassword}
          className={isDialog ? "dialog-save-button" : "large-save-button"}
          disabled={
            !(
              currentPassword &&
              passwordAdvanceIsValid[0].text === "Password Strength: Good" &&
              confirmPassError === "Passwords match"
            )
          }
        >
          Save Changes
        </VeraButton>
      </div>
    </div>
  );
};

export default ChangePasswordForm;
