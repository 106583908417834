import { TimeUnitEnum } from "./types";

export const timeUnit2Hours = ({ unit, time }: { unit: TimeUnitEnum; time: number }): number => {
  switch (unit) {
    case TimeUnitEnum.Days:
      return time * 24;
  }
  return time;
};

export const hours2TimeUnit = ({ unit, hour }: { unit: TimeUnitEnum; hour: number }): number => {
  switch (unit) {
    case TimeUnitEnum.Days:
      return hour / 24;
  }
  return hour;
};
