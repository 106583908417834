import React, { useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import { styled } from "@mui/material";
import Button from "@material-ui/core/Button/Button";
import { EventSourcePolyfill } from "event-source-polyfill";

import { AuthContext, getData } from "../../../contexts/authContext";
import UsersService from "../../../services/api/usersService";
import { emailsAllowedToCreateTestUsers } from "../../../components/AdminNavMenu/AdminNavMenu";
import { useNavigate } from "react-router-dom";

type TestUsersResponceType = { status: string, email: string, reason?: any }

const TestUsers = () => {
  const { user } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [createdUsers, setCreatedUsers] = React.useState<Array<any>>([]);
  const [ listening, setListening ] = React.useState<boolean>(false);
  const [ infoText, setInfoText ] = React.useState<string>('');
  const textStatus = useRef('');

  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const port = process.env.REACT_APP_API_PORT;
  const environment = process.env.NODE_ENV;
  const serverUrl = environment !== "development" ? `https://${baseURL}` : `${baseURL}:${port}`;
  
  useEffect( () => {
    if (listening) {
      return;
    }

    const asyncCall = async () => {
      const token = await getData("idToken");
      const sseUrl = `${serverUrl}/sse/events/${user?._id}`;
      const events = new EventSourcePolyfill(sseUrl ,{
        headers : {
          Authorization : `Bearer ${token}`,
        }
      });
      events.onmessage = (event) => {
        const sseData = JSON.parse(event.data);
        const receivedResult = sseData['0'].data.data;
        
        if (typeof receivedResult === 'object' && receivedResult.hasOwnProperty('createResult')) {
          const created = receivedResult.createResult.map((el: TestUsersResponceType) => {
            if (typeof el !== 'string') {
              el.reason = JSON.stringify(el.reason);
            }

            return el;
          })
          setCreatedUsers(created);
          if (created.length > 0) {
            document.getElementById('downloadCSV')?.click();
          }
          setInfoText('');
        }

        if (typeof receivedResult === 'object' && receivedResult.hasOwnProperty('deleteResult')) {
          const deleted = receivedResult.deleteResult.map((el: TestUsersResponceType) => {
            if (el.reason) {
              el.reason = JSON.stringify(el.reason);
            }

            return el;
          })
          setCreatedUsers(deleted);
          if (deleted.length > 0) {
            document.getElementById('downloadCSV')?.click();
          }
          if (textStatus.current !== 'Creating') {
            setInfoText('');
          }
        }
      };
      setListening(true);
    }
    asyncCall().catch((e) => console.error(e));

    return;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening]);

  useEffect(() => {
    textStatus.current = infoText;
  }, [infoText]);

  if (!user || !emailsAllowedToCreateTestUsers.includes(user?.email || '')) {
    navigate("/");
    return null;
  }

  const sleep = async (sleepTime: number) => {
    return new Promise((res) => setTimeout(() => res(1), sleepTime));
  };

  const createTestUsers = async () => {
    try {
      const numberOfUsers = prompt('How many test users do you want to create?', '0');
      if (!Number(numberOfUsers)) {
        alert(`It's not a number! Cancel operation!`);
        return;
      }
      setInfoText('Creating');
      await UsersService.deleteGeneratedTestUsers();
      await sleep(30_000);
      await UsersService.generateTestUsers({ numberOfUsers: Number(numberOfUsers) });
    } catch (err) {
      console.log('Error in GENERATE TEST USERS >>>>', err);
    }
  };

  const deleteTestUsers = async () => {
    try {
      setInfoText('Deleting');
      await UsersService.deleteGeneratedTestUsers();
    } catch (err) {
      console.log('Error in DELETE TEST USERS >>>>', err);
    }
  };

  return (
      <div style={{padding: '20px'}}>
        <h3>
          Instruction:
        </h3>
        <p>On this page, you can create the required number of test users.
          <br/>
          Important: Creating a large number of users takes a long time due to AWS restrictions (for example, creating 1000 users can take 4:30 - 5 minutes, deleting 1000 users can take 3 - 4 minutes).
          <br/>After you clicked on the CREATE (DELETE) button, <span style={{ fontWeight: 'bold', fontSize: 18 }}>DO NOT LEAVE THIS PAGE</span> - as soon as the required number of users is created (deleted), 
          a CSV file will be automatically downloaded, this list will also be displayed on this page with the status of the result of the operation.
          <br/>If there is an error in the status / results, please report it to the developer.
        </p>
        <p>Due to the limitations associated with phone number validation, you cannot create test users multiple times in a row.<br/>
          First, the old test users will be deleted (if you have not deleted them before, the operation time will increase accordingly). 
        <br/>Example: if you created 100 test users and decided to create 200 more, the first batch (100 users) will be deleted first, and then a request to create 200 users will be executed</p>
        <p>It is a good practice to delete all users after you complete testing.</p>

        <div>
          <StyledButton onClick={createTestUsers} disabled={!!infoText} style={{ margin: '0 50px' }}>
            CREATE
          </StyledButton>
          <StyledButton onClick={deleteTestUsers} disabled={!!infoText} style={{ margin: '0 50px' }}>
            DELETE
          </StyledButton>
        </div>
        {!!infoText && <h5>{`${infoText} users, it will take a few minutes, please wait.`}</h5>}
        <CSVLink id="downloadCSV" download={'result.csv'} style={{ display: 'none' }} data={createdUsers}>
          Download me
        </CSVLink>
        <div>
          {createdUsers.map((usr, i) => {
            return (
              <p key={usr.email}>
                {`${i + 1}) ${usr.email}, result: ${usr.status}`}
              </p>
            )
          })}
        </div>
      </div>
  )
}

export default TestUsers;

const StyledButton = styled(Button)({
  textTransform: "capitalize",
  backgroundColor: "#2EBDEE",
  border: "1px solid #2EBDEE",
  borderRadius: "4px",
  padding: "5px 40px",

  "&:hover": {
    background: "#2EBDEE",
    opacity: "0.7",
  },

  "&:disabled": {
    color: "#91A3A9",
    backgroundColor: "#DCE2E4",
    border: "1px solid #DCE2E4",
  },
});