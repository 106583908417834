import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { MainButton } from "../../../components/VeraButton";
import { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TablePagination, TableRow } from "@mui/material";
import { InvitationCode, InvitationCodeStatusEnum } from "../../../types/InvitationCode";
import { convertDatetimeStringToReadable } from "../../../helpers/date";
import InvitationsService from "../../../services/api/invitationsService";
import InvitationCodesFilter from "./InvitationCodesFilter";
import useSortableData from "../../../hooks/useSortableData";
import usePagination from "../../../hooks/usePagination";
import TableHeadSortable from "../../../components/common/TableHeadSortable";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";

const tableHeadCells = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "shortCode",
    label: "Short Code",
  },
  {
    id: "createdAt",
    label: "Created",
  },
  {
    id: "expireDate",
    label: "Expires",
  },
  {
    id: "discount",
    label: "Discount",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "delete",
    label: "Delete",
  },
];

const ROWS_PER_PAGE_OPTIONS = [10, 20, 30];

type InvitationCodePagingParamsType = {
  page?: number;
  limit?: number;
  sort?: string;
  order?: number;
};

export type InvitationCodeFiltersType = {
  statuses?: InvitationCodeStatusEnum[];
  createdAtAfterDate?: string | null;
  createdAtBeforeDate?: string | null;
  search?: string;
};

export type GetInvitationCodeParamsType = InvitationCodePagingParamsType & InvitationCodeFiltersType;

const InvitationsList = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [invitations, setInvitations] = useState<InvitationCode[]>([]);

  const [totalCount, setTotalCount] = useState<number>(0);

  const [filters, setFilters] = useState<InvitationCodeFiltersType>({});
  const [search, setSearch] = useState<string>("");

  const { page, setPage, rowsPerPage, handleChangePage, handleChangeRowsPerPage } = usePagination(
    ROWS_PER_PAGE_OPTIONS[0]
  );
  const { orderBy, order, handleRequestSort } = useSortableData(setPage);

  async function fetchInvitationCodes() {
    setIsLoading(true);

    const params: GetInvitationCodeParamsType = {
      page,
      limit: rowsPerPage,
      sort: orderBy,
      order: order === "asc" ? 1 : -1,
      ...(filters.statuses && { statuses: filters.statuses }),
      ...(filters.createdAtAfterDate && { createdAtAfterDate: filters.createdAtAfterDate }),
      ...(filters.createdAtBeforeDate && { createdAtBeforeDate: filters.createdAtBeforeDate }),
      ...(search && { search }),
    };

    const res = await InvitationsService.getInvitationCodes(params);
    setInvitations(res.data.docs);
    setTotalCount(res.data.total);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchInvitationCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, orderBy, filters, rowsPerPage, search]);

  const deleteInvitationCode = async (id: string) => {
    const confirmed = window.confirm("Are you sure you want to delete this invitation code?");

    if (!confirmed) return;

    try {
      await InvitationsService.deleteInvitationCode(id);

      enqueueSnackbar("Invitation code deleted successfully.", { variant: "success" });

      await fetchInvitationCodes();
    } catch (error) {
      enqueueSnackbar("Something went wrong.", { variant: "error" });
    }
  };

  return (
    <div className="content-wrapper">
      <div className="header">
        <div className="text-wrapper">
          <p className="title">Invitations</p>
          <p className="subtitle">Showing {invitations.length} invitation codes</p>
        </div>

        <Stack className="buttons-wrapper" direction="row" spacing={2}>
          <MainButton variant="outlined" color="primary" onClick={() => navigate(`/admin/invitations/new`)}>
            Create
          </MainButton>
        </Stack>
      </div>

      <InvitationCodesFilter setPage={setPage} setFilters={setFilters} setSearch={setSearch} />

      {isLoading ? (
        "Loading..."
      ) : (
        <Table>
          <TableHeadSortable
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={tableHeadCells}
          />
          <TableBody style={{ borderRadius: "16px" }}>
            {invitations.map((invitation) => (
              <TableRow key={invitation._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <TableCell>{invitation._id}</TableCell>
                <TableCell component="th" scope="row">
                  {invitation.shortCode}
                </TableCell>
                <TableCell>{convertDatetimeStringToReadable(invitation.createdAt.toString())}</TableCell>
                <TableCell>{convertDatetimeStringToReadable(invitation.expireDate?.toString() ?? "")}</TableCell>
                <TableCell>{invitation.discount}</TableCell>
                <TableCell>{invitation.status}</TableCell>
                <TableCell>
                  {invitation.status === InvitationCodeStatusEnum.Active && (
                    <button
                      onClick={() => deleteInvitationCode(invitation._id)}
                      onKeyDown={(e) => {
                        if (e.key === "d" || e.key === "D") {
                          deleteInvitationCode(invitation._id);
                        }
                      }}
                    >
                      <DeleteIcon sx={{ cursor: "pointer" }} />
                    </button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <TablePagination
        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default InvitationsList;
