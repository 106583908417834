import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

import ReplayIcon from "@mui/icons-material/Replay";

import { SurveyStateEnum, SurveyType } from "src/types/Survey";
import { UserRoles } from "src/types/User";
import { IconButton, TableCell, Tooltip } from "@mui/material";

type Props = {
  isResearcher: boolean;
  retryPayment: (id: string) => void;
  survey: Pick<SurveyType, "createdByRole" | "state" | "_id">;
};

const PaymentStatus = ({ isResearcher, retryPayment, survey: { createdByRole, state, _id } }: Props) => {
  if (createdByRole !== UserRoles.RESEARCHER) {
    return null;
  }

  const PendingIcon = () =>
    isResearcher ? (
      <IconButton data-testid="icon-retry-payment" onClick={() => retryPayment(_id)} aria-label="Retry Payment">
        <Tooltip title="Retry payment">
          <ReplayIcon color="warning" />
        </Tooltip>
      </IconButton>
    ) : (
      <IconButton aria-label="Payment Pending">
        <Tooltip title="payment Pending">
          <HourglassEmptyIcon color="warning" />
        </Tooltip>
      </IconButton>
    );

  return (
    <TableCell>
      {state !== SurveyStateEnum.PaymentPending ? (
        <IconButton>
          <CheckCircleIcon color="success" />
        </IconButton>
      ) : (
        <PendingIcon />
      )}
    </TableCell>
  );
};

export default PaymentStatus;
