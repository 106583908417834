export enum EmailTemplateParameterEnum {
  email = "email",
  firstName = "firstName",
  lastName = "lastName",
  loginUrl = "loginUrl",
}

export interface ScheduledEmailTemplateMeta {
  templateId: string;
  parameters: EmailTemplateParameterEnum[];
}

export enum TimeUnitEnum {
  Days = "Days",
  Hours = "Hours",
}

export interface ScheduledEmailConfig {
  hourSince: number;
  timeUnit: TimeUnitEnum;
  description?: String;
  enabled?: boolean;
  templateMeta: ScheduledEmailTemplateMeta;
}

export interface ScheduledEmailSetting {
  noSurveySinceSignup?: ScheduledEmailConfig;
  signup?: ScheduledEmailConfig;
}
