const surveyEndpoints = {
  surveys: "/surveys",
  surveysFeed: "/surveys/feed",
  querySurveys: "/surveys/query",
  survey: (id: string) => `/surveys/${id}`,
  surveyNotificationLogs: (id: string) => `/surveys/${id}/notification-logs`,
  externalSurveyRedirectUrl: (id: string) => `/surveys/${id}/external`,
  cancelScheduledNotification: (id: string, scheduleId: string) =>
    `/surveys/${id}/notification-schedules/${scheduleId}`,
  confirmPayment: (id: string) => `/surveys/${id}/confirm-payment`,
  syncSurveys: "/surveys/sync",
  getPaymentLink: (id: string) => `/surveys/${id}/payment-link`,
  likeSurvey: (id: string) => `/surveys/${id}/like`,
  unlikeSurvey: (id: string) => `/surveys/${id}/unlike`,
  removeSurveyImage: (id: string) => `/surveys/${id}/remove-image`,
  uploadUsers: (id: string) => `/surveys/${id}/target-users`,
  newSurveyNotification: (id: string) => `/surveys/${id}/send-new-survey-notification`,
  clearTargetUsers: (id: string) => `/surveys/${id}/clear-target-users`,
  getAvailableUsers: (id: string) => `/surveys/${id}/available-users`,
  downloadRedirectParams: (id: string) => `/surveys/${id}/download/user-redirect-params`,
  reportUrl: (id: string) => `/surveys/${id}/report-url`,
  downloadAvailableUsers: (id: string) => `/surveys/${id}/available-users/download`,
  pinSurvey: (id: string) => `/surveys/${id}/pin`,
  unpinSurvey: (id: string) => `/surveys/${id}/unpin`,
  csvUploadStatus: (id: string) => `/surveys/${id}/csv-upload-status`,
  surveysSyncStatus: "/surveys/sync/status",
};

const userEndpoints = {
  users: "/users",
  user: (id: string) => `/users/${id}`,
  userInterests: (id: string) => `/users/${id}/interests`,
  userInvite: (ref: string) => `/users/invite/${ref}`,
  userData: "/users/me",
  redeemPoints: "/users/me/redeem-points",
  userActivity: "/users/activity",
  userAddPoints: (id: string) => `/users/${id}/add-points`,
  addPointToBatchOfUsers: "/users/add-points-batch-users",
  usersBulkDelete: "/users/bulk-delete",
  verifyBatchOfUsers: "/users/verify-batch-users",
  completeSurvey: (id: string) => `/users/complete-survey/${id}`,
  uncompleteSurvey: (id: string) => `/users/uncomplete-survey/${id}`,
  registerCognitoUser: "/users/cognito-register",
  checkIfPhoneOrEmailExist: (phone: string, email: string) => `/users/${phone}/${email}`, //Stashed changes
  usersCSV: "/users/csv",
  isUserDisabled: (id: string) => `/users/is-disabled/${id}`,
  generateTestUsers: "/users/bulk-generate",
  deleteGeneratedTestUsers: "/users/bulk-remove",
  qualtricsApiToken: (userId: string) => `/users/${userId}/qualtrics-api-token`,
};

const targetingTagsEndpoints = {
  targetingTags: "/targeting",
  globalTargetingTags: "/targeting/global",
  removeTargetingTagFromUser: (targetingTagId: string) => `/targeting/${targetingTagId}/remove-from-user`,
  setReferralTags: "/targeting/set-referral-tags",
  surveyTargetingTags: (surveyId: string) => `/targeting/survey/${surveyId}`,
  addTagsToBatchOfUsers: "/targeting/add-tags-batch-users",
  utmTargetingTags: "/targeting/utm",
  createUtmTag: "/targeting/utm/create",
};

const settingsEndpoints = {
  settings: "/settings",
  setSettings: "/settings/set",
  setting: (key: string) => `/settings/${key}`,
};

const paymentsEndpoints = {
  prices: "payments/prices",
};

const emailEndpoints = {
  emailContactus: "/email/contact-us",
  emailTemplates: "/email/templates",
  emailTemplate: (id: string) => `/email/templates/${id}`,
};

const authEndpooints = {
  authenticatedUser: "/auth/authenticated-user",
  verifyCaptcha: "/auth/verify-captcha",
  checkIsPhoneNumberVoIP: "/auth/is-voip-number",
};

const invitationsEndpoints = {
  generateInvitations: "/invitations/generate",
  getAppliedInviteCode: "/invitations/applied",
  validateDiscount: "/invitations/validate-discount",
  invitationCodes: "/invitations",
  invitationCode: (id: string) => `/invitations/${id}`,
  validateInvitation: "/invitations/validate",
};

const endpoints = {
  ...surveyEndpoints,
  ...userEndpoints,
  ...targetingTagsEndpoints,
  ...settingsEndpoints,
  ...emailEndpoints,
  ...authEndpooints,
  ...invitationsEndpoints,
  ...paymentsEndpoints,
  tags: "/tags",
  interests: "/interests",
  completeSurveys: "/user-surveys/completed",
  apiStatus: "/healthz/status",
};

export default endpoints;
