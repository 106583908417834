import "./ContactUsButton.scss";
import contactUsIcon from "src/assets/icon-contact-us.svg";

import { MouseEventHandler } from "react";
type Props = {
  onClick: MouseEventHandler<any> | undefined;
};
const ContactUsButton = ({ onClick }: Props) => {
  return (
    <div className="contact-us-button" onClick={onClick}>
      <img src={contactUsIcon} alt="ContactUs" />
    </div>
  );
};

export default ContactUsButton;
