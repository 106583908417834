import DoNotDisturbAltIcon from "@mui/icons-material/DoNotDisturbAlt";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const DialogIcon = (props: any) => {
    const { open } = props;
    
    let icon = null;
    let iconBackgroundColor = '#FFFFFF';

    if (!open) return null;
    
    if (open === "disable" || open === "delete") {
        icon = <DoNotDisturbAltIcon className="disable-icon" />;
        iconBackgroundColor = '#ec3a5a';
    } else if (open === 'addTagsToBatchOfUserSubmitted' || open === 'addPointsToBatchOfUserSubmitted') {
        icon = <CheckCircleIcon className="disable-icon" />;
        iconBackgroundColor = '#27C9A1';
    } else {
        icon = <WarningAmberIcon className="disable-icon" />;
    }
    
    return (
        <div className="dialog-icon-container" style={{backgroundColor: iconBackgroundColor}}>
            <div className="dialog-icon-wrapper">
                {icon}
            </div>
        </div>
    );
}

export default DialogIcon;