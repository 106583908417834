import { useEffect, useState } from "react";
import UsersService from "../../../../services/api/usersService";
import {
  Button,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { convertDatetimeStringToReadable } from "../../../../helpers/date";
import "./UserActivityTab.scss";
import { RedeemStatusEnum, UserActivity, UserActivityEvents } from "../../../../types/User";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";

type props = {
  userId?: string;
};

const UserActivityTab = ({ userId }: props) => {
  const [allActivities, setAllActivities] = useState<UserActivity[]>([]);
  const [page, setPage] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [expandError, setExpandError] = useState<Record<number, boolean>>({});
  const [totalPages, setTotalPages] = useState<number>();

  const fetchActivities = async (page: number) => {
    setLoading(true);
    const activities = await UsersService.getUserActivity({ page, userId });
    setLoading(false);
    setAllActivities([...allActivities, ...activities.data.docs]);
    setTotalPages(activities.data.pages);
  };

  const loadMoreActivities = () => {
    const nextPage = page + 1;
    if (totalPages === undefined || nextPage <= totalPages) {
      fetchActivities(nextPage);
      setPage(nextPage);
    }
  };

  const loadMoreButtonDisabled = () => {
    return totalPages === undefined || totalPages <= page;
  };

  const setErrorOpen = (idx: number, val: boolean) => {
    setExpandError({
      ...expandError,
      [idx]: val,
    });
  };

  const eventFriendlyName = (event: keyof typeof UserActivityEvents): string => {
    return UserActivityEvents[event];
  };

  useEffect(() => {
    loadMoreActivities();
  }, []);

  const surveyLink = (surveyId: string, surveyTitle: string) => {
    const urlPrefix = window.location.pathname.includes("admin") ? "/admin/surveys" : "/surveys";

    return <Link to={`${urlPrefix}/${surveyId}`}>{surveyTitle}</Link>;
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Event</TableCell>
              <TableCell>Summary</TableCell>
              <TableCell>Running Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="user-activity-table-body">
            {allActivities.map((activity, index) => {
              const hasFailed = activity.status === RedeemStatusEnum.FAILED;
              return (
                <>
                  <TableRow
                    className={`activity-row ${hasFailed ? "failed" : ""}`}
                    key={`${activity.event}${activity.date}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {convertDatetimeStringToReadable(activity.date.toString())}
                    </TableCell>
                    <TableCell>{eventFriendlyName(activity.event)}</TableCell>
                    <TableCell>
                      {activity.surveyTitle ? (
                        <>
                          {activity.summary} {surveyLink(activity.reference as string, activity.surveyTitle)}
                        </>
                      ) : (
                        activity.summary
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="cell-content">
                        {activity.runningTotal}{" "}
                        {activity.error ? (
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setErrorOpen(index, !expandError[index])}
                            className="expand-button"
                          >
                            {expandError[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        ) : null}
                      </div>
                    </TableCell>
                  </TableRow>
                  {activity.error && (
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={expandError[index]} timeout="auto" unmountOnExit>
                          <div style={{ margin: 1 }}>
                            Failure Details:
                            <div className="error-message"> {activity.error}</div>
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {loading ? (
        <LoadingButton sx={{ padding: "10px 70px", margin: "40px 0" }} className="load-more-button" loading />
      ) : (
        <Button
          className="load-more-button"
          variant="outlined"
          onClick={() => loadMoreActivities()}
          disabled={loadMoreButtonDisabled()}
          sx={{
            textTransform: "capitalize",
            border: "1px solid #2EBDEE",
            borderRadius: "4px",
            padding: "10px 70px",
            margin: "40px 0",
            "&:hover": {
              background: "#2EBDEE",
              color: "#fff",
              opacity: "0.7",
            },
            "&:disabled": {
              border: "1px solid #91a3a9",
            },
          }}
        >
          Load More
        </Button>
      )}
    </>
  );
};

export default UserActivityTab;
